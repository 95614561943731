import { memo } from 'react';

/* eslint-disable-next-line */
export interface UpsDownsIconProps {
  type?: 'ups' | 'downs' | undefined;
  background?: string; // fill
  height?: number;
  width?: number;
}

/***********************************
 * Usage

  <GVTreeIcon type="ups" />
  <GVTreeIcon type="downs" />

 ***********************************/
export function UpsDownsIcon({ type = undefined, background = 'transparent' }: UpsDownsIconProps) {
  switch (type) {
    case 'ups':
      return (
        <svg width="1em" height="1em" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99992 13.6666C10.6818 13.6666 13.6666 10.6818 13.6666 6.99992C13.6666 3.31802 10.6818 0.333252 6.99992 0.333252C3.31802 0.333252 0.333252 3.31802 0.333252 6.99992C0.333252 10.6818 3.31802 13.6666 6.99992 13.6666ZM10.8392 3.27896L7.05229 3.72417C6.91086 3.73988 6.85325 3.91273 6.95277 4.01225L8.15747 5.21695L3.28105 10.0934C3.22867 10.1457 3.22343 10.2348 3.28105 10.2924L4.01958 11.0309C4.07196 11.0833 4.16101 11.0886 4.21862 11.0309L9.09504 6.15452L10.2997 7.35922C10.3993 7.45874 10.5721 7.40113 10.5878 7.25971L11.033 3.47276C11.0435 3.368 10.9492 3.27372 10.8445 3.28419L10.8392 3.27896Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'downs':
      return (
        <svg width="1em" height="1em" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.00016 13.6666C10.6821 13.6666 13.6668 10.6818 13.6668 6.99992C13.6668 3.31802 10.6821 0.333252 7.00016 0.333252C3.31827 0.333252 0.333496 3.31802 0.333496 6.99992C0.333496 10.6818 3.31827 13.6666 7.00016 13.6666ZM11.0333 10.5216L10.5881 6.73463C10.5723 6.59321 10.3995 6.5356 10.3 6.63511L9.09527 7.83982L4.21886 2.96339C4.16648 2.91102 4.07744 2.90578 4.01982 2.96339L3.28127 3.70193C3.22889 3.75431 3.22367 3.84335 3.28127 3.90097L8.15771 8.77739L6.95298 9.98208C6.85347 10.0816 6.9111 10.2544 7.05252 10.2702L10.8395 10.7154C10.9442 10.7259 11.0385 10.6316 11.028 10.5268L11.0333 10.5216Z"
            fill="currentColor"
          />
        </svg>
      );
    default:
      return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.5 7C5.22386 7 5 7.22386 5 7.5V8.5C5 8.77614 5.22386 9 5.5 9H10.5C10.7761 9 11 8.77614 11 8.5V7.5C11 7.22386 10.7761 7 10.5 7H5.5Z"
            fill="currentColor"
          />
        </svg>
      );
  }
}

export const GVUpsDownsIcon = memo(UpsDownsIcon);
