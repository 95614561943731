/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { sendDataToSubscriber } from '../hooks/tabkey/common-one-way-data.hook';
import { PAGE_TRANSACTION, usePageDataUXState } from '../state';

export function useSpinnerTransaction(
  tabKey: string,
  isLoading: boolean,
  isError: boolean,
  tranKey = PAGE_TRANSACTION
) {
  const [pageDataUX] = usePageDataUXState(tabKey, tranKey);

  useEffect(() => {
    if (pageDataUX) {
      sendDataToSubscriber(tabKey, tranKey, { isLoading, isError });
    }
  }, [pageDataUX, isLoading, isError]);

  return pageDataUX;
}

export function clearSpinnerTransaction(tabKey: string, tranKey = PAGE_TRANSACTION) {
  sendDataToSubscriber(tabKey, tranKey, { isLoading: false, isError: false });
}
