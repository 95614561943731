export function pipeAssetStatus(value: string): string {
  const status = value.toUpperCase();

  switch (status) {
    // good
    case 'UNACCEPTABLE':
      return 'gv_status type-unacceptable';
    case 'UNSATISFACTORY':
      return 'gv_status type-unsatisfactory';
    case 'SATISFACTORY':
      return 'gv_status type-satisfactory';
    case 'GOOD':
      return 'gv_status type-good';
    // severity
    case 'CRITICAL':
      return 'gv_status type-critical';
    case 'HIGH':
      return 'gv_status type-high';
    case 'MEDIUM':
      return 'gv_status type-medium';
    case 'LOW':
      return 'gv_status type-low';
  }

  return 'gv_status type-off';
}
