export enum AutomaticType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum RunningType {
  OFFLINE = 'OFFLINE',
  RUNNING = 'RUNNING',
  IDLE = 'IDLE',
}
