/**
 * analysis model type
 */
export enum ModelType {
  RULE = 'Rule Base Model',
  MULTI = 'Multi Variation Model',
  DOMAIN = 'Domain Base Model',
  SOUND = 'Sound',
}

export enum ModelGroupType {
  MERGED = 'MERGED',
  REVISION = 'REVISION',
}

/**
 * analysis model role
 */
export enum ModelRoleType {
  HEALTH = 'HEALTH',
  RUL = 'RUL',
  VIBRATION = 'VIBRATION',
}

export enum ModelCustomPropGroupType {
  ML_TRAINING_INFO = 'ML_MODEL_INFO_OPTION',
  ML_MODEL_TRAINING = 'ML_MODEL_TRAINING_OPTION',
  MVA_TRAINING_INFO = 'MVA_MODEL_INFO_OPTION',
  MVA_MODEL_TRAINING = 'MVA_MODEL_TRAINING_OPTION',
  CURRENT = 'CURRENT_MODEL_OPTION',
  FC_COM = 'VIBRATION_MODEL_COM_OPTION',
  FC_VEL = 'VIBRATION_MODEL_VEL_OPTION',
  FC_ACC = 'VIBRATION_MODEL_ACC_OPTION',
  FC_ENV = 'VIBRATION_MODEL_ENV_OPTION',
  RUL = 'RUL_MODEL_OPTION',
}

export enum AnalysisModelType {
  MACHINE_LEARNING_AI = 'ML',
  VIBRATION = 'VIBRATION',
  STATISTICAL = 'MVA',
  CURRENT_IMBALANCE = 'CURRENT',
  RUL = 'REGRESSION',
}

export enum ModelParamType {
  PARAMETER = 'Parameter',
  FEATURE = 'Feature',
}

export enum TrainingFieldType {
  TRAINING_PERIOD = 'trainingPeriod',
  EXPRESSION = 'expression',
  MODEL_INFO = 'modelInfo',
  NORMALIZER = 'normalizer',
}

export const ModelHealthTrendLevel = {
  LOW: { id: 'LOW', name: 'Low', averageSize: 10 },
  MODERATE: { id: 'MODERATE', name: 'Medium', averageSize: 50 },
  HIGH: { id: 'HIGH', name: 'High', averageSize: 100 },
  USERDEFINE: { id: 'USERDEFINE', name: 'Customize', averageSize: undefined },
};
export type MODEL_HEALTH_TREND_LEVEL = (typeof ModelHealthTrendLevel)[keyof typeof ModelHealthTrendLevel];

export const ModelSourceWeightLevel = {
  LOW: { id: 'LOW', name: 'Low', averageSize: 0.25 },
  MEDIUM: { id: 'MEDIUM', name: 'Medium', averageSize: 1 },
  HIGH: { id: 'HIGH', name: 'High', averageSize: 4 },
  CUSTOMIZE: { id: 'CUSTOMIZE', name: 'Customize', averageSize: undefined },
};
export type MODEL_SOURCE_WEIGHT_LEVEL = (typeof ModelSourceWeightLevel)[keyof typeof ModelSourceWeightLevel];

export enum TrendModelType {
  MERGED = 'MERGED',
  REVISION = 'REVISION',
}
