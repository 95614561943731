import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';
import { AppMenuState } from '../../state';

/**
 * Use None UI
 */
const setModalMaksSubject = new BehaviorSubject<any>(undefined);
const setModalMaskBridge = {
  sendPayload: (payload: boolean) => setModalMaksSubject.next(payload),
  getPayload: () => setModalMaksSubject.asObservable(),
};

export function setModalMask(modalMaskStatus: boolean) {
  // console.log('>>> [iframe-child: modal mask]', modalMaskStatus);
  setModalMaskBridge.sendPayload(modalMaskStatus);
}

export function useModalMask() {
  const [, setCurrentModalMaskStatus] = useAtom(AppMenuState.modalMask);

  useEffect(() => {
    const subscription = setModalMaskBridge.getPayload().subscribe((modalMaskStatus: boolean) => {
      setTimeout(() => setCurrentModalMaskStatus(modalMaskStatus), modalMaskStatus ? 50 : 150);
    });
    return () => subscription.unsubscribe();
  }, []);
}
