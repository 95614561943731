const SMALL_WORDS =
  /\b(?:an?d?|a[st]|because|but|by|en|for|i[fn]|neither|nor|o[fnr]|only|over|per|so|some|tha[tn]|the|to|up|upon|vs?\.?|versus|via|when|with|without|yet)\b/i;
const TOKENS = /[^\s:–—-]+|./g;
const WHITESPACE = /\s/;
const IS_MANUAL_CASE = /.(?=[A-Z]|\..)/;
const ALPHANUMERIC_PATTERN = /[A-Za-z0-9\u00C0-\u00FF]/;

export function titleCase(value: string) {
  return value ? value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase() : '';
}

export function camelCaseToSentence(text: any) {
  const convertText = text?.replace(/([A-Z])/g, ' $1');
  const word = convertText?.charAt(0)?.toUpperCase() + convertText?.slice(1);
  return word;
}
