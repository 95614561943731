import { memo } from 'react';

/* eslint-disable-next-line */
export interface AlarmCausedParameterToggleIconProps {
  type: 'each' | 'merge' | '';
  color?: string; // stroke
  background?: string; // fill
  height?: number;
  width?: number;
}

/***********************************
 * Usage

  <GVAlarmCausedParameterToggleIcon type="each" />
  <GVAlarmCausedParameterToggleIcon type="merge" />

 ***********************************/
export function AlarmCausedParameterToggleIcon({
  type = '',
  // width = 16,
  // height = 18,
}: AlarmCausedParameterToggleIconProps) {
  switch (type) {
    case 'each':
      return (
        // <svg width={width} height={height} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        //   <path
        //     fillRule="evenodd"
        //     clipRule="evenodd"
        //     d="M7.99998 5.40896L2.81758 8.00016L7.99998 10.5914L13.1824 8.00016L7.99998 5.40896ZM8.32431 4.35488C8.12014 4.2528 7.87982 4.2528 7.67565 4.35488L1.03376 7.67583C0.766497 7.80946 0.766497 8.19086 1.03376 8.32449L7.67565 11.6454C7.87982 11.7475 8.12014 11.7475 8.32431 11.6454L14.9662 8.32449C15.2335 8.19086 15.2335 7.80946 14.9662 7.67583L8.32431 4.35488Z"
        //     fill="currentColor"
        //   />
        // </svg>
        <svg width={16} height={16} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M1 12.8125V12.0625C1 11.959 1.08395 11.875 1.1875 11.875H14.8125C14.916 11.875 15 11.959 15 12.0625V12.8125C15 12.916 14.916 13 14.8125 13H1.1875C1.08395 13 1 12.916 1 12.8125ZM8 3.14587L2.81238 5.9998L8 8.85372L13.1876 5.9998L8 3.14587ZM8 2C8.15494 2 8.30986 2.03233 8.42739 2.09698L14.6338 5.51137C15.1221 5.78 15.1221 6.21958 14.6338 6.4882L8.42739 9.90261C8.30986 9.96727 8.15494 9.99959 8 9.99959C7.84506 9.99959 7.69014 9.96727 7.57261 9.90261L1.36622 6.4882C0.877938 6.21958 0.877938 5.78002 1.36622 5.51137L7.57263 2.09698C7.69016 2.03233 7.84506 2 8 2Z"/>
        </svg>
        
      );
    case 'merge':
      return (
        <svg width={16} height={18} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.09011 7.02015L1.59631 7.76705C1.35063 7.88989 1.35063 8.24049 1.59631 8.36334L7.70188 11.4161C7.88957 11.51 8.11048 11.51 8.29817 11.4161L14.4037 8.36334C14.6494 8.24049 14.6494 7.88989 14.4037 7.76705L12.9099 7.02015L11.7549 7.59766L12.69 8.06519L8.00003 10.4102L3.31008 8.06519L4.24514 7.59766L3.09011 7.02015Z"
            fill="currentColor"
          />
          <path
            d="M3.09011 9.8899L1.59631 10.6368C1.35063 10.7596 1.35063 11.1102 1.59631 11.2331L7.70188 14.2859C7.88957 14.3797 8.11048 14.3797 8.29817 14.2859L14.4037 11.2331C14.6494 11.1102 14.6494 10.7596 14.4037 10.6368L12.9099 9.8899L11.7549 10.4674L12.69 10.9349L8.00003 13.2799L3.31008 10.9349L4.24514 10.4674L3.09011 9.8899Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00003 2.68143L3.23609 5.0634L8.00003 7.44537L12.764 5.0634L8.00003 2.68143ZM8.29817 1.71247C8.11048 1.61863 7.88957 1.61863 7.70188 1.71247L1.59631 4.76526C1.35063 4.8881 1.35063 5.2387 1.59631 5.36154L7.70188 8.41433C7.88957 8.50817 8.11048 8.50817 8.29817 8.41433L14.4037 5.36154C14.6494 5.2387 14.6494 4.8881 14.4037 4.76526L8.29817 1.71247Z"
            fill="currentColor"
          />
        </svg>
      );
    default:
      return <span>x</span>;
  }
}

export const GVAlarmCausedParameterToggleIcon = memo(AlarmCausedParameterToggleIcon);
