/* eslint-disable @typescript-eslint/no-explicit-any */
import { atom, useAtom } from 'jotai';
import { useUnmount } from 'ahooks';
import { assignIn, isPlainObject } from 'lodash';

import { getRandomId } from '../../utilities/random-generator';

export interface PageState {
  handleKey: string;
}

/**
 * Page 안에 있는 모든 컴포넌트들끼리 공유하고 싶을 때, page, widget 등
 */
export function getPageSate(): any {
  if (!(window as any).GV_STATE_pageState) {
    (window as any).GV_STATE_pageState = {};
  }
  return (window as any).GV_STATE_pageState;
}

export function PageState<T>(tabKey: string, eventKey: string, defaultValue: T | null = null) {
  const pageStateAtom = atom<T | null>(defaultValue);
  const pageStateHandle = atom(
    (get) => get(pageStateAtom),
    (_get, set, state: T | null) => {
      set(pageStateAtom, isPlainObject(state) ? assignIn(state, { handleKey: getRandomId() }) : state);
    }
  );

  if (!getPageSate()[tabKey]) {
    getPageSate()[tabKey] = { [eventKey]: pageStateHandle };
  }

  if (!getPageSate()[tabKey][eventKey]) {
    getPageSate()[tabKey][eventKey] = pageStateHandle;
  }
  return getPageSate()[tabKey][eventKey];
}

export function usePageState<T>(tabKey: string, eventKey: string, defaultValue: T | null = null, isUnmount = false) {
  useUnmountPageState(tabKey, eventKey, isUnmount);
  return useAtom<any, any, any>(PageState<T>(tabKey, eventKey, defaultValue));
}

export function removePageState(tabKey: string, eventKey: string) {
  if (getPageSate()[tabKey]) {
    delete getPageSate()[tabKey][eventKey];
  }
}

export function useUnmountPageState(tabKey: string, eventKey: string, isUnmount = false) {
  useUnmount(() => {
    if (isUnmount) {
      removePageState(tabKey, eventKey);
    }
  });
}
