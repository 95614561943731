/* eslint-disable @typescript-eslint/no-explicit-any */

export interface IHealthCriteria {
  lastSelectedValue: string | undefined;
  selectedItems: [{
    tabKey: string;
    value: string;
  }] | undefined;
}

const SESSION_KEY = {
  HEALTH_CRITERIA: 'healthCriteria',
};

export function initSessionConfig() {
  initHealthCriteria();
}

export function getFromSS(key: string) {
  try {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  } catch (e) {
    console.error('> getFromSS error from sessionStorage:', e);
  }
}

export function saveToSS(key: string, value: any) {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error('> saveToSS error from sessionStorage:', e);
  }
}

function initHealthCriteria() {
  const value = {
    lastSelectedValue: undefined,
    selectedItems: undefined,
  };
  saveToSS(SESSION_KEY.HEALTH_CRITERIA, value);
}


// -------------------------------------------------------------------------
//
// interface
//
// -------------------------------------------------------------------------

export function getHealthCriteriaFromSS(): IHealthCriteria {
  return getFromSS(SESSION_KEY.HEALTH_CRITERIA);
}

export function setHealthCriteriaToSS(tabKey: string, value: string) {
  const item = getHealthCriteriaFromSS();
  const selectedItems = item?.selectedItems?.filter((d: any) => d.tabKey !== tabKey) || []; // 기존 tabKey 가 있으면 삭제
  const newSelectedItems = [...selectedItems, ...[{tabKey, value}]]; // 현재 선택되 화면의 tabKey, health criteria 추가
  const newItem = {
    lastSelectedValue: value,
    selectedItems: newSelectedItems
  };
  return saveToSS(SESSION_KEY.HEALTH_CRITERIA, newItem);
}

export function getLastHealthCriteriaFromSessionStorage(tabKey: string) {
    const item = getHealthCriteriaFromSS();
    const findItem = item?.selectedItems?.find((d: any) => d.tabKey === tabKey);
    return findItem?.value || item?.lastSelectedValue;
}
