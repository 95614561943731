/* eslint-disable no-empty */
import { notification } from 'antd';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import { Observable, Observer } from 'rxjs';

import { encodeQueryString } from '../utilities/query-util';
import { logout } from '../iframe/commands/unauthorized.hook';
import { redirectInvalidLicense, redirectInvalidLicenseForMicroApp } from '../auth/license';
import { apiRequestTimeout } from '../config/config';

// sample url: https://jsonplaceholder.typicode.com/users
interface RequestArgs {
  method: HttpMethod;
  url: string;
  queryParam?: any;
  payload?: any;
}

enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

// frontend ajax call interceptor
// (function () {
//   const origOpen = XMLHttpRequest.prototype.open;
//   XMLHttpRequest.prototype.open = function () {
//     console.log('request started:', arguments);
//     this.addEventListener('load', function () {
//       console.log('request completed!');
//       console.log(this.readyState); //will always be 4 (ajax is completed successfully)
//       console.log(this.responseText); //whatever the response was
//     });
//     origOpen.apply(this, arguments);
//   };
// })();

export class HttpService {
  private httpClient!: AxiosInstance;
  private cancelTokenSource!: CancelTokenSource;
  private options!: AxiosRequestConfig | undefined | null;
  private completed!: boolean;
  private CLEAR_BEFORE_MESSAGE_TIME = 3000;
  private beforeMessage!: string;
  private beforeTimeout!: any;

  async get<T>(url: string, queryParam?: any, options?: AxiosRequestConfig, payload?: any): Promise<T> {
    this.setOptions(options);
    return this.executeRequest<T>({
      method: HttpMethod.GET,
      url: encodeQueryString(url),
      queryParam,
      payload,
    });
  }

  async post<T>(url: string, payload: any, options?: AxiosRequestConfig): Promise<T> {
    this.setOptions(options);
    return this.executeRequest<T>({
      method: HttpMethod.POST,
      url,
      payload,
    });
  }

  async put<T>(url: string, payload: any, options?: AxiosRequestConfig): Promise<T> {
    this.setOptions(options);
    return this.executeRequest<T>({
      method: HttpMethod.PUT,
      url,
      payload,
    });
  }

  async patch<T>(url: string, payload: any, options?: AxiosRequestConfig): Promise<T> {
    this.setOptions(options);
    return this.executeRequest<T>({
      method: HttpMethod.PATCH,
      url,
      payload,
    });
  }

  async delete<T>(url: string, payload?: any, options?: AxiosRequestConfig): Promise<T> {
    this.setOptions(options);
    return this.executeRequest<T>({
      method: HttpMethod.DELETE,
      url,
      payload,
    });
  }

  /**
   * 가급적이면 사용하지 않는다.
   * react-query와 async~await 방식을 사용한다.
   */
  rxGet<T>(url: string, queryParam?: any, options?: AxiosRequestConfig): Observable<T> {
    this.setOptions(options);
    return this.rxExecuteRequest<T>({ method: HttpMethod.GET, url, queryParam });
  }

  rxPost<T>(url: string, payload: any, options?: AxiosRequestConfig): Observable<T> {
    this.setOptions(options);
    return this.rxExecuteRequest<T>({
      method: HttpMethod.POST,
      url,
      payload,
    });
  }

  rxPut<T>(url: string, payload: any, options?: AxiosRequestConfig): Observable<T> {
    this.setOptions(options);
    return this.rxExecuteRequest<T>({
      method: HttpMethod.PUT,
      url,
      payload,
    });
  }

  rxPatch<T>(url: string, payload: any, options?: AxiosRequestConfig): Observable<T> {
    this.setOptions(options);
    return this.rxExecuteRequest<T>({
      method: HttpMethod.PATCH,
      url,
      payload,
    });
  }

  rxDelete<T>(url: string, payload?: any, options?: AxiosRequestConfig): Observable<T> {
    this.setOptions(options);
    return this.rxExecuteRequest<T>({
      method: HttpMethod.DELETE,
      url,
      payload,
    });
  }

  abort(forcely = false): void {
    if (!this.completed || forcely) {
      this.cancelTokenSource.cancel(`${this.options?.url} is aborted`);
      this.options = undefined;
    }
  }

  private setOptions(options: AxiosRequestConfig = { timeout: apiRequestTimeout() }): void {
    if (this.options) {
      this.options = { ...this.options, ...options };
    } else {
      this.options = options;
    }

    this.cancelTokenSource = axios.CancelToken.source();
    this.httpClient = axios.create({ ...options, cancelToken: this.cancelTokenSource.token });
    this.completed = false;
  }

  private executeRequest<T>(args: RequestArgs): Promise<T> {
    const { method, url, queryParam, payload } = args;
    let request: AxiosPromise<T>;
    switch (method) {
      case HttpMethod.GET:
        if (payload) {
          request = this.httpClient.get<T>(url, { params: queryParam, data: payload });
        } else {
          request = this.httpClient.get<T>(url, { params: queryParam });
        }
        break;
      case HttpMethod.POST:
        request = this.httpClient.post<T>(url, payload);
        break;
      case HttpMethod.PUT:
        request = this.httpClient.put<T>(url, payload);
        break;
      case HttpMethod.PATCH:
        request = this.httpClient.patch<T>(url, payload);
        break;
      case HttpMethod.DELETE:
        if (payload) {
          request = this.httpClient.delete<T>(url, { data: payload });
        } else {
          request = this.httpClient.delete<T>(url);
        }
        break;
    }

    return request
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error: AxiosError | Error) => {
        this.abort(true);
        if (axios.isAxiosError(error)) {
          if (error?.code === 'ECONNABORTED') {
            // ex) axios "timeout" 설정에 걸렸을 경우 발생
            console.log('> ECONNABORTED error:', url, error);
            this.showNotification(error.code, `${error}`);
            return;
          }

          // license 호출인 경우는 root로 이동을 한다.
          // 307: Temporary Redirect, https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/307, https://kinsta.com/knowledgebase/307-redirect/
          if (error.response?.status === 307 || error?.response?.data.statusCode === 307) {
            const errorMessage = error.response?.data?.message;
            // for portal
            redirectInvalidLicense(errorMessage);
            // for micro-app
            redirectInvalidLicenseForMicroApp(errorMessage);
            return;
          }

          if (error.response) {
            const data: any = error.response?.data || {};
            if (data.statusCode) {
              this.showNotification(`[${data.statusCode}] ${data.error}`, data.message);
              this.checkAuthorized(data.statusCode);
            } else if (error.response?.status) {
              // check 401 status: unauthorized
              if (this.checkAuthorized(error.response.status)) {
                this.showNotification('Unauhtorized', 'Redirect login page');
                return;
              }

              /**
               * status 400 이면서, 서버 에러 코드가 GB로 시작하지 않으면 비지니스별 약속된 예외 사항이므로,
               * 공통 에러 팝업을 출력하지 않고 throw error
               */
              if (
                error.response?.status === 400 ||
                (error.response?.data?.code && error.response?.data?.code.indexOf('GB') === -1)
              ) {
                console.log('> error 400 status:', url, error.response?.data);
                throw error;
              }

              if (error.response?.statusText) {
                if ('string' === typeof data) {
                  this.showNotification(`${error.response.statusText}`, data);
                } else {
                  this.showNotification(`${error.response.status}`, error.response.statusText);
                }
              }
            } else {
              if ('string' === typeof data) {
                console.log(`> unknown error-1: ${data}`, '');
                // this.showNotification(`${data}`, '');
              }
            }

            if (data && data.statusCode) {
              console.log(`> error code [${data.statusCode}]:`, url, data.message);
            }
          }
        } else {
          // this.showNotification('Unknown Error', error.message);
          console.log('> unknown error-2:', url, error.message);
        }
        throw error;
      })
      .finally(() => {
        this.completed = true;
      });
  }

  private rxExecuteRequest<T>(args: RequestArgs): Observable<T> {
    const { method, url, queryParam, payload } = args;
    let request: AxiosPromise<T>;
    switch (method) {
      case HttpMethod.GET:
        request = this.httpClient.get<T>(url, { params: queryParam });
        break;
      case HttpMethod.POST:
        request = this.httpClient.post<T>(url, payload);
        break;
      case HttpMethod.PUT:
        request = this.httpClient.put<T>(url, payload);
        break;
      case HttpMethod.PATCH:
        request = this.httpClient.patch<T>(url, payload);
        break;
      case HttpMethod.DELETE:
        request = this.httpClient.delete<T>(url);
        break;
    }

    return new Observable<T>((observer: Observer<T>) => {
      request
        .then((response: AxiosResponse) => {
          observer.next(response.data);
        })
        .catch((error: AxiosError | Error) => {
          this.abort(true);
          if (axios.isAxiosError(error)) {
            if (error.response) {
              const data: any = error.response?.data || {};
              if (data.statusCode) {
                this.showNotification(`[${data.statusCode}] ${data.error}`, data.message);
                this.checkAuthorized(data.statusCode);
              } else if (error.response?.status) {
                // check 401 status: unauthorized
                if (this.checkAuthorized(error.response.status)) {
                  this.showNotification('Unauhtorized', 'Redirect login page');
                  return;
                }

                // status 400 이면서, 서버 에러 코드 GB로 시작하는 것이 없으면, 에러 보여주지 않음.
                if (
                  error.response?.status === 400 &&
                  error.response?.data?.code &&
                  error.response?.data?.code.indexOf('GB') === -1
                ) {
                  console.log('> error 400 status:', error.response?.data);
                  return;
                }

                if (error.response?.statusText) {
                  if ('string' === typeof data) {
                    this.showNotification(`${error.response.statusText}`, data);
                  } else {
                    this.showNotification(`${error.response.status}`, error.response.statusText);
                  }
                }
              } else {
                if ('string' === typeof data) {
                  this.showNotification(`${data}`, '');
                }
              }

              if (data && data.statusCode) {
                console.log(`[${data.statusCode}]: ${data.message}`);
              }
            }
          } else {
            this.showNotification('Unknown Error', error.message);
            console.log(error.message);
          }
          throw error;
        })
        .finally(() => {
          this.completed = true;
          observer.complete();
        });
      return () => this.abort();
    });
  }

  private checkAuthorized(statusCode: number) {
    if (statusCode === 401) {
      // first unauthorized in portal-web
      logout();
      // second iframe child: web-app
      logout(true);
      return true;
    }
    return false;
  }

  private showNotification(message: string, description: string): void {
    if (this.beforeTimeout) {
      try {
        clearTimeout(this.beforeTimeout);
      } catch (e) {}
    }

    if (this.beforeMessage !== message) {
      notification.error({
        message,
        description,
      });
    }
    this.beforeMessage = message;

    this.beforeTimeout = setTimeout(() => {
      this.beforeMessage = '';
    }, this.CLEAR_BEFORE_MESSAGE_TIME);
  }
}

export const httpService = new HttpService();
