export enum DataTypes {
  TRACE = 'TRACE',
  SCORE = 'SCORE'
}

/**
 * Rul 상태 타입
 */
export enum RulStatusTypes {
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  WATCH = 'WATCH',
  STABLE = 'STABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}

/**
 * Asset status type
 */
export enum AssetStatusTypes {
  UNACCEPTABLE = 'UNACCEPTABLE',
  UNSATISFACTORY = 'UNSATISFACTORY',
  SATISFACTORY = 'SATISFACTORY',
  GOOD = 'GOOD',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}
