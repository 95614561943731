/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { GlobalStateType } from '@grandview/shared';

import {
  useListenChangedGlobalState,
  useListenChangedGlobalStateByTabKey,
} from '../../iframe/commands/micro-app/change-global-state.command';
import { isFunction } from '../../utilities/helper/is-function';

/**
 * Global State 변경이 있을 때
 * 자동으로 refetch을 호출하는 hook
 */
export function useRefetchByChangedGlobalState(changedStateTypes: GlobalStateType[], refetch: () => void) {
  const changedGlobalState = useListenChangedGlobalState();

  useEffect(() => {
    if (
      !changedGlobalState?.id ||
      !isFunction(refetch) ||
      !changedStateTypes?.length ||
      !changedStateTypes.includes(changedGlobalState?.type)
    ) {
      return;
    }

    refetch();
  }, [changedGlobalState?.id]);

  return changedGlobalState;
}

/**
 * Global State 변경이 있을 때
 * alart modal 호출하는 hook
 */
export function useExcuteCallbackByChangedGlobalState(
  tabKey: string,
  changedStateTypes: GlobalStateType[],
  executeCallback: (type: GlobalStateType, payload: any) => void
) {
  const changedGlobalState = useListenChangedGlobalStateByTabKey(tabKey);

  useEffect(() => {
    if (
      !changedGlobalState?.id ||
      !isFunction(executeCallback) ||
      !changedStateTypes?.length ||
      !changedStateTypes.includes(changedGlobalState?.type)
    ) {
      return;
    }

    executeCallback(changedGlobalState?.type, changedGlobalState);
  }, [changedGlobalState?.id]);

  return changedGlobalState;
}

/**
 * Portal 레벨 & GlobalState
 */
export function useExcuteCallbackByChangedGlobalStateInPortal(
  changedStateTypes: GlobalStateType[],
  executeCallback: (payload?: any) => void
) {
  const changedGlobalState = useListenChangedGlobalState();

  useEffect(() => {
    if (
      !changedGlobalState?.id ||
      !isFunction(executeCallback) ||
      !changedStateTypes?.length ||
      !changedStateTypes.includes(changedGlobalState?.type)
    ) {
      return;
    }

    executeCallback(changedGlobalState?.payload);
  }, [changedGlobalState?.id]);

  return changedGlobalState;
}

export function useExcuteCallbackByChangedGlobalStateInMicroApp(
  changedStateTypes: GlobalStateType[],
  executeCallback: (payload?: any) => void
) {
  const changedGlobalState = useListenChangedGlobalState();

  useEffect(() => {
    if (
      !changedGlobalState?.id ||
      !isFunction(executeCallback) ||
      !changedStateTypes?.length ||
      !changedStateTypes.includes(changedGlobalState?.type)
    ) {
      return;
    }

    executeCallback(changedGlobalState?.payload);
  }, [changedGlobalState?.id]);

  return changedGlobalState;
}
