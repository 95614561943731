// json files
import en from './design-locale-en.json';
import ko from './design-locale-ko.json';
import ja from './design-locale-ja.json';
import cn from './design-locale-cn.json';

export const commonI18n = {
  en,
  ko,
  ja,
  cn,
};
