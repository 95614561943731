import { AlarmRuleThresholdType, ROLE } from '../model';
import { commonType } from './common-types.service';
import { commonStatus } from './statuses/common-status.service';

/**
 * alarm 의 threshold type 을 리턴
 * @param alarmRuleType
 * @param domain
 */
export function getThresholdType(alarmRuleType: string, domain: string): AlarmRuleThresholdType | undefined {
  if (!domain) {
    return undefined;
  } else if (alarmRuleType === 'TREND') {
    return AlarmRuleThresholdType.TREND;
  } else if (domain === 'PARAMETER') {
    return AlarmRuleThresholdType.PARAMETER;
  } else {
    return AlarmRuleThresholdType.HEALTH_INDEX;
  }
}

/**
 * alarm 의 threshold 상테명을 리턴
 * @param alarmRule
 */
export function getThresholdStatusLabel(alarmRule: any): string {
  // TODO: type 에 따라 commonStatus, commonType 을 분기해서 사용하고 있는데 이부분의 개선사항이 필요
  // TODO: 1. 분기를 하는 조건에 대한 기준이 정의되어 있지 않음. (api spec 으로 구분할수 없고 기회 문서 or 기타 문서에 정의 되어 있지 않음 - 게빌한 시림만 일고 있는 상황)
  // TODO: 2. commonStatus, commonType 을 통합하던지, api spec 의 postfix 로 commonStatus, commonType 을 구분하던지 어떤 방법이 필요한 상황
  const type = getThresholdType(alarmRule?.alarmRuleType, alarmRule?.domain);
  if (type === AlarmRuleThresholdType.HEALTH_INDEX) {
    return commonStatus.getTranslateLabel(AlarmRuleThresholdType.HEALTH_INDEX, alarmRule?.thresholdId);
  } else {
    const role = type === AlarmRuleThresholdType.TREND ? ROLE.ALARM_THRESHOLD_TREND : ROLE.ALARM_THRESHOLD_PARAMETER;
    return commonType.getTranslateLabel(role, alarmRule?.threshold);
  }
}
