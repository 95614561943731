import { atom, useAtom } from 'jotai';
import { IAlarmNotification, IType } from '../../model';

/**
 * Common cache
 */
export const CommonInfoState = {
  types_i18n: atom<IType[]>([]),
  customStatus: atom<any | undefined>(undefined),
  newNotification: atom<IAlarmNotification | undefined>(undefined),
};

export function useNewNotification() {
  return useAtom(CommonInfoState.newNotification);
}
