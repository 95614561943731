import { useEffect } from 'react';
import { useMemoizedFn } from 'ahooks';

import { usePageState } from '../web-page.state';
import { IAxisRange, IThreshold, IVibrationFFTThreshold } from '../../../model';

interface IChartActionState {
  yAxisRange?: {
    userRange?: IAxisRange;
    fitRange?: IAxisRange;
    hasUserRange: boolean;
    showFitButton: boolean;
    disabledFitButton: boolean;
  };
  threshold?: IThreshold;
  vibrationFFTThreshold?: IVibrationFFTThreshold;
}

interface ChartActionStateProps {
  chartActionState: IChartActionState;
  setTableActionState: any;
  setFitRange: any;
  setFitRangeWithUserRange: any;
  setUserRange: any;
  resetUserRange: any;
  setThreshold?: any;
  setVibrationFFTThreshold?: any;
}

export function useChartActionState<IChartActionState>(
  tabKey: string,
  componentKey: string,
  initValue?: IChartActionState,
  isUnmount = false
): ChartActionStateProps {
  const [chartActionState, setChartActionState] = usePageState<IChartActionState>(
    `${tabKey}-${componentKey}`,
    'chart-action-props',
    { ...defaultChartActionValue, ...initValue },
    isUnmount
  );

  useEffect(() => {
    if (chartActionState || !initValue) {
      return;
    }
    setChartActionState(initValue);
  }, [initValue]);

  return {
    chartActionState,
    setTableActionState: useMemoizedFn((actionState: IChartActionState) => {
      setChartActionState({
        ...chartActionState,
        ...actionState,
      });
    }),
    setFitRange: useMemoizedFn((value: IAxisRange) => {
      setChartActionState({
        ...chartActionState,
        yAxisRange: {
          ...chartActionState.yAxisRange,
          fitRange: value,
          disabledFitButton: !value?.min && !value?.max,
        },
      });
    }),
    setFitRangeWithUserRange: useMemoizedFn((value: IAxisRange) => {
      setChartActionState({
        ...chartActionState,
        yAxisRange: {
          ...chartActionState.yAxisRange,
          userRange: value,
          fitRange: value,
          hasUserRange: true,
          disabledFitButton: !value?.min && !value?.max,
        },
      });
    }),
    setUserRange: useMemoizedFn((value: IAxisRange) => {
      setChartActionState({
        ...chartActionState,
        yAxisRange: {
          ...chartActionState.yAxisRange,
          userRange: value,
          hasUserRange: true,
          disabledFitButton: false,
        },
      });
    }),
    resetUserRange: useMemoizedFn(() => {
      setChartActionState({
        ...chartActionState,
        yAxisRange: {
          ...chartActionState.yAxisRange,
          userRange: undefined,
          hasUserRange: false,
          disabledFitButton: false,
        },
      });
    }),
    setThreshold: useMemoizedFn((value: IThreshold) => {
      setChartActionState({
        ...chartActionState,
        threshold: value,
      });
    }),
    setVibrationFFTThreshold: useMemoizedFn((value: IVibrationFFTThreshold) => {
      setChartActionState({
        ...chartActionState,
        vibrationFFTThreshold: value,
      });
    }),
  };
}

const defaultChartActionValue: any = {
  userRange: undefined,
  fitRange: undefined,
  hasUserRange: false,
  showFitButton: false,
  disabledFitButton: false,
  yAxisRange: {
    userRange: undefined,
    fitRange: undefined,
    hasUserRange: false,
    showFitButton: false,
    disabledFitButton: false,
  },
  threshold: {
    targetValue: undefined,
    upperLimit: undefined,
    lowerLimit: undefined,
  },
};
