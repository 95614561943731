import { PLUGIN_INIT } from '../../model';
import { setMasterConfigByPluginName } from '../../config/config';
import { getRemoteDefinitions, loadDynamicRemoteModule } from '../dynamic-remote-module-nx.service';

export function initPlugins() {
  const remoteDefinitions = getRemoteDefinitions();
  if (!remoteDefinitions) {
    return;
  }

  const initPlugins = Object.keys(remoteDefinitions).map((pluginName: string) => loadDynamicRemoteModule(pluginName, PLUGIN_INIT));
  // ref: https://velog.io/@broccolism/Promise.all%EC%97%90%EC%84%9C-%EC%97%90%EB%9F%AC-%ED%95%B8%EB%93%A4%EB%A7%81%ED%95%98%EA%B8%B0
  return Promise.allSettled(initPlugins).then(
    (results: any[]) => {
      let status: string | undefined = undefined;
      results?.forEach((result: any) => {
        if (!result?.value || result?.status !== "fulfilled") {
          status = status ? status + ', ' + result?.reason?.message : result?.reason?.message;
          return;
        }

        // master config default function
        const defaultFn = result.value?.default;
        if (!defaultFn) {
          return;
        }
        const pluginConfigs = defaultFn();
        if (pluginConfigs?.pluginName) {
          // master config by id of reportlet must be re-loaded.
          setMasterConfigByPluginName(pluginConfigs?.pluginName, pluginConfigs);
          console.log('> [plugin] PRE LOADED plugin master config by id:', pluginConfigs?.pluginName, ', pluginConfigs:', pluginConfigs);
        }
      });
      return Promise.resolve(status ? status : 'ok');
    },
    (error: any) => {
      console.log('>>> [plugin] plugin initialization error:', error);
      return Promise.resolve('no');
    }
  );
}
