import { eval as evalExpression, parse } from 'expression-eval';
import { some, isNumber, isEmpty, isNaN, isArray, isObject } from 'lodash';
import deepEqual from 'lodash/isEqual';

import { $gv_chart_theme_color } from '@grandview/stylet';

import { getDayjs } from '../timezone/date-format';

export function customFixed(val: number, fixedLength = 2): string {
  if (undefined !== val) {
    if (0 === val - Math.floor(val)) {
      return val.toFixed(0);
    }
    return val.toFixed(fixedLength);
  }
  return '';
}

export function isNullOrUndefined<T>(obj: T | null | undefined): boolean {
  return typeof obj === 'undefined' || obj === null;
}

export function isUndefined<T>(obj: T | null | undefined): boolean {
  return typeof obj === 'undefined';
}

export function isNullOrNaN(value: any) {
  return value === null || isNaN(value);
}

export function isObjectValueNullOrNaN(value: any) {
  return some(value, (v: any) => {
    return isUndefined(v) || isNaN(v) || isEmpty(isNumber(v) ? String(v) : v);
  });
}

/**
 * ref: https://www.npmjs.com/package/expression-eval
 * lagacy code has unsafe-src
 */
export function expressionParse(expression: string, value: any) {
  const ast = parse(expression);
  return evalExpression(ast, { value });
}

export const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
export const fiveMinutes = 1000 * 60 * 5;
export const oneMinutes = 1000 * 60;

/**
 * WorkOrder status color
 * @param index
 * @returns가
 */
export function selectColorTheme(index: number) {
  const type = [
    [
      $gv_chart_theme_color.theme1,
      $gv_chart_theme_color.theme2,
      $gv_chart_theme_color.theme3,
      $gv_chart_theme_color.theme4,
      $gv_chart_theme_color.theme5,
      $gv_chart_theme_color.theme6,
      $gv_chart_theme_color.theme7,
      $gv_chart_theme_color.theme8,
      $gv_chart_theme_color.theme9,
      $gv_chart_theme_color.theme10,
    ],
  ];

  index = index + 1 > type.length ? index % type.length : index;
  return type[index];
}

export function selectColor(index: number) {
  const type = [
    $gv_chart_theme_color.theme1,
    $gv_chart_theme_color.theme2,
    $gv_chart_theme_color.theme3,
    $gv_chart_theme_color.theme4,
    $gv_chart_theme_color.theme5,
    $gv_chart_theme_color.theme6,
    $gv_chart_theme_color.theme7,
    $gv_chart_theme_color.theme8,
    $gv_chart_theme_color.theme9,
    $gv_chart_theme_color.theme10,
  ];

  index = index + 1 > type.length ? index % type.length : index;
  return type[index];
}

export function hexToRGBA(hex: string, alpha: number | never = 1): string {
  if (alpha > 1 || alpha < 0) {
    throw new Error('alpha is not correct!');
  }

  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export function getParentLocationPath(path: string) {
  let paths = path.split('/');
  paths = paths.slice(0, paths.length - 1);
  return paths.join(' / ');
}

// ref: https://stackoverflow.com/questions/53441292/why-downleveliteration-is-not-on-by-default
export function makeArrayKeys(size: number) {
  return Array.from(Array(size).keys());
}

export function deepEqualObject(obj1: any, obj2: any): boolean {
  return deepEqual(obj1, obj2);
}

export function objectDeepKeys(obj: any): any {
  return Object.keys(obj)
    .filter((key: string) => isObject(obj[key]) && !isArray(obj[key]))
    .map((key: string) => objectDeepKeys(obj[key]).map((k: string) => `${key}.${k}`))
    .reduce((x, y) => x.concat(y), Object.keys(obj))
    .filter((key: string) => !(isObject(obj[key]) && !isArray(obj[key])));
}

// ref: https://stackoverflow.com/questions/60586001/download-pdf-from-rest-api
// if POST api, it use responseType:'blob'
// pdf fileType is 'application/pdf'
export function downloadFile(fileData: any, fileName?: string, fileType = 'text/csv;charset=utf-8;', target = '_blank', dateFormat = 'YYYYMMDDHHmmss') {
  const blob = new Blob(['\ufeff' + fileData], { type: fileType });

  const name = fileName ?? getDayjs(new Date()).format(dateFormat);
  downloadBlobFile(blob, name, target);
}

export function downloadBlobFile(blob: any, fileName: string, target = '_blank') {
  const link: any = document.createElement('a');
  link.style.display = 'none';
  link.target = target;
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName;

  document.body.appendChild(link);
  // start download
  link.click();

  // To make this work on Firefox we need to wait
  setTimeout(() => {
    // clean up and remove the link
    link?.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
  }, 0);
}
