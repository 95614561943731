import { t } from 'i18next';
import { isObject } from 'lodash';

export function getTypesFilter(types: any, localeKey = 'TYPE') {
  return Object.keys(types).map((type: any) => {
    return {
      id: type,
      name: localeKey !== '' ? t(`${localeKey}.${types[type]}`) : types[type],
      i18n: localeKey !== '' ? `${localeKey}.${types[type]}` : types[type],
    };
  });
}

export function transferTypesToTableFilter(types: any, localeKey = 'TYPE') {
  return Object.keys(types).map((type: any) => {
    return {
      value: type,
      text: localeKey !== '' ? t(`${localeKey}.${types[type]}`) : types[type],
      i18n: localeKey !== '' ? `${localeKey}.${types[type]}` : types[type],
    };
  });
}

export function transferTypesToSelectOptions(types: any, localeKey = 'TYPE') {
  return Object.keys(types).map((type: any) => {
    let typeName = '';
    let extra = {};
    if (isObject(types[type])) {
      typeName = types[type]?.id;
      extra = { extra: types[type] };
    } else {
      typeName = types[type];
    }
    return {
      value: type,
      label: localeKey !== '' ? t(`${localeKey}.${typeName}`) : typeName,
      i18n: localeKey !== '' ? `${localeKey}.${typeName}` : typeName,
      ...extra,
    };
  });
}

export function transferDataToSelectOptions(results: any, localeKey = 'TYPE') {
  return (
    results &&
    results.map((data: any) => {
      return {
        value: data.id,
        label: localeKey !== '' ? t(`${localeKey}.${data.name}`) : data.name,
        i18n: localeKey !== '' ? `${localeKey}.${data.name}` : data.name,
      };
    })
  );
}
