import {
  getRealtimeFilterStateOnlyAfterChanged,
  getRealtimeFilterState,
  getHealthStatusState,
  getLocationFilterState,
  getDepthSelectedItemState,
  getSwitchRealtimeFilterState,
  getDisabledRealtimeFilterState,
  getRealtimeOptionsFilterState,
  getInitRealtimeState,
  getRealtimeCurrentState,
  getPeriodOptionsFilterState,
  getRuntimeIntervalState,
  getRefreshPageState,
  getSelectedDateTypeState,
  getChangedFilterState,
  getRealtimeBeforeState,
} from './web-realtime-filter.state';
import {
  getChartsListByTabKey,
  getModelsListByTabKey,
  getOriginSeriesByTabKey,
} from './asset-trend-detail/asset-trend-detail.state';
import { getCurrentPageEditModeState } from './web-edit-mode.state';
import { getCurrentPageDirtyFormState } from './web-dirty-form.state';
import { getPageSate } from './web-page.state';
import { getWidgetState } from './web-widget.state';
import { getUrlParamsState } from './web-url-params.state';
import { getPageContext } from './context/web-page.context';

/**
 * 반드시 remove filter를 등록하다. tab안의 page가 remove 될 때 제거한다.
 */
export function removeTabKeyStates(tabKey: string) {
  delete getHealthStatusState()[tabKey];
  delete getLocationFilterState()[tabKey];
  delete getDepthSelectedItemState()[tabKey];
  delete getSwitchRealtimeFilterState()[tabKey];
  delete getDisabledRealtimeFilterState()[tabKey];
  delete getRealtimeOptionsFilterState()[tabKey];
  delete getRealtimeFilterStateOnlyAfterChanged()[tabKey];
  delete getInitRealtimeState()[tabKey];
  delete getRealtimeCurrentState()[tabKey];
  delete getPeriodOptionsFilterState()[tabKey];
  delete getRuntimeIntervalState()[tabKey];
  delete getRefreshPageState()[tabKey];
  delete getRealtimeFilterState()[tabKey];
  delete getPageSate()[tabKey];
  delete getWidgetState()[tabKey];
  delete getUrlParamsState()[tabKey];
  delete getPageContext()[tabKey];
  delete getCurrentPageEditModeState()[tabKey];
  delete getCurrentPageDirtyFormState()[tabKey];
  delete getSelectedDateTypeState()[tabKey];
  delete getChangedFilterState()[tabKey];
  delete getRealtimeBeforeState()[tabKey];
  delete getModelsListByTabKey()[tabKey];
  delete getChartsListByTabKey()[tabKey];
  delete getOriginSeriesByTabKey()[tabKey];
}

export function removeAllStates(appName: string) {
  clearObject(getHealthStatusState(), appName);
  clearObject(getLocationFilterState(), appName);
  clearObject(getDepthSelectedItemState(), appName);
  clearObject(getSwitchRealtimeFilterState(), appName);
  clearObject(getDisabledRealtimeFilterState(), appName);
  clearObject(getRealtimeOptionsFilterState(), appName);
  clearObject(getRealtimeFilterStateOnlyAfterChanged(), appName);
  clearObject(getInitRealtimeState(), appName);
  clearObject(getRealtimeCurrentState(), appName);
  clearObject(getPeriodOptionsFilterState(), appName);
  clearObject(getRuntimeIntervalState(), appName);
  clearObject(getRefreshPageState(), appName);
  clearObject(getRealtimeFilterState(), appName);
  clearObject(getPageSate(), appName);
  clearObject(getWidgetState(), appName);
  clearObject(getUrlParamsState(), appName);
  clearObject(getPageContext(), appName);
  clearObject(getCurrentPageEditModeState(), appName);
  clearObject(getCurrentPageDirtyFormState(), appName);
  clearObject(getSelectedDateTypeState(), appName);
  clearObject(getChangedFilterState(), appName);
  clearObject(getRealtimeBeforeState(), appName);
  clearObject(getModelsListByTabKey(), appName);
  clearObject(getChartsListByTabKey(), appName);
  clearObject(getOriginSeriesByTabKey(), appName);
}

function clearObject(model: any, appName: string): any {
  Object.keys(model).forEach((key) => {
    if (key.indexOf(`${appName}.`) === 0) {
      delete model[key];
    }
  });
}
