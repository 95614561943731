export enum TimeRangeUnitType {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  YEARS = 'years'
}

export enum ContextTimeRangeUnit {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS'
}

export enum TimeUnit {
  SECONDS = 1,
  MINUTES = 60,
  HOURS = 3600
}
