import { usePageState } from '../../web-page.state';
import { ActionPayload } from '../../../../model';

/**
 * Select & CRUD State
 */
export const MANAGEMENT_PAGE_ACTION = 'management-page-action';

// // 주의) 화면을 전환하는 state는 3번째 파라미터가 false여야 한다.
export function useManagementPageAction(tabKey: string, setter?: (prev: ActionPayload<any>) => ActionPayload<any>) {
  const [actionType, setActionType] = usePageState<ActionPayload<any>>(tabKey, MANAGEMENT_PAGE_ACTION, null, false);
  return [actionType, (action: ActionPayload<any>) => setActionType(setter ?? action)];
}
