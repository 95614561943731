/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

export const getBrowserLang = () => {
  if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
    return undefined;
  }

  let browserLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
  browserLang =
    browserLang ||
    window.navigator.language ||
    (<any>window.navigator).browserLanguage ||
    (<any>window.navigator).userLanguage;

  if (browserLang.indexOf('-') !== -1) {
    browserLang = browserLang.split('-')[0];
  }

  if (browserLang.indexOf('_') !== -1) {
    browserLang = browserLang.split('_')[0];
  }

  console.log('> browserLang:', browserLang);
  if (browserLang !== 'en' && browserLang !== 'ko' && browserLang !== 'zh' && browserLang !== 'ja') {
    return 'en';
  }
  if (browserLang === 'zh') {
    return 'cn';
  }
  return browserLang;
};
