/* eslint-disable @typescript-eslint/no-explicit-any */
import { RcFile } from 'antd/lib/upload/interface';
import { isFunction } from 'lodash';
import { t } from 'i18next';

import { gvPODOApiPrefix } from '../config/config';
import { httpService } from '../ajax/http.service';
import { errorMessage } from '../message/status-message.service';

/**
 * Use AntD Upload component
 * <Upload
    accept="image/*"
    customRequest={uploadImage}
   >
    <Button icon={<CloudUploadOutlined />} disabled={!fileInfo.displayName}>
      Click to upload image
    </Button>
  </Upload>
 * ex) libs/web/builder/layout/src/lib/layout-builder/left-panel/image-list/image-upload-modal/image-upload-modal.tsx
 */
export function uploadImage(file: File, payload: any, type: string, onSuccess: any, onProgress?: any, onError?: any) {
  const formData = new FormData();
  formData.append('domain', type);
  // layout: displayName, description
  for (const key of Object.keys(payload)) {
    formData.append(key, payload[key]);
  }
  formData.append('file', file);

  upload(formData, onProgress)
    .then((result: any) => {
      if (onSuccess && isFunction(onSuccess)) {
        onSuccess(result);
      }
    })
    .catch((error: any) => {
      if (onError && isFunction(onError)) {
        onError(error);
      }
    });
}

async function upload(formData: FormData, onProgress?: any) {
  const url = `${gvPODOApiPrefix()}/images/upload`;
  const result: any = await httpService.post<any>(url, formData, {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: (event: any) => {
      onProgress({ percent: (event.loaded / event.total) * 100 });
    },
  });
  return result;
}

const imageCache: any = {};
export function setImageObjectIntoCache(imageId: string, imageObject: any) {
  imageCache[imageId] = imageObject;
}

export function getImageObjectFromCache(imageId: string) {
  return imageCache[imageId];
}

export function beforeUpload(file: RcFile) {
  const isJpgOrPng =
    file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/gif';
  if (!isJpgOrPng) {
    errorMessage(t('MANAGE.You can only upload JPEG/JPG/PNG/GIF file'));
    return false;
  }
  const is0M = file.size === 0;
  if (is0M) {
    errorMessage(t('MANAGE.Image is empty file'));
    return false;
  }
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    errorMessage(t('MANAGE.Image must smaller than 5MB'));
    return false;
  }
  return true;
}

// export async function getImageDomainWithSrc(imageList: any[]): Promise<IDomainImage[]> {
//   const promiseList = imageList?.map((image: any) => getImageById(image));
//   return Promise.all(promiseList).then((response: any) => {
//     return response;
//   });
// }
//
// async function getImageById(image: any) {
//   if (image?.src) {
//     return image;
//   }
//   const url = `${gvApiPrefix()}/images/load/id?id=${image.id}`;
//   const data = await httpService.get<any>(url, null, { responseType: 'blob' });
//   return {
//     ...image,
//     src: loadBlobToImage(data)
//   };
// }
//
// function loadBlobToImage(blob: Blob): Promise<any> {
//   return new Promise((resolve, reject) => {
//     if (!blob) {
//       reject();
//     }
//     const reader = new FileReader();
//     reader.readAsDataURL(blob);
//     reader.onload = () => {
//       resolve(reader.result);
//     };
//   });
// }
