export interface IFrameCommand {
  type: string;
  payload?: any;
}

export enum GV_IFRAME_COMMAND {
  INIT_IFRAME = 'INIT_IFRAME',
  INIT_MICRO_APP = 'INIT_MICRO_APP',
  ACTIVE_MICRO_APP = 'ACTIVE_MICRO_APP',
  UPDATE_ACTIVE_PAGE = 'UPDATE_ACTIVE_PAGE',
  REMOVE_ACTIVE_PAGE = 'REMOVE_ACTIVE_PAGE',
  LOAD_PAGE = 'LOAD_PAGE',
  SYNC_CONDITION = 'SYNC_CONDITION',
  PROPAGATE_UPDATED_GLOBAL_STATE = 'PROPAGATE_UPDATED_GLOBAL_STATE',
  UPDATE_GLOBAL_STATE = 'UPDATE_GLOBAL_STATE',
  SET_URL = 'SET_URL',
  CHANGE_DEFAULT_LANG = 'CHANGE_DEFAULT_LANG',
  LOGOUT = 'LOGOUT',
  REDIRECT_LICENSE = 'REDIRECT_LICENSE',
  MODAL_MASK = 'MODAL_MASK',
  FIRE_ACTION_FROM_PORTAL = 'FIRE_ACTION_FROM_PORTAL',
  CHECK_FORM_DIRTY = 'CHECK_FORM_DIRTY',
  // RESIZE_IFRAME = 'RESIZE_IFRAME',
  // BEFORE_UNLOAD = 'BEFORE_UNLOAD',
}
