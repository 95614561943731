import { GVIcon, CustomIconComponentProps } from '../antd-icon-generator';

const SvgAlarmSummary = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
    <path d="m832 924v36H64v-36c0-19.882 16.118-36 36-36h696c19.882 0 36 16.118 36 36ZM472 64h-48c-6.627 0-12 5.373-12 12v104c0 6.627 5.373 12 12 12h48c6.627 0 12-5.373 12-12v-104c0-6.627-5.373-12-12-12h0Zm-337.955 120.104c-3.071 0-6.142 1.172-8.485 3.515l-33.941 33.941c-4.686 4.686-4.686 12.284 0 16.97l73.539 73.539c2.343 2.343 5.414 3.515 8.485 3.515s6.142-1.172 8.485-3.515l33.941-33.941c4.686-4.686 4.686-12.284 0-16.97l-73.539-73.539c-2.343-2.343-5.414-3.515-8.485-3.515h0Zm673.955-29.982v61.879h61.878c-6.962-12.94-15.823-24.905-26.398-35.48-10.575-10.575-22.541-19.437-35.48-26.398m-59.999-89.781c.23 0 .461.007.694.02 54.766 3.11 106.707 26.258 145.697 65.248 38.99 38.99 62.138 90.931 65.248 145.697.391 6.889-5.087 12.695-11.987 12.695h-175.653c-19.882 0-36-16.118-36-36V76.348c0-6.667 5.421-12.007 12.001-12.007h0Zm122.769 294.063c-7.401 14.141-17.084 27.182-28.885 38.649-28.925 28.104-68.072 43.576-108.397 42.927-39.274-.632-76.125-16.077-104.141-43.678-28.949-28.521-45.347-67.663-45.347-108.302s15.811-78.771 44.52-107.48c11.093-11.093 23.605-20.246 37.121-27.313 3.929-2.055 6.36-6.153 6.36-10.587v-52.553c0-8.427-8.458-14.225-16.324-11.203-84.029 32.288-143.675 113.735-143.676 209.134 0 123.964 100.036 224.001 224 224.001 95.4 0 176.847-59.646 209.136-143.676 3.023-7.866-2.776-16.324-11.203-16.324h-52.556c-4.451 0-8.543 2.46-10.607 6.404Zm-114.77 217.596h-48c-6.627 0-12 5.373-12 12v172h-351.1v-172c0-6.627-5.373-12-12-12h-48c-6.627 0-12 5.373-12 12v172h-72.9v-184c0-33.512 6.547-65.982 19.459-96.511 12.487-29.523 30.379-56.053 53.178-78.852 22.799-22.799 49.329-40.691 78.852-53.178 27.02-11.428 55.562-17.871 85-19.2 6.43-.29 11.51-5.547 11.51-11.984v-48.043c0-6.784-5.621-12.255-12.4-11.997-4.504.172-8.983.436-13.437.792-12.954 1.035-25.69 2.841-38.163 5.373h0c-146.063 29.649-256 158.785-256 313.599v220c0 19.882 16.118 36 36 36h568c19.882 0 36-16.118 36-36v-208c0-6.627-5.373-12-12-12Z" />
  </svg>
);

export function ABAlarmSummaryIcon(props: CustomIconComponentProps) {
  return <GVIcon component={SvgAlarmSummary} {...props} />;
}
