import {
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { IPageInfo, IQueryFnPagingData } from '../../model/interface';

type QueryOptionType<
  TQueryFnData = unknown,
  TError = unknown,
  TData = unknown,
  TQueryKey extends QueryKey = QueryKey
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & {
  initialData?: () => undefined;
};

export function useQueryPaging<
  TContent = unknown,
  TQueryFnData = IQueryFnPagingData<TContent>,
  TError = unknown,
  TData = TContent,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKeys: TQueryKey,
  queryFn: QueryFunction<IQueryFnPagingData<TContent>, TQueryKey>,
  queryOptions: QueryOptionType<TQueryFnData, TError, TData, TQueryKey> & {
    convert?: (data: unknown) => TContent;
  }
): UseQueryResult<TData, TError> & {
  pageInfo?: IPageInfo;
} {
  const { select, convert, ...restQueryOptions } = queryOptions;

  const combindOptions: any = {
    keepPreviousData: true,
    ...(select ? { select: (data: any) => ({ ...data, content: select(data.content) }) } : {}),
    ...restQueryOptions,
  };

  const queryResult = useQuery<IQueryFnPagingData<TContent>, TError, IQueryFnPagingData<TContent>, TQueryKey>(
    queryKeys,
    async (context: QueryFunctionContext<TQueryKey, any>) => {
      const result = await queryFn(context);
      if (!result?.page?.first && result?.page?.last && isEmpty(result.content)) {
        result.page = { ...result.page, number: (result.page?.number ?? 0) - 1 };
      }
      if (result.content && convert && Array.isArray(result.content)) {
        result.content = result.content.map((data: any) => convert(data)) as unknown as TContent;
      }
      return result;
    },
    combindOptions
  );

  return {
    ...queryResult,
    data: queryResult?.data?.content,
    pageInfo: queryResult?.data?.page,
  } as any;
}
