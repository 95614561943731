import { endsWith, get, isEmpty, keys, startsWith } from 'lodash';

import { RequiredFilters, TableActionState } from '../../index';

import { fiveMinutes, isNullOrUndefined, twentyFourHoursInMs } from './utils';

// for reqce-query option if realtime or not
export function queryOptions(isRealTime = false, timeCycle = 10) {
  if (isRealTime) {
    return {
      staleTime: timeCycle * 1000,
      cacheTime: timeCycle * 1000,
    };
  } else {
    return {
      staleTime: twentyFourHoursInMs,
      cacheTime: fiveMinutes,
    };
  }
}

/**
 * url 파라미터 세팅 Only Dashboard
 *
 * @param originUrl
 * @param paramList
 * @param conditions
 */
export function toQueryString(originUrl: string, paramList: string[], conditions: any = {}) {
  let url = originUrl;
  if (conditions && !isEmpty(conditions)) {
    Object.keys(conditions).map((key: string) => {
      const value = conditions[key];
      if (!isNullOrUndefined(value)) {
        paramList.push(`${key}=${value}`);
      }
    });
  }
  url += `?${paramList.join('&')}`;
  return url;
}

/**
 * case-1) use it for browser location
 */
export function makeQueryString(initConditions: any, conditions: any, locationPrefix = 'podo') {
  let url: string | undefined;
  if (initConditions && !isEmpty(initConditions)) {
    const initList: string[] = [];
    Object.keys(initConditions).map((key: string) => {
      const value = initConditions[key];
      if (!isNullOrUndefined(value)) {
        initList.push(`${key}=${value}`);
      }
    });
    url = `${initList.join('&')}`;
  }

  if (conditions && !isEmpty(conditions)) {
    const conList: string[] = [];
    Object.keys(conditions).map((key: string) => {
      const value = conditions[key];
      if (!isNullOrUndefined(value)) {
        conList.push(`${key}=${value}`);
      }
    });
    if (url) {
      url += `&${conList.join('&')}`;
    } else {
      url = `${conList.join('&')}`;
    }
  }
  return url !== '' && url !== undefined && url !== null ? `${locationPrefix}?${url}` : `${locationPrefix}`;
}

/**
 * 기존 url 에 params 에 있는 pagination 내용을 queryString 형식으로 더해서 리턴 (pagination: size, page, sort)
 * 조회 api 를 post 방식으로 사용하는 경우에 pagination 내용을 payload 가 아닌 queryString 으로 보내야 하는 경우에 사용
 * ex) /parameters -> /parameters?page=0&size=20&sort=NAME
 *
 * @param url
 * @param params
 */
export function appendPageQueryString(url: string, params: any = {}) {
  const pageParam = { size: params?.size, page: params?.page, sort: params?.sort };
  return toQueryString(url, [], pageParam);
}

export function decodeQueryString(qs: string = window.location.search.substr(1)) {
  // expects qs to not have a ?
  // return if empty qs
  if (qs === '') return {};
  return qs.split('&').reduce((acc: any, pair: any) => {
    // skip no param at all a=1&b=2&
    if (pair.length === 0) return acc;
    const parts = pair.split('=');
    // fix params without value
    if (parts.length === 1) parts[1] = '';
    // for value handle multiple unencoded = signs
    const key = decodeURIComponent(parts[0]);
    const value = decodeURIComponent(parts.slice(1).join('='));
    acc[key] = value;
    return acc;
  }, {});
}

/**
 * Rest get 메소드 사용시 url의 queryString value 값을 특수문자 인코딩 처리
 * 처리전 : gv/api/assets?locationId=catalog_asset_01&name=\&
 * 처리후 : gv/api/assets?locationId=catalog_asset_01&name=%5C&
 * @param url url
 * @returns 인코딩 처리 된 url
 */
export function encodeQueryString(url: string): string {
  const { api, search } = parseUrl(url);
  let queryString: string | undefined;
  if (search) {
    queryString = `&${search}`
      // eslint-disable-next-line no-useless-escape
      .split(/(\&[\w.]+\=)/g)
      .map((str: any) => {
        if ((startsWith(str, '&') && endsWith(str, '=')) || isEmpty(str)) {
          return str;
        }
        return encodeURIComponent(str);
      })
      .join('')
      // eslint-disable-next-line no-useless-escape
      .split(/^\&/)[1];
  }

  return queryString ? `${api}?${queryString}` : api;
}

/**
 * url 에서 host, search 내용을 object 형식으로 변환
 * ex) gv/api/assets?locationId=catalog_asset_01&name=\&
 * @param url url
 * @returns {api: 'gv/api/assets', search: 'locationId=catalog_asset_01&name=\&'}
 */
export function parseUrl(url: string): any {
  const list: string[] = url.split('?');
  const api = list[0];
  const search = list.length > 1 ? list[1] : '';
  return { api, search };
}

// export function encodeQueryString(url: string): any {
//   const { api, search } = parseUrl(url);
//   const params = new URLSearchParams(search);
//   for (const [key, value] of params) {
//     params.set(key, encodeURIComponent(value));
//   }
//   return search ? `${api}?${params.toString()}` : api;
// }

// export function parseUrl(url: string): any {
//   const list: string[] = url.split('?');
//   const api = list[0];
//   const search = list.length > 1 ? `?${list[1]}` : '';
//   return { api, search };
// }

/*
requiredFilters = { externalFilters: ['assetId'] }
*/
export function enabledQuery(tableState: TableActionState, requiredFilters: any) {
  return !keys(requiredFilters).some((key: string) => {
    return get(requiredFilters, key).some((field: string) => {
      return isEmpty(get(tableState, [key, ...field.split('.')]));
    });
  });
}