import { usePageState } from './web-page.state';

export function useRefetchCommentState(tabKey: string, initValue: any = null) {
  return usePageState(tabKey, 'refetchComment', initValue);
}

export function useAppendCommentState(tabKey: string, initValue: any = null) {
  return usePageState(tabKey, 'appendComment', initValue);
}

export function useUpdateCommentState(tabKey: string, initValue: any = null) {
  return usePageState(tabKey, 'updateComment', initValue);
}

export function useDeleteCommentState(tabKey: string, initValue: any = null) {
  return usePageState(tabKey, 'deleteComment', initValue);
}
