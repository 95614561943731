export enum MenuType {
  MENU_VIEW = 'MENU_VIEW',
  MENU_LINK = 'MENU_LINK',
  TAB = 'MENU_TAB',
  DETAIL = 'MENU_DETAIL',
  HIDDEN = 'MENU_HIDDEN',
}
export enum MenuPermission {
  APP_MANAGE = 'PERM_SYSTEM_MANAGE_APP',
  ASSET_MANAGE = 'PERM_SYSTEM_MANAGE_ASSET',
}
