import { getURLSearchParam, updateRootLocation } from '../browser/location';
import { sendToHost } from '../iframe/communication/micro-app/iframe-child-to-host.sender';
import { GV_IFRAME_COMMAND } from '../model';

export function validateLicense(url: string = window.parent.document.location.search) {
  return url && (url.indexOf('license=expired') > 0 || url.indexOf('license=invalid') > 0);
}

export function isRedirectLicenseKey(url: string = window.parent.document.location.search) {
  return url?.indexOf('redirectLicenseKey=true') > 0 ? true : false;
}

export function redirectInvalidLicense(url: string) {
  const license = getURLSearchParam('license', url);
  const admin = getURLSearchParam('admin', url);

  // portal에서 history 업데이트된 상태이고, micro-app에서 보낸 url이 같은 url이라면 무시한다.
  if (license === getURLSearchParam('license') && admin === getURLSearchParam('admin')) {
    return;
  }

  updateRootLocation({
    license,
    admin,
  });
  window.location.reload();
}

export function redirectInvalidLicenseForMicroApp(url: string) {
  sendToHost({
    type: GV_IFRAME_COMMAND.REDIRECT_LICENSE,
    payload: {
      url,
    },
  });
}
