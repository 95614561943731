import { memo, useEffect, useState } from 'react';
import cn from 'classnames';

import './animation.scss';

/**
 * reference: https://animate.style/#documentation options
 */
/* eslint-disable-next-line */
export interface AnimationProps {
  run?: any;
  type?:
    | 'flash'
    | 'pulse'
    | 'heartBeat'
    | 'bounceIn'
    | 'bounceInDown'
    | 'fadeIn'
    | 'fadeInDown'
    | 'fadeOut'
    | 'fadeInUp'
    | 'slideInDown';
  repeat?: number;
  delay?: number; // 2, 3, 4, 5
  speed?: 'slow' | 'slower' | 'fast' | 'faster' | null;
  children?: any;
  className?: string;
  isAnimation?: boolean;
}

export function Animation({
  run,
  type = 'fadeInDown',
  repeat = 1, // ms unit
  delay = 0.5,
  speed = 'faster',
  className = 'gv_animation',
  isAnimation = true,
  children,
}: AnimationProps) {
  const [update, setUpdate] = useState<boolean>(true);

  // run은 변경된 value가 올 때 실행 된다.
  useEffect(() => {
    setUpdate(true);
    const timer = setTimeout(() => {
      if (timer) {
        clearTimeout(timer);
      }
      setUpdate(false);
    }, repeat * 1000 + 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [run]);

  return update && isAnimation ? (
    <span
      className={cn(
        'animate__animated',
        `animate__${type}`,
        `animate__repeat-${repeat}`,
        `animate__delay-${delay}s`,
        speed ? `animate__${speed}` : '',
        className
      )}
    >
      {children}
    </span>
  ) : (
    <span className={className}>{children} </span>
  );
}

export const GVAnimation = memo(Animation);
