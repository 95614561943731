/* eslint-disable @typescript-eslint/no-explicit-any */
export function getParentTreeNodeKey(key: any, tree: any): any {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentTreeNodeKey(key, node.children)) {
        parentKey = getParentTreeNodeKey(key, node.children);
      }
    }
  }
  return parentKey;
}
