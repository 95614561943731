export enum ColorType {
  NOT_AVAILABLE_START = '#333333',
  NOT_AVAILABLE_END = '#666666',
  UNACCEPTABLE_START = '#762941',
  UNACCEPTABLE_END = '#fc2454',
  UNSATISFACTORY_START = '#824949',
  UNSATISFACTORY_END = '#ffb335',
  SATISFACTORY_START = '#28516c',
  SATISFACTORY_END = '#2bb1df',
  GOOD_START = '#0a3b08',
  GOOD_END = '#80d123'
}

export enum StatusColorType {
  NOT_AVAILABLE = '#999999',
  UNACCEPTABLE = '#f21344',
  UNSATISFACTORY = '#ffa11b',
  SATISFACTORY = '#14bfdc',
  GOOD = '#39c218'
}

export enum SeverityColorType {
  NOT_AVAILABLE = '#999999',
  CRITICAL = '#f21344',
  HIGH = '#ffa11b',
  MEDIUM = '#14bfdc',
  LOW = '#39c218'
}

/**
 * Parameter radar chart color type
 */
export enum ParamRadarColorType {
  UNACCEPTABLE_START = '#d82870',
  UNACCEPTABLE_END = '#e10035',
  UNSATISFACTORY_START = '#f7a51f',
  UNSATISFACTORY_END = '#ff7f2c',
  SATISFACTORY_START = '#05bcb1',
  SATISFACTORY_END = '#06aabc',
  GOOD_START = '#75c21e',
  GOOD_END = '#2bbf38'
}

/**
 * Activity Status color type
 */
export enum ActivityColorType {
  ACTIVE = '#2e5bff',
  INACTIVE = '#6d7896',
  UNKNOWN = '#999999'
}
