export enum GV_ALARM_SEVERITY_STATUS_NAME {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  CRITICAL = 'CRITICAL',
}
export enum GV_ALARM_SEVERITY_STATUS_ID {
  NOT_AVAILABLE = 'alarm_status_not_available',
  LOW = 'alarm_status_low',
  MEDIUM = 'alarm_status_medium',
  HIGH = 'alarm_status_high',
  CRITICAL = 'alarm_status_critical',
}

export enum GV_HEALTH_SEVERITY_STATUS_NAME {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  UNACCEPTABLE = 'UNACCEPTABLE',
  UNSATISFACTORY = 'UNSATISFACTORY',
  SATISFACTORY = 'SATISFACTORY',
  GOOD = 'GOOD',
}
export enum GV_HEALTH_SEVERITY_STATUS_ID {
  NOT_AVAILABLE = 'health_status_not_available',
  UNACCEPTABLE = 'health_status_unacceptable',
  UNSATISFACTORY = 'health_status_unsatisfactory',
  SATISFACTORY = 'health_status_satisfactory',
  GOOD = 'health_status_good',
}

export enum GV_RUL_SEVERITY_STATUS_NAME {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  WATCH = 'WATCH',
  STABLE = 'STABLE',
}
export enum GV_RUL_SEVERITY_STATUS_ID {
  NOT_AVAILABLE = 'rul_status_not_available',
  DANGER = 'rul_status_danger',
  WARNING = 'rul_status_warning',
  WATCH = 'rul_status_watch',
  STABLE = 'rul_status_stable',
}

export enum GV_RUNNING_SEVERITY_STATUS_NAME {
  UNKNOWN = 'UNKNOWN',
  R = 'R',
  I = 'I',
}
export enum GV_RUNNING_SEVERITY_STATUS_ID {
  UNKNOWN = 'running_status_unknown',
  R = 'running_status_run',
  I = 'running_status_idle',
}
