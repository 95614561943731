// export enum InputType {
//   INPUT = 'input',
//   SELECT = 'select',
//   MULTI_SELECT = 'multiSelect',
// }

// use editable type for basic component
export enum ItemType {
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  TEXT_AREA = 'TEXT_AREA',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  SWITCH = 'SWITCH',
  PROGRESS_BAR = 'PROGRESS_BAR',
  TEXT_INFORMATION = 'TEXT_INFORMATION',
}

export enum SelectItemType {
  SELECT = 'SELECT',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
}
