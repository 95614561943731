// asset-health-heatmap-widget
export interface AssetHealthModel {
  id: string;
  name: string;
  path: string;
  type: string;
  health: AssetHealthScoreModel;
}

// health score
export interface AssetHealthScoreModel {
  activity: string;
  assetScore: number;
  modelName: string;
  status: {
    name: string; // Good
    id: string; // health_status_good
  };
  healthCriteria: string;
}

export const KPI_ROLE = {
  HEALTH: 'KPI_MAX_HEALTH',
  AVG_HEALTH: 'KPI_AVG_HEALTH',
  RUNNING: 'KPI_RUNNING_STATUS',
  RUL: 'KPI_RUL',
  ALARM: 'KPI_ALARM',
};
export type KPI_ROLE = typeof KPI_ROLE[keyof typeof KPI_ROLE];

export const KPI_STATUS = {
  HEALTH: 'HEALTH',
  RUL: 'RUL',
  ALARM: 'ALARM',
};
