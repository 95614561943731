import { useDebounceFn } from 'ahooks';
import { useEffect, useState } from 'react';

export function useWindowSize(wait = 500) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<any>({
    width: undefined,
    height: undefined,
  });
  const { run } = useDebounceFn(
    () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    { wait }
  );

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', run);
    // Call handler right away so state gets updated with initial window size
    run();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', run);
  }, []);

  return windowSize;
}
