import { HierarchySelectType, themeType } from '../types';
import { ILayoutLink, IPageLink } from './link.model';

export interface IDashboardPersistence {
  id?: string | null;
  name: string;
  code?: string;
  description?: string;
  publish?: boolean;
  config?: { theme?: string; icon?: string; hierarchy?: string; realtime?: string; healthStatus?: string, createTabType?: string, hideMenu?: string };
  widgets?: IWidgetPersistence[];
}

export interface IWidgetMasterGroup {
  id: string;
  name: string;
  widgets: IWidgetMaster[];
}

export interface IWidgetMaster {
  id: string;
  name: string;
  description: string | null;
  path: string;
  pluginName?: string;
  pluginPath?: string;
  config: IWidgetMasterConfig;
  type: {
    id: string;
    name: string;
  };
  images: string | string[];
}

export interface IWidgetMasterConfig {
  width: number;
  height: number;
  x?: number;
  y?: number;
  events?: any;
  enableLink?: boolean;
  links?: any[];
  enableLayout?: boolean;
  layouts?: any[];
  enableHierarchy?: boolean;
  hierarchySelectType?: string;
  hierarchies?: { type: string; id: string }[];
}

/**
 * DB Save Model
 * 최종 DB에 저장되는 모델
 */
export interface IWidgetPersistence {
  id?: string | null;
  name: string;
  config: IWidgetPersistenceConfig;
  master: IWidgetMaster | { id: string };
  role?: 'INSTANCE';
}

export interface IWidgetPersistenceConfig {
  width: number;
  height: number;
  x: number;
  y: number;
  events?: any;
  enableLink?: boolean;
  links?: any[];
  enableLayout?: boolean;
  layouts?: any[];
  enableHierarchy?: boolean;
  hierarchySelectType?: string;
  hierarchies?: { type: string; id: string }[];
}

/**
 * Instance Model
 * 인스턴스성 모델
 */
export enum GV_BUILDER_WIDGET_STATUS {
  SAVED = 'SAVED',
  NEW = 'NEW',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface IWidgetInstance {
  widgetPersistence: IWidgetPersistence | any;
  createWidgetPath: string;
  pluginName?: string; // ex) apm-default-widgets
  // 'save', 'new', 'delete'
  widgetStatus: string;
  compareInstances?: IWidgetInstance[];
  config?: IWidgetProps;
  // instance model
  i: string;
  name: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW?: number;
  minH?: number;
}

export interface IWidgetLayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface IHierarchyProperty {
  type: 'hierarchy' | 'asset';
  id: string;
  extra?: any;
}

export interface IWidgetProps {
  widgetId?: string;
  height?: number | string | any;
  width?: number | string | any;
  theme?: themeType;
  editInstanceId?: string; // use in dashboard-builder
  widgetLayoutSize?: IWidgetLayout;
  resizeWidgetLayout?: (reszie: IWidgetLayout | undefined) => void;
  title?: string;
  events?: { in?: string; out?: string };
  enableLayout?: boolean;
  layouts?: ILayoutLink[];
  enableLink?: boolean;
  links?: IPageLink[];
  enableHierarchy?: boolean;
  hierarchySelectType?: HierarchySelectType;
  hierarchies?: IHierarchyProperty[];
  urlParams?: any;
  transactionKey?: string; // spinner를 하나만 표현하기 위한 transaction key
}

export interface ITableWidgetProps extends IWidgetProps {
  tabKey: string;
  tableId?: string;
  title?: string;
  singleSelection?: boolean; // single selection 여부
  checkWhenSelectRow?: boolean; // 체크박스 컬럼 변경 할때만 onSelectChange 실행 여부 (기본값은 row 전체 선택시 onSelectChange 실행됨)
  onCheckRow?: any; // row checkbox 선택 및 해제시 실행 (상위 컴포넌트에서 사용)
  onSelectRow?: any; // row 선택 시 실행 (상위 컴포넌트에서 사용)
  showSizeChanger?: boolean; // pagination > page size selectbox 사용 여부
  showQuickJumper?: boolean; // pagination > go to page 사용 여부
  disabledRowSelection?: boolean; // rowSelection 사용 유무
  disabledPagination?: boolean; // pagination 사용 유무
  visibleTableColumns?: string[]; // 보여질 columns dataIndex 배열
  hideTableColumns?: any[]; // 감출 columns dataIndex 배열
  visibleFilterColumns?: string[]; // filter columns dateIndex
  showTableTitle?: boolean; // <GVTableCount> 타이틀 표시 여부
  // pagination?: TablePaginationConfig | false; // table pagination property
}
