export enum PageCreateType {
  SINGLE = 'single',
  MUTLI = 'multi',
}

export enum SideMenuType {
  SINGLE = 'single',
  DYNAMIC = 'dynamic',
}

export enum PageType {
  PARAMETER = 'PARAMETER',
  PARAMETER_GROUP = 'PARAMETER_GROUP',
  PARAMETER_DETAIL = 'PARAMETER_DETAIL',
  PARAMETER_GROUP_DETAIL = 'PARAMETER_GROUP_DETAIL',
}

export enum PageHeaderType {
  LIST = 'list',
  DETAIL = 'detail',
}
