import { memo } from 'react';

/* eslint-disable-next-line */
export interface SmileyIconProps {
  type: 'smiley' | 'smiley-nervous' | 'smiley-sad' | 'smiley-blank' | 'x-circle' | '';
  height?: number;
  width?: number;
}

/***********************************
 * Usage

  <GVSmileyIcon type="smiley" />
  <GVSmileyIcon type="smiley-sad" />
  <GVSmileyIcon type="smiley-nervous" />
  <GVSmileyIcon type="smiley-blank" />
  <GVSmileyIcon type="x-circle" />

 ***********************************/
export function SmileyIcon({ type = '', height = 32, width = 32 }: SmileyIconProps) {
  switch (type) {
    case 'smiley':
      return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 13C2 6.92487 6.92487 2 13 2C19.0751 2 24 6.92487 24 13C24 19.0751 19.0751 24 13 24C6.92487 24 2 19.0751 2 13ZM13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0ZM10 10.5C10 11.3284 9.32843 12 8.5 12C7.67157 12 7 11.3284 7 10.5C7 9.67157 7.67157 9 8.5 9C9.32843 9 10 9.67157 10 10.5ZM17.5 12C18.3284 12 19 11.3284 19 10.5C19 9.67157 18.3284 9 17.5 9C16.6716 9 16 9.67157 16 10.5C16 11.3284 16.6716 12 17.5 12ZM8.66483 15.4979C8.38758 15.0203 7.77562 14.8578 7.29796 15.1351C6.82031 15.4123 6.65786 16.0243 6.9351 16.5019C7.55167 17.5641 8.4364 18.4458 9.50076 19.0587C10.5651 19.6715 11.7718 19.9941 13 19.9941C14.2282 19.9941 15.4348 19.6715 16.4992 19.0587C17.5635 18.4458 18.4483 17.5641 19.0648 16.5019C19.3421 16.0243 19.1796 15.4123 18.702 15.1351C18.2243 14.8578 17.6124 15.0203 17.3351 15.4979C16.8944 16.2572 16.262 16.8874 15.5012 17.3255C14.7404 17.7635 13.8779 17.9941 13 17.9941C12.1221 17.9941 11.2595 17.7635 10.4987 17.3255C9.73795 16.8874 9.10555 16.2572 8.66483 15.4979Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'smiley-nervous':
      return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.78091 15.3753C9.59113 15.1381 9.30382 15 9.00004 15C8.69625 15 8.40894 15.1381 8.21917 15.3753L6.21917 17.8753C5.87416 18.3066 5.94408 18.9359 6.37534 19.2809C6.8066 19.6259 7.4359 19.556 7.78091 19.1247L9.00004 17.6008L10.2192 19.1247C10.4089 19.3619 10.6963 19.5 11 19.5C11.3038 19.5 11.5911 19.3619 11.7809 19.1247L13 17.6008L14.2192 19.1247C14.4089 19.3619 14.6963 19.5 15 19.5C15.3038 19.5 15.5911 19.3619 15.7809 19.1247L17 17.6008L18.2192 19.1247C18.5642 19.556 19.1935 19.6259 19.6247 19.2809C20.056 18.9359 20.1259 18.3066 19.7809 17.8753L17.7809 15.3753C17.5911 15.1381 17.3038 15 17 15C16.6963 15 16.4089 15.1381 16.2192 15.3753L15 16.8992L13.7809 15.3753C13.5911 15.1381 13.3038 15 13 15C12.6963 15 12.4089 15.1381 12.2192 15.3753L11 16.8992L9.78091 15.3753Z"
            fill="currentColor"
          />
          <path
            d="M8.5 12C9.32843 12 10 11.3284 10 10.5C10 9.67157 9.32843 9 8.5 9C7.67157 9 7 9.67157 7 10.5C7 11.3284 7.67157 12 8.5 12Z"
            fill="currentColor"
          />
          <path
            d="M19 10.5C19 11.3284 18.3284 12 17.5 12C16.6716 12 16 11.3284 16 10.5C16 9.67157 16.6716 9 17.5 9C18.3284 9 19 9.67157 19 10.5Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0ZM2 13C2 6.92487 6.92487 2 13 2C19.0751 2 24 6.92487 24 13C24 19.0751 19.0751 24 13 24C6.92487 24 2 19.0751 2 13Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'smiley-sad':
      return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 10.5C10 11.3284 9.32843 12 8.5 12C7.67157 12 7 11.3284 7 10.5C7 9.67157 7.67157 9 8.5 9C9.32843 9 10 9.67157 10 10.5Z"
            fill="currentColor"
          />
          <path
            d="M17.5 12C18.3284 12 19 11.3284 19 10.5C19 9.67157 18.3284 9 17.5 9C16.6716 9 16 9.67157 16 10.5C16 11.3284 16.6716 12 17.5 12Z"
            fill="currentColor"
          />
          <path
            d="M13 15.0059C11.7718 15.0059 10.5651 15.3284 9.50076 15.9413C8.4364 16.5542 7.55167 17.4358 6.9351 18.4981C6.65786 18.9757 6.82031 19.5877 7.29796 19.8649C7.77562 20.1422 8.38758 19.9797 8.66483 19.5021C9.10555 18.7428 9.73795 18.1126 10.4987 17.6745C11.2595 17.2364 12.1221 17.0059 13 17.0059C13.8779 17.0059 14.7404 17.2364 15.5012 17.6745C16.262 18.1126 16.8944 18.7428 17.3351 19.5021C17.6124 19.9797 18.2243 20.1422 18.702 19.8649C19.1796 19.5877 19.3421 18.9757 19.0648 18.4981C18.4483 17.4358 17.5635 16.5542 16.4992 15.9413C15.4348 15.3284 14.2282 15.0059 13 15.0059Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0ZM2 13C2 6.92487 6.92487 2 13 2C19.0751 2 24 6.92487 24 13C24 19.0751 19.0751 24 13 24C6.92487 24 2 19.0751 2 13Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'smiley-blank':
      return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.5 12C9.32843 12 10 11.3284 10 10.5C10 9.67157 9.32843 9 8.5 9C7.67157 9 7 9.67157 7 10.5C7 11.3284 7.67157 12 8.5 12Z"
            fill="currentColor"
          />
          <path
            d="M19 10.5C19 11.3284 18.3284 12 17.5 12C16.6716 12 16 11.3284 16 10.5C16 9.67157 16.6716 9 17.5 9C18.3284 9 19 9.67157 19 10.5Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13ZM13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'x-circle':
      return (
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path d="M20 12L12 20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M20 20L12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    default:
      return <span>x</span>;
  }
}

export const GVSmileyIcon = memo(SmileyIcon);
