import { cookieService } from '../cookie/cookie.service';

export function translateByI18nObject(i18n: any, customlang?: 'en' | 'ko' | 'ja' | 'cn') {
  const lang = customlang ? customlang : cookieService.get('I18N_LANG');
  if (i18n) {
    const title = i18n[lang || 'en'] || '';
    // lang이 'en'이 아닐 경우 ->  default 'en'값으로 리턴
    if (!title || title === '') {
      return i18n['en'];
    }
    return title;
  } else {
    return '';
  }
}
