import { usePageState } from '../web-page.state';

export type LocationId = string;

export const LOCATION_ACTIONS = {
  LOCATION_HIGHLIGHT: 'location-highlight-action',
};
export type LocationAction = typeof LOCATION_ACTIONS[keyof typeof LOCATION_ACTIONS];

export function useLocationAction(tabKey: string, actionType: LocationAction) {
  return usePageState<LocationId>(tabKey, actionType, null, true);
}
