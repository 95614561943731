import { useEffect } from 'react';
import { flatten, groupBy, map, mapValues } from 'lodash';
import { useQuery } from '@tanstack/react-query';

import { queryOptions } from '../../utilities/query-util';
import { commonType } from '../common-types.service';
import { GlobalStateType, ROLE } from '../../model';
import { gvPODOApiPrefix } from '../../config/config';
import { httpService } from '../../ajax/http.service';
import { useRefetchByChangedGlobalState } from '../../hooks/shared/changed-global-state.hook';

export const COMMON_TYPE_LIST_QUERY = 'common-type-list';
export const COMMON_TYPE_LIST_QUERY_ROLES = [];

export function useCommonTypesDomain() {
  const { refetch, data, isError, isLoading } = useQuery(
    [COMMON_TYPE_LIST_QUERY],
    () => getTypes(COMMON_TYPE_LIST_QUERY_ROLES),
    {
      ...queryOptions(),
    }
  );

  useEffect(() => {
    if (data) {
      commonType.set(data);
    }
  }, [data]);

  useRefetchByChangedGlobalState([GlobalStateType.CHANGED_GLOBAL_CONFIG], refetch);

  return {
    data,
    refetch: (code: string) => {
      refetch();
    },
  };
}

async function getTypes(roles: ROLE[] = []) {
  if (roles.length === 0) {
    const url = `${gvPODOApiPrefix()}/types/masters?projections=default`;
    const datas = await httpService.get<any>(url);
    return mapValues(groupBy(datas, 'code'), (o: any[]) => flatten(map(o, 'typeOptions')));
  } else {
    const url = `${gvPODOApiPrefix()}/types/masters?role=${roles.join(',')}`;
    const data = await httpService.get<any>(url);
    return groupBy(data, 'role');
  }
}
