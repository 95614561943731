import { isFunction } from 'lodash';
import { useEffect, useState } from 'react';
import { ReplaySubject } from 'rxjs';

export function sendSharedState(eventKey: string, payload: any) {
  if (payload) {
    getOneWaySubject(eventKey).sendPayload(payload);
  }
}

/**
 * Use None UI
 */
export function getSharedOneWaySubject() {
  if (!(window as any).GV_STATE_RX_sharedOneWaySubject) {
    (window as any).GV_STATE_RX_sharedOneWaySubject = {};
  }
  return (window as any).GV_STATE_RX_sharedOneWaySubject;
}

function getOneWaySubject(eventKey: string) {
  if (!getSharedOneWaySubject()[eventKey]) {
    const subject = new ReplaySubject<any>(1);
    getSharedOneWaySubject()[eventKey] = {
      sendPayload: (payload: any) => subject.next(payload),
      payloadObservable: () => subject.asObservable(),
    };
  }
  return getSharedOneWaySubject()[eventKey];
}

export function useSharedState(eventKey: string, cb?: any): any {
  const [state, setState] = useState({});

  useEffect(() => {
    if (!eventKey || getSharedOneWaySubject()[eventKey]) {
      return;
    }

    const subject = getOneWaySubject(eventKey);
    const subscription = subject.payloadObservable().subscribe((payload: any) => {
      if (!payload) {
        return;
      }

      if (isFunction(cb)) {
        cb(payload);
      }
      setState(payload);
    });
    return () => subscription.unsubscribe();
  }, [eventKey]);

  return state;
}
