/**
 * current page editing mode or read mode
 */
export function getCurrentPageDirtyFormState() {
  if (!(window as any).GV_STATE_currentPageDirtyFormState) {
    (window as any).GV_STATE_currentPageDirtyFormState = {};
  }
  return (window as any).GV_STATE_currentPageDirtyFormState;
}

export function setCurrentPageDirtyForm(tabKey: string, isDirty: boolean) {
  getCurrentPageDirtyFormState()[tabKey] = isDirty;
}

export function getCurrentPageDirtyForm(tabKey: string) {
  return getCurrentPageDirtyFormState()[tabKey] || false;
}

export function clearCurrentPageDirtyForm(tabKey: string) {
  if (getCurrentPageDirtyFormState()[tabKey]) {
    delete getCurrentPageDirtyFormState()[tabKey];
  }
}

export function getFormsDirty() {
  const dirtys = Object.keys(getCurrentPageDirtyFormState()).map((key: string) => getCurrentPageDirtyFormState()[key]);
  return dirtys.includes(true);
}
