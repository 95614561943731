class IFrameMicroAppManager {
  private iframeMap: Map<string, any> = new Map();

  addIFrame(microAppName: string, iframe: any) {
    this.iframeMap.set(microAppName, iframe);
  }

  removeIFrame(microAppName: string) {
    const iframe: any = this.iframeMap.get(microAppName);
    if (iframe && iframe['iFrameResizer']) {
      iframe['iFrameResizer'].close();
    }
    this.iframeMap.delete(microAppName);
  }

  getIFrame(microAppName: string) {
    return this.iframeMap.get(microAppName);
  }

  getIFrames() {
    return this.iframeMap;
  }
}

export const iframeMicroAppManager = new IFrameMicroAppManager();
