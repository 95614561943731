/* eslint-disable no-restricted-globals */
import { IPlugin } from '../../model';
import { initPlugins } from './plugin-config.service';
import { setPluginNameByHostAppNPluginType, getPluginDev, isProduction } from '../../config/config';
import { getRemoteDefinitions, setDynamicRemoteDefinitions } from '../dynamic-remote-module-nx.service';

export function initRemoteDefinitionsAndPlugins(result: IPlugin[]) {
  if (!result?.length) {
    return;
  }

  // [1] set url info of remote module
  const definitions = makeRemoteDefintions(result);
  setDynamicRemoteDefinitions(definitions);
  console.log('> [plugin] Set Remote Module Definitions for Dev Mode:', getRemoteDefinitions());

  // [2] plugin initialization about i18n, etc
  return initPlugins();

}

export function makeRemoteDefintions(result: IPlugin[]) {
  const mappedPluginsRemotes: any = {};
  const hostApps: any = {};

  // activating인 것만 사용한다.
  result.forEach((plugin: IPlugin) => {
    const appName = plugin.application.contextPath;
    const pluginType = plugin.type.code.toLowerCase();
    const pluginName = plugin.physicalName;

    if (!hostApps[appName]) {
      hostApps[appName] = {};
    }
    if (!hostApps[appName][pluginType]) {
      hostApps[appName][pluginType] = [];
    }
    // for host applications
    hostApps[appName][pluginType].push(pluginName);

    // for remote definitions
    mappedPluginsRemotes[pluginName] = makePluginStorePath(appName, pluginType, pluginName);
  });

  // set hostApps info into global window object
  setPluginNameByHostAppNPluginType(hostApps);

  return mappedPluginsRemotes;
}

function makePluginStorePath(appName: string, pluginType: string, pluginName: string) {
  if (!isProduction()) {
    // DEVELOP (DEPLOYED) MODE
    // local host application에서 배포 plugin 접근
    const devPlugin = getPluginDev()?.DEPLOYED_PLUGIN?.definitions;
    const remoteTargetUrl = devPlugin?.[pluginName];
    if (remoteTargetUrl) {
      return `${remoteTargetUrl}/plugins/${appName}/${pluginType}/${pluginName}`;
    }
  }

  // PRODUTION (DEPLOYED) MODE
  // 배포 host application에서 배포 환경 접근
  const location = parent.window.document.location || window.document.location;
  return `${location.protocol}//${location.hostname}:${location.port}/plugins/${appName}/${pluginType}/${pluginName}`;
}
