export enum ColorThemeType {
  BLACK = 'black',
  PURPLE = 'purple',
  INDIGO = 'indigo',
  WHITE = 'white',
  RED = 'red',
  BLUE = 'blue',
}

export enum ColorModeType {
  DARK = 'dark',
  LIGHT = 'light'
}
