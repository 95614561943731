/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCreation } from 'ahooks';

import { usePageState } from '../web-page.state';
import { IAsset, IFavoriteItem, IPageInformation, ITrendSeries, TrendTab } from '../../../model';

export const TREND_FEATURE_KEY = 'TREND';
export const PAGE_COUNT = 5;

export function useInitTrendState(tabKey: string, paramId: string | undefined) {
  const initParam = useCreation(() => {
    return initTrendDetail(paramId ? TrendTab.PARAMETER : TrendTab.FAVORITE);
  }, [paramId]);

  return usePageState(tabKey, 'asset-trend-detail-state', initParam);
}

export function useAssetTrendState(tabKey: string) {
  return usePageState(tabKey, 'asset-trend-detail-state');
}

/*************************
 * 여러 탭의 chart content를 동시에 병렬로 띄울 경우 처리한다. (page splitting 대응)
 *************************/
export function getModelsListByTabKey() {
  if (!(window as any).GV_STATE_modelsListByTabKey) {
    (window as any).GV_STATE_modelsListByTabKey = {};
  }
  return (window as any).GV_STATE_modelsListByTabKey;
}

export function getChartsListByTabKey() {
  if (!(window as any).GV_STATE_chartsListByTabKey) {
    (window as any).GV_STATE_chartsListByTabKey = {};
  }
  return (window as any).GV_STATE_chartsListByTabKey;
}

export function getOriginSeriesByTabKey() {
  if (!(window as any).GV_STATE_originSeriesByTabKey) {
    (window as any).GV_STATE_originSeriesByTabKey = {};
  }
  return (window as any).GV_STATE_originSeriesByTabKey;
}

export function getModelsList(tabKey: string) {
  const modelsList = getModelsListByTabKey()[tabKey];
  if (!modelsList) {
    getModelsListByTabKey()[tabKey] = [];
  }
  return getModelsListByTabKey()[tabKey];
}
export function setModelsList(tabKey: string, modelsList: any[]) {
  getModelsListByTabKey()[tabKey] = modelsList;
}

export function getChartsList(tabKey: string) {
  const chartsList = getChartsListByTabKey()[tabKey];
  if (!chartsList) {
    getChartsListByTabKey()[tabKey] = [];
  }
  return getChartsListByTabKey()[tabKey];
}
export function setChartsList(tabKey: string, chartsList: any[]) {
  getChartsListByTabKey()[tabKey] = chartsList;
}

export function getOriginSeries(tabKey: string) {
  const originSeries = getOriginSeriesByTabKey()[tabKey];
  if (!originSeries) {
    getOriginSeriesByTabKey()[tabKey] = {};
  }
  return getOriginSeriesByTabKey()[tabKey];
}
export function setOriginSeries(tabKey: string, originSeries: any) {
  getOriginSeriesByTabKey()[tabKey] = originSeries;
}

export interface TrendState {
  randomId: string | null | undefined;
  asset: IAsset | null;

  // tab 정보
  tabName: TrendTab;

  // timeOption: RealtimeOptions;

  // 차트 영역에서 보여주는 contents
  contents: {
    tabName: string;
    selectItem: any;
    chart: IFavoriteItem[];
    page: IPageInformation;
  };

  // param.paramGroup.name / param.sensorType
  groupBy: string;
  // GroupBy 항목 중에서 선택한 묶음이 아이탬
  selectGroupByName: string;
  // asc / desc
  orderBy: 'asc' | 'desc';

  holderItems: any;
  // Left-Panel에서 클릭 시 Contents에서 Focus 하기 위해서
  selectItem: any;

  favorite: IFavoriteItem[];
  parameter: IFavoriteItem[];
  feature: IFavoriteItem[];
  model: IFavoriteItem[];
  batch: IFavoriteItem[];
  vibration?: IFavoriteItem[];
  chartType: string;
  series: ITrendSeries;

  // reset data when do inter-action
  resetData: string;

  // use trend-list.tsx
  list: any;

  isMergeView?: boolean; // merge chart view mode 여부
  normalize?: boolean; // normalize 조회 여부
}

export function initTrendDetail(tabName: string): any {
  return {
    // randomId: null,
    asset: null,
    assetParamInfoMap: null,
    tabName, // TrendTab.FAVORITE,
    contents: {
      tabName: '',
      selectItem: null,
      chart: [],
      page: {
        totalElements: 0,
        totalPages: 0,
        number: 0,
      },
    },

    groupBy: '',
    // 아코디언 목록에서 선택 한 그룹 대표
    selectGroupByName: '',
    orderBy: 'asc',
    // groupBy(map) 하거나 orderBy(array) 한 경우 잠시 데이터를 보관 하는 장소
    holderItems: [],
    selectItem: null,

    favorite: [],
    parameter: [],
    feature: [],
    model: [],
    batch: [],
    vibration: [],

    chartType: '',
    series: {},
    resetData: '',
    list: [],
  };
}
