import { NumericFormatProps, numericFormatter } from 'react-number-format';

// https://s-yadav.github.io/react-number-format/docs/numeric_format#common-props
export type FormatNumberProps = NumericFormatProps & {
  disabledRound?: boolean; // 소수 round 처리 여부
};

/**
 * number formatter
 * @param value
 * @param decimalScale
 * @param userProps
 * ----------------------------------
 * formatNumber(1.34567) -> 1.34567
 * formatNumber(1.34567, 2) -> 1.34
 * formatNumber(1.34567, 3) -> 1.345
 * formatNumber(1.34567, 4) -> 1.3457
 * formatNumber(1.34567, 4, {disabledRound: ture}) -> 1.3456
 * formatNumber(1.2, 2, {fixedDecimalScale: ture}) -> 1.20
 */
export function formatNumber(
  value: string | number | undefined,
  decimalScale?: number,
  userProps?: FormatNumberProps
): string {
  if (value === null || value === undefined) {
    return String(userProps?.defaultValue ?? '-');
  }
  // set default value
  const props: NumericFormatProps = {
    ...userProps,
    decimalScale: decimalScale ?? userProps?.decimalScale,
    decimalSeparator: userProps?.decimalSeparator ?? '.',
    thousandSeparator: userProps?.thousandSeparator ?? ',',
  };
  const newValue: string = userProps?.disabledRound
    ? toFixedNotRound(String(value), props.decimalScale)
    : String(round(value, props.decimalScale));
  return numericFormatter(newValue, props);
}

export function round(value: string | number | undefined, decimalScale?: number): any {
  if (value === undefined || decimalScale === undefined) {
    return value;
  }
  return +(Math.round(Number(`${value}e+${decimalScale}`)) + `e-${decimalScale}`);
}

export function toFixedNotRound(numStr: string, scale?: number) {
  if (scale === undefined) return numStr;
  if (['', '-'].indexOf(numStr) !== -1) return numStr; //if number is empty don't do anything return empty string
  if (numStr?.indexOf('.') === -1 || scale <= 0) return numStr;

  const int = numStr.split('.')?.[0];
  const decimal = numStr.split('.')?.[1]?.slice(0, scale);
  return `${int}.${decimal}`;
}
