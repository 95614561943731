export enum AlarmRuleItemType {
  ASSET = 'ASSET',
  PARAMETER = 'PARAMETER',
}

export enum AlarmRuleDomainType {
  PARAMETER = 'PARAMETER',
  MODEL = 'MODEL',
}

export enum AlarmRuleThresholdType {
  PARAMETER = 'PARAMETER',
  TREND = 'TREND',
  HEALTH_INDEX = 'HEALTH_INDEX',
}

export const ALARM_RULE_BASE_THRESHOLD_ID = 'health_status_unacceptable';
