export class CommonActionStatus {
  static ADD_FINISH = 'ADD_FINISH'
  static EDIT_FINISH = 'EDIT_FINISH'
  static ERROR = 'ERROR'
  static COPY = 'COPY'
  static DELETE = 'DELETE'
  static EDIT_SUB_FINISH = 'EDIT_SUB_FINISH'
  static ADD_OPEN = 'ADD_OPEN'
  static EDIT_OPEN = 'EDIT_OPEN'
  static REFRESH = 'REFRESH'
  static CLOSE = 'CLOSE'
}

export enum TreeCommonData {
  TREE_ROOT_ID = 'root'
}
