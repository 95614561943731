/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useUnmount } from 'ahooks';
import { ReplaySubject } from 'rxjs';
import { isEmpty } from 'lodash';

export function sendOneWaySubject(tabKey: string, eventKey: string, payload: any) {
  if (payload) {
    getOneWaySubject(tabKey, eventKey).sendPayload(payload);
    // console.log('[one-way subject send] tabKey, eventKey, payload', tabKey, eventKey, payload);
  }
}

/**
 * Use None UI
 */
export function getCommonOneWaySubject() {
  if (!(window as any).GV_STATE_RX_commonOneWaySubject) {
    (window as any).GV_STATE_RX_commonOneWaySubject = {};
  }
  return (window as any).GV_STATE_RX_commonOneWaySubject;
}

export function getOneWaySubject(tabKey: string, eventKey: string) {
  if (!getCommonOneWaySubject()[tabKey]) {
    const subject = new ReplaySubject<any>(1);
    getCommonOneWaySubject()[tabKey] = {
      [eventKey]: {
        sendPayload: (payload: any) => subject.next(payload),
        payloadObservable: () => subject.asObservable(),
      },
    };
  }
  if (!getCommonOneWaySubject()[tabKey][eventKey]) {
    const subject = new ReplaySubject<any>(1);
    getCommonOneWaySubject()[tabKey][eventKey] = {
      sendPayload: (payload: any) => subject.next(payload),
      payloadObservable: () => subject.asObservable(),
    };
  }
  return getCommonOneWaySubject()[tabKey][eventKey];
}

export function useOneWaySubject(tabKey: string, eventKey: string, isUnmount = true) {
  const [state, setState] = useState({});

  useUnmountOneWaySubject(tabKey, eventKey, isUnmount);

  useEffect(() => {
    if (!tabKey || !eventKey) {
      return;
    }

    const subject = getOneWaySubject(tabKey, eventKey);
    const subscription = subject.payloadObservable().subscribe((payload: any) => {
      if (!payload || isEmpty(payload)) {
        return;
      }

      setState(payload);
    });
    return () => subscription.unsubscribe();
  }, [tabKey, eventKey]);

  return state;
}

export function removeOneWaySubject(tabKey: string, eventKey: string) {
  if (getCommonOneWaySubject()[tabKey]) {
    delete getCommonOneWaySubject()[tabKey][eventKey];
  }
}

export function useUnmountOneWaySubject(tabKey: string, eventKey: string, isUnmount = false) {
  useUnmount(() => {
    if (isUnmount) {
      removeOneWaySubject(tabKey, eventKey);
    }
  });
}
