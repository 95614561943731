// Action detail state
export enum ActionType {
  // page state actions
  SELECT = 'select',
  RENAME = 'rename',
  DUPLICATE = 'duplicate',
  PATCH_LIST = 'patchList',
  UPDATE_LIST = 'updateList',
  RESET_LIST = 'resetList',
  REORDER_LIST = 'reorderList',
  DETAIL = 'detail',
  LIST = 'list',
  COPY = 'copy',
  // header actions
  CREATE = 'create',
  CREATE_SUCCESS = 'create success',
  BULK_CREATE = 'bulk-create',
  UPDATE = 'update',
  UPDATE_SUCCESS = 'update success',
  DELETE = 'delete',
  DELETE_SUCCESS = 'delete success',
  EDIT = 'edit',
  EDIT_INFO = 'edit info',
  CANCEL = 'cancel',
  RESET = 'reset',
  UNLOAD = 'unload',
  ROLLBACK = 'rollback',
  DRAG_SUCCESS = 'drag success',
  // work order actions
  CHECK = 'CHECK',
  REPAIR = 'REPAIR',
  CHANGE = 'CHANGE',
  // user
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
  // change global state alert
  CHANGED_GLOBAL_STATE_ALERT = 'CHANGED_GLOBAL_STATE_ALERT',
  CANCEL_READY = 'CANCEL_READY',
  RESET_READY = 'RESET_READY',
  EDIT_READY = 'EDIT_READY',
  PREVIOUS = 'PREVIOUS',
  NEXT = 'NEXT',
}

export interface ActionPayload<T> {
  action: ActionType;
  payload?: T;
}

// Detail UI - Header action mode
// create, read, edit mode
export enum HeaderActionType {
  CREATE = 'create',
  READ = 'read',
  MODEL_READ = 'modelRead',
  EDIT = 'edit',
  COPY = 'copy',
  COPIED = 'copied',
  PREVIEW = 'preview',
}
