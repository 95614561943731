import { atom, useAtom } from 'jotai';

/**
 * Common cache
 */
export const ManagementAppState = {
  updateHierarchyStatus: atom<string | undefined>(undefined),
};

export function useUpdateHierarchyStatus() {
  return useAtom(ManagementAppState.updateHierarchyStatus);
}
