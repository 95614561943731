import { isObject, isString } from 'lodash';

import {
  AntDConfiguration,
  CustomConfiguration,
  ThemeAntD,
  ColorLevelConfiguration,
  ThemeCustomComponent,
  ThemeColorLevel,
} from '../types';
import { GV_MICRO_APP } from '../../model';
import { getThemeModeFromLS } from '../../config/personalization-config.service';
import { applyDarkAlogrithmByApplication, getThemeAntDToken, setAntDAllModeConfig } from '../antd-theme-config.service';

/**
 *
 * @param colorLevel
 * @param antd
 * @param custom
 * @returns
 */
export function interpolateTheme(
  colorLevelConfig: ColorLevelConfiguration,
  antdConfig: AntDConfiguration,
  customConfig: CustomConfiguration,
  appName: GV_MICRO_APP | string | undefined,
): { themeAntD: ThemeAntD; themeCustomComponent: ThemeCustomComponent } {
  const mode = getThemeModeFromLS();

  // [1] Interpolate AntD Theme & Get AntD All Tokens from AntD Alogrithm
  setAntDAllModeConfig(antdConfig);
  const themeAntD: ThemeAntD = applyDarkAlogrithmByApplication(antdConfig[mode], mode, appName);
  const themeAntDAllTokensMode: any = getThemeAntDToken(themeAntD);
  console.log('> [theme] interpolate-1 - AntD All Mode:', themeAntDAllTokensMode);

  // [2-1] customConfig의 mode별 'base' 하위의 level 값을 'base'이외의 customConfig에도 interpolation 한다.
  // [2-2] colorLevel의 다층구조 정의를 1 depth 의 Key:Value 객체로 치환
  const customMode = customConfig[mode] ? makeOneDepthKeyValueObject(customConfig[mode]) : {};
  const interpolatedCustomModeValues: any = {};
  const integratedMiddleValues = { ...customConfig[mode]?.['token'] || {}, ...colorLevelConfig['base-variables'], ...colorLevelConfig['common-variables'], ...themeAntDAllTokensMode };
  interpolateInJson(interpolatedCustomModeValues, customMode, integratedMiddleValues);
  console.log('> [theme] interpolate-2 - Custom Mode:', interpolatedCustomModeValues);

  // [3] Interpolate Custom Theme
  // AntD의 token값만 사용한다.
  const integratedAllValues = { ...integratedMiddleValues, ...interpolatedCustomModeValues };
  const themeCustomComponent: ThemeCustomComponent = {};
  interpolateInJson(themeCustomComponent, customConfig.components || {}, integratedAllValues);
  console.log('> [theme] interpolate-3 - Custom Component:', themeCustomComponent);

  return { themeAntD, themeCustomComponent };
}

function makeOneDepthKeyValueObject(colorLevel: ThemeColorLevel) {
  const keysTemp: any = [];
  const result: any = {};
  let depth = 0;
  recursiveKeyValueInObject(keysTemp, result, colorLevel, ++depth);
  // console.log('> [theme] transfer level theme 1-depth key/value:', result);
  return result;
}

function recursiveKeyValueInObject(
  keysTemp: any,
  result: any,
  colorLevel: ThemeColorLevel,
  depth: number
) {
  const currentDepth = depth;
  for (const [key, value] of Object.entries(colorLevel)) {
    if (isObject(value)) {
      if (keysTemp.length > 0 && currentDepth < depth) {
        const resetKeysTemp: any = [];
        keysTemp.forEach((key: string, idx: number) => {
          if (idx < currentDepth - 1) {
            resetKeysTemp.push(key);
          }
        });
        keysTemp = resetKeysTemp;
        depth = currentDepth;
      }
      keysTemp.push(key);
      // console.log('-- keysTemp:', keysTemp, ', depth:', depth, ', currentDepth:', currentDepth);
      recursiveKeyValueInObject(keysTemp, result, value, ++depth);
    } else {
      let variable = '';
      if (keysTemp.length) {
        keysTemp.forEach((key: string) => (variable = variable.concat(variable === '' ? key : `.${key}`)));
      }
      variable = variable.concat(variable === '' ? key : `.${key}`);

      result[variable] = value;
    }
  }
}

function interpolateInJson(result: any, configuration: any, colorLevel: any) {
  for (const [key, value] of Object.entries(configuration)) {
    if (isObject(value)) {
      result[key] = {};
      interpolateInJson(result[key], value, colorLevel);
    } else {
      if (isString(value)) {
        const vStr = value as string;
        if (vStr.indexOf('{') >= 0) {
          if (vStr.indexOf('{') === 0) {
            // sample: "{level-1}"
            const levelKey = vStr.substring(1).substring(0, vStr.indexOf('}') - 1);
            result[key] = colorLevel[levelKey];
          } else {
            // sample: "rgb(1,2,3 {level-1})"
            const firstString = vStr.substring(0, vStr.indexOf('{'));
            const levelKey = vStr.substring(vStr.indexOf('{') + 1, vStr.indexOf('}'));
            const lastString = vStr.substring(vStr.indexOf('}') + 1);
            result[key] = firstString + colorLevel[levelKey] + lastString;
          }
        } else {
          result[key] = value;
        }
      } else {
        result[key] = value;
      }
    }
  }
}
