export function transferLocationsToTableFilter(locationTree: any[]) {
  if (!locationTree) {
    return;
  }
  const tree: any[] = [];
  generateLocationTableFilterNode(tree, locationTree);

  return tree;
}

function generateLocationTableFilterNode(children: any[], remainNodes: any[]) {
  for (let i = 0; i < remainNodes.length; i++) {
    const node = remainNodes[i];
    const { title, key, path } = node;
    // value use in TreeSelect Form of AntD
    const newNode: any = { text: title, value: key, path, children: [] };
    children.push(newNode);

    if (node.children && node.children.length) {
      generateLocationTableFilterNode(newNode.children, node.children);
    }
  }
}
