/* eslint-disable @typescript-eslint/no-explicit-any */
import { isFunction } from 'lodash';

import { httpService } from '../../ajax/http.service';
import { gvPODOApiPrefix } from '../../config/config';
import { cookieService } from '../../cookie/cookie.service';
import { DomainType } from '../../model';

/**
 * Use AntD Upload component
 * <Upload
    accept="image/*"
    customRequest={uploadImage}
   >
    <Button icon={<CloudUploadOutlined />} disabled={!fileInfo.displayName}>
      Click to upload image
    </Button>
  </Upload>
 * ex) libs/web/builder/layout/src/lib/layout-builder/left-panel/image-list/image-upload-modal/image-upload-modal.tsx
 */
export function uploadUserImage(file: File, type: string, onSuccess: any, onProgress?: any, onError?: any) {
  const formData = new FormData();
  formData.append('domain', type || DomainType.USER);
  formData.append('domainId', cookieService.getUserId() || 'not-login-user');
  formData.append('file', file);

  upload(formData, onProgress)
    .then((result: any) => {
      if (onSuccess && isFunction(onSuccess)) {
        onSuccess(result);
      }
    })
    .catch((error: any) => {
      if (onError && isFunction(onError)) {
        onError(error);
      }
    });
}

async function upload(formData: FormData, onProgress?: any) {
  const url = `${gvPODOApiPrefix()}/users/images/upload`;
  const result: any = await httpService.post<any>(url, formData, {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: (event: any) => {
      // console.log('++ uploading file:', (event.loaded / event.total) * 100);
      onProgress({ percent: (event.loaded / event.total) * 100 });
    },
  });
  return result;
}

const imageCache: any = {};
export function setUserImageObjectIntoCache(imageId: string, imageObject: any) {
  imageCache[imageId] = imageObject;
}

export function getUserImageObjectFromCache(imageId: string) {
  return imageCache[imageId];
}
