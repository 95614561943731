import { useMutation } from '@tanstack/react-query';

import { httpService } from '../ajax/http.service';
import { gvAssetMetaApiPrefix } from '../config/config';

export function useUploadMutation(url: string) {
  const mutation = useMutation((payload: any) => {
    return formDataToJson(payload, url);
  });
  return { formDataToJson: (payload: FormData) => mutation.mutate(payload), uploadMutation: mutation };
}

async function formDataToJson(formData: FormData, url: string) {
  const result = await httpService.post<any>(`${gvAssetMetaApiPrefix()}${url}`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
  return result;
}
