import { usePageState } from '../web-page.state';

export const BATCH_ACTIONS = {
  BATCH_DETAIL: 'batch-detail-action',
};
export type BatchAction = typeof BATCH_ACTIONS[keyof typeof BATCH_ACTIONS];

export function useBatchAction(tabKey: string, actionType: BatchAction) {
  return usePageState<any>(tabKey, actionType, null, false);
}
