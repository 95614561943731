/* eslint-disable prefer-const */
import { cloneDeep, isEmpty } from 'lodash';

import { clearActivePageHistories, getActivePageHistory } from '../iframe/active-page-history-manager';
import { switchPageByCurrentMicroApp, updateCurrentMicroApp } from '../iframe/commands/switch-page.hook';
import { updateBrowserLocation } from './location';

export function listenBrowserHistoryBack() {
  // Support: https://developer.mozilla.org/ko/docs/Web/API/Window/popstate_event
  window.addEventListener('popstate', (event) => {
    // app is microAppName
    // tabKey is tabKey of page (tabKey: dashboard.health-status:rvmmhkaf79e)
    // params is locationParams with realtime filter & other params)
    const { tabKey, params } = event.state || {};
    if (tabKey && params) {
      // send app and tabKey with params
      let [pageName, tabId] = tabKey.split(':');
      const [appName] = pageName.split('.');
      // APM 의 asset detail 체크
      const detailPageName = params['detailPage'];
      pageName = detailPageName ? `${pageName}.${detailPageName}` : pageName;
      params['tabId'] = tabId;

      // ref: libs/web/shared/src/lib/iframe/commands/switch-page.command.ts
      // console.log('> [back browser history]', appName, pageName, params);
      switchPageByCurrentMicroApp(appName, pageName, params);
    } else {
      console.log('There is no history info.');
    }
  });
}

export function switchActivePageHistory(microAppName: string | undefined) {
  updateCurrentMicroApp(microAppName || '');

  if (!microAppName) {
    clearActivePageHistories();
    return;
  }

  const page = getActivePageHistory(microAppName);
  if (page) {
    const params = page.initParams && !isEmpty(page.initParams) ? cloneDeep(page.initParams) : {};

    // console.log('> [switch active page in app-title]', microAppName, page);
    updateBrowserLocation(microAppName, page.key, params);
  }
}
