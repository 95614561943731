import { memo } from 'react';

export enum ProgressTypes {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

/* eslint-disable-next-line */
export interface WorkProgressIconProps {
  type: string | undefined;
  height?: number;
  width?: number;
}

/***********************************
 * Usage

 <GVWorkProgressIcon type={ProgressTypes.OPEN} />
 <GVWorkProgressIcon type={ProgressTypes.IN_PROGRESS} />
 <GVWorkProgressIcon type={ProgressTypes.DONE} />

 ***********************************/
export function WorkProgressIcon({ type, height = 14, width = 14 }: WorkProgressIconProps) {
  switch (type) {
    case ProgressTypes.OPEN:
      return (
        <svg
          className={'gv_alarm_action_status_open'}
          width={width}
          height={height}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12.25C9.8995 12.25 12.25 9.8995 12.25 7C12.25 4.1005 9.8995 1.75 7 1.75C4.1005 1.75 1.75 4.1005 1.75 7C1.75 9.8995 4.1005 12.25 7 12.25ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
            fill="#E60099"
          />
        </svg>
      );

    case ProgressTypes.IN_PROGRESS:
      return (
        <svg
          className={'gv_alarm_action_status_in_progress'}
          width={width}
          height={height}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12.25C9.89949 12.25 12.25 9.89949 12.25 7C12.25 4.1005 9.89949 1.75 7 1.75C4.1005 1.75 1.75 4.1005 1.75 7C1.75 9.89949 4.1005 12.25 7 12.25ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
            fill="#1D7CD6"
          />
          <path
            d="M11.375 7C11.375 9.41625 9.41626 11.375 7.00001 11.375C5.3044 11.375 3.83409 10.4104 3.10788 9L7.00001 7V2.625C9.41626 2.625 11.375 4.58375 11.375 7Z"
            fill="#1D7CD6"
          />
        </svg>
      );

    case ProgressTypes.COMPLETE:
      return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12.25C9.8995 12.25 12.25 9.8995 12.25 7C12.25 4.10051 9.8995 1.75 7 1.75C4.10051 1.75 1.75 4.10051 1.75 7C1.75 9.8995 4.10051 12.25 7 12.25ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
            fill="#0C9200"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1187 5.43122L6.1708 10.3792L2.49309 7.22685L3.63197 5.89815L6.07926 7.99583L9.88131 4.19378L11.1187 5.43122Z"
            fill="#0C9200"
          />
        </svg>
      );

    default:
      return <span></span>;
  }
}

export const GVWorkProgressIcon = memo(WorkProgressIcon);
