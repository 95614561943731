import { atom } from 'jotai';

export function getWidgetState() {
  if (!(window as any).GV_STATE_widgetState) {
    (window as any).GV_STATE_widgetState = {};
  }
  return (window as any).GV_STATE_widgetState;
}

export function WidgetState(tabKey: string, widgetName: string, defaultValue: any = null) {
  if (!getWidgetState()[tabKey]) {
    getWidgetState()[tabKey] = { [widgetName]: atom<any>(defaultValue) };
  }
  if (!getWidgetState()[tabKey][widgetName]) {
    getWidgetState()[tabKey][widgetName] = atom<any>(defaultValue);
  }
  return getWidgetState()[tabKey][widgetName];
}
