import { usePageState } from '../web-page.state';

export const FAVORITE_ACTIONS = {
  ADD: 'ADD',
  SELECT: 'SELECT',
  REMOVE: 'REMOVE',
};
export type FavoriteAction = typeof FAVORITE_ACTIONS[keyof typeof FAVORITE_ACTIONS];

export function useFavoriteAction(tabKey: string, actionType: FavoriteAction, initValue: any = undefined) {
  return usePageState<any>(tabKey, actionType, initValue, false);
}
