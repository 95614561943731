import { t } from 'i18next';
import dayjs from 'dayjs';

import { timezoneService } from './timezone.service';
import { timeFormatDate, timeFormatMonthDate, timeFormatSecond } from './time-format-locale';
import { IPeriod, TimeRangeUnitType } from '../model';

export const BEFORE_DAY = 27;
export const MAX_LIMIT_FOR_DAY_UNIT = 50; // 50일 넘으면 unit은 week 이다.
export const WEEK_DAY = 7;
export const MONDAY = 1;

export function getInterval(timeRange: number, timeRangeUnit: string, timeFormat = timeFormatSecond()) {
  switch (timeRangeUnit) {
    case TimeRangeUnitType.MINUTES:
      return {
        start: dayjs().subtract(timeRange, 'm').format(timeFormat),
        end: dayjs().format(timeFormat),
      };
    case TimeRangeUnitType.HOURS:
      return {
        start: dayjs().subtract(timeRange, 'h').format(timeFormat),
        end: dayjs().format(timeFormat),
      };
    case TimeRangeUnitType.DAYS:
      return {
        start: dayjs().subtract(timeRange, 'd').format(timeFormat),
        end: dayjs().format(timeFormat),
      };
    case TimeRangeUnitType.MONTHS:
      return {
        start: dayjs().subtract(timeRange, 'M').format(timeFormat),
        end: dayjs().format(timeFormat),
      };
    default:
      return {
        start: dayjs().subtract(timeRange, 'h').format(timeFormat),
        end: dayjs().format(timeFormat),
      };
  }
}

/**
 * Use Alarm Widgets in Dashboard
 */
export function getStartBeforeDay(
  isRealtime: boolean,
  start: any,
  end: any,
  beforeDay = BEFORE_DAY,
  timeFormat = 'YYYY-MM-DD HH:mm:ss'
) {
  const isUseStartHourOver28Days = isOver28Days(start, end);
  let startBeforeDay = start;

  if (isRealtime) {
    startBeforeDay = getBeforeDay(end, beforeDay, 'YYYY-MM-DD') + ' 00:00:00';
  } else if (isSmallerThanBeforeDay(start, end, beforeDay)) {
    if (isUseStartHourOver28Days) {
      // range filter로 28일 이상 조회한 경우
      startBeforeDay = dayjs(start).format(timeFormat);
    } else {
      startBeforeDay = getBeforeDay(end, beforeDay, 'YYYY-MM-DD') + ' 00:00:00';
    }
  }

  return startBeforeDay;
}

export function isOver28Days(start: any, end: any) {
  const diffDays = dayjs(end).diff(dayjs(start).startOf('d'), 'day');
  return diffDays > BEFORE_DAY;
}

export function getDDay(curr: any, date: any) {
  const currentDate = dayjs(curr);
  const targetDate = dayjs(date);
  const dDay = currentDate.diff(targetDate, 'day');
  return dDay;
}

export function getBeforeHour(date: any, rangeDay: number, timeFormat: string) {
  return dayjs(date).subtract(rangeDay, 'h').format(timeFormat);
}

export function getBeforeDay(date: any, rangeDay: number, timeFormat: string) {
  return dayjs(date).subtract(rangeDay, 'd').format(timeFormat);
}

export function getBeforeWeek(date: any, rangeDay: number, timeFormat: string) {
  return dayjs(date).subtract(rangeDay, 'd').weekday(MONDAY).format(timeFormat);
}

export function getBeforeWeekDate(date: any, rangeDay: number) {
  return dayjs(date).subtract(rangeDay, 'd').weekday(MONDAY).format(timeFormatMonthDate());
}

// beforeDay is 30
export function getBeforeDayStartEnd(beforeDay: number) {
  return {
    start: dayjs().subtract(beforeDay, 'd').startOf('d').format(timeFormatSecond()),
    end: dayjs().format(timeFormatSecond()),
  };
}

export function getDateToUtcString(date: any) {
  return timezoneService.dateToUtcString(date);
}

export function getBeforeDayToString(date: any, rangeDay: number, isUseDateEnd = true) {
  if (isUseDateEnd) {
    return dayjs(date).subtract(rangeDay, 'd').format('YYYY-MM-DD') + ' 00:00:00';
  }
  return dayjs(date).subtract(rangeDay, 'd').format('YYYY-MM-DD HH:mm:ss');
}

export function getBeforeDate(date: any, rangeDay: number): number {
  return dayjs(date).subtract(rangeDay, 'd').date();
}

export function getBeforeMonthDate(date: any, rangeDay: number): string {
  return dayjs(date).subtract(rangeDay, 'd').format(timeFormatMonthDate());
}

export function getBeforeHourDate(date: any, rangeDay: number): string {
  return dayjs(date).subtract(rangeDay, 'h').format('HH');
}

function isSmallerThanBeforeDay(start: any, end: any, rangeDay: number) {
  const beforeTime = dayjs(end).subtract(rangeDay, 'd').unix();
  const startTime = dayjs(start).unix();
  return startTime - beforeTime > 0 ? true : false;
}

export function getDateFormat(date: Date | string, formatType = timeFormatSecond()) {
  if (!date) {
    return '';
    // return dayjs(new Date()).format(formatType);
  }
  return dayjs(date).format(formatType);
}

export function getUtcDateFormat(date: Date | string, formatType = timeFormatSecond()) {
  if (!date) {
    return '';
  }
  return dayjs(date).utc().format(formatType);
}

export function getDateTimestamp(date: Date | string) {
  if (!date) {
    return '';
    // return dayjs(new Date()).format(formatType);
  }
  return dayjs(date).format('x');
}

export function getDate(date: Date | string) {
  return dayjs(date).date();
}

export function getDayjs(date: Date | string | number) {
  return dayjs(date);
}

export function getDayjsUtc(date: Date | string) {
  return dayjs(date).utc();
}

/**
 * time series chart 에서 사용되는 date format (backend api response 에서 사용되는 format)
 * @param date
 */
export function getDateFormatByTimeSeriesChart(date: Date | string) {
  if (!date) {
    return '';
  }
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * Interval Param Setting
 *
 * @param paramList
 * @param start
 * @param end
 */
export function addIntervalQueryParam(paramList: string[], start: any, end: any) {
  if (start && end) {
    const intervals = timezoneService.changeIntervals(start, end);
    paramList.push(`intervals=${intervals}`);
  }
  return paramList;
}

export function getIntervals(start: any, end: any) {
  return timezoneService.changeIntervals(start, end);
}

export function getIntervalStringToArray(intervalString: string) {
  const period = intervalString.split('/');
  return [dayjs(timezoneService.utcStringToDate(period[0])), dayjs(timezoneService.utcStringToDate(period[1]))];
}

export function getIntervalStringToPeriod(intervalString: string): IPeriod {
  const period = intervalString?.split('/');
  return {
    from: dayjs(timezoneService.utcStringToDate(period?.[0])),
    to: dayjs(timezoneService.utcStringToDate(period?.[1])),
  };
}

export function getIntervalsByHourTrim(start: any, end: any) {
  return timezoneService.changeIntervals(
    dayjs(start, timeFormatDate()).startOf('h'),
    dayjs(end, timeFormatDate()).endOf('h')
  );
}

export function getIntervalsByStartEndUtc(
  start: any,
  end: any,
  isUseRangeStartInFirst = false,
  isUseRangeEndInLast = false
) {
  return timezoneService.changeIntervals(
    isUseRangeStartInFirst
      ? dayjs(start, start.indexOf('/') > 0 ? timeFormatSecond() : 'YYYY-MM-DD hh:mm:ss')
      : dayjs(start, start.indexOf('/') > 0 ? timeFormatSecond() : 'YYYY-MM-DD hh:mm:ss').startOf('d'),
    isUseRangeEndInLast
      ? dayjs(end, end.indexOf('/') > 0 ? timeFormatSecond() : 'YYYY-MM-DD hh:mm:ss')
      : dayjs(end, end.indexOf('/') > 0 ? timeFormatSecond() : 'YYYY-MM-DD hh:mm:ss').endOf('d')
  );
}

export function getIntervalsByStartDayUtc(
  date: string,
  rangeDay: number,
  isUseRangeStartInFirst = false,
  isUseRangeEndInLast = false
) {
  const formattedDate = dayjs(date);
  return timezoneService.changeIntervals(
    rangeDay > 0
      ? formattedDate.subtract(rangeDay, 'd').startOf('d')
      : isUseRangeStartInFirst
        ? formattedDate
        : formattedDate.startOf('d'),
    isUseRangeEndInLast ? formattedDate : formattedDate.endOf('d')
  );
}

export function getIntervalsByStartWeekUtc(date: string, end?: string) {
  const formattedDate = dayjs(date);

  return timezoneService.changeIntervals(
    formattedDate.startOf('d'),
    end ? dayjs(end) : formattedDate.add(6, 'day').endOf('d')
  );
}

export function getTimePeriodFormat(from: string, to: string, format = 'YYYY-MM-DD HH:mm:ss', separator = '~') {
  const dateFormat = format.split(' ')[0];
  const timeFormat = format.split(' ')[1];
  if (dayjs(from).format(dateFormat) === dayjs(to).format(dateFormat) && timeFormat) {
    return `${dayjs(from, 'YYYY-MM-DD HH:mm:ss').format(format)}${separator}${dayjs(to, 'YYYY-MM-DD HH:mm:ss').format(
      timeFormat
    )}`;
  }
  return `${dayjs(from).format(format)}${separator}${dayjs(to).format(format)}`;
}

export function getMiddleTime(min: any, max: any, unit: any = 'ms') {
  return dayjs(String(min))
    .add((dayjs(String(max)).valueOf() - dayjs(String(min)).valueOf()) / 2, unit)
    .utc()
    .format();
}

export function add(date: any, gap: number, unit: any = 'd') {
  return dayjs(date || dayjs())
    .add(gap, unit)
    .utc()
    .format();
}

/**
 * Preview 1 day TimeZone
 */
export function getBeforeOneDayTimezoneIntervals() {
  return [timezoneService.add(new Date(), -1, 'd', false), timezoneService.add(new Date(), 0, 'd', false)];
}

export function getBeforeDayTimezone(day = 0, utc = true, format = null) {
  return timezoneService.add(new Date(), day, 'd', utc, format);
}

/**
 * Today, Yesterday 변환
 * ref: https://day.js.org/docs/en/display/calendar-time
 */
export function transferHumanDate(date: string, timeFormat = timeFormatDate()) {
  if (date === dayjs(new Date()).format(timeFormat)) {
    return t('ALARM.Today');
  } else if (date === dayjs(new Date()).subtract(1, 'd').format(timeFormat)) {
    return t('ALARM.Yesterday');
  }
  return date;
}

/**
 * dayjs duration
 * ref: https://day.js.org/docs/en/durations/durations
 */
export function duration(value: any, unit?: any) {
  return dayjs.duration(value, unit);
}

/**
 * 현재일자가 시작일자, 종료일자 사이에 포함 되었는지 여부 (시작일자 <= 현재일자 <= 종료일자)
 * @param current 현재일자
 * @param start 시작일자
 * @param end 종료일자
 * @return boolean
 */
export function isBetweenInterval(current: any, start: any, end: any): boolean {
  if (current.isSameOrAfter && current.isSameOrBefore) {
    return current.isSameOrAfter(start) && current.isSameOrBefore(end);
  }
  return false;
}
