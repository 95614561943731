import { atom, useAtom } from 'jotai';
import { IUser } from '../../model';

/**
 * Login
 */
export const LoginState = {
  signed: atom<boolean>(false),
  userInfo: atom<IUser | null>(null),
};

export const PermissionState = {
  editablePermissions: atom<
    | {
        assetEditable: boolean;
        alarmEditable: boolean;
        workOrderEditable: boolean;
      }
    | undefined
  >(undefined),
};

export function useEditablePermissions() {
  return useAtom(PermissionState.editablePermissions);
}
