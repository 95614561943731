import { useTranslation } from 'react-i18next';

import { cookieService } from '../cookie/cookie.service';
import { TranslateType } from '../model';

/**
 * 정의
 * Title Level : 띄어쓰기 기준 첫글자 대문자 (table column, menu label, button label, form label..)
 * Sentence Level : 문장 첫글자만 대문자 (tooltip message, popover message, error message..)
 */

function setSentenceLevel(text: any) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

function setTitleLevel(text: any, t: any) {
  const translatedTitle = t(text);
  const arr = translatedTitle.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
}

export function useGVTranslation() {
  const { t: translate } = useTranslation();

  const gvt = (text: any, type: TranslateType = 'TITLE') => {
    try {
      if (cookieService.getLocale() === 'en' && text) {
        switch (type) {
          case 'TITLE':
            return setTitleLevel(text, translate);
          case 'SENTENCE':
            return setSentenceLevel(text);
          default:
            return translate(text);
        }
      } else {
        return translate(text);
      }
    } catch (e: any) {
      console.log('translation err', e);
    }
  };
  return { gvt };
}
