import { atom, useAtom } from 'jotai';
import { IGVAppMenu } from '../../model';

/**
 * Portal App
 * App Menu
 * App Tab
 * Caution: Don't use it into micro-app
 */
export const AppMenuState = {
  backdrop: atom<boolean>(false),
  modalMask: atom<boolean>(false),
  alarmNotificationListStatus: atom<boolean>(false),
  drawerStatus: atom<boolean>(false),
  menus: atom([]),
  createMicroApp: atom<IGVAppMenu | undefined>(undefined),
};

export const AppTabState = {
  apps: atom<IGVAppMenu[]>([]),
  activeMicroAppKey: atom<string | undefined>(undefined),
  tabIndex: atom<number>(-1),
  shareGlobalState: atom<any>(undefined),
};

export function useAppBackdropStatus() {
  return useAtom(AppMenuState.backdrop);
}

export function useModalBackdropStatus() {
  return useAtom(AppMenuState.modalMask);
}

export function useNotificationDrawerStatus() {
  return useAtom(AppMenuState.alarmNotificationListStatus);
}

/**
 * iFrame을 통한 Portal과 Child micro app간 통신상태 설정한다.
 */
const microAppIFrameStatus: any = {};
export function setIframeCommunicationStatus(microAppName: string, activStatus = true) {
  microAppIFrameStatus[microAppName] = activStatus;
}

export function removeIframeCommunicationStatus(microAppName: string) {
  delete microAppIFrameStatus[microAppName];
}

export function getIframeCommunicationStatus(microAppName: string) {
  return microAppIFrameStatus[microAppName];
}
