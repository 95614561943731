import { usePageState } from '../web-page.state';

export type KpiRoleId = string;

export const KPI_ACTIONS = {
  KPI_HIGHLIGHT: 'kpi-highlight-action',
};
export type KpiAction = typeof KPI_ACTIONS[keyof typeof KPI_ACTIONS];

export function useKpiAction(tabKey: string, actionType: KpiAction) {
  return usePageState<KpiRoleId>(tabKey, actionType, null, true);
}
