/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';

import { gvPODOApiPrefix } from '../../config/config';
import { httpService } from '../../ajax/http.service';
import { twentyFourHoursInMs } from '../../utilities/utils';

/**
 * GET Single Image File content
 * When using Image Tag
 */
export function useImageByUsernameDomain(username: string | undefined, ref: any = null): any {
  const prevData = useRef<any>(null);

  const { data, isError, isLoading }: any = useQuery(
    ['image-by-username', username],
    () => getImageByUsername(username),
    {
      enabled: username && ref ? true : false,
      staleTime: twentyFourHoursInMs,
    }
  );

  if (data) {
    prevData.current = data;
  }
  if (isError) {
    prevData.current = null;
  }

  useEffect(() => {
    if (!prevData.current || !ref) {
      return;
    }

    // console.log('> read user image:', prevData.current);
    const reader = new FileReader();
    reader.readAsDataURL(prevData.current);
    reader.onload = () => {
      if (ref.current) {
        ref.current.src = reader.result;
      }
    };
  }, [prevData.current, ref]);

  return { data: prevData.current, isError, isLoading };
}

export function useImageRawByUsernameDomain(username: string, staleTime = 0): any {
  const { data, isError, isLoading }: any = useQuery(
    ['image-by-username', username],
    () => getImageByUsername(username),
    {
      enabled: username ? true : false,
      staleTime,
    }
  );
  return { data, isError, isLoading };
}

/**
 * ref: apm-frontend/apps/metatron-apm/src/app/core/services/image.service.ts
 * ref: apm-frontend/apps/metatron-apm/src/app/shared/components/image-box/image-box.component.ts
 * @param username
 * @returns
 */
export async function getImageByUsername(username: string | undefined) {
  const url = `${gvPODOApiPrefix()}/users/${username}/image`;
  return await httpService.get<any>(url, null, { responseType: 'blob' });
}

export async function getImageByUsernameAndSetImageStream(username: string, setImageStream: (image: any) => void) {
  const url = `${gvPODOApiPrefix()}/users/${username}/image`;
  const imageObject = await httpService.get<any>(url, null, { responseType: 'blob' });
  setImageStream(imageObject);
}
