/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum GV_MICRO_APP {
  // 1.0 version
  PORTAL = 'portal',
  PORTAL_STORE = 'store',
  DASHBOARD = 'dashboard',
  PODO = 'podo', // new v1.1
  REPORT = "report",
  PRINT = "print",
  ASSET = 'apm',
  APM = 'apm', // new v1.1
  MANAGEMENT = 'ameta',
  ASSET_METADATA = 'ameta', // new v1.1
  STUDIO = 'studio',
  SYSTEM = 'system',
  USER = 'user',
  // next version
  ANALYTICS = 'analytics',
  CMMS = 'cmms',
  ENERGY = 'energy',
  INVENTORY = 'inventory',
  PIPELINE = 'pipeline',
  // KOSMOS
  DETECTOR = 'detector',
  ANOMALY_DETECTION = 'ad', //'adetection',
}
