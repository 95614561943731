import { floor } from 'lodash';

// decimal 제약만 사용 (negative, positive 모두 허용)
/**
 양수, 음수 입력 가능하고 소수점 자릿수 제한에 사용
 * @param size 소수 자릿수
 * ex) '123.456' 처리 결과
 * positive() -> '123'
 * positive(0) -> '123'
 * positive(-1) -> '123'
 * positive(1) -> '123.4'
 * positive(3) -> '123.456'
 * positive(5) -> '123.456'
 */
function decimal(size = 0) {
  size = size < 0 ? 0 : size; // 음수 입력시 0으로 고정
  return (value: string) => {
    return floor(Number(value.replace(/,/g, '')), size);
  };
}

/**
 * 양수만 입력 가능하고 소수점 자릿수 제한에 사용
 * @param size 소수 자릿수
 * ex) '123.456' 처리 결과
 * positive() -> '123'
 * positive(0) -> '123'
 * positive(-1) -> '123'
 * positive(1) -> '123.4'
 * positive(3) -> '123.456'
 * positive(5) -> '123.456'
 */
function positive(size = 0) {
  size = size < 0 ? 0 : size; // 음수 입력시 0으로 고정
  return (value: string | undefined) => {
    if (!value || Number(value.replace(/,/g, '')) < 0) {
      return NaN;
    }
    return value ? floor(Number(value.replace(/,/g, '')), size) : NaN;
  };
}

const numberParser = {
  decimal,
  positive,
};

export { numberParser };
