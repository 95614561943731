/* eslint-disable @typescript-eslint/no-explicit-any */
import { BehaviorSubject } from 'rxjs';
import { isEmpty } from 'lodash';
import axios from 'axios';

import { deepEqualObject } from '../utilities/utils';
import { getBrowserLang } from '../i18n/browser-lang';
import { getDefaultThemeMode } from './config';
import { DisplayTypes, GV_APM_PAGE, GV_MICRO_APP, IChartInstance, IWidgetInstance, KOSMOS_DETECTOR_PAGE } from '../model';

/**
 * Load config.json of micro app
 */
export function getMicroAppConfig(microAppName: GV_MICRO_APP | string, jsonFile = 'config.json') {
  const url =
    microAppName !== GV_MICRO_APP.PORTAL ? `/${microAppName}/environments/${jsonFile}` : `/environments/${jsonFile}`;
  return axios.get<any>(url);
}

/**
 * Local Storage configuration per micro-apps
 */
export interface PersonalizationConfig {
  version: string;
  language: string;
  allLanguage: boolean;
  theme?: {
    mode?: string;
  },
  dashboard?: {
    filters?: {
      isRealtime: boolean,
      selectedDateType?: string,
      timeCycle?: number, timeRange?: number, timeRangeUnit?: string,
      period?: { from?: string, to?: string }
    },
    sortByFilter: string;
    viewTypes: string[];
    healthColumn: string;
    healthSort: string;
    locationStatusType: string;
    showAssetsSummary: boolean;
    sensorSortByAsset: string;
    sensorSortByParameter: string;
  };
  apm?: {
    overview: {
      widgets: IWidgetInstance[];
    };
    // parameter: {
    //   charts: IChartInstance[];
    // }
  };
  charts: IChartInstance[];
}

/**
 * Get Personalization Config Global Object
 */
function getPConfig() {
  // pConfig: PersonalizationConfig;
  if (!(window as any).GV_CONFIG_personalization) {
    (window as any).GV_CONFIG_personalization = {};
  }
  return (window as any).GV_CONFIG_personalization;
}

/**
 *  Personalization Config Version & Local Stroage Key
 */
const CONFIG_KEY = 'podo_personalization_config';
const VERSION = 'podo_1.3.0';
const onLanguageChange = new BehaviorSubject<string>('en');

export function initPersonalizationConfig(appName?: GV_MICRO_APP | string) {
  console.log('> [personalization] init personalization config into local storage');
  (window as any).GV_CONFIG_personalization = JSON.parse(localStorage.getItem(CONFIG_KEY) || '{}');

  // Init default personalization
  if (!getPConfig() || isEmpty(getPConfig()) || !getPConfig().version || getPConfig().version !== VERSION) {
    // for all applications
    // 기본값 변경은 version으로 관리
    (window as any).GV_CONFIG_personalization = {
      version: VERSION,
      language: getBrowserLang(),
      allLanguage: false,
      charts: [],
    };
  }

  // For portal personalization
  if (appName === GV_MICRO_APP.PORTAL && !getPConfig()?.theme) {
    (window as any).GV_CONFIG_personalization['theme'] = {
      mode: getDefaultThemeMode()
    };
  }

  // For dashboard personalization
  if (appName === GV_MICRO_APP.DASHBOARD && !getPConfig()?.dashboard) {
    (window as any).GV_CONFIG_personalization['dashboard'] = {
      // 주의) 초기화 때는 default value를 설정하면 안된다.
      // filters: {
      //   isRealtime: true,
      //   timeCycle: 10,
      //   timeRange: 1,
      //   timeRangeUnit: 'hours',
      //   period: {from: "2023-08-16T15:00:00.000Z", to: "2023-08-17T09:22:57.937Z"}
      // },
      sortByFilter: 'default',
      viewTypes: [
        DisplayTypes.ALARM.toUpperCase(),
        DisplayTypes.STATUS.toUpperCase(),
        DisplayTypes.TEMPERATURE.toUpperCase(),
        DisplayTypes.QUANTITY.toUpperCase(),
        DisplayTypes.DEFAULT.toUpperCase(),
      ],
      healthColumn: 'assetName,type,assetScore',
      healthSort: 'health,desc',
      locationStatusType: 'HEALTH',
      showAssetsSummary: true,
      sensorSortByAsset: 'statusName,asc',
      sensorSortByParameter: 'statusName,asc',
    };
  }

  // For apm personalization
  if (appName === GV_MICRO_APP.APM && !getPConfig()?.apm) {
    (window as any).GV_CONFIG_personalization['apm'] = {
      overview: {
        widgets: [],
      },
      // parameter: {
      //   charts: [],
      // }
    };
  }

  // For detector, edge등 apm UX 사용 경우 대비
  if (appName && !getPConfig()?.[appName]) {
    (window as any).GV_CONFIG_personalization[appName] = {
      overview: {
        widgets: [],
      },
      // parameter: {
      //   charts: [],
      // }
    };
  }

  localStorage.setItem(CONFIG_KEY, JSON.stringify(getPConfig()));
  console.log('> [personalization] load config:', getPConfig());
}

/**
 * Dashboard realtime filter option
 */
export function isPersonalizationApp(tabKey: string) {
  return tabKey.indexOf(GV_MICRO_APP.DASHBOARD) >= 0
    || tabKey.indexOf(GV_APM_PAGE.ASSET_LIST) >= 0
    || tabKey.indexOf(GV_APM_PAGE.ASSET_DETAIL_OVERVIEW) >= 0
    || tabKey.indexOf(GV_APM_PAGE.ASSET_DETAIL_TREND) >= 0
    || tabKey.indexOf(KOSMOS_DETECTOR_PAGE.ASSET_DETAIL_OVERVIEW) >= 0
    || tabKey.indexOf(KOSMOS_DETECTOR_PAGE.ASSET_DETAIL_PARAMETER) >= 0
    ;
}

export function saveRealtimeFiltersToLS(
  tabKey: string,
  filters: {
    isRealtime: boolean;
    selectedDateType?: string;
    timeCycle?: number;
    timeRange?: number;
    timeRangeUnit?: string;
    period?: { from?: string; to?: string };
  }
) {
  // if (!isPersonalizationApp(tabKey)) {
  //   return;
  // }

  // 하나의 dashboard 저장 경로로 모든 애플리케이션에서 사용한다.
  const microApp = 'dashboard'; //tabKey.split('.')[0];
  let updatedPersonalizationApp = getFromLS(microApp);
  if (!updatedPersonalizationApp) {
    updatedPersonalizationApp = { filters };
  } else {
    updatedPersonalizationApp.filters = filters;
  }

  saveToLS(microApp, updatedPersonalizationApp);
  console.log(`>> [personalization] save ${microApp} filters into LS:`, filters);
}

export function getRealtimeFiltersFromLS(tabKey: string) {
  if (!isPersonalizationApp(tabKey)) {
    return;
  }

  const microApp = 'dashboard'; //tabKey.split('.')[0];
  return getFromLS(microApp)?.filters;
}

export function getThemeModeFromLS(): 'light' | 'dark' {
  const mode = getFromLS('theme')?.mode;
  return mode || getDefaultThemeMode() || 'light';
}

export function updateThemeModeToLS(mode: 'light' | 'dark') {
  let theme = getFromLS('theme');
  if (!theme) {
    theme = {};
  }
  theme['mode'] = mode;

  saveToLS('theme', theme);
  console.log('> [personalization] update theme mode into LS:', mode);
}

/**
 * Direct access get with Micro App nam
 */
export function getAssetOverviewWidgetsFromLS(appName: GV_MICRO_APP | string = GV_MICRO_APP.APM) {
  const appLs = getFromLS(appName);
  if (!appLs) {
    console.log(`> You must set initPersonalizationConfig(${appName}) in ${appName}-app.tsx`);
    return;
  }
  
  return appLs?.overview?.widgets;
}

export function initAssetOverviewWidgetsToLS(appOverviewWidgets: IWidgetInstance[], appName: GV_MICRO_APP | string = GV_MICRO_APP.APM) {
  const appLs = getFromLS(appName);
  if (!appLs) {
    console.log(`> You must set initPersonalizationConfig(${appName}) in ${appName}-app.tsx`);
    return;
  }

  if (appOverviewWidgets && !deepEqualObject(appOverviewWidgets, appLs?.overview?.widgets)) {
    appLs.overview.widgets = appOverviewWidgets;

    saveToLS(appName, appLs);
    console.log('>> [personalization] initialize ' + appName + ' overview layout into LS:', appOverviewWidgets);
  }
}

export function saveAssetOverviewWidgetsToLS(appOverviewWidgets: IWidgetInstance[], appName: GV_MICRO_APP | string = GV_MICRO_APP.APM) {
  const appLs = getFromLS(appName);
  if (!appLs) {
    console.log(`> You must set initPersonalizationConfig(${appName}) in ${appName}-app.tsx`);
    return;
  }

  appLs.overview.widgets = appOverviewWidgets;
  saveToLS(appName, appLs);
  console.log('> [personalization] save APM overview layout into LS:', appOverviewWidgets);
}

/**
 * Common save and get with LocalStorage (LS)
 */
export function saveToLS(key: string, value: any) {
  (window as any).GV_CONFIG_personalization = {
    ...getPConfig(),
    [key]: value,
  };
  localStorage.setItem(CONFIG_KEY, JSON.stringify(getPConfig()));
}

export function getFromLS(key: string) {
  let ls: any = {};
  try {
    ls = JSON.parse(localStorage.getItem(CONFIG_KEY) || '') || {};
  } catch (e) {
    console.log('> [personalization] parse pConfig from localStorage:', e);
  }
  return ls[key];
}

export function saveLangToLS(value: string) {
  (window as any).GV_CONFIG_personalization = {
    ...getPConfig(),
    language: value,
  };
  localStorage.setItem(CONFIG_KEY, JSON.stringify(getPConfig()));
  onLanguageChange.next(value);
}

export function setNotice(value: string) {
  localStorage.setItem('noticeIds', value);
}

// -------------------------------------------------------------------------
//
// yAxis
//
// -------------------------------------------------------------------------
export function getChartInstanceFromLs(id: string): IChartInstance {
  const charts = getFromLS('charts');
  return charts?.find((d: IChartInstance) => d.id === id);
}

export function saveChartInstanceToLs(chartInstance: IChartInstance) {
  const charts = getFromLS('charts');
  const filteredCharts = charts?.filter((d: IChartInstance) => d.id !== chartInstance.id) || [];
  const newCharts = [...filteredCharts, chartInstance];
  saveToLS('charts', newCharts);
}
