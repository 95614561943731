import { AnalysisModelType, IModel, ModelGroupType } from '../model';
import { ModelRoleType } from '../model/types/model-type';

export function setModelType(models: any[]) {
  if (!models || !models.length) {
    return models;
  }

  return models.map((model: any) => {
    return {
      ...model,
      // modelType: model.analysisModelVersion?.analysisModel?.role,
      modelRoleType: model.analysisModel?.role,
    };
  });
}

/**
 * Not Used from 2022.10.04
 */
// export function getAnalysisModelVersionAndName(model: IModel): string {
//   const anylysisModelVersion: any = model.analysisModelVersion;
//   const anylysisModel = anylysisModelVersion.analysisModel;
//   return `${anylysisModel.name} ${anylysisModelVersion.name}`;
// }

export function getAnalysisModelName(model: IModel): string {
  return model.modelType === ModelGroupType.REVISION ? `${model?.name}` : `${model?.analysisModel?.name}`;
}

export function clearObject(model: any): any {
  Object.keys(model).forEach((key) => {
    delete model[key];
  });
}

export function getModelRoleType(modelTypeId: AnalysisModelType): ModelRoleType {
  switch (modelTypeId) {
    case AnalysisModelType.MACHINE_LEARNING_AI: //ModelRoleType.HEALTH:
    case AnalysisModelType.CURRENT_IMBALANCE:
    case AnalysisModelType.STATISTICAL:
      return ModelRoleType.HEALTH;
    case AnalysisModelType.VIBRATION:
      return ModelRoleType.VIBRATION;
    case AnalysisModelType.RUL:
      return ModelRoleType.RUL;
    default:
      return ModelRoleType.HEALTH;
  }
}
