// AssetTemplateProjectionType
export enum AssetTemplateProjectionType {
  ForListView = 'forListView',
  ForAddAssetView = 'forAddAssetView',
  ForAssetTemplateTypeView = 'forAssetTemplateTypeView',
}

// AssetTemplateType
export enum AssetTemplateType {
  AddParameterGroup = 'addParameterGroup',
}
