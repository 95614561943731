import { map, flatten } from 'lodash';

export function getFlattenFieldsError(allFields: any[], fieldNameForFilter?: string): string[] {
  return fieldNameForFilter
    ? flatten(
        map(
          allFields.filter(({ name }: any) => name.indexOf(fieldNameForFilter) > -1),
          'errors'
        )
      )
    : flatten(map(allFields, 'errors'));
}
