import dayjs from 'dayjs';
import { t } from 'i18next';

import { cookieService } from '../cookie/cookie.service';
import { IntervalShortType, TimeRangeUnitType } from '../model';
import { getDayjs } from './date-format';
import {
  timeFormatHourMinute,
  timeFormatMonthDate,
  timeFormatMonthHourMinDate,
  timeFormatSecond,
} from './time-format-locale';
import { timezoneService } from './timezone.service';

/**
 * With Class include static methods
 */
export class TimeUtil {
  static DEFAULT_FORMAT = timeFormatSecond();
  static DAY_FORMAT = 'MM-DD';
  static TIME_FORMAT = 'HH:mm';

  static time(date = dayjs().toDate(), format = TimeUtil.DEFAULT_FORMAT): any {
    return dayjs(date, format);
  }

  static format(date = dayjs().toDate(), format = TimeUtil.DEFAULT_FORMAT): string {
    return dayjs(date).format(format);
  }

  /**
   * Format ISO String
   *
   * @param date
   */
  static formatISOString(date: any = dayjs()) {
    return dayjs(date).toISOString();
  }

  static hours() {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(`${i.toFixed(0).padStart(2, '0')}:00`);
    }
    return hours;
  }

  static calculateRange(option: any) {
    return {
      from: TimeUtil.format(dayjs().subtract(option.timeRange, option.timeRangeUnit).toDate()),
      to: TimeUtil.format(dayjs().toDate()),
    };
  }

  /**
   * end time
   *   - 기준 7일 이내면 7일간격으로 만들어주고,
   *   - 7일 이상이면 그대로 사용한다.
   */
  static make7DaysRangeByEndDate(start: string, end: string) {
    if (dayjs(end).diff(dayjs(start), 'day') < 7) {
      return {
        start: timezoneService.dateToUtcString(dayjs(end).subtract(7, 'day').startOf('day').toDate()),
        end: timezoneService.stringToUtcString(end),
      };
    } else {
      return {
        start: timezoneService.stringToUtcString(start),
        end: timezoneService.stringToUtcString(end),
      };
    }
  }

  static axisFormat(date: any, period: number) {
    if (period > 24 * 7) {
      return dayjs(date).format(timeFormatMonthDate());
      // return dayjs(date).format(TimeUtil.DAY_FORMAT);
    }
    if (period > 24) {
      return dayjs(date).format(timeFormatMonthHourMinDate());
      // return `${dayjs(date).format(TimeUtil.DAY_FORMAT)}
      // ${dayjs(date).format(TimeUtil.TIME_FORMAT)}`.replace(/ /g, '');
    }
    return dayjs(date).format(timeFormatHourMinute());
    // return dayjs(date).format(TimeUtil.TIME_FORMAT);
  }
}

/**
 * With Function
 */
export function setDaysOfWeekTranslate() {
  return [t('CAL.SUN'), t('CAL.MON'), t('CAL.TUE'), t('CAL.WED'), t('CAL.THU'), t('CAL.FRI'), t('CAL.SAT')];
}

export function setMonthTranslate() {
  return [
    t('CAL.Jan'),
    t('CAL.Fed'),
    t('CAL.Mar'),
    t('CAL.Apr'),
    t('CAL.May'),
    t('CAL.Jun'),
    t('CAL.Jul'),
    t('CAL.Aug'),
    t('CAL.Sep'),
    t('CAL.Oct'),
    t('CAL.Nov'),
    t('CAL.Dec'),
  ];
}

export function convertIntervalTypeToISODuration(intervalType: IntervalShortType, time: number): any {
  if (!intervalType || !time) {
    return undefined;
  }
  let value = time;
  const isoLastDateType = intervalType.charAt(0);
  if (intervalType === IntervalShortType.MLS) {
    value = value / 1000;
  } else if (intervalType === IntervalShortType.MCS) {
    value = value / 1000000;
  }
  return `PT${value}${isoLastDateType}`;
}

export function convertISO8601DurationToMicroSec(isoFormat: string): any {
  //맨뒤값만 처리: PT10S 또는 PT20M 또는 PT2H
  let time = convertISO8601DurationToNumber(isoFormat);
  let intervalType = convertISO8601DurationToSelectType(isoFormat);

  if (!time || !intervalType) {
    return [undefined, undefined];
  }

  if (intervalType === IntervalShortType.SEC && time < 1) {
    if (time * 1000 >= 1) {
      intervalType = IntervalShortType.MLS;
      time = time * 1000;
    } else if (time * 1000000 >= 1) {
      intervalType = IntervalShortType.MCS;
      time = time * 1000000;
    }
  }
  return [time, intervalType];
}

/**
 * Select 컴포넌트에 H, M, S 표현
 */
export function convertISO8601DurationToSelectType(
  isoFormat: string,
  isoLastDateType: string | undefined = undefined
): any {
  if (!isoFormat || isoFormat === '') {
    return '';
  }

  if (!isoLastDateType) {
    isoLastDateType = isoFormat.substring(isoFormat.length - 1);
  }

  switch (isoLastDateType) {
    case 'Y':
      return IntervalShortType.YEAR;
    case 'W':
      return IntervalShortType.WEEK;
    case 'D':
      return IntervalShortType.DAY;
    case 'H':
      return IntervalShortType.HOUR;
    case 'M':
      return isoFormat.indexOf('T') > 0 ? IntervalShortType.MIN : IntervalShortType.MONTH;
    case 'S':
      return IntervalShortType.SEC;
  }
}

/**
 * InputNumber 컴포넌트에 number 표현
 */
export function convertISO8601DurationToNumber(
  isoFormat: string,
  isoLastDateType: string | undefined = undefined
): any {
  if (!isoFormat || isoFormat === '') {
    return '';
  }

  if (!isoLastDateType) {
    isoLastDateType = isoFormat.substring(isoFormat.length - 1);
  }

  const duration = dayjs.duration(isoFormat);
  switch (isoLastDateType) {
    case 'Y':
      return duration.asYears();
    case 'W':
      return duration.asWeeks();
    case 'D':
      return duration.asDays();
    case 'H':
      return duration.asHours();
    case 'M':
      return isoFormat.indexOf('T') > 0 ? duration.asMinutes() : duration.asMonths();
    case 'S':
      return duration.asSeconds();
  }
}

/**
 * 화면에 user에게 보여주는 display value로 변환
 */
export function convertISO8601DurationToDisplayFormat(
  isoFormat: string,
  isoLastDateType: string | undefined = undefined
): any {
  if (!isoFormat || isoFormat === '') {
    return '';
  }

  if (!isoLastDateType) {
    isoLastDateType = isoFormat.substring(isoFormat.length - 1);
  }

  const duration = dayjs.duration(isoFormat);
  switch (isoLastDateType) {
    case 'Y':
      return `${duration.asYears()} ${t('TIME.years')}`;
    case 'W':
      return `${duration.asWeeks()} ${t('TIME.weeks')}`;
    case 'D':
      return `${duration.asDays()} ${t('TIME.days')}`;
    case 'H':
      return `${duration.asHours()} ${t('TIME.hours')}`;
    case 'M':
      return isoFormat.indexOf('T') > 0
        ? `${duration.asMinutes()} ${t('TIME.minutes')}`
        : `${duration.asMonths()} ${t('TIME.months')}`;
    case 'S':
      if (duration.asSeconds() < 1) {
        if (duration.asSeconds() * 1000 >= 1) {
          return `${duration.asSeconds() * 1000} ${t('TIME.Milli Sec')}`;
        } else if (duration.asSeconds() * 1000000 >= 1) {
          return `${duration.asSeconds() * 1000000} ${t('TIME.Micro Sec')}`;
        }
      }
      return `${duration.asSeconds()} ${t('TIME.seconds')}`;
  }
}

// From GV APM
// ref: https://www.digi.com/resources/documentation/digidocs/90001437-13/reference/r_iso_8601_duration_format.htm
function convertISO8601ToDateFormat(isoFormat: string, dateFormat: string): any {
  const duration = dayjs.duration(isoFormat);
  const data = duration.asMilliseconds();
  return dateFormat ? dayjs(data).format(dateFormat) : dayjs(data);
}

export function convertISO8601DurationToDisplayDate(
  isoFormat: string,
  lang: string = cookieService.getLocale(),
  dateFormat?: string
): string {
  if (!isoFormat || isoFormat === '') {
    return '';
  }

  const hmsKey: any = {
    en: {
      hms: 'H [hours], m [minutes] [and] s [seconds]',
      hm: 'H [hours] [and] m [minutes]',
      hs: 'H [hours] [and] s [seconds]',
      ms: 'm [minutes] [and] s [seconds]',
      h: 'H [hours]',
      m: 'm [minutes]',
      s: 's [seconds]',
    },
    ko: {
      hms: 'H 시간 m 분 s 초',
      hm: 'H 시간 m 분',
      hs: 'H 시간 s 초',
      ms: 'm 분 s 초',
      h: 'H 시간',
      m: 'm 분',
      s: 's 초',
    },
  };
  const hms = [
    isoFormat.includes('H') ? 'h' : null,
    isoFormat.includes('M') ? 'm' : null,
    isoFormat.includes('S') ? 's' : null,
  ]
    .filter((d) => d)
    .join('');
  const finalFormat = dateFormat || hmsKey[lang][hms] || 'HH:mm:ss';
  return convertISO8601ToDateFormat(isoFormat, finalFormat);
}

export function convertAlarmElapsedTime(isoFormat: string) {
  // 향후 alarm time format에 대한 값은 menus api의 filter 값으로 받아서 처리한다.
  // default 포멧 "mm:ss"
  return convertISO8601DurationToDisplayDate(isoFormat, undefined, 'mm:ss');
}

/**
 * Checks if a given date is in the future.
 *
 * @param {any} date - The date to be checked.
 * @return {boolean} - Returns true if the date is in the future, false otherwise.
 */
export function checkFutureDate(date: any): boolean {
  const now = getDayjs(new Date());
  return date && date > now;
}

export function checkEndDate(startDate: any, endDate: any): any {
  const start = getDayjs(startDate);
  const end = getDayjs(endDate);
  if (start > end) {
    return start.add(1, 'hour');
  }
  return end;
}

export function convertTimeUnitToISO8601Duration(value: number, unit: string): any {
  switch (unit.toLowerCase()) {
    case TimeRangeUnitType.MINUTES:
      return `PT${value}M`;
    case TimeRangeUnitType.HOURS:
      return `PT${value}H`;
    case TimeRangeUnitType.DAYS:
      return `P${value}D`;
  }
  return undefined;
}
