export enum GlobalStateType {
  CHANGED_HIERARCHY_PATH = 'CHANGED_HIERARCHY_PATH',
  CHANGED_GLOBAL_CONFIG = 'CHANGED_GLOBAL_CONFIG',
  CHANGED_ASSET = 'CHANGED_ASSET',
  CHANGED_PARAMETER = 'CHANGED_PARAMETER',
  CHANGED_PARAMETER_SPEC = 'CHANGED_PARAMETER_SPEC',
  CHANGED_MODEL = 'CHANGED_MODEL',
  CHANGED_DASHBOARD = 'CHANGED_DASHBOARD',
  CHANGED_USER_DETAIL_INFO = 'CHANGED_USER_DETAIL_INFO',
  CHANGED_ALARM_STATUS = 'CHANGED_ALARM_STATUS',
  CHANGED_WORK_ORDER_STATUS = 'CHANGED_WORK_ORDER_STATUS',
  CHANGED_REPORT = 'CHANGED_REPORT',
  CHANGED_PORTAL_HEADER = 'CHANGED_PORTAL_HEADER',
  CHANGED_REPORT_HISTORY = 'CHANGED_REPORT_HISTORY'
}

export interface GlobalStatePayload {
  id?: string;
  type: GlobalStateType;
  payload?: any;
}
