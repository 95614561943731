import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import { defaultsDeep } from 'lodash';

import { ja } from './antd/ja_JP';
import { en } from './antd/en_GB';
import { ko } from './antd/ko_KR';
import { cn } from './antd/zh_CN';
import { ko_datepicker_locale } from './date-picker/ko';
import { en_datepicker_locale } from './date-picker/en';
import { ja_datepicker_locale } from './date-picker/ja';
import { cn_datepicker_locale } from './date-picker/cn';
import { pagination_ko } from './pagination/ko';
import { pagination_en } from './pagination/en';
import { pagination_ja } from './pagination/ja';
import { pagination_cn } from './pagination/cn';

import { getBrowserLang } from './browser-lang';
import { cookieService } from '../cookie/cookie.service';
import { commonI18n } from '../../assets/i18n';

export function initI18N(resources: any) {
  i18next
    .use(XHR)
    .use(initReactI18next)
    .init({
      debug: false,
      lng: getDefaultLang(),
      fallbackLng: false,
      // 4가지 설정하면 초기에 모든 파일이 다운로드됨
      // i18n 변경시 download 하도록 변경한다.
      // ref: https://www.i18next.com/overview/api - loadLanguages
      // fallbackLng: ['en', 'ko', 'ja', 'cn'],
      react: {
        useSuspense: true,
      },
      resources,
    });
}

export function getDefaultLang() {
  return cookieService.get('I18N_LANG') || getBrowserLang() || 'en';
}

export function getAntDLocale() {
  const lang = getDefaultLang();
  switch (lang) {
    case 'ko':
      return ko;
    case 'en':
      return en;
    case 'cn':
    case 'zh': // 중국어
      return cn;
    case 'ja':
      return ja;
    default:
      return en;
  }
}

export function getAntDLang() {
  const lang = getDefaultLang();
  switch (lang) {
    case 'ko':
    case 'en':
    case 'cn':
    case 'zh':
    case 'ja':
      return lang;
    default:
      return 'en';
  }
}

export function getDatepickerLocale(): any {
  const lang = getDefaultLang();
  switch (lang) {
    case 'ko':
      return ko_datepicker_locale;
    case 'en':
      return en_datepicker_locale;
    case 'cn':
    case 'zh':
      return cn_datepicker_locale;
    case 'ja':
      return ja_datepicker_locale;
    default:
      return en_datepicker_locale;
  }
}

export function getPaginationLocale(): any {
  const lang = getDefaultLang();
  switch (lang) {
    case 'ko':
      return pagination_ko;
    case 'en':
      return pagination_en;
    case 'cn':
    case 'zh':
      return pagination_cn;
    case 'ja':
      return pagination_ja;
    default:
      return pagination_en;
  }
}

export function changeDefaultLang(lang: string) {
  if (lang) {
    i18next.loadLanguages([lang]).then(() => {
      i18next.changeLanguage(lang).then(() => {
        cookieService.set('I18N_LANG', lang);
      });
    });
  }
}

export function getI18nResources(localResource: any): any {
  const { en, ko, ja, cn } = commonI18n;
  return {
    en: {
      translation: defaultsDeep(localResource.en, en),
    },
    ko: {
      translation: defaultsDeep(localResource.ko, ko),
    },
    ja: {
      translation: defaultsDeep(localResource.ja, ja),
    },
    cn: {
      translation: defaultsDeep(localResource.cn, cn),
    },
  };
}
