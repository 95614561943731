export interface IChartProps {
  height?: number | string;
  width?: number;
  forceInitChart?: boolean;
  hasZoom?: boolean;
  connectChartGroupName?: string;
  expandChart?: boolean;
  isHide?: boolean;
}

export enum ChartType {
  BAR = 'bar',
  TIME = 'time',
  FFT = 'fft',
  STATUS = 'status',
  HEAT_MAP = 'heatMap',
  PIE = 'pie',
  GANTT = 'gantt',
  CATEGORY = 'category',
  TREND = 'trend',
}

export enum SeriesType {
  BAR = 'bar',
  LINE = 'line',
  SCATTER = 'scatter',
  EFFECT_SCATTER = 'effectScatter',
  HEAT_MAP = 'heatMap',
  PIE = 'pie',
  BOX_PLOT = 'boxPlot',
  RADAR = 'radar',
  GRAPH = 'graph',
  GAUGE = 'gauge',
}

export enum Position {
  AUTO = 'auto',
  START = 'start',
  END = 'end',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
  INSIDE = 'inside',
  INSIDE_TOP = 'insideTop',
  INSIDE_LEFT = 'insideLeft',
  INSIDE_RIGHT = 'insideRight',
  INSIDE_BOTTOM = 'insideBottom',
  OUTSIDE = 'outside',
}

export enum AxisType {
  CATEGORY = 'category',
  TIME = 'time',
  VALUE = 'value',
  LOG = 'log',
  X = 'xAxis',
  Y = 'yAxis',
  SUB = 'subAxis',
  NONE = 'none',
}

export enum LineType {
  SOLID = 'solid',
  DASHED = 'dashed',
  DOTTED = 'dotted',
}

export enum ColorSet {
  text = '#838695',
  line = '#cbcede',

  threshold = '#FC2454',
  normal = '#63BCEC',
}

export interface IAxisRange {
  min?: number;
  max?: number;
}

export interface IChartInstance {
  id: string;
  xAxis?: IAxisRange;
  yAxis?: IAxisRange;
  // config?: any;
  // name: string;
  // x: number;
  // y: number;
  // w: number;
  // h: number;
  // minW?: number;
  // minH?: number;
}

export interface ILegend {
  name?: string;
  selected?: boolean;
  color?: string;
  type?: string;
}

export interface IPieSeriesData {
  id: string;
  name: string;
  value: number;
  rate: number;
  itemStyle: {
    color: any;
  };
}
