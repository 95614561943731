/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useUnmount } from 'ahooks';
import { ReplaySubject } from 'rxjs';

export function clearStateInInitParams(eventKey: string, payload: any) {
  if (payload) {
    getSender(eventKey).sendPayload(payload);
    // console.log('[one-way subject send] tabKey, eventKey, payload', tabKey, eventKey, payload);
  }
}

/**
 * Use None UI
 */
export function getOneWayClearSubject() {
  if (!(window as any).GV_STATE_RX_oneWayClearSubject) {
    (window as any).GV_STATE_RX_oneWayClearSubject = {};
  }
  return (window as any).GV_STATE_RX_oneWayClearSubject;
}

function getSender(eventKey: string) {
  if (!getOneWayClearSubject()[eventKey]) {
    const subject = new ReplaySubject<any>(1);
    getOneWayClearSubject()[eventKey] = {
      sendPayload: (payload: any) => subject.next(payload),
      payloadObservable: () => subject.asObservable(),
    };
  }
  return getOneWayClearSubject()[eventKey];
}

export function useListenClearStateInInitParams(eventKey: string, calllerFn: any = undefined, isUnmount = true) {
  const [state, setState] = useState<any>({});
  useUnmountOneWayClearState(eventKey, isUnmount);

  useEffect(() => {
    if (!eventKey) {
      return;
    }

    const subject = getSender(eventKey);
    const subscription = subject.payloadObservable().subscribe((payload: any) => {
      if (!payload) {
        return;
      }

      calllerFn ? calllerFn(payload) : setState(payload);
    });
    return () => subscription.unsubscribe();
  }, [eventKey]);

  return state;
}

function removeOneWayClearState(eventKey: string) {
  if (getOneWayClearSubject()) {
    delete getOneWayClearSubject()[eventKey];
  }
}

function useUnmountOneWayClearState(eventKey: string, isUnmount = false) {
  useUnmount(() => {
    if (isUnmount) {
      removeOneWayClearState(eventKey);
    }
  });
}
