export function getSortItems(key: string, type: string) {
  return {
    sorter: {
      compare: (a: any, b: any) => {
        if (type === 'number') {
          return a[key] - b[key];
        } else {
          const upperCaseA = a[key].toUpperCase();
          const upperCaseB = b[key].toUpperCase();

          if (upperCaseA > upperCaseB) return 1;
          if (upperCaseA < upperCaseB) return -1;
          if (upperCaseA === upperCaseB) return 0;
        }
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
  };
}
