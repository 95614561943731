import { createContext, useContext } from 'react';

export const sharedContext: any = {};

export function createSharedContext<T>(contextKey: string, defaultValue: T | null = null) {
  const createdContext = createContext<T | null>(defaultValue);

  if (!sharedContext[contextKey]) {
    sharedContext[contextKey] = createdContext;
  }
  return sharedContext[contextKey];
}

export function useSharedContext(contextKey: string): any {
  return useContext(sharedContext[contextKey]);
}
