import { t } from 'i18next';

const COLUMN_TYPE: any = {
  parameterName: 'Parameter Name',
  physicalName: 'Data Name',
  unit: 'Unit',
  dataType: 'Parameter Data Type',
  displayType: 'Display Type',
  assetName: 'Asset Name',
};

function hasCustomException(error: any): boolean {
  console.log('hasCustomException', error, error?.response?.status);
  return error && error.response?.status === 400;
}

function getMessageToJson(error: any): any {
  return JSON.parse(error.response.data.message);
}

function codeToMessage(data: any, values?: any) {
  switch (data.code) {
    case 'PGFU0003':
    case 'PGFU0005':
    case 'PGFU0007':
    case 'PGFU0008':
    case 'PGFU0009':
    case 'AS0008':
    case 'AS0006':
      return (
        (data?.row ? t(`MANAGE.Row marker`, { row: data.row }) : '') +
        t(`API.${data.code}`, {
          column: COLUMN_TYPE[data.column] ? t(`MANAGE.${COLUMN_TYPE[data.column]}`) : data.column,
        })
      );
    case 'PGFU0006':
    case 'AS0003':
    case 'AS0007':
    case 'AS0010':
      return t(`API.${data.code}`, { values: data.values.join(',') });
    case 'PGFU0001':
    case 'PGFU0002':
    case 'MD0004':
    case 'MD0005':
    case 'AS0004':
    case 'AS0009':
      return t(`API.${data.code}`, values);
    default:
      return t(`API.${data.code}`, values);
  }
}

export const apiResponseService = {
  hasCustomException,
  getMessageToJson,
  codeToMessage,
};
