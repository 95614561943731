import { usePageState } from './web-page.state';

export function useSelectedAlarmsByMonthState(tabKey: string, initValue: any = null) {
  return usePageState(tabKey, 'selected-alarms-by-month', initValue);
}

export function useSelectedAlarmsByDayState(tabKey: string, initValue: any = null) {
  return usePageState(tabKey, 'selected-alarms-by-day', initValue);
}

export function useChangedAssetDetailAlarmSearchCondition(tabKey: string, initValue: any = null) {
  return usePageState(tabKey, 'asset-detail-alarm-search-condition', initValue);
}

export function useAlarmGroupRecrodsDrawerState(tabKey: string, initValue = false) {
  return usePageState(tabKey, 'alarm-group-records-drawer', initValue);
}

export function useWorkOrderRecrodsDrawerState(tabKey: string, initValue = false) {
  return usePageState(tabKey, 'work-order-records-drawer', initValue);
}
