/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep, isString } from 'lodash';
import { atom, useAtom } from 'jotai';
import dayjs from 'dayjs';

import { usePageState } from './web-page.state';
import { getDateFormat, getInterval } from '../../timezone';
import { getRandomId } from '../../utilities/random-generator';
import { realtimeAggregation } from '../../config/config';
import { getRealtimeFiltersFromLS } from '../../config/personalization-config.service';
import { AggregationFilterType, DateType, TimeRangeUnitType } from '../../model';
import { isNullOrUndefined } from '../../utilities/utils';

/**
 * Location Filter
 */
export function getLocationFilterState() {
  if (!(window as any).GV_STATE_locationFilterState) {
    (window as any).GV_STATE_locationFilterState = {};
  }
  return (window as any).GV_STATE_locationFilterState;
}

function LocationFilterState(tabKey: string) {
  if (!getLocationFilterState()[tabKey]) {
    getLocationFilterState()[tabKey] = atom<any>({});
  }
  return getLocationFilterState()[tabKey];
}

export function useLocationFilterState(tabKey: string) {
  return useAtom(LocationFilterState(tabKey));
}

/**
 * Widget Location Filter
 */
export function useWidgetLocationFilterState(tabKey: string, widgetInstanceId: string) {
  return usePageState(tabKey, widgetInstanceId, '', true);
}

/**
 * Location Filter
 */
export function getDepthSelectedItemState() {
  if (!(window as any).GV_STATE_depthSelectedItemState) {
    (window as any).GV_STATE_depthSelectedItemState = {};
  }
  return (window as any).GV_STATE_depthSelectedItemState;
}

export function DepthSelectedItemState(tabKey: string) {
  if (!getDepthSelectedItemState()[tabKey]) {
    getDepthSelectedItemState()[tabKey] = atom<any>({ depthItems: [], selectedItem: [] });
  }

  return getDepthSelectedItemState()[tabKey];
}

export function useDepthSelectedItemState(tabKey: string) {
  return useAtom<any, any, any>(DepthSelectedItemState(tabKey));
}

/**
 * RealTime Option Filter
 */
export function getSwitchRealtimeFilterState() {
  if (!(window as any).GV_STATE_switchRealtimeFilterState) {
    (window as any).GV_STATE_switchRealtimeFilterState = {};
  }
  return (window as any).GV_STATE_switchRealtimeFilterState;
}

function SwitchRealTimeState(tabKey: string, initValue: any = true) {
  if (!isNullOrUndefined(initValue)) {
    if (initValue === true || initValue === 'true') {
      initValue = true;
    }
    if (initValue === false || initValue === 'false') {
      initValue = false;
    }
  }

  if (!getSwitchRealtimeFilterState()[tabKey]) {
    getSwitchRealtimeFilterState()[tabKey] = atom<boolean>(initValue);
  }
  return getSwitchRealtimeFilterState()[tabKey];
}
export function useSwitchRealTime(tabKey: string, initValue = true) {
  return useAtom<any, any, any>(SwitchRealTimeState(tabKey, initValue));
}

export function getSelectedDateTypeState() {
  if (!(window as any).GV_STATE_selectedDateTypeState) {
    (window as any).GV_STATE_selectedDateTypeState = {};
  }
  return (window as any).GV_STATE_selectedDateTypeState;
}
function SelectedDateTypeState(tabKey: string, initValue: any = null) {
  if (!getSelectedDateTypeState()[tabKey]) {
    getSelectedDateTypeState()[tabKey] = atom<string | null>(initValue);
  }
  return getSelectedDateTypeState()[tabKey];
}
export function useSelectedDateTypeState(tabKey: string, initValue: any = null) {
  return useAtom<any, any, any>(SelectedDateTypeState(tabKey, initValue));
}

export function getDisabledRealtimeFilterState() {
  if (!(window as any).GV_STATE_disabledRealtimeFilterState) {
    (window as any).GV_STATE_disabledRealtimeFilterState = {};
  }
  return (window as any).GV_STATE_disabledRealtimeFilterState;
}

function DisabledRealTimeState(tabKey: string, initValue = false) {
  if (!getDisabledRealtimeFilterState()[tabKey]) {
    getDisabledRealtimeFilterState()[tabKey] = atom<boolean>(initValue);
  }
  return getDisabledRealtimeFilterState()[tabKey];
}

export function useDisabledRealTime(tabKey: string, initValue = false) {
  return useAtom<any, any, any>(DisabledRealTimeState(tabKey, initValue));
}

function getDefaultInitRealtimeFilters(tabKey: string) {
  // 1순위
  const realtimeFiltersFromLS = getRealtimeFiltersFromLS(tabKey);
  if (realtimeFiltersFromLS?.timeCycle && realtimeFiltersFromLS?.timeRange) {
    return realtimeFiltersFromLS;
  }

  // 2순위
  const dashboardMenuFilters = getDashboardMenuFilters();
  if (dashboardMenuFilters?.timeCycle && dashboardMenuFilters?.timeRange) {
    return dashboardMenuFilters;
  }

  // default
  return {
    timeCycle: 10,
    timeRange: 1,
    timeRangeUnit: TimeRangeUnitType.HOURS,
  };
}

export function getRealtimeOptionsFilterState() {
  if (!(window as any).GV_STATE_realtimeOptionsFilterState) {
    (window as any).GV_STATE_realtimeOptionsFilterState = {};
  }
  return (window as any).GV_STATE_realtimeOptionsFilterState;
}

function RealtimeOptionsFilterState(tabKey: string) {
  const defaultInitRealtimeFilters = getDefaultInitRealtimeFilters(tabKey);

  if (!getRealtimeOptionsFilterState()[tabKey]) {
    getRealtimeOptionsFilterState()[tabKey] = atom<{
      timeCycle: number;
      timeRange: number;
      timeRangeUnit: TimeRangeUnitType;
      period: {
        from: Date;
        to: Date;
      };
    }>({
      timeCycle: defaultInitRealtimeFilters.timeCycle,
      timeRange: defaultInitRealtimeFilters.timeRange,
      timeRangeUnit: defaultInitRealtimeFilters.timeRangeUnit,
      period: {
        from: dayjs().startOf('h').toDate(),
        to: new Date(),
      },
    });
  }
  return getRealtimeOptionsFilterState()[tabKey];
}

export function useRealtimeOptionsFilterState(tabKey: string) {
  return useAtom<any, any, any>(RealtimeOptionsFilterState(tabKey));
}

/**
 * RealTime Option Filter
 */
export function getPeriodOptionsFilterState() {
  if (!(window as any).GV_STATE_periodOptionsFilterState) {
    (window as any).GV_STATE_periodOptionsFilterState = {};
  }
  return (window as any).GV_STATE_periodOptionsFilterState;
}

function PeriodOptionsFilterState(tabKey: string) {
  if (!getPeriodOptionsFilterState()[tabKey]) {
    getPeriodOptionsFilterState()[tabKey] = atom<{
      aggregation: string;
      period: {
        from: Date;
        to: Date;
      };
    }>({
      aggregation: AggregationFilterType.PERIOD,
      period: {
        from: dayjs().startOf('d').toDate(),
        to: new Date(),
      },
    });
  }
  return getPeriodOptionsFilterState()[tabKey];
}

export function usePeriodOptionsFilterState(tabKey: string) {
  return useAtom<any, any, any>(PeriodOptionsFilterState(tabKey));
}

export function useRefreshFilterState(tabKey: string) {
  return useAtom<any, any, any>(RefreshPageState(tabKey));
}

export function useHealthStatusFilterState(tabKey: string, initValue?: string) {
  return useAtom<any, any, any>(HealthStatusState(tabKey, initValue));
}

export function setDashboardMenuFilters(filters: any) {
  (window as any).GV_STATE_dashboardMenuFilters = filters;
}

export function getDashboardMenuFilters() {
  return (window as any).GV_STATE_dashboardMenuFilters;
}

export function setSearchMaxDays(days: number) {
  (window as any).GV_STATE_searchMaxDays = days;
}

export function getSearchMaxDays() {
  if (!(window as any).GV_STATE_searchMaxDays) {
    (window as any).GV_STATE_searchMaxDays = 90;
  }
  return (window as any).GV_STATE_searchMaxDays;
}

/**
 * Use Runtime Interval
 */
export function getRuntimeIntervalState() {
  if (!(window as any).GV_STATE_runtimeIntervalState) {
    (window as any).GV_STATE_runtimeIntervalState = {};
  }
  return (window as any).GV_STATE_runtimeIntervalState;
}

function RuntimeIntervalState(tabKey: string) {
  if (!getRuntimeIntervalState()[tabKey]) {
    getRuntimeIntervalState()[tabKey] = atom<string>('');
  }
  return getRuntimeIntervalState()[tabKey];
}

export function useRuntimeIntervalState(tabKey: string) {
  return useAtom(RuntimeIntervalState(tabKey));
}

/**
 * Use Refresh Page
 */
export function getRefreshPageState() {
  if (!(window as any).GV_STATE_refreshPageState) {
    (window as any).GV_STATE_refreshPageState = {};
  }
  return (window as any).GV_STATE_refreshPageState;
}
export function RefreshPageState(tabKey: string) {
  if (!getRefreshPageState()[tabKey]) {
    getRefreshPageState()[tabKey] = atom<string>('');
  }
  return getRefreshPageState()[tabKey];
}

/**
 * Use Health Status
 */
export function getHealthStatusState() {
  if (!(window as any).GV_STATE_healthStatusState) {
    (window as any).GV_STATE_healthStatusState = {};
  }
  return (window as any).GV_STATE_healthStatusState;
}

export function HealthStatusState(tabKey: string, initValue?: string) {
  if (!getHealthStatusState()[tabKey]) {
    getHealthStatusState()[tabKey] = atom<string | undefined>(initValue);
  }
  return getHealthStatusState()[tabKey];
}

/**
 * Check Change Condition
 *   - change location
 *   - change runtime <-> period
 *   - change period custom interval(start ~ end)
 */
export function getChangedFilterState() {
  if (!(window as any).GV_STATE_changedFilterState) {
    (window as any).GV_STATE_changedFilterState = {};
  }
  return (window as any).GV_STATE_changedFilterState;
}
export function ChangedFilterState(tabKey: string) {
  if (!getChangedFilterState()[tabKey]) {
    getChangedFilterState()[tabKey] = atom((get) => {
      const beforeFilter = getBeforeRealtimeState(tabKey);
      const periodOption: any = get(PeriodOptionsFilterState(tabKey));
      const location: any = get(LocationFilterState(tabKey));
      const isRealtime = get(SwitchRealTimeState(tabKey));
      const healthStatus = get(HealthStatusState(tabKey));
      const selectedDateType = get(SelectedDateTypeState(tabKey));

      let changedCondition: any = null;
      if (beforeFilter) {
        if (
          healthStatus !== beforeFilter.healthStatus ||
          location.locationId !== beforeFilter.location?.locationId ||
          isRealtime !== beforeFilter.isRealtime ||
          selectedDateType !== beforeFilter.selectedDateType ||
          (beforeFilter.selectedDateType === DateType.CUSTOM &&
            (beforeFilter?.interval?.start !== getDateFormat(periodOption?.period?.from, 'YYYY-MM-DD HH:mm:ss') ||
              beforeFilter?.interval?.end !== getDateFormat(periodOption?.period?.to, 'YYYY-MM-DD HH:mm:ss')))
        ) {
          changedCondition = getRandomId();
          // console.log('--- changedCondition:', changedCondition, beforeFilter);
        }
      }

      return changedCondition;
    });
  }
  return getChangedFilterState()[tabKey];
}

/**
 * Use Dashboard Page
 */
export function getRealtimeFilterState(): any {
  if (!(window as any).GV_STATE_realtimeFilterState) {
    (window as any).GV_STATE_realtimeFilterState = {};
  }
  return (window as any).GV_STATE_realtimeFilterState;
}

export function RealtimeFilterState(tabKey: string, forcelyUnrealtime = false, forcelyAllPeriod = false) {
  if (!getRealtimeFilterState()[tabKey]) {
    let initializedRealtimeState = false;
    getRealtimeFilterState()[tabKey] = atom((get) => {
      const realtimeOption: any = get(RealtimeOptionsFilterState(tabKey));
      const periodOption: any = get(PeriodOptionsFilterState(tabKey));
      const location: any = get(LocationFilterState(tabKey));
      const healthStatus = get(HealthStatusState(tabKey));
      const isRealtime = forcelyUnrealtime ? false : get(SwitchRealTimeState(tabKey));
      const selectedDateType =
        forcelyAllPeriod && !initializedRealtimeState ? DateType.ALL : get(SelectedDateTypeState(tabKey));
      const runtimeInterval = get(RuntimeIntervalState(tabKey));

      // 위에서 사용후 true로 변경
      if (!initializedRealtimeState) {
        initializedRealtimeState = true;
      }

      // TODO: check this logic
      const statusType = undefined;
      let interval: any;
      if (isRealtime) {
        if (!realtimeOption.timeCycle || !realtimeOption.timeRange) {
          const defaultInitRealtimeFilters = getDefaultInitRealtimeFilters(tabKey);
          realtimeOption.timeCycle = defaultInitRealtimeFilters.timeCycle;
          realtimeOption.timeRange = defaultInitRealtimeFilters.timeRange;
          realtimeOption.timeRangeUnit = defaultInitRealtimeFilters.timeRangeUnit;
          realtimeOption.period = {
            from: dayjs().startOf('h').toDate(),
            to: new Date(),
          };
        }
        periodOption.aggregation = realtimeAggregation();
        interval = getInterval(realtimeOption.timeRange, realtimeOption.timeRangeUnit);
      } else {
        if (periodOption.period && selectedDateType !== DateType.ALL) {
          interval = {
            start: getDateFormat(periodOption.period.from, 'YYYY-MM-DD HH:mm:ss'),
            end: getDateFormat(periodOption.period.to, 'YYYY-MM-DD HH:mm:ss'),
          };
        } else {
          interval = {
            start: undefined,
            end: undefined,
          };
        }
        periodOption.aggregation = realtimeAggregation();
      }

      const filter = {
        ...realtimeOption,
        ...periodOption,
        isRefresh: get(RefreshPageState(tabKey)) !== '',
        refreshKey: get(RefreshPageState(tabKey)),
        interval,
        statusType,
        locationId: location.locationId,
        locationPath: location.locationPath,
        healthStatus,
        isRealtime,
        selectedDateType: isRealtime ? DateType.CURRENT : selectedDateType ? selectedDateType : DateType.CUSTOM,
      };
      // current location & realtime filter 값을 저장한다.
      setCurrentRealtimeState(tabKey, filter);

      // console.log('> [realtime filter] tabKey, filter:', tabKey, filter);
      return filter;
    });
  }
  return getRealtimeFilterState()[tabKey];
}

export function getRealtimeFilterStateOnlyAfterChanged() {
  if (!(window as any).GV_STATE_realtimeFilterStateOnlyAfterChanged) {
    (window as any).GV_STATE_realtimeFilterStateOnlyAfterChanged = {};
  }
  return (window as any).GV_STATE_realtimeFilterStateOnlyAfterChanged;
}

export function RealtimeFilterStateOnlyAfterChanged(
  tabKey: string,
  forcelyUnrealtime = false,
  forcelyAllPeriod = false,
  initCustomPeriod: { selectedDateType: string; start: string; end: string } | null = null
) {
  if (!getRealtimeFilterStateOnlyAfterChanged()[tabKey]) {
    let initializedRealtimeState = false;
    getRealtimeFilterStateOnlyAfterChanged()[tabKey] = atom((get) => {
      const realtimeOption: any = get(RealtimeOptionsFilterState(tabKey));
      const periodOption: any = get(PeriodOptionsFilterState(tabKey));
      const location: any = get(LocationFilterState(tabKey));
      const healthStatus = get(HealthStatusState(tabKey));
      const isRealtime = forcelyUnrealtime ? false : get(SwitchRealTimeState(tabKey));
      const selectedDateType =
        (forcelyAllPeriod || initCustomPeriod) && !initializedRealtimeState
          ? initCustomPeriod
            ? initCustomPeriod.selectedDateType
            : DateType.ALL
          : get(SelectedDateTypeState(tabKey));

      // 위에서 사용후 true로 변경
      if (!initializedRealtimeState) {
        initializedRealtimeState = true;
      }

      // TODO: check this logic
      const statusType = undefined;
      let interval: any;
      if (isRealtime) {
        periodOption.aggregation = realtimeAggregation(); // old version is 'LAST'
        if (!realtimeOption.timeCycle || !realtimeOption.timeRange) {
          const savedRealtimeFilters = getDefaultInitRealtimeFilters(tabKey);
          realtimeOption.timeCycle = savedRealtimeFilters.timeCycle;
          realtimeOption.timeRange = savedRealtimeFilters.timeRange;
          realtimeOption.timeRangeUnit = savedRealtimeFilters.timeRangeUnit;
          realtimeOption.period = {
            from: dayjs().startOf('h').toDate(),
            to: new Date(),
          };
        }
        interval = getInterval(realtimeOption.timeRange, realtimeOption.timeRangeUnit);
      } else {
        if (periodOption.period && selectedDateType !== DateType.ALL) {
          if (initCustomPeriod) {
            interval = {
              start: getDateFormat(initCustomPeriod.start, 'YYYY-MM-DD HH:mm:ss'),
              end: getDateFormat(initCustomPeriod.end, 'YYYY-MM-DD HH:mm:ss'),
            };
          } else {
            interval = {
              start: getDateFormat(periodOption.period.from, 'YYYY-MM-DD HH:mm:ss'),
              end: getDateFormat(periodOption.period.to, 'YYYY-MM-DD HH:mm:ss'),
            };
          }
        } else {
          interval = {
            start: undefined,
            end: undefined,
          };
        }
        periodOption.aggregation = realtimeAggregation();
      }

      return {
        isRefresh: get(RefreshPageState(tabKey)) !== '',
        refreshKey: get(RefreshPageState(tabKey)),
        locationId: location.locationId,
        locationPath: location.locationPath,
        healthStatus,
        isRealtime,
        ...realtimeOption,
        ...periodOption,
        interval,
        statusType,
        selectedDateType,
      };
    });
  }
  return getRealtimeFilterStateOnlyAfterChanged()[tabKey];
}

/**
 * set first realtime state
 */
export function getInitRealtimeState() {
  if (!(window as any).GV_STATE_initRealtimeState) {
    (window as any).GV_STATE_initRealtimeState = {};
  }
  return (window as any).GV_STATE_initRealtimeState;
}

export function setInitRealtimeState(tabKey: string, init = false) {
  getInitRealtimeState()[tabKey] = init;
}

export function isInitRealtimeState(tabKey: string) {
  return getInitRealtimeState()[tabKey] || false;
}

/**
 * current realtime state
 */
export function getRealtimeCurrentState() {
  if (!(window as any).GV_STATE_currentRealtimeState) {
    (window as any).GV_STATE_currentRealtimeState = {};
  }
  return (window as any).GV_STATE_currentRealtimeState;
}
export function setCurrentRealtimeState(tabKey: string, state: any) {
  if (getRealtimeCurrentState()[tabKey]) {
    setBeforeRealtimeState(tabKey, cloneDeep(getRealtimeCurrentState()[tabKey]));
  }
  getRealtimeCurrentState()[tabKey] = cloneDeep(state);
}

export function getCurrentRealtimeState(tabKey: string) {
  return getRealtimeCurrentState()[tabKey] || {};
}

export function updateCurrentRealtimeState(tabKey: string, property: string, value: any) {
  if (getRealtimeCurrentState()[tabKey]) {
    getRealtimeCurrentState()[tabKey][property] = value;
  }
}

/**
 * before realtime state
 */
export function getRealtimeBeforeState() {
  if (!(window as any).GV_STATE_beforeRealtimeState) {
    (window as any).GV_STATE_beforeRealtimeState = {};
  }
  return (window as any).GV_STATE_beforeRealtimeState;
}

export function setBeforeRealtimeState(tabKey: string, state: any) {
  getRealtimeBeforeState()[tabKey] = cloneDeep(state);
}

export function getBeforeRealtimeState(tabKey: string) {
  return getRealtimeBeforeState()[tabKey] || {};
}
