import { ROLE } from '../model/common.model';
export interface ICustomFieldProps {
  name: string;
  id: string;
  defaultValue: any;
  description: string;
  descriptionEnabled: boolean;
  inputType: CustomFieldItemType;
  code: string;
  dataType: CustomFieldDataType;
  required: boolean;
  visible: boolean;
  referenceTypeValues?: IReferenceTypes[];
  referenceType?: ROLE;
  groupCode: string;
}

export interface IReferenceTypes {
  name: string;
  id: string;
}

export enum CustomFieldItemType {
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  TEXTAREA = 'TEXTAREA',
  TEXTBOX = 'TEXTBOX',
  COMBOBOX = 'COMBOBOX',
  DATE_PERIOD = 'DATE_PERIOD',
}

export enum CustomFieldDataType {
  ENUM = 'ENUM',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
}
