import { cloneDeep } from 'lodash';

import { usePageState } from './web-page.state';

export function useUrlParamsState(tabKey: string, initValue: any = null) {
  return usePageState(tabKey, 'url-params', initValue);
}

/**
 * current url params
 */
export function getUrlParamsState() {
  if (!(window as any).GV_STATE_currentUrlParamsState) {
    (window as any).GV_STATE_currentUrlParamsState = {};
  }
  return (window as any).GV_STATE_currentUrlParamsState;
}

export function setCurrentUrlParamsState(tabKey: string, initParams: any) {
  getUrlParamsState()[tabKey] = cloneDeep(initParams);
}

export function getCurrentUrlParamsState(tabKey: string) {
  return getUrlParamsState()[tabKey] || {};
}
