import { GVIcon, CustomIconComponentProps } from '../antd-icon-generator';

const SvgAssetGroup = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
    <path d="M959.68 564.2c-1.13-13-11.38-25.83-30.74-35.71l-175.72-89.61v-213.69c0-1.63-.12-3.21-.32-4.76-1.13-13-11.38-25.83-30.74-35.7l-178.84-91.21c-8.62-4.4-19.98-6.59-31.34-6.59s-22.72 2.2-31.34 6.59l-178.84 91.21c-21 10.71-31.27 24.9-30.82 39.01-.02.48-.04.95-.04 1.43l-.17 213.7-175.74 89.62c-21 10.71-31.27 24.9-30.82 39.01-.02.48-.04.95-.04 1.43l-.19 236.15c-.01 13.17 7.5 25.19 19.34 30.97l198.15 96.62c7.82 2.94 16.04 4.4 24.25 4.4s16.05-1.4 23.7-4.2l182.61-89.42 182.97 89.22c7.82 2.94 16.04 4.4 24.25 4.4s16.05-1.4 23.7-4.2l197.72-96.82c11.81-5.78 19.29-17.77 19.29-30.91v-236.19c0-1.63-.12-3.21-.32-4.76ZM718.76 634.51l-133.78-69.28 140.37-68.74 131.04 66.83-137.62 71.19ZM689.23 436.42c-.61.28-1.21.57-1.8.87l-143.43 73.15v-158.57l145.23-74.07v158.63ZM480 351.86v158.57l-143.43-73.15c-.58-.3-1.18-.58-1.78-.86l.13-158.56 145.08 73.99ZM298.71 496.47l140.72 68.62-134.21 69.42-137.54-71.22 131.02-66.82ZM337.23 695.63l140.45-71.63-.13 165.09-140.32 68.71v-162.18ZM546.46 624.07l140.31 71.56v161.81l-140.31-68.42v-164.95ZM508.38 151.22c.71-.13 1.97-.3 3.62-.3s2.91.17 3.62.3l133.99 68.33-137.62 71.19-137.54-71.22 133.93-68.3ZM128.15 621.64l145.09 73.99v161.81l-145.22-70.81.13-164.99ZM750.77 857.81v-162.18l145.23-74.06v165.12l-145.23 71.12Z" />
  </svg>
);

export function ABAssetGroupIcon(props: CustomIconComponentProps) {
  return <GVIcon component={SvgAssetGroup} {...props} />;
}
