import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import CalendarLocale from 'rc-picker/lib/locale/ja_JP';
import TimePickerLocale from 'antd/es/time-picker/locale/ja_JP';
// import type { PickerLocale } from '../generatePicker';

const jaLocale: any = {
  ...CalendarLocale,
  shortWeekDays: ['日', '月', '火', '水', '木', '金', '土'],
  shortMonths: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
};

// Merge into a locale object
export const ja_datepicker_locale: PickerLocale = {
  lang: {
    placeholder: '日付を選択',
    rangePlaceholder: ['開始日付', '終了日付'],
    ...jaLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

// export default locale;
