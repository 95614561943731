/* eslint-disable react-hooks/rules-of-hooks */

import {
  ActionType,
  IReportPage,
  IReport,
  PageState,
  sendDataToSubscriber,
  usePageState,
  useSubscribeData,
  IReportletInstance,
} from '@grandview/shared';

export interface ReportAction extends PageState {
  action: ActionType;
  report?: IReport;
}

export interface ReportPageAction extends PageState {
  action: ActionType;
  report?: IReport;
  page?: IReportPage;
}

/**
 * Builder State - report page list
 */
// export const REPORT_BUILDER_ACTION = 'report-builder-action';
// export function useReportBuilderAction(tabKey: string) {
//   return usePageState<ReportAction>(tabKey, REPORT_BUILDER_ACTION, null, );
// }
export function sendReportBuilderAction(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'report-builder-action', value);
}
export function useSubscribeReportBuilderAction(tabKey: string) {
  return useSubscribeData(tabKey, 'report-builder-action', null, false);
}

export function sendReportBuilderMoreAction(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'report-builder-more-action', value);
}
export function useSubscribeReportBuilderMoreAction(tabKey: string) {
  return useSubscribeData(tabKey, 'report-builder-more-action', null, false);
}

// export const REPORT_BUILDER_PAGE_ACTION = 'report-builder-page-action';
// export const REPORT_BUILDER_PAGE_MORE_ACTION = 'report-builder-page-more-action';
// export function useReportPageAction(tabKey: string, actionName?: string) {
//   return usePageState<ReportPageAction>(tabKey, actionName || REPORT_BUILDER_PAGE_ACTION, null, true);
// }

export function sendReportPageList(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'report-builder-page-list', value);
}
export function useSubscribeReportPageList(tabKey: string) {
  return useSubscribeData(tabKey, 'report-builder-page-list', null, true);
}

/**
 * Builder Layout
 */
export function useCollapseReportLeftPanel(tabKey: string, isPreview: boolean | undefined) {
  return usePageState(tabKey, 'report-builder-left-panel-collapse', !isPreview, true);
}

export function useCollapseReportRightPanel(tabKey: string, isPreview: boolean) {
  return usePageState(tabKey, 'report-builder-right-panel-collapse', !isPreview, true);
}

export function useActiveTabInLeftPanel(tabKey: string) {
  return usePageState(tabKey, 'report-builder-active-tab-in-left-panel', null, true);
}

/**
 * Report config
 */
export function sendDirtyReportConfig(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'report-builder-config-setup', value);
}
export function useSubscribeDirtyReportConfig(tabKey: string) {
  return useSubscribeData(tabKey, 'report-builder-config-setup', null, true);
}

/**
 * Page
 */
export function sendSelectReportPage(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'report-builder-select-a-page', value);
}
export function useSubscribeSelectReportPage(tabKey: string) {
  return useSubscribeData(tabKey, 'report-builder-select-a-page', null, true);
}

/**
 * Add page & copy & delete
 */
export function sendDirtyPages(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'report-builder-dirty-pages', value);
}
export function useSubscribeDirtyPages(tabKey: string) {
  return useSubscribeData(tabKey, 'report-builder-dirty-pages', null, true);
}

/**
 * Reportlet
 * report-builder Styles & Reportlets Styles: From report-builder Template to Layout Builder
 * dashboard state: useDirtyWidgetProperties
 */
export function sendDirtyReportlets(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'report-builder-dirty-reportlets', value);
}
export function useSubscribeDirtyReportlets(tabKey: string) {
  return useSubscribeData(tabKey, 'report-builder-dirty-reportlets', null, true);
}

export function useSelectReportlet(tabKey: string, reportlet?: IReportletInstance) {
  return usePageState(tabKey, 'report-builder-selected-reportlet', reportlet, true);
}

/**
 * Add reportlet to selected page
 */
export function sendAddReportlet(tabKey: string, pageId: string, value: any) {
  sendDataToSubscriber(tabKey, `report-builder-add-a-reportlet::${pageId}`, value);
}
export function useSubscribeAddReportlet(tabKey: string, pageId: string) {
  return useSubscribeData(tabKey, `report-builder-add-a-reportlet::${pageId}`, null, true);
}

/**
 * report template -> reportlet instance list of report builder
 */
export function sendReportletInstances(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'report-builder-reportlet-instances', value);
}
export function useSubscribeReportletInstances(tabKey: string) {
  return useSubscribeData(tabKey, 'report-builder-reportlet-instances', null, true);
}
/**
 * reportlet instance list of report builder -> report template
 */
export function sendUpdateReportletInstances(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'report-builder-update-reportlet-instances', value);
}
export function useSubscribeUpdateReportletInstances(tabKey: string) {
  return useSubscribeData(tabKey, 'report-builder-update-reportlet-instances', null, true);
}


/**
 * From reportlet style config
 */
export function sendDirtyReportletStyleConfig(tabKey: string, pageId: string, value: any) {
  sendDataToSubscriber(tabKey, `report-builder-dirty-reportlet-style-config::${pageId}`, value);
}

export function useSubscribeDirtyReportletStyleConfig(tabKey: string, pageId: string) {
  return useSubscribeData(tabKey, `report-builder-dirty-reportlet-style-config::${pageId}`, null, true);
}

/**
 * From reportlet style config
 */
export function sendDirtyReportletDataConfig(tabKey: string, pageId: string, value: any) {
  sendDataToSubscriber(tabKey, `report-builder-dirty-reportlet-data-config::${pageId}`, value);
}

export function useSubscribeDirtyReportletDataConfig(tabKey: string, pageId: string) {
  return useSubscribeData(tabKey, `report-builder-dirty-reportlet-data-config::${pageId}`, null, true);
}

/**
 * Switch Preview and Edit mode
 */
export function useSwitchReportPreviewMode(tabKey: string) {
  return usePageState(tabKey, 'report-builder-switch-preview-edit-mode', null, true);
}
