/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import { useEffect } from 'react';
import { useAtom } from 'jotai';

import { cookieService } from '../../cookie/cookie.service';
import { sendToHost } from '../communication/micro-app/iframe-child-to-host.sender';
import { sendSharedState, useSharedState } from '../../hooks/shared/shared-one-way-subject';
import { GV_IFRAME_COMMAND } from '../../model';
import { LoginState } from '../../state';

export function logout(isMicroApp = false) {
  if (isMicroApp) {
    // send portal web to require logout
    sendToHost({
      type: GV_IFRAME_COMMAND.LOGOUT,
      payload: undefined,
    });
  } else {
    // only use in portal web
    sendSharedState('authorized-state', 'logout');
  }
}

export function signed() {
  sendSharedState('authorized-state', 'signed');
}

/**
 * Use portal web
 */
export function useListenUnAuthorized() {
  const [, setSigned] = useAtom(LoginState.signed);
  const payload = useSharedState('authorized-state');

  useEffect(() => {
    if (payload === 'logout') {
      cookieService.removeAll();
      setSigned(false);

      // TODO: redirect login
      window.location.reload();
    } else if (payload === 'signed') {
      setSigned(true);
    }
  }, [payload]);
}
