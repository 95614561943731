/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { transferDashboardIcon } from '@grandview/config-icon';

import { gvPODOApiPrefix } from '../../config/config';
import { httpService } from '../../ajax/http.service';
import { useSideBarMenusForMicroApp } from '../../state';
import { useRefetchByChangedGlobalState } from '../../hooks/shared/changed-global-state.hook';
import { GV_MICRO_APP, GlobalStateType, IDashboardPersistence, PageCreateType, SideMenuType } from '../../model';

export function usePublishedDashboardMenus() {
  const { dashboardSidebarMenu } = useDashboardSidbarMenuDomain();
  const [, setMenus] = useSideBarMenusForMicroApp();
  useEffect(() => {
    if (!dashboardSidebarMenu) {
      return;
    }
    setMenus(dashboardSidebarMenu);
  }, [dashboardSidebarMenu]);
  return { data: dashboardSidebarMenu };
}

export function useDashboardSidbarMenuDomain(): any {
  const { data, refetch, isError, isLoading }: any = useQuery(
    ['published-dashboard-list'],
    () => getPublishedDashboardSidebarMenu(),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );

  useRefetchByChangedGlobalState([GlobalStateType.CHANGED_DASHBOARD], refetch);

  return { dashboardSidebarMenu: data, isError, isLoading };
}

async function getPublishedDashboardSidebarMenu() {
  const url = `${gvPODOApiPrefix()}/dashboards?publish=true&projection=detail`;
  const result: any = await httpService.get<any[]>(url);
  return transferToDashboardSidebarMenu(result);
}

function transferToDashboardSidebarMenu(dashboards: IDashboardPersistence[]): any {
  if (!dashboards || !dashboards.length) {
    return [];
  }

  return makeDashboardSidebarMenusInApp(dashboards);
}

export function makeDashboardSidebarMenusInApp(dashboards: IDashboardPersistence[]) {
  return dashboards.map((dashboard: IDashboardPersistence) => ({
    key: `${GV_MICRO_APP.DASHBOARD}.${dashboard.id}`,
    title: dashboard.name,
    code: dashboard.code,
    content: dashboard.widgets,
    icon: transferDashboardIcon(dashboard?.config?.icon),
    theme: dashboard?.config?.theme,
    hierarchyFilterUsage: dashboard?.config?.hierarchy,
    healthStatusFilterUsage: dashboard?.config?.healthStatus,
    realtimeFilterUsage: dashboard?.config?.realtime,
    createTabType: dashboard?.config?.createTabType === 'single' ? PageCreateType.SINGLE : PageCreateType.MUTLI, //dashboard.code === 'peter_test' ? PageCreateType.SINGLE : PageCreateType.MUTLI,
    hidden: dashboard?.config?.hideMenu === 'enable' ? true : false, // dashboard.code === 'peter_test' ? true : false,
    sidebarMenuType: dashboard?.config?.hideMenu === 'enable' ? SideMenuType.DYNAMIC : '',
  }));
}
