import { memo } from 'react';

export enum ALARM_ACTION_STATUS_TYPE {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSE = 'CLOSE',
}

/* eslint-disable-next-line */
export interface AlarmGroupStatusIconProps {
  type: ALARM_ACTION_STATUS_TYPE;
  height?: number;
  width?: number;
}

/***********************************
 * Usage

 <GVAlarmGroupStatusIcon type={ALARM_ACTION_STATUS_TYPE.OPEN} />
 <GVAlarmGroupStatusIcon type={ALARM_ACTION_STATUS_TYPE.IN_PROGRESS} />
 <GVAlarmGroupStatusIcon type={ALARM_ACTION_STATUS_TYPE.CLOSE} />

 ***********************************/
export function AlarmGroupStatusIcon({ type, height = 14, width = 14 }: AlarmGroupStatusIconProps) {
  switch (type) {
    case ALARM_ACTION_STATUS_TYPE.OPEN:
      return (
        <svg
          className={'gv_alarm_action_status_open'}
          width={width}
          height={height}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12.25C9.8995 12.25 12.25 9.8995 12.25 7C12.25 4.1005 9.8995 1.75 7 1.75C4.1005 1.75 1.75 4.1005 1.75 7C1.75 9.8995 4.1005 12.25 7 12.25ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
            fill="#E60099"
          />
        </svg>
      );

    case ALARM_ACTION_STATUS_TYPE.IN_PROGRESS:
      return (
        <svg
          className={'gv_alarm_action_status_in_progress'}
          width={width}
          height={height}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12.25C9.89949 12.25 12.25 9.89949 12.25 7C12.25 4.1005 9.89949 1.75 7 1.75C4.1005 1.75 1.75 4.1005 1.75 7C1.75 9.89949 4.1005 12.25 7 12.25ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
            fill="#1D7CD6"
          />
          <path
            d="M11.375 7C11.375 9.41625 9.41626 11.375 7.00001 11.375C5.3044 11.375 3.83409 10.4104 3.10788 9L7.00001 7V2.625C9.41626 2.625 11.375 4.58375 11.375 7Z"
            fill="#1D7CD6"
          />
        </svg>
      );

    case ALARM_ACTION_STATUS_TYPE.CLOSE:
      return (
        <svg
          className={'gv_alarm_action_status_in_close'}
          width={width}
          height={height}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12.25C9.89949 12.25 12.25 9.89949 12.25 7C12.25 4.10051 9.89949 1.75 7 1.75C4.10051 1.75 1.75 4.10051 1.75 7C1.75 9.89949 4.10051 12.25 7 12.25ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
            fill="#61687E"
          />
          <path d="M3.28766 9.47488L9.47484 3.28769L10.7123 4.52513L4.5251 10.7123L3.28766 9.47488Z" fill="#61687E" />
          <path d="M4.52507 3.2877L10.7123 9.47488L9.47482 10.7123L3.28763 4.52514L4.52507 3.2877Z" fill="#61687E" />
        </svg>
      );

    default:
      return <span></span>;
  }
}

export const GVAlarmGroupStatusIcon = memo(AlarmGroupStatusIcon);
