import { usePageState } from '../../web-page.state';
import { ActionPayload } from '../../../../model';

/**
 * Select & CRUD State
 */
export const PAGELET_ACTION = 'pagelet-action';
// // 주의) 화면을 전환하는 state는 3번째 파라미터가 false 여야 한다.
export function usePageletAction(tabKey: string, initValue?: any) {
  return usePageState<ActionPayload<any>>(tabKey, PAGELET_ACTION, initValue, true);
}
