import { usePageState } from '../../web-page.state';
import { ActionPayload } from '../../../../model';

/**
 * Share Global State
 */
// 주의) 화면을 전환하는 state는 3번째 파라미터가 false여야 한다.
export const SHARE_STATE_PAGE_ACTION = 'share-state-page-action';
export function useShareStatePageAction(tabKey: string) {
  return usePageState<ActionPayload<any>>(tabKey, SHARE_STATE_PAGE_ACTION, null, true);
}
