/**
 * Define rule status type
 */
export enum RulesType {
  SingleOut = 'SINGLEOUT',
  MultipleOut = 'MULTIPLEOUT',
  Trend = 'TREND',
  Bias = 'BIAS',
  FaultClassification = 'FAULTCLASSIFICATION',
  Oscillation = 'OSCILLATION',
  Zigzag = 'ZIGZAG',
  StatusChange = 'STATUSCHANGE'
}

/**
 * Define rule status
 */
export enum RuleStatus {
  Limit = 'LIMIT',
  UpperLimit = 'upperLimit',
  Target = 'target',
  LowerLimit = 'lowerLimit'
}
