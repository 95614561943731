import { PickerLocale } from 'antd/lib/date-picker/generatePicker';

import CalendarLocale from 'rc-picker/lib/locale/zh_CN';
import locale from 'antd/locale/zh_CN';

import TimePickerLocale from 'antd/es/time-picker/locale/zh_CN';
// import type { PickerLocale } from '../generatePicker';

const cnLocale: any = {
  ...CalendarLocale,
  ...locale,
  shortWeekDays: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
  shortMonths: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
};

// Merge into a locale object
export const cn_datepicker_locale: PickerLocale = {
  lang: {
    placeholder: '请选择日期',
    yearPlaceholder: '请选择年份',
    quarterPlaceholder: '请选择季度',
    monthPlaceholder: '请选择月份',
    weekPlaceholder: '请选择周',
    rangePlaceholder: ['开始日期', '结束日期'],
    rangeYearPlaceholder: ['开始年份', '结束年份'],
    rangeMonthPlaceholder: ['开始月份', '结束月份'],
    rangeQuarterPlaceholder: ['开始季度', '结束季度'],
    rangeWeekPlaceholder: ['开始周', '结束周'],
    ...cnLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

// export default locale;
