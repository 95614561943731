import { getSharedOneWaySubject } from './shared/shared-one-way-subject';
import { getOneWayClearSubject } from './tabkey/clear-state-init-params.hook';
import { getOneWayData } from './tabkey/common-one-way-data.hook';
import { getCommonOneWaySubject } from './tabkey/common-one-way-subject';
import { getRealtimeOneWaySubject } from './tabkey/realtime-filter-one-way-subject';

/**
 * 반드시 remove filter를 등록하다. tab안의 page가 remove 될 때 제거한다.
 */
export function removeTabKeyHooks(tabKey: string) {
  delete getOneWayData()[tabKey];
  delete getCommonOneWaySubject()[tabKey];
  delete getRealtimeOneWaySubject()[tabKey];
}

export function removeAllHooks(appName: string) {
  clearObject(getOneWayClearSubject(), appName);
  clearObject(getOneWayData(), appName);
  clearObject(getCommonOneWaySubject(), appName);
  clearObject(getRealtimeOneWaySubject(), appName);
  clearObject(getSharedOneWaySubject(), appName);
}

function clearObject(model: any, appName: string): any {
  Object.keys(model).forEach((key) => {
    if (key.indexOf(`${appName}.`) === 0) {
      delete model[key];
    }
  });
}
