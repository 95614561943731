import { getRootStyle } from "./root-style.util";

// Light Mode
// Use = All micro apps excluding dashboard micro app
export const $ab_light_primary_1 = () => getRootStyle('--ab-light-primary-1');
export const $ab_light_primary_2 = () => getRootStyle('--ab-light-primary-2');
export const $ab_light_primary_3 = () => getRootStyle('--ab-light-primary-3');
export const $ab_light_primary_4 = () => getRootStyle('--ab-light-primary-4');
export const $ab_light_primary_5 = () => getRootStyle('--ab-light-primary-5');
export const $ab_light_primary_6 = () => getRootStyle('--ab-light-primary-6');
export const $ab_light_primary_7 = () => getRootStyle('--ab-light-primary-7');
export const $ab_light_primary_8 = () => getRootStyle('--ab-light-primary-8');
export const $ab_light_primary_9 = () => getRootStyle('--ab-light-primary-9');
export const $ab_light_primary_10 = () => getRootStyle('--ab-light-primary-10');

export const $ab_light_black_1 = () => getRootStyle('--ab-light-black-1');
export const $ab_light_black_2 = () => getRootStyle('--ab-light-black-2');
export const $ab_light_black_3 = () => getRootStyle('--ab-light-black-3');
export const $ab_light_black_4 = () => getRootStyle('--ab-light-black-4');
export const $ab_light_black_5 = () => getRootStyle('--ab-light-black-5');
export const $ab_light_black_6 = () => getRootStyle('--ab-light-black-6');
export const $ab_light_black_7 = () => getRootStyle('--ab-light-black-7');
export const $ab_light_black_8 = () => getRootStyle('--ab-light-black-8');
export const $ab_light_black_9 = () => getRootStyle('--ab-light-black-9');
export const $ab_light_black_10 = () => getRootStyle('--ab-light-black-10');

export const $ab_light_primary_gray_1 = () => getRootStyle('--ab-light-primary-gray-1');
export const $ab_light_primary_gray_2 = () => getRootStyle('--ab-light-primary-gray-2');
export const $ab_light_primary_gray_3 = () => getRootStyle('--ab-light-primary-gray-3');

// Dark Mode
// Use = Dashboard micro app and Portal micro app
export const $ab_dark_primary_1 = () => getRootStyle('--ab-dark-primary-1');
export const $ab_dark_primary_2 = () => getRootStyle('--ab-dark-primary-2');
export const $ab_dark_primary_3 = () => getRootStyle('--ab-dark-primary-3');
export const $ab_dark_primary_10 = () => getRootStyle('--ab-dark-primary-10');

export const $ab_dark_black_1 = () => getRootStyle('--ab-dark-black-1');
