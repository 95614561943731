import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { BehaviorSubject } from 'rxjs';

import { PageMenuState } from '../../../state';

/**
 * Use None UI
 */
const setActiveMicroAppSubject = new BehaviorSubject<any>(undefined);
const setActiveMicroAppBridge = {
  sendPayload: (payload: string) => setActiveMicroAppSubject.next(payload),
  getPayload: () => setActiveMicroAppSubject.asObservable(),
};

export function setActiveMicroApp(payload: string) {
  // console.log('>>> [iframe-child: active micro-app]', payload);
  setActiveMicroAppBridge.sendPayload(payload);
}

export function useActiveMicroApp() {
  const [, setCurrentActiveApp] = useAtom(PageMenuState.currentActiveApp);

  useEffect(() => {
    const subscription = setActiveMicroAppBridge.getPayload().subscribe((payload: string) => {
      if (!payload) {
        return;
      }
      // console.log('>>> [iframe-child: set active micro-app]', payload);
      setCurrentActiveApp(payload);
    });
    return () => subscription.unsubscribe();
  }, []);
}
