import { memo } from 'react';

/* eslint-disable-next-line */
export interface ChartViewIconProps {
  height?: number;
  width?: number;
}

/***********************************
 * Usage
 ***********************************/
export function ChartViewIcon({ height = 16, width = 16 }: ChartViewIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.653333 6.51334C0.7 6.56 0.78 6.56 0.826667 6.51334L2.76 4.52667L4.88 6.25334C4.92667 6.3 5.00667 6.3 5.05333 6.25334L9.9 1.5C9.94667 1.45334 9.94667 1.37334 9.9 1.32667L9.28667 0.700005C9.28667 0.700005 9.23333 0.666672 9.2 0.666672C9.16667 0.666672 9.13333 0.680005 9.11333 0.700005L4.97333 4.74667L2.86 3.02C2.86 3.02 2.80667 2.98667 2.77333 2.98667C2.74 2.98667 2.70667 3 2.68667 3.02L0.0333333 5.71334C0.0333333 5.71334 0 5.76667 0 5.8C0 5.83334 0.0133333 5.86667 0.0333333 5.88667L0.653333 6.50667V6.51334Z"
        fill="currentColor"
      />
      <path
        d="M1 7.92667H0.133333C0.0596954 7.92667 0 7.98636 0 8.06V10.24C0 10.3136 0.0596954 10.3733 0.133333 10.3733H1C1.07364 10.3733 1.13333 10.3136 1.13333 10.24V8.06C1.13333 7.98636 1.07364 7.92667 1 7.92667Z"
        fill="currentColor"
      />
      <path
        d="M3.19995 6.41333H2.33328C2.25965 6.41333 2.19995 6.47303 2.19995 6.54666V10.24C2.19995 10.3136 2.25965 10.3733 2.33328 10.3733H3.19995C3.27359 10.3733 3.33328 10.3136 3.33328 10.24V6.54666C3.33328 6.47303 3.27359 6.41333 3.19995 6.41333Z"
        fill="currentColor"
      />
      <path
        d="M5.40002 7.54668H4.53336C4.45972 7.54668 4.40002 7.60637 4.40002 7.68001V10.24C4.40002 10.3136 4.45972 10.3733 4.53336 10.3733H5.40002C5.47366 10.3733 5.53336 10.3136 5.53336 10.24V7.68001C5.53336 7.60637 5.47366 7.54668 5.40002 7.54668Z"
        fill="currentColor"
      />
      <path
        d="M6.74002 5.38667C6.66669 5.38667 6.60669 5.44667 6.60669 5.52V6.84667C6.60669 6.88 6.65336 6.93334 6.70669 6.89334C6.82002 6.82 7.32669 6.44 7.64002 6.31334C7.70002 6.28667 7.74002 6.26 7.74002 6.17334V5.46667C7.74002 5.42 7.70002 5.38 7.64669 5.38H6.74002V5.38667Z"
        fill="currentColor"
      />
      <path
        d="M9.80664 3.60667H8.93997C8.86664 3.60667 8.80664 3.66667 8.80664 3.74001V5.93334C8.80664 5.99334 8.83997 6.07334 8.95331 6.05334C9.07997 6.03334 9.21331 6.02667 9.34664 6.02667C9.49997 6.02667 9.64664 6.02667 9.79331 6.04667C9.89997 6.06001 9.93997 5.98001 9.93997 5.92001V3.74001C9.93997 3.66667 9.87997 3.60667 9.80664 3.60667V3.60667Z"
        fill="currentColor"
      />
      <path
        d="M12.5466 9.01334C11.86 7.55334 10.7933 6.8 9.3733 6.8C7.9533 6.8 6.88663 7.55334 6.1933 9.00667C6.10663 9.18667 6.10663 9.4 6.1933 9.58C6.88663 11.0333 7.9533 11.7867 9.3733 11.7867C10.7933 11.7867 11.86 11.0333 12.5533 9.58C12.64 9.4 12.64 9.19334 12.5533 9.01334H12.5466ZM9.36663 10.7867C8.3933 10.7867 7.68663 10.32 7.15996 9.29334C7.68663 8.26667 8.3933 7.8 9.36663 7.8C10.34 7.8 11.0466 8.26667 11.5733 9.29334C11.0466 10.32 10.34 10.7867 9.36663 10.7867V10.7867Z"
        fill="currentColor"
      />
      <path
        d="M9.37333 8.42667C9.14 8.42667 8.92 8.52 8.75333 8.68C8.58667 8.84 8.5 9.06667 8.5 9.3C8.5 9.53333 8.59333 9.75333 8.75333 9.92C8.92 10.0867 9.14 10.1733 9.37333 10.1733C9.60667 10.1733 9.82667 10.08 9.99333 9.92C10.16 9.75333 10.2467 9.53333 10.2467 9.3C10.2467 9.06667 10.1533 8.84667 9.99333 8.68C9.83333 8.51333 9.60667 8.42667 9.37333 8.42667Z"
        fill="currentColor"
      />
    </svg>
  );
}

export const GVChartViewIcon = memo(ChartViewIcon);
