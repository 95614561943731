// https://www.digi.com/resources/documentation/digidocs/90001437-13/reference/r_iso_8601_duration_format.htm
export enum IntervalShortType {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  DAY = 'DAY',
  WEEK = 'WEEK',
  HOUR = 'HOUR',
  MIN = 'MIN',
  SEC = 'SEC',
  MLS = 'SEC_MILLI', //MilliSec
  MCS = 'SEC_MICRO', //MicroSec
}

export enum ParameterGroupCollectionIntervalType {
  HOUR = 'HOUR',
  MIN = 'MIN',
  SEC = 'SEC',
}
