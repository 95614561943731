/**
 * Define data type
 */
export enum ParameterDataTypes {
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE',
  STRING = 'STRING',
  // ARRAY = 'DOUBLE_ARRAY', // GVI 1.0 범위에서 제외됨 (https://dev.azure.com/Aidentyx/GVI/_workitems/edit/2691)
}

export enum ManagementDisplayTypes {
  DEFAULT = 'DEFAULT',
  // FREQUENCY = 'FREQUENCY', // GVI 1.0 범위에서 제외됨 (https://dev.azure.com/Aidentyx/GVI/_workitems/edit/2691)
}

export enum DisplayTypes {
  DEFAULT = 'DEFAULT',
  FREQUENCY = 'FREQUENCY',
  QUANTITY = 'QUANTITY',
  TEMPERATURE = 'TEMPERATURE',
  STATUS = 'STATUS',
  ALARM = 'ALARM',
  FFT = 'FFT',
}

/**
 * Define label Color
 */
export enum LabelColor {
  WHITE = '#ffffff',
  GRAY = '#c3c3c3',
  BLACK = '#000000',
  GREEN = '#aaff4f',
  BLUE = '#4e7eff',
  ORANGE = '#ff9e00',
  RED = '#ff4e64',
  YELLOW = 'ffeb00',
  VIOLET = '#bc52ff',
}

/**
 * Define Vibration Type
 */
export enum VibrationTypes {
  VELOCITY = 'Velocity',
  ACCELERATION = 'Acceleration',
  ENVELOPING = 'Enveloping',
}
