import { getRootStyle } from './root-style.util';

export * from './vars-color';

// App Menu
export const $ab_app_menu_width = 320;

// Sidebar Menu
export const $ab_sidebar_menu_width = 200;
export const $ab_sidebar_collapsed_width = 55;

// Layout Builder
export const $ab_layout_builder_left_panel_width = 250;
export const $ab_layout_builder_right_panel_width = 320;

// Table
export const $ab_table_head_height = 49;
export const $ab_table_row_height = 48;

// Common Layout
export const $ab_header_height = 50;
export const $ab_tab_height = 35; // 35;

// -------------- New variables
// Base Color
export const $ab_base_gray_1 = () => getRootStyle('--ab-base-gray-1'); // white background

export const $ab_base_blue_1 = () => getRootStyle('--ab-base-blue-1'); // selected bgcolor
export const $ab_base_blue_2 = () => getRootStyle('--ab-base-blue-2'); // selected. hover bgcolor
export const $ab_base_blue_3 = () => getRootStyle('--ab-base-blue-3'); // border
export const $ab_base_blue_4 = () => getRootStyle('--ab-base-blue-4');
export const $ab_base_blue_5 = () => getRootStyle('--ab-base-blue-5'); // #BFBFBFhover
export const $ab_base_blue_6 = () => getRootStyle('--ab-base-blue-6'); // normal

export const $ab_base_dark_1 = () => getRootStyle('--ab-base-dark-1'); // page bgcolor, white_bg5_color
export const $ab_base_dark_2 = () => getRootStyle('--ab-base-dark-2'); // logo bgcolor, header_bg_color
export const $ab_base_dark_3 = () => getRootStyle('--ab-base-dark-3'); // panel border, sub-text
export const $ab_base_dark_4 = () => getRootStyle('--ab-base-dark-4');
export const $ab_base_dark_5 = () => getRootStyle('--ab-base-dark-5'); // font (table name) / font_gray

export const $ab_backdrop = () => getRootStyle('--ab-backdrop');

export const $ab_indigo_bg_color = () => getRootStyle('--ab-indigo-bg-1');
export const $ab_indigo_bg2_color = () => getRootStyle('--ab-indigo-bg-2');
export const $ab_indigo_widget_bg_color = () => getRootStyle('--ab-indigo-widget-bg-color');

// white
export const $ab_white_bg_color = () => getRootStyle('--ab-white-bg-1');

export const $ab_font_white = () => getRootStyle('--ab-font-white-1');
export const $ab_font_white2 = () => getRootStyle('--ab-font-white-2');
export const $ab_font_gray = () => getRootStyle('--ab-font-gray-1');
export const $ab_font_gray2 = () => getRootStyle('--ab-font-gray-2');
export const $ab_font_gray3 = () => getRootStyle('--ab-font-gray-3');

// hover, click
export const $ab_action_default = () => getRootStyle('--ab-action-default');
export const $ab_action_hover = () => getRootStyle('--ab-action-hover');
export const $ab_action_click = () => getRootStyle('--ab-action-click');

// required
export const $ab_required_message_color = () => getRootStyle('--ab-required-message-color');

//sidebar
export const $ab_sidebar_bg_color = () => getRootStyle('--ab-indigo-bg-2');
export const $ab_sidebar_header_color = () => getRootStyle('--ab-indigo-bg-6');
export const $ab_sidebar_body_color = () => getRootStyle('--ab-indigo-bg-6');
export const $ab_sidebar_collpase_color = () => getRootStyle('--ab-indigo-bg-2');

// Status Color
// good
export const $ab_status_unacceptable_color = () => getRootStyle('--ab-status-unacceptable-color');
export const $ab_status_unsatisfactory_color = () => getRootStyle('--ab-status-unsatisfactory-color');
export const $ab_status_satisfactory_color = () => getRootStyle('--ab-status-satisfactory-color');
export const $ab_status_good_color = () => getRootStyle('--ab-status-good-color');
export const $ab_status_not_available_color = () => getRootStyle('--ab-status-not-available-color');
// active
export const $ab_status_active_color = () => getRootStyle('--ab-status-active-color');
export const $ab_status_inactive_color = () => getRootStyle('--ab-status-inactive-color');
//severity
export const $ab_status_critical_color = () => getRootStyle('--ab-status-critical-color');
export const $ab_status_high_color = () => getRootStyle('--ab-status-high-color');
export const $ab_status_medium_color = () => getRootStyle('--ab-status-medium-color');
export const $ab_status_low_color = () => getRootStyle('--ab-status-low-color');
//RUL
export const $ab_status_danger_color = () => getRootStyle('--ab-status-danger-color');
export const $ab_status_warning_color = () => getRootStyle('--ab-status-warning-color');
export const $ab_status_watch_color = () => getRootStyle('--ab-status-watch-color');
export const $ab_status_stable_color = () => getRootStyle('--ab-status-stable-color');

export const $ab_status_ups_color = () => getRootStyle('--ab-status-ups-color');
export const $ab_status_downs_color = () => getRootStyle('--ab-status-downs-color');

// Select
export const $ab_status_border_color = () => getRootStyle('--ab-select-border-color');
export const $ab_select_color = () => getRootStyle('--ab-select-color');
