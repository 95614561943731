import { memo } from 'react';

/* eslint-disable-next-line */
export interface GroupTitleProps {
  color?: any;
}

/***********************************
 * Usage
  <GVGroupTitleIcon />
 ***********************************/

export function GroupTitleIcon({ color }: GroupTitleProps) {
  return (
    <svg
      className={'gv_group_title_icon'}
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.44444 4.16667L6.22222 2.5L4.44444 0.833333L5.33333 0L8 2.5L5.33333 5L4.44444 4.16667Z"
        fill="#9999C5"
      />
      <path d="M5.33333 2.5L2.66667 0L0 2.5L2.66667 5L5.33333 2.5Z" fill="#9999C5" />
    </svg>
  );
}

export const GVGroupTitleIcon = memo(GroupTitleIcon);
