import { useEffect, useState } from 'react';

import { getRandomId } from '../../utilities/random-generator';

export function useScrollIntoView(elRef: any) {
  const [scrollTrigger, setScrollTrigger] = useState<string>();
  useEffect(() => {
    if (!scrollTrigger || !elRef?.current) {
      return;
    }
    elRef.current.scrollIntoView(false);
  }, [scrollTrigger]);

  return () => {
    setScrollTrigger(getRandomId());
  };
}
