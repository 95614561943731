export enum DateType {
  // realtime interval
  CURRENT = 'CURRENT',
  // period select
  ALL = 'ALL',
  LAST_1_HOUR = 'LAST_1_HOUR',
  LAST_3_HOURS = 'LAST_3_HOURS',
  LAST_6_HOURS = 'LAST_6_HOURS',
  LAST_12_HOURS = 'LAST_12_HOURS',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  DAYS7 = 'DAYS7',
  MONTH1 = 'MONTH1',
  CUSTOM = 'CUSTOM',
}
