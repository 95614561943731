/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { changeDefaultLang } from '../../../i18n/i18n';
import { changeTimeLocale } from '../../../timezone/time-format-locale';
import { loadMicroAppPage } from '../../commands/micro-app/load-page.command';
import { initializedMicroApp } from '../../commands/micro-app/init-iframe.command';
import { resizerContentWindow } from './resizer-library/iframeResizer.contentWindow';
import { sendChangedGlobalState } from '../../commands/micro-app/change-global-state.command';
import { initMicroAppWithInitPage } from '../../commands/micro-app/init-micro-app.command';
import { setActiveMicroApp } from '../../commands/micro-app/set-active-micro-app.command';
import { setFiredActionFromPortal } from '../../commands/micro-app/fire-action-from-portal.command';
import { sendFormsDirtyState } from '../../commands/micro-app/check-form-dirty.command';
import { GV_IFRAME_COMMAND, IFrameCommand } from '../../../model';

class IFrameChildService {
  listenHostIFrame(): void {
    this.configIFrameResizerContentWindow();
    this.loadIFrameResizerWindow();
  }

  configIFrameResizerContentWindow() {
    const targetOrigin = '*';
    (<any>window).iFrameResizer = {
      targetOrigin,
      onMessage: (message: string) => {
        let command: IFrameCommand;
        try {
          command = JSON.parse(message);
          this.listenCommand(command);
        } catch (e) {
          console.log('fail iframe child init:', e);
        }
      },
      onReady: () => {
        initializedMicroApp();
        console.log('> ready to listen child iframe communication');
      },
    };
  }

  loadIFrameResizerWindow() {
    const resizer = resizerContentWindow();
    // console.log('load resizerContentWindow lib:', resizer);
  }

  listenCommand(command: IFrameCommand) {
    const { type, payload } = command;
    // console.log('>>> [iframe-child: received command]:', type, payload, !!payload.page);
    switch (type) {
      case GV_IFRAME_COMMAND.INIT_MICRO_APP:
        initMicroAppWithInitPage(payload, !!payload.page);
        break;
      case GV_IFRAME_COMMAND.LOAD_PAGE:
        loadMicroAppPage(payload);
        break;
      case GV_IFRAME_COMMAND.CHECK_FORM_DIRTY:
        sendFormsDirtyState();
        break;
      case GV_IFRAME_COMMAND.UPDATE_GLOBAL_STATE:
        sendChangedGlobalState(payload);
        break;
      case GV_IFRAME_COMMAND.ACTIVE_MICRO_APP:
        setActiveMicroApp(payload);
        break;
      case GV_IFRAME_COMMAND.CHANGE_DEFAULT_LANG:
        changeDefaultLang(payload);
        changeTimeLocale(payload);
        break;
      case GV_IFRAME_COMMAND.SET_URL:
        break;
      case GV_IFRAME_COMMAND.SYNC_CONDITION:
        break;
      case GV_IFRAME_COMMAND.FIRE_ACTION_FROM_PORTAL:
        setFiredActionFromPortal(payload);
        break;
    }
  }

  getIFrameId(): string {
    const hostIFrame: any = (<any>window)['parentIFrame'];
    if (hostIFrame) {
      return hostIFrame.getId();
    }
    return '';
  }

  resizeIFrame(height: number, width: number): void {
    const hostIFrame: any = (<any>window)['parentIFrame'];
    if (hostIFrame) {
      hostIFrame.size(height, width);
    }
  }

  removeIFrame() {
    const hostIFrame: any = (<any>window)['parentIFrame'];
    if (hostIFrame) {
      hostIFrame.close();
    }
  }
}

export const iframeChildService = new IFrameChildService();
