import { GVIcon, CustomIconComponentProps } from '../antd-icon-generator';

const SvgContext = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
    <path d="M527 356.25h-124c-19.88 0-36-16.12-36-36s16.12-36 36-36h124c19.88 0 36 16.12 36 36s-16.12 36-36 36ZM547.97 702.75c0-19.88-16.12-36-36-36h-167.97c-19.88 0-36 16.12-36 36s16.12 36 36 36h167.97c19.88 0 36-16.12 36-36ZM237.78 703c0-19.88-16.12-36-36-36h-73.78c-19.88 0-36 16.12-36 36s16.12 36 36 36h73.78c19.88 0 36-16.12 36-36ZM547.97 894c0-19.88-16.12-36-36-36H128c-19.88 0-36 16.12-36 36s16.12 36 36 36h383.97c19.88 0 36-16.12 36-36ZM932 511.5c0-19.88-16.12-36-36-36h-396c-19.88 0-36 16.12-36 36s16.12 36 36 36h396c19.88 0 36-16.12 36-36ZM932 238c0-80.5-65.5-146-146-146s-146 65.5-146 146 65.5 146 146 146 146-65.5 146-146ZM860 238c0 40.8-33.2 74-74 74s-74-33.2-74-74 33.2-74 74-74 74 33.2 74 74ZM381.19 529.97c6.43-11.15 6.41-24.89-.05-36.03L159.15 110.95c-8.17-14.09-24.76-20.94-40.49-16.71-15.72 4.23-26.65 18.48-26.65 34.77v383c0 19.88 16.12 36 36 36h222c12.87 0 24.77-6.87 31.19-18.03ZM164 262.9l123.52 213.1h-123.52v-213.1ZM932 897v-194c0-19.88-16.12-36-36-36h-220c-19.88 0-36 16.12-36 36v194c0 19.88 16.12 36 36 36h220c19.88 0 36-16.12 36-36ZM712 739h148v122h-148v-122ZM563 129c0-19.88-16.12-36-36-36h-213c-19.88 0-36 16.12-36 36s16.12 36 36 36h213c19.88 0 36-16.12 36-36Z" />
  </svg>
);

export function ABContextIcon(props: CustomIconComponentProps) {
  return <GVIcon component={SvgContext} {...props} />;
}
