import { isNumber } from 'lodash';

import { $ab_action_click, $ab_action_default, $ab_action_hover } from '@grandview/stylet';

import { getAntDLang } from '../i18n/i18n';

// ex) libs/component/src/lib/common/delete-circle-button/delete-circle-button.tsx
export function styleClickColor(dom: any, action: 'click' | 'blur' | 'hover', styleProperty = 'color') {
  if (!dom) {
    return;
  }

  if (action === 'click') {
    dom.style[styleProperty] = $ab_action_click();
  } else if (action === 'hover') {
    dom.style[styleProperty] = $ab_action_hover();
  } else {
    dom.style[styleProperty] = $ab_action_default();
  }
}

export function styleClickBorder(dom: any, action: 'click' | 'blur' | 'hover', borderValue = '1px solid') {
  if (!dom) {
    return;
  }

  if (action === 'click') {
    dom.style['border'] = `${borderValue} ${$ab_action_click()}`;
  } else if (action === 'hover') {
    dom.style['border'] = `${borderValue} ${$ab_action_hover()}`;
  } else {
    dom.style['border'] = `${borderValue} ${$ab_action_default()}`;
  }
}

export function styleWidthHeight(
  width: number | string | undefined,
  height: number | string | undefined,
  defaultValue = '100%'
) {
  return {
    width: width ? (isNumber(width) ? `${width}px` : width) : defaultValue,
    height: height ? (isNumber(height) ? `${height}px` : height) : defaultValue,
  };
}

export function fetchStyle(cssURL: string) {
  return new Promise((resolve: any, reject: any) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = cssURL;
    document.head.appendChild(link);
    link.onload = function () {
      resolve();
    };
  });
}

export function initAntDStyleI18n(locale: string = getAntDLang()) {
  const style = `/assets/antd-i18n/antd_${locale}.css`;
  fetchStyle(style).then(
    () => console.log('> antd style loaded succesfully'),
    () => console.error('> antd style could not be loaded')
  );
}
