/**
 * Define page size type
 */
export enum PageSize {
  ALL = 100000,
  DEFAULT = 15,
  DETAIL = 5,
  MAIN_MODEL_LIST = 50,
  // TEST = 20,
  THOUSAND = 1000
}
