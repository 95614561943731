import { isEmpty } from 'lodash';

import { getDayjs } from '../timezone';
import { isNullOrUndefined } from './utils';
import { commonStatus } from '../domain/statuses/common-status.service';
import { IRulResult, STATUS_ROLE } from '../model';

export const getRulDay = (status: { id: string; name: string }, rul: number) => {
  const { id, name } = status;
  const isStableStatus = id === getRulStatus(0).id ? true : id !== getRulStatus(-1).id ? false : undefined;

  let val = rul;
  let rulRemainStatus = undefined;
  let rulStatusLabel = undefined;
  if (isStableStatus != undefined) {
    if (!isNullOrUndefined(rul) && isFinite(rul) && !isNaN(rul)) {
      val = Math.floor(val) > 0 ? Math.round(val) : 0;
      const maxRul = 999;
      rulRemainStatus = val === 0 ? 'D-Day' : `D-${val > maxRul ? maxRul : val}`;
      rulStatusLabel = isStableStatus ? commonStatus.getTranslateLabel(STATUS_ROLE.RUL, id) : rulRemainStatus;
    }
  }
  return {
    status,
    rul: val,
    remainStatus: rulRemainStatus,
    statusLabel: rulStatusLabel,
    isStable: isStableStatus,
  };
};

const getRulStatus = (sequence: number) => {
  const stableStatus = commonStatus.get(STATUS_ROLE.RUL).find((status: any) => status.sequence === sequence);
  return stableStatus || { id: undefined };
};

/**
 * RUL HealthIndex Data Valid Check
 * @param chartData
 */
export const isRulDataValid = (chartData: IRulResult) => {
  if (!chartData || !chartData.healthIndex || isEmpty(chartData.healthIndex) || !chartData.healthIndex.times) {
    return false;
  }
  const health = chartData.healthIndex;
  return health && health.times.length > 0 && health.values.length > 0;
};

export const getAllowLineInfo = (originData: any) => {
  const allow = originData.allowLine || {};
  return {
    start: {
      time: allow.times?.[0],
      value: allow.values?.[0],
      // failureTime 마크라인과 가까울 경우 그리지 않도록 처리
      isLabel:
        getDayjs(originData.failureTime).format('MM-DD') !== getDayjs(allow.times[0]).format('MM-DD') &&
        allow.values?.[0] !== originData.rul,
      label: getRulNameByValue(allow.values?.[0]),
    },
    end: allow.times[1]
      ? {
          value: allow.values[1],
          time: allow.times[1],
          isLabel:
            getDayjs(originData.failureTime).format('MM-DD') !== getDayjs(allow.times[1]).format('MM-DD') &&
            allow.values[1] !== originData.rul,
          label: getRulNameByValue(allow.values[1]),
        }
      : {
          isLabel: false,
          time: null,
          value: null,
          label: '',
        },
  };
};

const getRulNameByValue = (val: any) => {
  if (isNullOrUndefined(val) || isNaN(val) || !isFinite(val)) {
    return val;
  }
  val = Math.floor(val) > 0 ? Math.round(val) : 0;
  if (val === 0) {
    return 'D-Day';
  }
  if (val >= 999) {
    return 'ASSET.Stable';
  }
  return `D-${val}`;
};
