import { logout } from '../commands/unauthorized.hook';
import { switchPage } from '../commands/switch-page.hook';
import { setModalMask } from '../commands/modal-mask.hook';
import { redirectInvalidLicense } from '../../auth/license';
import { loadedMicroApp } from '../commands/loaded-micro-app.command';
import { sendChangedGlobalState } from '../commands/micro-app/change-global-state.command';
import { fireUpdatedGlobalStateToMicroApps, initMicroApp } from './iframe-host-to-child.sender';
import { removeActivePageHistory, updateActivePageHistory } from '../active-page-history-manager';
import { receiveFormsDirtyState } from '../commands/micro-app/check-form-dirty.command';
import { GV_IFRAME_COMMAND, GlobalStateType, IFrameCommand } from '../../model';
import { setIframeCommunicationStatus } from '../../state';

class IFrameHostService {
  listenChildIFrame(microAppName: string, data: any, activeApp: any) {
    try {
      const { type, payload }: IFrameCommand = JSON.parse(data.message);
      console.log('>> [iframe-host: received command]:', microAppName, type, payload);
      switch (type) {
        case GV_IFRAME_COMMAND.INIT_IFRAME:
          initMicroApp(microAppName, activeApp);
          setIframeCommunicationStatus(microAppName);
          break;
        case GV_IFRAME_COMMAND.INIT_MICRO_APP:
          loadedMicroApp(payload);
          break;
        case GV_IFRAME_COMMAND.LOAD_PAGE:
          switchPage(microAppName, payload);
          break;
        case GV_IFRAME_COMMAND.CHECK_FORM_DIRTY:
          receiveFormsDirtyState(microAppName, payload);
          break;
        case GV_IFRAME_COMMAND.PROPAGATE_UPDATED_GLOBAL_STATE:
          // TO PORTAL
          if ([GlobalStateType.CHANGED_DASHBOARD, GlobalStateType.CHANGED_USER_DETAIL_INFO, GlobalStateType.CHANGED_PORTAL_HEADER].includes(payload?.type)) {
            sendChangedGlobalState(payload);
          }
          // TO MICRO APPS
          fireUpdatedGlobalStateToMicroApps(payload);
          break;
        case GV_IFRAME_COMMAND.UPDATE_ACTIVE_PAGE:
          updateActivePageHistory(microAppName, payload);
          if (payload.isReload) {
            setTimeout(() => window.location.reload(), 300);
          }
          break;
        case GV_IFRAME_COMMAND.REMOVE_ACTIVE_PAGE:
          removeActivePageHistory(microAppName);
          break;
        case GV_IFRAME_COMMAND.SYNC_CONDITION:
          break;
        case GV_IFRAME_COMMAND.SET_URL:
          break;
        case GV_IFRAME_COMMAND.LOGOUT:
          logout();
          break;
        case GV_IFRAME_COMMAND.REDIRECT_LICENSE:
          redirectInvalidLicense(payload.url);
          break;
        case GV_IFRAME_COMMAND.MODAL_MASK:
          setModalMask(payload);
          break;
      }
    } catch (e) {
      // TODO notification
    }
  }
}

export const iframeHostService = new IFrameHostService();
