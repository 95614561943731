import { translateByI18nObject } from '../../services/translate.service';
import { commonStatus } from './common-status.service';
import { formatNumber } from '../../utilities/number-util';
import { IManageStatus, KPI_ROLE, NONE, ROLE, STATUS_ROLE } from '../../model';

export function getValueFormatByKpi(role: STATUS_ROLE | KPI_ROLE | ROLE, value: number): number | string | typeof NONE {
  if (value === undefined || value === null) {
    return NONE;
  } else {
    /**
     *   HEALTH: 'KPI_MAX_HEALTH',
  AVG_HEALTH: 'KPI_AVG_HEALTH',
  RUNNING: 'KPI_RUNNING_STATUS',
  RUL: 'KPI_RUL',
  ALARM: 'KPI_ALARM',
     */
    if (
      role === STATUS_ROLE.HEALTH ||
      role === KPI_ROLE.HEALTH ||
      role === KPI_ROLE.AVG_HEALTH ||
      role === KPI_ROLE.RUNNING
    ) {
      return formatNumber(value, 2);
    } else if (role === STATUS_ROLE.BATCH) {
      return formatNumber(value, 2);
    } else {
      return value;
    }
  }
}

/**
 * 서버 statuses 와 model statuses(사용자가 설정한 정보) 정보를 합쳐서 리턴
 * 서버 statuses 에서 (N/A) 는 사용하지 않음 (sequence === -1)
 * 요구사항 for Eric
 * - N/A 삭제 : sequence === -1
 * - Good value 값은 null 로 넘어오지만 정렬시 마지막 row 에 배치
 * - Good 이외의 항목에 value 값이 undefined 경우는 없는 조건으로 아래 로직 실행 (undefined 나오면 서버 에러)
 * @param userStatuses : 사용자가 지정한(model statuses)
 */
export function getModelStatuses(userStatuses: IManageStatus[] = []): IManageStatus[] {
  const defaultStatuses = commonStatus.get(STATUS_ROLE.HEALTH);
  return (defaultStatuses as any[])
    .filter((d: IManageStatus) => d.sequence !== -1) // N/A 삭제
    .map((d: IManageStatus) => {
      const item = userStatuses?.find((x: IManageStatus) => x.id === d.id);
      return {
        ...d,
        value: item?.value || d.value,
        label: translateByI18nObject(d?.i18n),
      };
    })
    .sort((a: IManageStatus, b: IManageStatus) => a.value - b.value)
    .reverse();
}
