/**
 * current page editing mode or read mode
 */
export function getCurrentPageEditModeState() {
  if (!(window as any).GV_STATE_currentPageEditModeState) {
    (window as any).GV_STATE_currentPageEditModeState = {};
  }
  return (window as any).GV_STATE_currentPageEditModeState;
}

export function setCurrentPageEditMode(tabKey: string, isEditMode: boolean) {
  getCurrentPageEditModeState()[tabKey] = isEditMode;
}

export function getCurrentPageEditMode(tabKey: string) {
  return getCurrentPageEditModeState()[tabKey] || false;
}
