import { atom, useAtom } from 'jotai';
import { IGVPageMenu } from '../../model';

/**
 * Micro App
 * Page Menu
 * Page Tab
 */
export const PageMenuState = {
  microApp: atom<any | undefined>(undefined),
  currentActiveApp: atom<any | undefined>(undefined),
  menus: atom<IGVPageMenu[] | undefined>(undefined),
  createPage: atom<IGVPageMenu | undefined>(undefined),
  collapsed: atom<boolean>(true),
  myProfile: atom<any | undefined>(undefined),
  fireActionFromPortal: atom<any | undefined>(undefined),
};

export const PageTabState = {
  pages: atom<IGVPageMenu[]>([]),
  activeTabKey: atom<string | undefined>(undefined),
  splittedTabKeys: atom<string[] | undefined>(undefined),
  removeTabKey: atom<string | undefined>(undefined),
  removeDynamicMenuTabKey: atom<string | undefined>(undefined),
  programicalRemoveTab: atom<string | undefined>(undefined),
  tabIndex: atom<number>(-1),
  shareGlobalStateAction: atom<any>(undefined),
};

export function useFiredActionsFromPortal() {
  return useAtom(PageMenuState.fireActionFromPortal);
}

export function useActiveTabKey() {
  return useAtom(PageTabState.activeTabKey);
}

export function useSplittedTabKeys() {
  return useAtom(PageTabState.splittedTabKeys);
}

export function useRemoveTabKey() {
  return useAtom(PageTabState.removeTabKey);
}

export function useRemoveDynamicMenuTabKey() {
  return useAtom(PageTabState.removeDynamicMenuTabKey);
}

export function useProgramicalRemoveTab() {
  return useAtom(PageTabState.programicalRemoveTab);
}

export function useShareGlobalStateAction() {
  return useAtom(PageTabState.shareGlobalStateAction);
}

export function useMicroApp() {
  return useAtom(PageMenuState.microApp);
}

export function useSideBarMenusForMicroApp() {
  return useAtom(PageMenuState.menus);
}

export function useMyProfile() {
  return useAtom(PageMenuState.myProfile);
}

export const microAppBackdropState = atom<boolean>(false);
export function useMicroAppBackdrop() {
  return useAtom(microAppBackdropState);
}

export const visibleTabKeys: any = {};
