import { mapValues, keyBy, groupBy } from 'lodash';

import { getDayjs, getIntervalStringToArray } from '../timezone/date-format';
import { CustomFieldDataType, CustomFieldItemType, ICustomFieldProps } from '../model';

export function convertCustomPropsToFormValue(props: any[]) {
  const modelInfoCustomProps = mapValues(keyBy(props, 'attribute.code'), ({ value, attribute }: any) =>
    attribute.inputType === CustomFieldItemType.DATE_PERIOD && !value
      ? [getDayjs(new Date()).subtract(1, 'd'), getDayjs(new Date())]
      : castCustomPropsValue(value, attribute)
  );
  return { customProps: modelInfoCustomProps, customPropsByGroup: groupBy(props, 'attribute.groupCode') };
}

export const castCustomPropsValue = (value: any, attribute: ICustomFieldProps) => {
  if (attribute.inputType === CustomFieldItemType.DATE_PERIOD) {
    return getIntervalStringToArray(value);
  } else {
    switch (attribute.dataType) {
      case CustomFieldDataType.NUMBER:
        return value && Number(value);
      default:
        return value;
    }
  }
};
