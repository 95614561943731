/**
 * @param obj
 * @param keys
 */
export function pick(obj: Record<string, unknown>, keys: string[]): Record<string, unknown> {
  const r: any = {};
  keys.forEach((key) => {
    r[key] = obj[key];
  });
  return r;
}
