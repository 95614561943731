export enum ElementType {
  TEXT = 'TEXT',
  BOX = 'BOX',
  CIRCLE = 'CIRCLE',
  RING = 'RING',
  LINE = 'LINE',
  ARROW = 'ARROW',
  IMAGE = 'IMAGE',
}
export interface ILayoutPersistence {
  id?: string | null;
  layoutCode?: string | null;
  name: string;
  description?: string;
  publish?: boolean;
  config: { width: number; height: number; fill: string };
  elements?: IElementPersistence[];
}

export interface IElementMaster {
  type: string;
  elementId: string;
  config: { id?: string; type: string; x: number; y: number; width: number; height: number };
}

/**
 * DB Save Model
 * 최종 DB에 저장되는 모델
 */
export interface IElementPersistence {
  id?: string | null;
  elementId?: string | null;
  type: string;
  objectId?: string | null;
  objectType?: string | null;
  objectStatus?: string | null;
  sequence: number;
  config: any;
}

export interface IElementInstance {
  elementId: string;
  id: any;
  type: string;
  objectId?: string | null;
  objectType?: string | null;
  objectStatus?: string | null;
  sequence: number;
  config: any;
  elementPersistence: IElementPersistence;
}

// export interface IElementProps {
//   x?: number;
//   y?: number;
//   height?: number;
//   width?: number;
//   editInstanceId?: string;
//   config?: any;
// }

export interface IElementData {
  objectId: string;
  objectType: string;
  objectStatus: 'HEALTH' | 'RUL' | 'ALARM' | string;
  elementId: string;
  health?: {
    assetScore: number;
    id: string;
  };
  rul?: {
    value: number;
    id: string;
  };
  alarm?: {
    id: string;
  };
}
