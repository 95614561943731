import { isFunction } from "lodash";
import { getDateFormatByTimeSeriesChart } from "../timezone";

interface IExportCvsColumns {
  key: string;
  name: string;
  render?: (value: string) => any;
}

/**
 * array 내용을 csv 형식에 맞게 변환
 * @param data export 할 data
 * @param exportColumns
 */
export const convertCsvData = (data: any[], exportColumns: IExportCvsColumns[]) => {
  return data?.map((d: any) => {
    const keyValues = exportColumns?.map((column: IExportCvsColumns) => { // [['파라미터명', 'param1'], ['개수',  100]];
      const name = column.name;
      const value = isFunction(column.render)
        ? column.render(d?.[column.key])
        : d?.[column.key];
      return [name, value];
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Object.fromEntries(keyValues); // {'파라미터명': 'param1', '개수': 100};
  });
};

/**
 * array 내용을 csv 형식에 맞게 변환 - time series chart 용
 * @param data export 할 timeSeries data
 * @param valueColumnName
 * @param timeColumnName
 */
export const convertCsvDataByTimeSeries = (data: any, valueColumnName: string, timeColumnName = 'event_time') => {
  const list = data?.map((d: any) => ({
    [timeColumnName]: getDateFormatByTimeSeriesChart(d?.[0]),
    [valueColumnName]: d?.[1],
  }));
  // 첫번째 값이 NaN 이면 삭제 (보통 x 축을 조회조건 기간에 맞게 그리기 위해 시작일자를 NaN으로 설정한 케이스)
  if (Number.isNaN(list?.at(0)?.[valueColumnName])) {
    list.shift();
  }
  // 마지막 값이 NaN 이면 삭제 (보통 x 축을 조회조건 기간에 맞게 그리기 위해 종료일자를 NaN으로 설정한 케이스)
  if (Number.isNaN(list?.at(-1)?.[valueColumnName])) {
    list.pop();
  }
  return list;
};


export const convertCsvDataBySequenceSeries = (data: any, valueColumnName: string, sequenceColumnName = 'sequence') => {
  const list = data?.map((d: any) => ({
    [sequenceColumnName]: d?.[0],
    [valueColumnName]: d?.[1],
  }));
  // 첫번째 값이 NaN 이면 삭제 (보통 x 축을 조회조건 기간에 맞게 그리기 위해 시작일자를 NaN으로 설정한 케이스)
  if (Number.isNaN(list?.at(0)?.[valueColumnName])) {
    list.shift();
  }
  // 마지막 값이 NaN 이면 삭제 (보통 x 축을 조회조건 기간에 맞게 그리기 위해 종료일자를 NaN으로 설정한 케이스)
  if (Number.isNaN(list?.at(-1)?.[valueColumnName])) {
    list.pop();
  }
  return list;
}
