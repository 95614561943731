import { useEffect } from 'react';
import { ReplaySubject } from 'rxjs';
import { merge } from 'lodash';

import {
  getCurrentRealtimeState,
  getDashboardMenuFilters,
  isInitRealtimeState,
  setCurrentUrlParamsState,
  setInitRealtimeState,
  useHealthStatusFilterState,
  useLocationFilterState,
  usePeriodOptionsFilterState,
  useRealtimeOptionsFilterState,
  useSelectedDateTypeState,
  useSwitchRealTime,
} from '../../state';
import { DateType, TimeRangeUnitType } from '../../model';
import { timezoneService } from '../../timezone/timezone.service';
import { sendDataToSubscriber } from './common-one-way-data.hook';
import { getRealtimeFiltersFromLS, isPersonalizationApp, saveRealtimeFiltersToLS } from '../../config/personalization-config.service';
import { chageRangeFilterByDateType } from '../../filters/realtime-filter-options.service';

// ref: realtimeOptions: libs/web/shared/src/lib/hooks/tabkey/realtime-filter.hook.ts
/*
[Realtime]
  assetId=32868c61-d357-4967-ae19-a818facd4df5
  isRealtime=true
  locationId=catalog_asset_03
  locationPath=catalog_asset_01.catalog_asset_02.catalog_asset_03
  timeCycle=10
  timeRange=1
  timeRangeUnit=hours
  aggregation=MAX // 'LAST'
  selectedDateType=TODAY
  start=2021-12-03%2009:48:30
  end=2021-12-03%2010:48:30
  detailPage=overview
  selectedDateType=CURRENT

[Period]
  assetId=32868c61-d357-4967-ae19-a818facd4df5
  isRealtime=false
  locationId=catalog_asset_03
  locationPath=catalog_asset_01.catalog_asset_02.catalog_asset_03
  timeCycle=10
  timeRange=1
  timeRangeUnit=hours
  selectedDateType=YESTERDAY
  aggregation=MAX
  start=2021-12-02%2000:00:00
  end=2021-12-02%2023:59:59
  detailPage=overview
  selectedDateType=TODAY (YESTERDAY, DAYS7, CUSTOM)
*/
export function sendDrillDownRealtimeFilterState(tabKey: string, params: any) {
  if (params) {
    getRealtimeFilterOneWaySubject(tabKey).sendRealtimeFilter(params);
    // console.log('>>>> [drill-down send] tabKey, realtimeFilter', tabKey, params);
  }
}


/**
 * Use None UI
 */
export function getRealtimeOneWaySubject() {
  if (!(window as any).GV_STATE_RX_realtimeOneWaySubject) {
    (window as any).GV_STATE_RX_realtimeOneWaySubject = {};
  }
  return (window as any).GV_STATE_RX_realtimeOneWaySubject;
}

function getRealtimeFilterOneWaySubject(tabKey: string) {
  if (!getRealtimeOneWaySubject()[tabKey]) {
    const subject = new ReplaySubject<any>(1);
    getRealtimeOneWaySubject()[tabKey] = {
      sendRealtimeFilter: (realtimeOptions: any) => subject.next(realtimeOptions),
      realtimeFilterOservable: () => subject,
    };
  }
  return getRealtimeOneWaySubject()[tabKey];
}

/**
 * <3가지 우선순위에 따른 초기값 설정>
 * 1순위: Link를 타고 오거나, Browser refresh시에 uri querystring값을 기준으로 다시 상태정보를 설정한다. ex) email alarm link url
 * 2순위: local storage 설정 값
 * 3순위: menus api의 dashboard의 filters 객체값
 */
export function useUrlPathSubscribeRealtimeFilterState(tabKey: string, initRealtime = true) {
  const [, setLocation] = useLocationFilterState(tabKey);
  const [, setSwitchRealtime] = useSwitchRealTime(tabKey, initRealtime);
  const [, setRealtimeOption] = useRealtimeOptionsFilterState(tabKey);
  const [, setPeriodOption] = usePeriodOptionsFilterState(tabKey);
  const [, setSelectedDateType] = useSelectedDateTypeState(tabKey);
  const [, setHealthStatusState] = useHealthStatusFilterState(tabKey);

  useEffect(() => {
    if (!tabKey) {
      return;
    }

    const subject = getRealtimeFilterOneWaySubject(tabKey);
    const subscription = subject.realtimeFilterOservable().subscribe((realtimeOptions: any) => {
      if (!realtimeOptions) {
        return;
      }

      if (isInitRealtimeState(tabKey) && getCurrentRealtimeState(tabKey)) {
        realtimeOptions = getCurrentRealtimeState(tabKey);
        // console.log('>>>>> [realtime filter subscribe] tabKey, 0) set filter:', tabKey, realtimeOptions);
      }

      let stateInfo: any;
      // 3순위) get dashboard menus api - filters, only for dashboard application
      const dashboardMenuFilters = getDashboardMenuFilters() || {};
      // 2순위) get local storage, only for dashboard application
      const realtimeFiltersFromLS = isPersonalizationApp(tabKey) ? getRealtimeFiltersFromLS(tabKey) : {};

      // 1순위) get query string of URL
      const { isRealtime, selectedDateType, start, end, period, intervals } = realtimeOptions;
      // url query string에 없는 값은 undefined으로 그대로 설정해야 한다.
      const isRealtimeValue = start && end && selectedDateType !== DateType.CURRENT ? false : isRealtime === 'true' || isRealtime === true ? true : isRealtime;
      const isCheckCustomPeriod = start && end && selectedDateType !== DateType.CURRENT ? true : isRealtime === 'false' || isRealtime === false ? true : false;
      const urlFilters = {
        ...realtimeOptions,
        isRealtime: isRealtimeValue,
        selectedDateType: isCheckCustomPeriod ? (selectedDateType ? selectedDateType : DateType.CUSTOM) : undefined,
        period: {
          from: start,
          to: end,
        },
      };

      // 3순위 -> 2순위 -> 1순위 merge object
      const finalOptions = merge(dashboardMenuFilters, realtimeFiltersFromLS, urlFilters);
      console.log('>>>>> [realtime filter subscribe] tabKey, 1) set filter:', tabKey, finalOptions);

      // finalOptions을 LocalStorage를 업데이트 한다. isRealtime이 true 또는 false 둘중 하나이어야 한다.
      if (finalOptions.isRealtime !== undefined) {
        saveRealtimeFiltersToLS(
          tabKey,
          finalOptions.isRealtime
            ? {
              isRealtime: finalOptions.isRealtime,
              selectedDateType: finalOptions.selectedDateType,
              timeCycle: finalOptions.timeCycle,
              timeRange: finalOptions.timeRange,
              timeRangeUnit: finalOptions.timeRangeUnit,
            }
            : {
              isRealtime: finalOptions.isRealtime,
              selectedDateType: finalOptions.selectedDateType,
              period: finalOptions.period,
            }
        );
      }

      if (finalOptions.isRealtime) {
        // REALTIME OPTION
        const realtimeOptions = {
          timeCycle:
            typeof finalOptions.timeCycle === 'string'
              ? parseInt(finalOptions.timeCycle)
              : typeof finalOptions.timeCycle === 'number'
                ? finalOptions.timeCycle
                : 10,
          timeRange:
            typeof finalOptions.timeRange === 'string'
              ? parseInt(finalOptions.timeRange)
              : typeof finalOptions.timeRange === 'number'
                ? finalOptions.timeRange
                : 1,
          timeRangeUnit: finalOptions.timeRangeUnit ? finalOptions.timeRangeUnit : TimeRangeUnitType.HOURS,
        };

        stateInfo = {
          isRealtime: true,
          locationId: finalOptions.locationId,
          locationPath: finalOptions.locationPath,
          selectedDateType: DateType.CURRENT,
          timeCycle: realtimeOptions.timeCycle,
          timeRange: realtimeOptions.timeRange,
          timeRangeUnit: realtimeOptions.timeRangeUnit,
        };
        setRealtimeOption(realtimeOptions);

        if (finalOptions.healthStatus) {
          setHealthStatusState(finalOptions.healthStatus);
        }
        // console.log('>>>>> [realtime filter subscribe] tabKey, 2) set realtime filter:', tabKey, updatedRealtimeOptions);
      } else {
        // REPIOD OPTION
        // 1) interval만 있을 경우
        const isCUSTOM = !finalOptions.selectedDateType && intervals;
        finalOptions.selectedDateType = isCUSTOM ? DateType.CUSTOM : finalOptions.selectedDateType;
        // 2) Default 값) current 이거나, selectedDateType이 없으면 period 에서 default는 TODAY 로 설정함.
        const isTODAY =
          finalOptions.selectedDateType === DateType.TODAY ||
          finalOptions.selectedDateType === DateType.CURRENT ||
          (!finalOptions.selectedDateType && !intervals);
        finalOptions.selectedDateType = isTODAY ? DateType.TODAY : finalOptions.selectedDateType;

        // custom 일 경우만 option값이 아닌, 넘겨받은 period또는 start~end값을 사용한다.
        const option = chageRangeFilterByDateType(finalOptions.selectedDateType, intervals);
        if (period) {
          option.period = {
            from: timezoneService.utcStringToDate(period.from),
            to: timezoneService.utcStringToDate(period.to),
          };
          finalOptions.start = period.from;
          finalOptions.end = period.to;
        } else {
          if (start && end) {
            option.period = {
              from: timezoneService.utcStringToDate(start),
              to: timezoneService.utcStringToDate(end),
            };
          }
        }

        stateInfo = {
          isRealtime: false,
          locationId: finalOptions.locationId,
          locationPath: finalOptions.locationPath,
          selectedDateType: finalOptions.selectedDateType,
          start: finalOptions.start ? finalOptions.start : finalOptions.period?.from,
          end: finalOptions.end ? finalOptions.end : finalOptions.period?.to,
        };
        setPeriodOption(option);

        if (finalOptions.healthStatus) {
          setHealthStatusState(finalOptions.healthStatus);
        }
      }

      setSelectedDateType(finalOptions.selectedDateType);

      if (finalOptions.locationId !== '' || finalOptions.locationPath !== '') {
        setLocation({ locationId: finalOptions.locationId, locationPath: finalOptions.locationPath });
        sendDataToSubscriber(tabKey, 'location-filter-while-init-page', {
          locationId: finalOptions.locationId,
          locationPath: finalOptions.locationPath,
        });
      }

      setSwitchRealtime(finalOptions.isRealtime);
      setInitRealtimeState(tabKey, true);
      // upate current params in cache
      setCurrentUrlParamsState(tabKey, stateInfo);
      // console.log('>>>>> [realtime filter subscribe] tabKey, 3) set state:', tabKey, stateInfo);
    });
    return () => subscription.unsubscribe();
  }, [tabKey]);
}
