import { merge } from 'lodash';

import {
  $gv_chart_health_index_line_series_color,
  $gv_chart_health_trend_series_color,
  $gv_chart_parameter_line_series_color,
} from '@grandview/stylet';

import { isNullOrUndefined } from '../utilities/utils';
import {
  ColorModeType,
  ColorThemeType,
  CustomSVGInMapType,
  IMicroFrontendConfiguration,
  PluginType,
  SiteThemeType,
} from '../model';
import { getRandomId } from '../utilities/random-generator';

export function setConfig(key: string, value: any): IMicroFrontendConfiguration {
  const config = (window as any).GV_CONFIG || {};
  config[key] = value;
  return config;
}

export function gvConfig(): IMicroFrontendConfiguration {
  return (window as any).GV_CONFIG || {};
}

/**
 * PODO api prefix
 */
// 더 이상 사용하지 않음. 사용하지 마세요!!! from 2023.06.20
// export function gvApiPrefix(): string {
//   return gvConfig()?.GV_API_PREFIX || '/podo/api';
// }
export function gvPODOApiPrefix(): string {
  return gvConfig()?.GV_PODO_API_PREFIX || '/podo/api';
}
export function gvOAuthApiPrefix(): string {
  return gvConfig()?.GV_OAUTH_API_PREFIX || '/podo/oauth';
}
export function gvWebsocketApiPrefix(): string {
  return gvConfig()?.GV_WEBSOCKET_API_PREFIX || '/podo/stomp';
}

/**
 * APM, MeteData api prefix
 */
export function gvAPMApiPrefix(): string {
  return gvConfig()?.GV_APM_API_PREFIX || '/apm/api';
}

export function gvAssetMetaApiPrefix(): string {
  return gvConfig()?.GV_ASSET_META_API_PREFIX || '/ameta/api';
}

/**
 * Optima - Anomaly Detection api
 */
export function optimaADApiPrefix(): string {
  return gvConfig()?.OPTIMA_AD_API_PREFIX || '/ad/api';
}

/**
 * KOSMOS - Detector
 */
export function kosmosDTApiPrefix(): string {
  return gvConfig()?.KOSMOS_DT_API_PREFIX || '/detector/api';
}

/**
 * Font Family
 */
export function gvEChartFontStyle(): any {
  return gvConfig()?.ECHART_FONT_STYLE || { fontFamily: 'SpoqaHanSans' };
}

export function limitTabCount(): number {
  return gvConfig()?.LIMIT_TAB_COUNT || 10;
}

export function isUseAuthMicroApp(): boolean {
  return !!gvConfig()?.USE_AUTH_MICRO_APP;
}

export function isUseCookie(): boolean {
  return !!gvConfig()?.USE_COOKIE_AUTH;
}

export function locationPrefix(): string {
  return gvConfig()?.LOCATION_PREFIX || '';
}

export function getPageTheme(): string {
  return gvConfig()?.PAGE_THEME || ColorThemeType.WHITE;
}

export function getLayoutTheme(): string {
  return gvConfig()?.APP_LAYOUT_THEME || ColorThemeType.PURPLE;
}

export function getStyle(): any {
  return (
    gvConfig()?.STYLE || {
      CHART_PARAMETER_COLOR: $gv_chart_parameter_line_series_color,
      CHART_HEALTH_INDEX_COLOR: $gv_chart_health_index_line_series_color,
      CHART_HEALTH_TREND_COLOR: $gv_chart_health_trend_series_color,
    }
  );
}

export function getCustomSVGStyle(): any {
  const config: any = {};
  if (gvConfig()?.MAP_CUSTOM_SVG_STYLE && gvConfig()?.MAP_CUSTOM_SVG_STYLE?.SVG_TYPE) {
    config['svgType'] = gvConfig()?.MAP_CUSTOM_SVG_STYLE?.SVG_TYPE;
    if (gvConfig()?.MAP_CUSTOM_SVG_STYLE?.PATH && !isNullOrUndefined(gvConfig()?.MAP_CUSTOM_SVG_STYLE?.PATH)) {
      config['d'] = gvConfig()?.MAP_CUSTOM_SVG_STYLE?.PATH;
    }
    if (gvConfig()?.MAP_CUSTOM_SVG_STYLE?.WIDTH) {
      config['width'] = gvConfig()?.MAP_CUSTOM_SVG_STYLE?.WIDTH;
    }
    if (gvConfig()?.MAP_CUSTOM_SVG_STYLE?.HEIGHT) {
      config['height'] = gvConfig()?.MAP_CUSTOM_SVG_STYLE?.HEIGHT;
    }
  }

  return gvConfig()?.MAP_CUSTOM_SVG_STYLE
    ? config
    : {
      svgType: CustomSVGInMapType.PIN,
      width: 60,
      height: 40,
    };
}

export function getWidgetContainerLayout(): any {
  const config: any = {};
  if (gvConfig()?.WIDGETS_GRID_LAYOUT) {
    const layout = gvConfig()?.WIDGETS_GRID_LAYOUT;
    if (layout?.COLUMN_COUNT) {
      config['cols'] = layout.COLUMN_COUNT;
    }
    if (layout?.ROW_HEIGHT) {
      config['rowHeight'] = layout.ROW_HEIGHT;
    }
    if (layout?.ITEM_COUNT) {
      config['items'] = layout.ITEM_COUNT;
    }
    if (layout?.AUTO_RESIZE) {
      config['autoSize'] = layout.AUTO_RESIZE;
    }
    if (layout?.ALLOW_OVERLAP) {
      config['allowOverlap'] = layout.ALLOW_OVERLAP;
    }
    if (layout?.PREVENT_COLLISION) {
      config['preventCollision'] = layout.PREVENT_COLLISION;
    }
  }

  return merge(
    {
      preventCollision: false,
      allowOverlap: false,
      autoSize: true,
      rowHeight: 10,
      items: 10,
      cols: 24,
    },
    config
  );
}

export function getReportletContainerLayout(): any {
  const config: any = {};
  if (gvConfig()?.REPORTLETS_GRID_LAYOUT) {
    const layout = gvConfig()?.REPORTLETS_GRID_LAYOUT;
    if (layout?.COLUMN_COUNT) {
      config['cols'] = layout.COLUMN_COUNT;
    }
    if (layout?.ROW_HEIGHT) {
      config['rowHeight'] = layout.ROW_HEIGHT;
    }
    if (layout?.ITEM_COUNT) {
      config['items'] = layout.ITEM_COUNT;
    }
    if (layout?.AUTO_RESIZE) {
      config['autoSize'] = layout.AUTO_RESIZE;
    }
    if (layout?.ALLOW_OVERLAP) {
      config['allowOverlap'] = layout.ALLOW_OVERLAP;
    }
    if (layout?.PREVENT_COLLISION) {
      config['preventCollision'] = layout.PREVENT_COLLISION;
    }
    if (layout?.VERTICAL_COMPACT) {
      config['verticalCompact'] = layout.VERTICAL_COMPACT;
    }
  }

  return merge(
    {
      verticalCompact: false,
      preventCollision: true,
      allowOverlap: false,
      autoSize: true,
      rowHeight: 5,
      items: 20,
      cols: 24,
    },
    config
  );
}

export function getChartParameterColor(): string {
  return getStyle().CHART_PARAMETER_COLOR;
}

export function getChartHealthIndexColor(): string {
  return getStyle().CHART_HEALTH_INDEX_COLOR;
}

export function getChartHealthTrendColor(): string {
  return getStyle().CHART_HEALTH_TREND_COLOR;
}

export function getEChartsDecimalScale(): number {
  return gvConfig()?.ECHARTS?.DECIMAL_SCALE || 2;
}

export function typesI18N(): any {
  return gvConfig()?.TYPES_I18N || {};
}

export function isProduction(): boolean {
  return (window as any).GV_PRODUCTION;
}

export function secretToken(): string {
  return gvConfig()?.AUTH?.SECRET || '';
}

export function apiRequestTimeout(): number {
  return gvConfig()?.API_REQUEST_OPTION?.timeout || 0; // default value is 0, 0 means "not use timeout"
}

export function realtimeAggregation(): string {
  return gvConfig()?.REALTIME_AGGREGATION || 'MAX';
}

export function getMicroAppUrl(): string {
  if (gvConfig()?.MICRO_APP_URL) {
    return gvConfig()?.MICRO_APP_URL || '';
  } else {
    return '';
  }
}

/**
 * Theme antd, portal, application
 */
export function getThemeAntD() {
  return (window as any).GV_THEME_ANTD || {};
}

export function getThemeAntDAllMode() {
  return (window as any).GV_THEME_ANTD_ALL_MODE || {};
}

export function getThemePortal() {
  return (window as any).GV_THEME_PORTAL || {};
}

export function getThemeApplication() {
  return (window as any).GV_THEME_APPLICATION || {};
}

/**
 * Portal
 */
export function PortalTheme() {
  return {
    title: getThemePortal().portal?.title || 'GrandView',
    favicon: getThemePortal().portal?.favicon || 'podo.ico',
    login: getThemePortal().portal?.login || {},
    logo: getThemePortal().portal?.logo || {},
  };
}

export function gvPortalLayoutThemeConfig() {
  // not used
  // return gvConfig()['PORTAL_LAYOUT_THEME'] || ColorThemeType.BLACK;
  return getThemePortal().portal?.['layout-color'] || ColorThemeType.BLACK;
}

export function gvPortalLayoutModeConfig() {
  // not used
  // return gvConfig()['PORTAL_LAYOUT_THEME'] || ColorThemeType.BLACK;
  return getThemePortal().portal?.['layout-mode'] || ColorModeType.DARK;
}

/**
 * Application
 */
export function gvApplicationLayoutThemeConfig() {
  // not used
  // return gvConfig()['APP_LAYOUT_THEME'] || ColorThemeType.PURPLE;
  return getThemeApplication().application?.['layout-color'] || ColorThemeType.PURPLE;
}

export function gvApplicationPageThemeConfig() {
  // not used
  // return gvConfig()['PAGE_THEME'] || ColorThemeType.WHITE;
  return getThemeApplication().application?.['page-color'] || ColorThemeType.WHITE;
}

/**
 * Report in application
 */
export function gvReportPageThemeConfig() {
  return {
    footerImage:
      getThemeApplication().application?.report?.['page-template']?.footer?.image ||
      '/studio/assets/images/gvi_logo_224_gray.png',
    footerImageStyle: getThemeApplication().application?.report?.['page-template']?.footer?.imageStyle || {
      width: '140px',
      height: '16px',
    },
  };
}

/**
 * Site Name: will deprecated in v1.1.2
 */
export function gvSiteNameConfig() {
  // design/libs/web/layout/micro-app/src/lib/main/micro-app-main-flexlayout.scss 안에서 사용중 예) pfeiffer
  return gvConfig()['SITE_NAME'] || SiteThemeType.AIDENTYX;
}

export function gvPrintAppDevPort() {
  return gvConfig()?.PRINT_APP_DEV_PORT || 4000;
}

/**
 * Main Runtime Theme
 * podo-purple, kosmos-blue
 */
export function mainRuntimeTheme(type: 'portal' | 'application' = 'portal') {
  if (type === 'portal') {
    return getThemePortal().portal?.['main-runtime-theme'] || 'podo-purple';
  } else {
    return getThemeApplication().application?.['main-runtime-theme'] || 'podo-purple';
  }
}

/**
 * Runtime Theme Mode
 * light, dark
 */
export function getDefaultThemeMode(): 'light' | 'dark' {
  return getThemePortal()?.portal?.['default-theme-mode'] || 'light';
}

export function getDefaultSidebarWidth() {
  return getThemeApplication().application?.['default-sidebar-width'] || 0;
}

/**
 * 구조
 {
    "apm": {
      "widget": ["apm-default-widgets"],
      "reportlet": ["apm-default-reportlets"]
    },
    "kosmos": {
      "widget": ["detector-default-widgets"]
    }
  }
 */
export function setPluginNameByHostAppNPluginType(hostApps: any) {
  (window as any).GV_MF_HOST_APPLICATIONS = hostApps;
  console.log('> [plugin] GV_MF_HOST_APPLICATIONS:', (window as any).GV_MF_HOST_APPLICATIONS);
}

export function getPluginNameByHostAppNPluginType() {
  return (window as any).GV_MF_HOST_APPLICATIONS;
}

export function getPluginNamesByApp(appName: string, pluginType: string) {
  if (!(window as any).GV_MF_HOST_APPLICATIONS) {
    return;
  }

  // transfer print to report

  return (window as any).GV_MF_HOST_APPLICATIONS[appName]?.[pluginType];
}

export function getPluginHostApplicationContextPaths(): string[] {
  if (!(window as any).GV_MF_HOST_APPLICATIONS) {
    return [];
  }

  return Object.keys((window as any).GV_MF_HOST_APPLICATIONS);
}

export function setPluginDev(pluginDev: any) {
  (window as any).GV_MF_PLUGIN_DEV_MODE = pluginDev;
  console.log('> [plugin] GV_MF_PLUGIN_DEV_MODE:', (window as any).GV_MF_PLUGIN_DEV_MODE);
}

export function getPluginDev() {
  return (window as any).GV_MF_PLUGIN_DEV_MODE;
}

export function setPluginCacheKey(appName: string) {
  (window as any).GV_PLUGIN_CACHE_KEY = `${appName}:${getRandomId()}`;
  console.log('> [plugin] GV_PLUGIN_CACHE_KEY:', (window as any).GV_PLUGIN_CACHE_KEY);
}

export function getPluginCacheKey() {
  return (window as any).GV_PLUGIN_CACHE_KEY;
}

/**
 * 전체 애플리케이션에서 widget 또는 reportlet의 plugin name 목록
 */
export function getPluginNamesByType(type: PluginType): string[] {
  if (!(window as any).GV_MF_HOST_APPLICATIONS) {
    return [];
  }

  let result: string[] = [];
  const appNames = Object.keys((window as any).GV_MF_HOST_APPLICATIONS);
  appNames.forEach((appName: string) => {
    const application = (window as any).GV_MF_HOST_APPLICATIONS[appName];
    const pluginNames = application[type];
    if (pluginNames?.length) {
      result = result.concat(pluginNames);
    }
  });
  return result;
}

export function setMasterConfigByPluginName(pluginName: string, masterConfig: any) {
  if (!(window as any).GV_MF_MASTER_CONFIG_BY_PLUGIN_NAME) {
    (window as any).GV_MF_MASTER_CONFIG_BY_PLUGIN_NAME = {};
  }

  (window as any).GV_MF_MASTER_CONFIG_BY_PLUGIN_NAME[pluginName] = masterConfig;
  console.log('> [plugin] GV_MF_MASTER_CONFIG_BY_PLUGIN_NAME:', (window as any).GV_MF_MASTER_CONFIG_BY_PLUGIN_NAME);
}

export function getMasterConfigByPluginName(pluginName?: string) {
  if (!(window as any).GV_MF_MASTER_CONFIG_BY_PLUGIN_NAME || !pluginName) {
    return;
  }

  return (window as any).GV_MF_MASTER_CONFIG_BY_PLUGIN_NAME[pluginName];
}

export function getMasterConfigByPlugin_FunctionName(pluginName?: string, functionName?: string) {
  if (!(window as any).GV_MF_MASTER_CONFIG_BY_PLUGIN_NAME || !pluginName || !functionName) {
    return;
  }

  return (window as any).GV_MF_MASTER_CONFIG_BY_PLUGIN_NAME[pluginName][functionName];
}
