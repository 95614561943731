import { memo } from 'react';

export enum PriorityTypes {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

/* eslint-disable-next-line */
export interface PriorityIconIconProps {
  type: PriorityTypes;
  height?: number;
  width?: number;
}

/***********************************
 * Usage

 <GVPriorityIcon type={PriorityTypes.HIGH} />
 <GVPriorityIcon type={PriorityTypes.LOW} />
 <GVPriorityIcon type={PriorityTypes.MEDIUM} />

 ***********************************/
export function PriorityIcon({ type }: PriorityIconIconProps) {
  switch (type) {
    case PriorityTypes.HIGH:
      return (
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.66736 0.998568L4.66712 0.998841L0.790556 5.48947L0.790363 5.48969C0.675632 5.62313 0.669195 5.79351 0.739712 5.92664C0.809387 6.05818 0.951148 6.14998 1.12285 6.14998H4.53664L0.790556 10.4895L0.790363 10.4897C0.675632 10.6231 0.669195 10.7935 0.739712 10.9266C0.809387 11.0582 0.951148 11.15 1.12285 11.15H8.87754C9.04924 11.15 9.191 11.0582 9.26068 10.9266C9.33119 10.7935 9.32476 10.6231 9.21003 10.4897L9.20981 10.4894L5.46224 6.14998H8.87754C9.04924 6.14998 9.191 6.05818 9.26068 5.92664C9.33119 5.79351 9.32476 5.62313 9.21003 5.48969L9.20981 5.48944L5.33169 0.998818L5.33147 0.998568C5.15916 0.799936 4.83966 0.799936 4.66736 0.998568Z"
            fill="#FF4D4F"
            stroke="#FF4D4F"
            strokeWidth="0.3"
          />
        </svg>
      );

    case PriorityTypes.LOW:
      return (
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.46247 5.84963H5.26428M5.46247 5.84963L5.33293 5.99963H8.87621C9.10902 5.99963 9.23871 6.24494 9.09496 6.41213L5.26428 5.84963M5.46247 5.84963L9.21005 1.51017L9.21026 1.50992C9.32499 1.37648 9.33143 1.2061 9.26091 1.07297C9.19124 0.941431 9.04948 0.849626 8.87777 0.849626H1.12152C0.950364 0.849626 0.808729 0.941639 0.739165 1.07297C0.668621 1.20615 0.675048 1.37681 0.790937 1.51031C0.790998 1.51039 0.79106 1.51046 0.791122 1.51053L4.53688 5.84963H1.12152C0.950365 5.84963 0.808729 5.94164 0.739165 6.07297C0.668615 6.20616 0.675049 6.37683 0.790964 6.51035C0.791016 6.51041 0.791069 6.51047 0.791122 6.51053L4.66691 11.0003C4.66701 11.0004 4.6671 11.0005 4.6672 11.0006C4.83799 11.1998 5.15976 11.1998 5.33054 11.0006L5.33036 11.0008L5.21684 10.9028L5.33082 11.0003L9.20848 6.51017L9.2087 6.50992C9.32343 6.37648 9.32987 6.2061 9.25935 6.07297C9.18967 5.94143 9.04791 5.84963 8.87621 5.84963H5.46247ZM5.26428 5.84963L9.09652 1.41213C9.24027 1.24494 9.11059 0.999626 8.87777 0.999626H1.12152C0.890273 0.999626 0.759023 1.24494 0.904335 1.41213L5.26428 5.84963Z"
            fill="#11C3E1"
            stroke="#11C3E1"
            strokeWidth="0.3"
          />
        </svg>
      );

    case PriorityTypes.MEDIUM:
      return (
        <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.866677 0.666664C0.572125 0.666664 0.333344 0.905446 0.333344 1.2V2.8C0.333344 3.09455 0.572125 3.33333 0.866677 3.33333H9.13334C9.4279 3.33333 9.66668 3.09455 9.66668 2.8V1.2C9.66668 0.905446 9.4279 0.666664 9.13334 0.666664H0.866677Z"
            fill="#52C41A"
          />
        </svg>
      );

    default:
      return <span></span>;
  }
}

export const GVPriorityIcon = memo(PriorityIcon);
