import { isSingleMicroAppInDevMode } from '../../../browser/location';
import { GV_IFRAME_COMMAND, IFrameCommand } from '../../../model';
import { loadMicroAppPage } from '../../commands/micro-app/load-page.command';

export function sendToHost(command: IFrameCommand) {
  const hostIFrame: any = (<any>window)['parentIFrame'];
  if (hostIFrame) {
    let msg: any = '';
    try {
      msg = JSON.stringify(command);
    } catch (e) {
      console.log('fail iframe send to host:', e);
    }
    hostIFrame.sendMessage(msg);
  }
}

export function gotoMicroAppPage(microAppName: string, pageName: string, payload: any) {
  if (isSingleMicroAppInDevMode()) {
    // Dev Mode: If run only micro app.
    loadMicroAppPage({
      app: { name: microAppName },
      page: { name: pageName },
      params: payload,
    });
  } else {
    sendToHost({
      type: GV_IFRAME_COMMAND.LOAD_PAGE,
      payload: {
        microAppName,
        pageName,
        params: payload,
      },
    });
  }
}
