/**
 * Use it on portal-web
 */

import { updateBrowserLocation } from '../browser/location';

const activePagePerMicroApp: Map<string, any> = new Map();

export function updateActivePageHistory(microAppName: string, page: any) {
  const tabKey = page['key'];
  const params = page['initParams'] || {};
  updateBrowserLocation(microAppName, tabKey, params);

  activePagePerMicroApp.set(microAppName, page);
  // console.log('>>>>> [iframe-host: update active page history]', microAppName, page, activePagePerMicroApp);
}

export function removeActivePageHistory(microAppName: string) {
  activePagePerMicroApp.delete(microAppName);
  updateBrowserLocation(microAppName, '');
  // console.log('>>>>> [iframe-host: remove active page history]', microAppName, activePagePerMicroApp);
}

export function getActivePageHistory(microAppName: string) {
  return activePagePerMicroApp.get(microAppName);
}

export function getActivePageHistories() {
  return activePagePerMicroApp;
}

export function clearActivePageHistories() {
  activePagePerMicroApp.clear();
  updateBrowserLocation('', '');
}
