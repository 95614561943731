import { themeType } from '../types';

export interface PageletProps {
  tabKey: string;
  page?: any;
  theme?: themeType;
  initParams?: any;
}

// Dashboard
export enum GV_DASHBOARD_CODE_PAGE {
  HEALTH = 'dashboard.code-health',
  KPI = 'dashboard.code-kpi',
  ALARM = 'dashboard.code-alarm',
  SENSOR = 'dashboard.code-sensor',
}

export enum GV_USER_PAGE {
  MY_PROFILE = 'user.profile',
}

export enum GV_APM_PAGE {
  // asset
  ASSET_LIST = 'apm.asset-list',
  ASSET_DETAIL = 'apm.detail',
  ASSET_DETAIL_OVERVIEW = 'apm.detail.overview',
  ASSET_DETAIL_DATA = 'apm.detail.trend',
  // - start 최신 DATA tab 이지만 하위 호환을 위하여 PARAMETER, TREND 유지함. (향후 삭제)
  ASSET_DETAIL_PARAMETER = 'apm.detail.trend',
  ASSET_DETAIL_TREND = 'apm.detail.trend',
  // - end
  ASSET_DETAIL_ALARM = 'apm.detail.alarm',
  // alarm-group
  ALARM_LIST = 'apm.alarm-list',
  ALARM_DETAIL = 'apm.alarm-detail',
  // work-order
  WORK_ORDER_LIST = 'apm.work-order-list',
  WORK_ORDER_DETAIL = 'apm.work-order-detail',
}

export enum OPTIMA_AD_PAGE {
  // asset
  ASSET_LIST = 'ad.asset-list',
  ASSET_DETAIL = 'ad.detail',
  ASSET_DETAIL_OVERVIEW = 'ad.detail.overview',
  ASSET_DETAIL_PARAMETER = 'ad.detail.parameter',
  ASSET_DETAIL_ALARM = 'ad.detail.alarm',
  ALARM_MANAGEMENT = 'ad.alarm-management',
}

export enum KOSMOS_DETECTOR_PAGE {
  ASSET_LIST = 'detector.asset-list',
  ASSET_DETAIL = 'detector.detail',
  ASSET_DETAIL_OVERVIEW = 'detector.detail.overview',
  ASSET_DETAIL_PARAMETER = 'detector.detail.parameter',
  ASSET_DETAIL_ALARM = 'detector.detail.alarm',
  ALARM_MANAGEMENT = 'detector.alarm-management',
  WORK_ORDER_LIST = 'detector.work-order-list',
  WORK_ORDER_DETAIL = 'detector.work-order-detail',
}

export enum GV_REPORT_PAGE {
  HISTORY = 'report.history',
  ON_DEMAND = 'report.on-demand',
}

export enum GV_PIPELINE_PAGE {
  EDGE_FLOW = 'pipeline.edge-flow',
  RULE_ENGINE = 'pipeline.rule-engine',
}

// deplicated v1.1
// export enum GV_ALARM_GROUP_PAGE {
//   LIST = 'apm.alarm-list',
//   DETAIL = 'apm.alarm-detail',
// }

// export enum GV_WORK_ORDER_PAGE {
//   LIST = 'apm.work-order-list',
//   DETAIL = 'apm.work-order-detail',
// }

export enum GV_ASSET_METADATA_PAGE {
  ASSET_CONFIG_WIZARD = 'ameta.asset-config-wizard',
  ASSET_HIERARCHY = 'ameta.asset-hierarchy',
  MODEL_LIST = 'ameta.model',
  ASSET_LIST = 'ameta.asset',
  CONTEXT = 'ameta.context',
  ASSET_GROUP = 'ameta.asset-group',
  ALARM_RULE_LIST = 'ameta.alarm-rule',
  ASSET_TEMPLATE = 'ameta.asset-template',

  // deprecated: link 화면 이동시에는 사용할 수 없다.
  MODEL_DETAIL = 'ameta.model.detail',
  ASSET_INFO_DETAIL = 'ameta.asset.detail',
  ALARM_RULE_DETAIL = 'ameta.alarm-rule.edit',
}

export enum GV_STUDIO_PAGE {
  DASHBOARD = 'studio.dashboard',
  LAYOUT = 'studio.layout',
  REPORT = 'studio.report',
}

export enum GV_SYSTEM_PAGE {
  DATASOURCE = 'system.datasource',
  LICENSE = 'system.license',
  GLOBAL_CONFIG = 'system.global-config',
  PLUGIN_STORE = 'system.plugin-store',
}

// GV_ASSET_METADATA_PAGE 쪽으로 옮겨감. 하위호환성유지위해 향후 제거함.
export const GV_MODEL_MANAGEMET_PAGE = 'ameta.model';
export const GV_ASSET_MANAGEMET_PAGE = 'ameta.asset';

export const GV_ASSET_OVERVIEW = 'overview';
export const GV_ASSET_DATA = 'data';
export const GV_ASSET_TREND = 'trend';
export const GV_ASSET_ALARM = 'alarm';
export const GV_ASSET_DETAIL = 'detail';

export const GV_ASSET_TYPE_ID = 'type_master_asset';
export const GV_LOCATION_TYPE_ID = 'type_master_location';
