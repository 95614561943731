import { ActionType } from '@grandview/shared';

import { PageState, usePageState } from '../web-page.state';
import { sendDataToSubscriber, useSubscribeData } from '../../../hooks/tabkey/common-one-way-data.hook';

export interface Layout {
  id: string;
  name: string;
  description: string;
  modifiedTime: Date;
  modifier: string;
  layoutCode: string;
  elementCount: number;
  config: { width: number; height: number; fill: string };
}

export interface LayoutAction extends PageState {
  action: ActionType;
  layout?: Layout;
}

export const DEFAULT_KPI_STATUS = 'HEALTH';

/**
 * Builder State
 */
// export const LAYOUT_MANAGEMENT_ACTION = 'layout-management-action';
// export const LAYOUT_MANAGEMENT_BUILDER_ACTION = 'layout-builder-more-action';
// export function useLayoutAction(tabKey: string, actionName?: string) {
//   return usePageState<LayoutAction>(tabKey, actionName || LAYOUT_MANAGEMENT_ACTION, null, true);
// }
export function sendLayoutBuilderAction(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'layout-builder-action', value);
}
export function useSubscribeLayoutBuilderAction(tabKey: string) {
  return useSubscribeData(tabKey, 'layout-builder-action', null, false);
}

export function sendLayoutBuilderMoreAction(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'layout-builder-more-action', value);
}
export function useSubscribeLayoutBuilderMoreAction(tabKey: string) {
  return useSubscribeData(tabKey, 'layout-builder-more-action', null, false);
}

/**
 * Builder State
 */
export function useCollapseLeftPanel(tabKey: string, isPreview: boolean | undefined) {
  return usePageState(tabKey, 'layout-left-panel-collapse', !isPreview);
}

export function useCollapseRightPanel(tabKey: string, isPreview: boolean) {
  return usePageState(tabKey, 'layout-right-panel-collapse', !isPreview);
}

export function useAddElement(tabKey: string) {
  // return usePageState(tabKey, 'layout-builder-add-element', null, isDestroy);
  return useSubscribeData(tabKey, 'layout-builder-add-element');
}

export function sendAddElement(tabKey: string, elementMaster: any) {
  sendDataToSubscriber(tabKey, 'layout-builder-add-element', elementMaster);
}

/**
 * Layout Styles & Elements Styles: From Layout Template to Layout Builder
 */
export function useDirtyAllElements(tabKey: string) {
  return usePageState(tabKey, 'layout-builder-dirty-all-element-styles');
}

/**
 * From Layout Style Form to Layout Template
 */
export function useDirtyLayoutStyles(tabKey: string, initStyles?: any) {
  return usePageState(tabKey, 'layout-builder-dirty-layout-styles', initStyles, true);
}

/**
 * Select Element
 */
export function useSelectElement(tabKey: string) {
  return usePageState(tabKey, 'layout-builder-selected-element');
}

/**
 * Select Object
 */
export function useSelectObject(tabKey: string) {
  return useSubscribeData(tabKey, 'layout-builder-selected-object');
}

export function sendSelectedObject(tabKey: string, selectedObject: any) {
  sendDataToSubscriber(tabKey, 'layout-builder-selected-object', selectedObject);
}

/**
 * From Element Style Form to Element component
 */
export function useDirtyElementStylesById(tabKey: string) {
  return usePageState(tabKey, 'layout-builder-dirty-element-styles', null, true);
}

/**
 * From Transform of Element compopnent to Element Style Form
 */
export function sendTransformedElementById(tabKey: string, elementId: string, config: any) {
  sendDataToSubscriber(tabKey, 'layout-builder-dirty-element-transform', {
    elementId,
    config,
  });
}

export function useDirtyTransformedElementById(tabKey: string) {
  return useSubscribeData(tabKey, 'layout-builder-dirty-element-transform');
}

/**
 * Switch Preview and Edit mode
 */
export function useSwitchPreviewMode(tabKey: string) {
  return usePageState(tabKey, 'layout-builder-switch-preview-edit-mode');
}
