export enum PriorityTypes {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum EstimatedUnit {
  D = 'Day',
  H = 'Hour',
  M = 'Minute',
}

export enum WorkTypes {
  BM = 'BM',
  PM = 'PM',
  GENERAL = 'GENERAL',
}
