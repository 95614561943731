/**
 * analysis model execution type
 */
export enum DomainType {
  LAYOUT = 'LAYOUT',
  ASSET = 'ASSET',
  ASSET_TEMPLATE = 'ASSET_TEMPLATE',
  BATCH = 'BATCH',
  FAVORITE = 'FAVORITE',
  LOCATION = 'LOCATION',
  MODEL = 'MODEL',
  PARAMETER = 'PARAMETER',
  QRCODE = 'QRCODE',
  USER = 'USER',
  WIDGET = 'WIDGET',
  WORKORDER = 'WORKORDER',
  ALARM = 'ALARM',
  RUL = 'RUL',
  SENSOR = 'SENSOR',
}
