import { iframeMicroAppManager } from '../iframe-micro-app-manager';
import { getRandomId } from '../../utilities/random-generator';
import { GV_IFRAME_COMMAND, GlobalStatePayload, IFrameCommand } from '../../model';

export function sendToChild(microAppName: string, command: IFrameCommand, targetIFrame: any = null) {
  if (targetIFrame) {
    // tslint:disable-next-line:no-shadowed-variable
    let msg = '';
    try {
      msg = JSON.stringify(command);
    } catch (e) {
      console.log('fail iframe send to child:', e);
    }
    targetIFrame.iFrameResizer.sendMessage(msg);
    return;
  }

  const iframe: any = iframeMicroAppManager.getIFrame(microAppName);
  if (!iframe) {
    // case-1) If user open the saved workspace, a widget of dashboard already was deleted.
    console.log(`There is no iframe for ${microAppName}`);
    return;
  }
  let msg: any = '';
  try {
    msg = JSON.stringify(command);
  } catch (e) {
    console.log('fail iframe send to child:', e);
  }
  iframe['iFrameResizer'].sendMessage(msg);
}

export function sendToChilds(command: IFrameCommand) {
  const iframes: any = iframeMicroAppManager.getIFrames();

  iframes.forEach((iframe: any, appKey: string) => {
    let msg: any = '';
    try {
      msg = JSON.stringify(command);
    } catch (e) {
      console.log('fail iframe send to child:', e);
    }
    if (iframe) {
      iframe['iFrameResizer'].sendMessage(msg);
    } else {
      console.log('there is no iframe for', appKey);
    }
  });
}

export function initMicroApp(appId: string, activeApp: any) {
  const sendCommand = {
    type: GV_IFRAME_COMMAND.INIT_MICRO_APP,
    payload: activeApp,
  };
  sendToChild(appId, sendCommand);
}

export function fireActionOnHeader(actionType = 'click') {
  const sendCommand = {
    type: GV_IFRAME_COMMAND.FIRE_ACTION_FROM_PORTAL,
    payload: { fireAction: `${actionType}:${getRandomId()}` },
  };
  sendToChilds(sendCommand);
}

export function fireUpdatedGlobalStateToMicroApps(payload: GlobalStatePayload) {
  // send to child micro-apps
  sendToChilds({
    type: GV_IFRAME_COMMAND.UPDATE_GLOBAL_STATE,
    payload,
  });
}
