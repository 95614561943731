import { PickerLocale } from 'antd/lib/date-picker/generatePicker';

import CalendarLocale from 'rc-picker/lib/locale/ko_KR';
import locale from 'antd/locale/ko_KR';

import TimePickerLocale from 'antd/es/time-picker/locale/ko_KR';
// import type { PickerLocale } from '../generatePicker';

// import 'dayjs/locale/ko';

const koLocale: any = {
  // ...CalendarLocale,
  ...locale,
  ok: '확인',
  shortWeekDays: ['일', '월', '화', '수', '목', '금', '토'],
  shortMonths: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
};

// Merge into a locale object
export const ko_datepicker_locale: PickerLocale = {
  lang: {
    placeholder: '날짜 선택',
    rangePlaceholder: ['시작일', '종료일'],
    ...koLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

// export default locale;