import { memo } from 'react';
import { ConfigProvider } from 'antd';

import { getThemeAntD } from '../config/config';
import { getCurrentAntDAlgorithm, getDefaultAlgorithmAntDAlgorithm, getThemeAntDByModeType } from './antd-theme-config.service';
import { ThemeModeType } from '../model';

/* eslint-disable-next-line */
interface AntDCurrentThemeProviderProps {
  children: any;
}

function AntDCurrentThemeProvider({ children }: AntDCurrentThemeProviderProps) {
  return (
    <ConfigProvider theme={{ algorithm: getCurrentAntDAlgorithm(), token: getThemeAntD()?.token }}>
      {children}
    </ConfigProvider>
  );
}

export const GVAntDCurrentThemeProvider = memo(AntDCurrentThemeProvider);

function AntDDefaultAlogrithmThemeProvider({ children }: AntDCurrentThemeProviderProps) {
  return (
    <ConfigProvider theme={{ algorithm: getDefaultAlgorithmAntDAlgorithm(), token: getThemeAntDByModeType(ThemeModeType?.LIGHT || 'light')?.token }}>
      {children}
    </ConfigProvider>
  );
}

export const GVAntDDefaultAlogrithmThemeProvider = memo(AntDDefaultAlogrithmThemeProvider);
