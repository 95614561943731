import { t } from 'i18next';
import { isEmpty } from 'lodash';

import { cookieService } from '../cookie/cookie.service';
import { ROLE, SelectOption } from '../model';

interface CommonType {
  id: string;
  code: string;
  sequence: number;
  i18n: any;
}

interface ICommonType {
  set: (roleTypes: { [roleId: ROLE]: CommonType[] }) => void;
  get: (role: ROLE) => CommonType[];
  getObject: (role: ROLE, propertyValue: string, identifier?: string) => CommonType;
  getProperty: (role: ROLE, id: string, property: string, identifier?: string) => any;
  getTranslateLabel: (role: ROLE, code: string, identifier?: string) => string;
  getSelectOptions: (role: ROLE, identifier?: string) => SelectOption[];
}

function applyGVTypeList(data: any) {
  (window as any).GV_typeList = data;
}

function getGVTypeList() {
  return (window as any).GV_typeList;
}

function setTypeList(data: { [roleId: ROLE]: CommonType[] }) {
  if (data) {
    applyGVTypeList(data);
  }
}

function getTypesRole(role: string) {
  if (!getGVTypeList()) {
    return;
  }
  return getGVTypeList()[role] || [];
}

function getTranslateOptions(role: ROLE, key = 'id') {
  if (!getGVTypeList()) {
    return;
  }
  const lang = cookieService.get('I18N_LANG');

  return (getGVTypeList()[role] || []).map((item: any) => {
    return {
      value: item[key],
      label: item.i18n[lang || 'en'] || item.i18n['en'],
      extra: item,
    };
  });
}

function translateTypes(role: ROLE, code: string, identifier = 'code') {
  if (!role || !code) {
    return '';
  }
  return get(code, role || '', identifier) || '';
}

function get(key: string, role: string, identifier = 'code'): string {
  const lang = cookieService.get('I18N_LANG') || 'en';
  // const type: any = getGVTypeList().find((item: any) => item.code === key && (!role || (!!role && item.role === role)));
  const type: any = getGVTypeList()[role]?.find((d: any) => d[identifier] === key);
  if (!type?.i18n?.[lang] && !type?.i18n?.['en']) {
    return '';
  }
  return isEmpty(type.i18n[lang]) ? type.i18n['en'] : type.i18n[lang];
}

function getObjectByProperty(role: ROLE, propertyValue: string, identifier = 'code') {
  if (!getGVTypeList() || getGVTypeList().length === 0) {
    return;
  }
  if (!getGVTypeList()[role]) {
    console.log(`${role} type data : `, getGVTypeList()[role]);
    return null;
  }
  return getGVTypeList()[role].find((status: any) => status[identifier] === propertyValue) || null;
}

function getProperty(role: ROLE, id: string, type: string, identifier = 'id') {
  if (!getGVTypeList() || getGVTypeList().length === 0) {
    return;
  }
  if (!getGVTypeList()[role]) {
    console.log(`${role} type data : `, getGVTypeList()[role]);
    return '';
  }
  return getGVTypeList()[role].find((status: any) => status[identifier] === id)?.[type] || '';
}

export const commonType: ICommonType = {
  set: setTypeList,
  //getProperty: getStatusLevel,
  get: getTypesRole,
  getObject: getObjectByProperty,
  getProperty: getProperty,
  getTranslateLabel: translateTypes,
  getSelectOptions: (role: ROLE, identifier = 'code') => getTranslateOptions(role, identifier),
};
