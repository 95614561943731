import { camelCase, isArray, merge } from "lodash";

/**
 *
 * @param columns : table 에 설정된 columns
 * @param visibleColumns : 사용 할 columns
 * @param invisibleColumns : 사용하지 않을 columns
 */
export const filterAndMergeUserColumns = (columns: any[], visibleColumns: any[] = [], invisibleColumns: any[] = []) => {
  const mergeList = mergeVisibleColumns(columns, visibleColumns);
  const filteredVisible = filterVisibleColumns(mergeList, visibleColumns);
  const filteredInvisible = filterInvisibleColumns(filteredVisible, invisibleColumns);
  return filteredInvisible;
};

export const mergeVisibleColumns = (columns: any[], visibleColumns: any[]) => {
  const convertVisibleColumns = convertTableColumns(visibleColumns);
  return columns?.map((column: any) => {
    const matchColumn = convertVisibleColumns.find((d: any) => {
      return camelCase(column.dataIndex) === camelCase(d.dataIndex);
    });
    return matchColumn
      ? merge(column, matchColumn)
      : column;
  });
};

export const filterVisibleColumns = (columns: any[], visibleColumns: any[]) => {
  const convertColumns = convertTableColumns(visibleColumns);
  if (convertColumns?.length === 0) {
    return columns;
  }
  return columns?.filter((column: any) => {
    return !!convertColumns.find((d: any) => {
      return camelCase(column.dataIndex) === camelCase(d.dataIndex);
    });
  });
};

export const filterInvisibleColumns = (columns: any[], invisibleColumns: any[]) => {
  const convertColumns = convertTableColumns(invisibleColumns);
  if (convertColumns?.length === 0) {
    return columns;
  }
  return columns?.filter((column: any) => {
    return !convertColumns.find((d: any) => {
      return camelCase(column.dataIndex) === camelCase(d.dataIndex);
    });
  });
};

/**
 * columns 에 설정된 냐용이 string, array 인 경우 table column type 으로 변환
 * table column type 이 아니면 정상적으로 merge 가 실행되지 않기 때문에 object 형태로 변환
 * @param columns
 */
const convertTableColumns = (columns: any[]) => {
  return columns?.map((d: any) => {
    return (typeof d === 'string' || isArray(d))
      ? {dataIndex: d}
      : d;
  });
};
