import { cloneDeep, isEmpty } from 'lodash';

import { sendToHost } from '../iframe/communication/micro-app/iframe-child-to-host.sender';
import { GV_APM_PAGE, GV_IFRAME_COMMAND, GV_MICRO_APP, IGVPageMenu, IUrlParams, KOSMOS_DETECTOR_PAGE, OPTIMA_AD_PAGE } from '../model';
import { getCurrentRealtimeState, setCurrentUrlParamsState } from '../state';
import { getSingleMicroAppNameInDevMode, isSingleMicroAppInDevMode } from '../browser/location';
import { updateActivePageHistory } from '../iframe/active-page-history-manager';

// use it in page
export function updateUrlParams(params: IUrlParams | IGVPageMenu, isApplyCurrentState = true, isReloadHostApp = false) {
  const payload: any = cloneDeep(params);
  if (payload?.initParams) {
    if (payload?.initParams['pageKey']) {
      delete payload.initParams['pageKey'];
    }
    if (payload?.initParams['handleKey']) {
      delete payload.initParams['handleKey'];
    }
  }

  if (payload.initParams && isApplyCurrentState) {
    setCurrentUrlParamsState(payload.key, payload.initParams);
  }

  if (isReloadHostApp) {
    payload.isReload = true;
  }

  if (isSingleMicroAppInDevMode()) {
    payload['initParams'] = { single: true };
    updateActivePageHistory(getSingleMicroAppNameInDevMode(), payload);
  } else {
    sendToHost({
      type: GV_IFRAME_COMMAND.UPDATE_ACTIVE_PAGE,
      payload,
    });
  }
}

export function updateClearUrlParams(tabKey: string) {
  const payload = {
    key: tabKey,
    initParams: {},
  };

  updateUrlParams(payload);
}

// use it in micro-app
export function updateUrlParamsByActivePage(page: IGVPageMenu) {
  if (!page) {
    return;
  }

  // send current active tab info to portal-web
  const payload: IGVPageMenu = cloneDeep(page);
  delete payload['content'];
  delete payload['icon'];

  // management쪽은 realtime 관련 querystring이 없다.

  const state = getCurrentRealtimeState(page.key);
  if (state && !isEmpty(state)) {
    if (payload.initParams) {
      if (payload.initParams['isRealtime'] === undefined) {
        if (checkUseRealtimeFilter(page.key)) {
          // 탭전환시에 설정된다. 불필요
          // payload.initParams['isRealtime'] = state.isRealtime;
        }
      }
    } else {
      if (checkUseRealtimeFilter(page.key)) {
        payload.initParams = {
          isRealtime: state.isRealtime,
        };
      }
    }

    // if isRealtime is false, set range filter
    if (state.isRealtime === false || state.isRealtime === 'false') {
      payload.initParams['selectedDateType'] = state.selectedDateType;
      if (state.period) {
        payload.initParams['start'] = state.period?.from;
        payload.initParams['end'] = state.period?.to;
      }
      // payload.initParams['aggregation'] = state.aggregation;
    }
  } else {
    // 최초 로딩시
    if (payload.initParams) {
      if (
        payload.initParams['isRealtime'] === undefined &&
        // check default realtime page
        (payload?.key?.indexOf(GV_MICRO_APP.DASHBOARD) >= 0 ||
          payload?.key?.indexOf(GV_APM_PAGE.ASSET_DETAIL) >= 0 ||
          payload?.key?.indexOf(KOSMOS_DETECTOR_PAGE.ASSET_DETAIL) >= 0)
      ) {
        if (checkUseRealtimeFilter(page.key)) {
          // 최초 sidebar menu 클릭시에 설정된다. 불필요
          // payload.initParams['isRealtime'] = true;
        }
      }
    } else {
      if (checkUseRealtimeFilter(page.key)) {
        payload.initParams = {
          isRealtime: true,
        };
      }
    }
  }

  updateUrlParams(payload);
}

function checkUseRealtimeFilter(pageKey: string) {
  return pageKey.indexOf('ameta.') < 0 && pageKey.indexOf('system.') < 0 && pageKey.indexOf('user.') < 0;
}
