/**
 * Add dayjs plugins
 */
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
// import advanced from 'dayjs/plugin/advancedFormat';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// import relativeTime from 'dayjs/plugin/relativeTime';

export function initDayjsPlugins() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(duration);
  // dayjs.extend(advanced);
  dayjs.extend(weekday);
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
}

/**
 * Export all
 */
export * from './date-format';
export * from './time-format-locale';
export * from './time-util';
export * from './timezone.service';
export * from './timezone-list';
