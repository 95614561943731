export type HierarchyTreeType = 'location' | 'asset';

export enum HierarchyType {
  LOCATION = 'location',
  ASSET = 'asset',
}

export enum HierarchySelectType {
  SINGLE_HIERARCHY = 'SINGLE_HIERARCHY',
  SINGLE_ASSET = 'SINGLE_ASSET',
}
