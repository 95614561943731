/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef } from 'react';
import { find } from 'lodash';

import { gvPODOApiPrefix } from '../config/config';
import { httpService } from '../ajax/http.service';
import { cookieService } from '../cookie/cookie.service';
import { IApplicationLicense, IUser } from '../model';
import { useEditablePermissions } from '../state';


export function getCurrentUser() {
  // let currentUser: IUser | undefined;
  if (!(window as any).GV_AUTH_currentUser) {
    (window as any).GV_AUTH_currentUser = {};
  }
  return (window as any).GV_AUTH_currentUser;
}

export function getAppsLicense() {
  // let appsLicense: IApplicationLicense[];
  if (!(window as any).GV_AUTH_appsLicense) {
    (window as any).GV_AUTH_appsLicense = {};
  }
  return (window as any).GV_AUTH_appsLicense;
}

export function initUserProfileInfo(profile: IUser | null = null, licenses: IApplicationLicense[] = []) {
  // user profile
  if (profile) {
    (window as any).GV_AUTH_currentUser = profile;
  } else if (cookieService.get('LOGIN_USER_ID')) {
    httpService.get<IUser>(`${gvPODOApiPrefix()}/users/my?projection=forDetailView`).then((result: IUser) => {
      (window as any).GV_AUTH_currentUser = result;
    });
  }
  // application licenses
  if (licenses?.length) {
    (window as any).GV_AUTH_appsLicense = licenses;
  } else {
    try {
      httpService
        .get<IApplicationLicense[]>(`${gvPODOApiPrefix()}/applications/license/check`)
        .then((licenses: IApplicationLicense[]) => {
          (window as any).GV_AUTH_appsLicense = licenses;
        });
    } catch (e: any) {
      console.log('> application license error:', e);
    }
  }
}

export function refetchUserProfile() {
  if (cookieService.get('LOGIN_USER_ID')) {
    httpService.get<IUser>(`${gvPODOApiPrefix()}/users/my?projection=forDetailView`).then((result: IUser) => {
      (window as any).GV_AUTH_currentUser = result;
    });
  }
}

export function isApplicationLicenseValid(appName: any) {
  // real code
  if (!getAppsLicense()?.length) {
    // 체크하지 않음.
    return true;
  }

  const appLicense = find(getAppsLicense(), { contextPath: appName });
  return !!appLicense?.licenseCheckResult;
}

export function clearCurrentUser() {
  (window as any).GV_AUTH_currentUser = undefined;
}

export function getUserId() {
  return cookieService.getUserId() || getCurrentUser()?.username || getCurrentUser()?.userName || 'no-user';
}

export function getUserImageUrl() {
  return cookieService.getUserImageUrl() || getCurrentUser()?.imageUrl;
}

// user profile에서 변경될 수 있기에 getCurrentUser()를 먼저 사용한다.
export function getUserEmail() {
  return getCurrentUser()?.email || getUserId();
}

export function getUserFullName() {
  return getCurrentUser()?.fullName || cookieService.getUserFullName() || 'no-user';
}

export function loginToken() {
  return cookieService.get('LOGIN_TOKEN');
}

export function getPermission() {
  return !!getCurrentUser()?.permissions ? getCurrentUser().permissions : [];
}

export function hasAppSystemPermission() {
  const permissionList = !!getCurrentUser() && !!getCurrentUser().permissions ? getCurrentUser().permissions : [];
  return permissionList.indexOf('PERM_SYSTEM_MANAGE_APP') > -1;
}

export function hasAssetSystemPermission() {
  const permissionList = !!getCurrentUser() && !!getCurrentUser().permissions ? getCurrentUser().permissions : [];
  return permissionList.indexOf('PERM_SYSTEM_MANAGE_ASSET') > -1;
}

/**
 * IUser.featureAuth을 setPermission 설정한다.
 */

export function useInitEditablePermission() {
  const [, setPermissions] = useEditablePermissions();
  const initLoad = useRef<boolean>(false);

  if (!initLoad.current) {
    httpService.get<IUser>(`${gvPODOApiPrefix()}/users/my?projection=forDetailView`).then((result: IUser) => {
      (window as any).GV_AUTH_currentUser = result;
      setPermissions(getCurrentUser()?.featureAuth);
      initLoad.current = true;
    });
  }
}
