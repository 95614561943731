import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { useMount, useUnmount } from 'ahooks';
import { forOwn, isEmpty } from 'lodash';
import { useAtom } from 'jotai';

import { getRandomId } from '../../../utilities/random-generator';
import { sendToHost } from '../../communication/micro-app/iframe-child-to-host.sender';
import { ActionType, GV_IFRAME_COMMAND, GlobalStatePayload, GlobalStateType } from '../../../model';
import { AppTabState, useShareStatePageAction } from '../../../state';

/**
 * [1]
 * Send to portal from my micro app
 * 변경을 전파하고자 하는 곳에서 호출
 * ex) 공용으로 사용할 때
 */
export function propagateGlobalState(payload: GlobalStatePayload) {
  console.log('>>> [propagate global state]', payload);
  sendToHost({
    type: GV_IFRAME_COMMAND.PROPAGATE_UPDATED_GLOBAL_STATE,
    payload: {
      ...payload,
      id: getRandomId(),
    },
  });
}

// If changed work order status: open, process...
export function propagateGlobalStateByWorkOrderStatus(payload: any) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_WORK_ORDER_STATUS, payload });
}

// If changed alarm status: open, process...
export function propagateGlobalStateByAlarmStatus(payload: any) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_ALARM_STATUS, payload });
}

// If changed asset detail info
export function propagateGlobalStateByAsset(payload: any) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_ASSET, payload });
}

// global state alert 을 위하여 action, payload 값을 전달한다.
export function propagateGlobalStateByParameter(payload: { action: ActionType; payload: any }) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_PARAMETER, payload });
}

// global state alert 을 위하여 action, payload 값을 전달한다.
export function propagateGlobalStateByParameterSpec(payload: { action: ActionType; payload: any }) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_PARAMETER_SPEC, payload });
}

export function propagateGlobalStateByModel(payload: { action: ActionType; payload: any }) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_MODEL, payload });
}

export function propagateGlobalStateByDashboard(payload: any) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_DASHBOARD, payload });
}

export function propagateGlobalStateByReport(payload: any) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_REPORT, payload });
}

export function propagateGlobalStateByUserDetailInfo(payload: any) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_USER_DETAIL_INFO, payload });
}

// If changed hierarch tree path in hierarchy
export function propagateGlobalStateByHierarchyPath(payload: any) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_HIERARCHY_PATH, payload });
}

export function propagateGlobalStateByReportHistory(payload: any) {
  propagateGlobalState({ type: GlobalStateType.CHANGED_REPORT_HISTORY, payload });
}


// ASSET외에 추가로 global state에 대해 전파하고 싶은 것이 있다면 배열에 열거한다.
const PROPAGATE_GLOBAL_CONFIG_TYPES = ['ASSET', 'LOCATION'];
export function propagateGlobalStateByGlobalConfig(code: string) {
  if (PROPAGATE_GLOBAL_CONFIG_TYPES.includes(code)) {
    propagateGlobalState({ type: GlobalStateType.CHANGED_GLOBAL_CONFIG, payload: code });
  }
}

// Portal Header 의 open, hide
const PROPAGATE_PORTAL_HEADER_STATUS_TYPES = ['open', 'hide'];
export function propagateGlobalStateByCollapsePortalHeader(status: 'open' | 'hide') {
  if (PROPAGATE_PORTAL_HEADER_STATUS_TYPES.includes(status)) {
    propagateGlobalState({ type: GlobalStateType.CHANGED_PORTAL_HEADER, payload: status });
  }
}

/**
 * Use None UI in Micro App
 */
const changedGlobalStateSubject = new BehaviorSubject<GlobalStatePayload | null>(null);
const globalStateBridge = {
  sendPayload: (payload: GlobalStatePayload) => changedGlobalStateSubject.next(payload),
  getPayload: () => changedGlobalStateSubject.asObservable(),
};

/**
 * [2]
 * Receive payload from portal to others micro app and portal
 * 변경을 정취하고 하는 곳에서 hook 사용
 */
export function useListenChangedGlobalState() {
  const [receivedChangedGlobalState, setChangedGlobalState] = useAtom(AppTabState.shareGlobalState);

  useMount(() => {
    if ((window as any).GV_STATE_global_listenState) {
      return;
    }
    (window as any).GV_STATE_global_listenState = true;

    const subscription = globalStateBridge
      .getPayload()
      .pipe(
        debounceTime(200)
      )
      .subscribe((changedStatePayload: GlobalStatePayload | null) => {
        if (changedStatePayload) {
          console.log('>>> portal [receive changed state]', changedStatePayload);
          setChangedGlobalState(changedStatePayload);
        }
      });
    return () => subscription.unsubscribe();
  });

  return receivedChangedGlobalState;
}

/**
 * [3]
 * CUD 변경사항을 Modal로 보여줘야하는 곳에서 사용한다.
 * Refresher 컴포넌트와 함께 사용한다.
 */
export function getGlobalShareState() {
  if (!(window as any).GV_STATE_global_shareState) {
    (window as any).GV_STATE_global_shareState = {};
  }
  return (window as any).GV_STATE_global_shareState;
}

export function useListenChangedGlobalStateByTabKey(tabKey: string) {
  const [revcievedChangedGlobalState, setChangedGlobalState] = useShareStatePageAction(tabKey);

  useMount(() => {
    if ((window as any).GV_STATE_global_listenTabKeyState) {
      return;
    }
    (window as any).GV_STATE_global_listenTabKeyState = true;

    if (getGlobalShareState()[tabKey]) {
      delete getGlobalShareState()[tabKey];
    }
    const subject = new Subject<GlobalStatePayload | null>();
    getGlobalShareState()[tabKey] = subject;

    const subscription = subject
      .asObservable()
      .pipe(
        debounceTime(200)
      )
      .subscribe((changedStatePayload: GlobalStatePayload | null) => {
        if (changedStatePayload) {
          console.log('>>> tabkey [receive changed state]', changedStatePayload);
          setChangedGlobalState(changedStatePayload);
        }
      });
    return () => subscription.unsubscribe();
  });

  useUnmount(() => {
    if (getGlobalShareState()[tabKey]) {
      delete getGlobalShareState()[tabKey];
    }
  });

  return revcievedChangedGlobalState;
}

/**
 * Only for iframe-host.listener.ts & iframe-child.listener.ts
 * Use None UI in Micro App
 */
export function sendChangedGlobalState(changedStatePayload: GlobalStatePayload) {
  // 자동으로 업데이트 되는 global state
  globalStateBridge.sendPayload(changedStatePayload);

  // 현재 open된 화면에만 전파되는 state -> Modal popup에만 사용한다.
  if (getGlobalShareState() && !isEmpty(getGlobalShareState())) {
    forOwn(getGlobalShareState(), (subject: any, tabKey: string) => {
      console.log('>>> [share state by tabKey]:', tabKey, subject);
      subject?.next(changedStatePayload);
    });
  }
}
