/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { groupBy, map } from 'lodash';
import i18next from 'i18next';

import { KPI_ROLE, ROLE, STATUS_ROLE } from '../../model';
import { queryOptions } from '../../utilities/query-util';
import { commonStatus } from '../statuses/common-status.service';
import { commonType } from '../common-types.service';
import { gvPODOApiPrefix } from '../../config/config';
import { httpService } from '../../ajax/http.service';

export const COMMON_STATUS_LIST_QUERY = 'common-status-list';

export function useCommonStatusDomain() {
  // language 변경시 refetch 수행
  // useEffect(() => {
  //   if (i18next.language) {
  //     refetch();
  //   }
  // }, [i18next.language]);

  const { refetch, data, isError, isLoading, isSuccess } = useQuery(
    [COMMON_STATUS_LIST_QUERY],
    () => getAllStatus('listView'),
    {
      ...queryOptions(),
    }
  );

  useEffect(() => {
    if (data) {
      commonStatus.set(data);
    }
  }, [data]);

  return {
    data,
    isSuccess,
    isError,
    isLoading,
  };
}

async function getAllStatus(projection = 'default') {
  const url = `${gvPODOApiPrefix()}/statuses?projection=${projection}`;
  const result = await httpService.get<any>(url);
  const mergeResult = [...result, ...getFrontStatus()];
  return groupBy(mergeResult, 'role');
}

/**
 * For KPI Statuses
 */
export function useKpiStatusDomain() {
  const { data, isError, isLoading } = useQuery(
    ['kpi-status'],
    async () => {
      const kpis = commonType.get(ROLE.KPI);
      const status = await getStatusByRole(map(kpis, 'code'), 'listView');

      return kpis.map((kpi: any) => {
        return {
          ...kpi,
          translatedName: i18next.t(`KPI.${kpi.code} Status Description`),
          translatedShortName: i18next.t(`KPI.${kpi.code} Status Name`),
          status: status[kpi.code],
        };
      });
    },
    {
      ...queryOptions(),
    }
  );
  return { kpis: data, isError: true, isLoading: true };
}

async function getStatusByRole(roles: STATUS_ROLE[] | KPI_ROLE[], projection = 'default') {
  const url = `${gvPODOApiPrefix()}/statuses?projection=${projection}&roles=${roles.join(',')}`;
  const result = await httpService.get<any>(url);
  return groupBy(result, 'role');
}

function getFrontStatus() {
  return [
    // work order progress
    {
      id: 'type_work_status_01',
      code: 'OPEN',
      color: '#e60099',
      iconType: 'OPEN',
      i18n: {
        en: 'Open',
        ko: '발생',
        ja: 'Open',
        cn: 'Open',
      },
      role: 'WORK_STATUS',
      sequence: 0,
    },
    {
      id: 'type_work_status_02',
      code: 'IN_PROGRESS',
      color: '#1d7cd6',
      iconType: 'IN_PROGRESS',
      i18n: {
        en: 'In Progress',
        ko: '조치중',
        ja: 'In Progress',
        cn: 'In Progress',
      },
      role: 'WORK_STATUS',
      sequence: 1,
    },
    {
      id: 'type_work_status_03',
      code: 'COMPLETE',
      color: '#0C9200',
      iconType: 'COMPLETE',
      i18n: {
        en: 'Complete',
        ko: '종료',
        ja: 'Complete',
        cn: 'Complete',
      },
      role: 'WORK_STATUS',
      sequence: 2,
    },
    // alarm group status
    {
      id: 'type_alarm_action_01',
      code: 'OPEN',
      color: '#e60099',
      iconType: 'OPEN',
      i18n: {
        en: 'Open',
        ko: '발생',
        ja: 'Open',
        cn: 'Open',
      },
      role: 'ALARM_ACTION',
      sequence: 0,
    },
    {
      id: 'type_alarm_action_02',
      code: 'IN_PROGRESS',
      color: '#1d7cd6',
      iconType: 'IN_PROGRESS',
      i18n: {
        en: 'In Progress',
        ko: '조치중',
        ja: 'In Progress',
        cn: 'In Progress',
      },
      role: 'ALARM_ACTION',
      sequence: 1,
    },
    {
      id: 'type_alarm_action_03',
      code: 'CLOSE',
      color: '#61687e',
      iconType: 'CLOSE',
      i18n: {
        en: 'Close',
        ko: '완료',
        ja: 'Close',
        cn: 'Close',
      },
      role: 'ALARM_ACTION',
      sequence: 2,
    },
    // alarm group status close options
    {
      id: 'type_alarm_action_close_01',
      code: 'TRUE',
      color: '#61687e',
      iconType: 'TRUE',
      i18n: {
        en: 'True',
        ko: '진성',
        ja: 'True',
        cn: 'True',
      },
      role: 'ALARM_ACTION_ClOSE',
      sequence: 0,
    },
    {
      id: 'type_alarm_action_close_02',
      code: 'FALSE',
      color: '#61687e',
      iconType: 'FALSE',
      i18n: {
        en: 'False',
        ko: '가성',
        ja: 'False',
        cn: 'False',
      },
      role: 'ALARM_ACTION_ClOSE',
      sequence: 1,
    },
    {
      id: 'type_alarm_action_close_03',
      code: 'HOLD',
      color: '#61687e',
      iconType: 'HOLD',
      i18n: {
        en: 'Hold',
        ko: '보류',
        ja: 'Hold',
        cn: 'Hold',
      },
      role: 'ALARM_ACTION_ClOSE',
      sequence: 2,
    },
    // work action
    {
      id: 'type_work_action_01',
      code: 'CHECK',
      color: '#0C4A87',
      iconType: 'CHECK',
      i18n: {
        en: 'Check',
        ko: '점검',
        ja: 'Check',
        cn: 'Check',
      },
      role: 'WORK_ACTION',
      sequence: 0,
    },
    {
      id: 'type_work_action_02',
      code: 'REPAIR',
      color: '#1E83C1',
      iconType: 'REPAIR',
      i18n: {
        en: 'Repair',
        ko: '수리',
        ja: 'Repair',
        cn: 'Repair',
      },
      role: 'WORK_ACTION',
      sequence: 1,
    },
    {
      id: 'type_work_action_03',
      code: 'CHANGE',
      color: '#5EA7DA',
      iconType: 'CHANGE',
      i18n: {
        en: 'Change',
        ko: '교체',
        ja: 'Change',
        cn: 'Change',
      },
      role: 'WORK_ACTION',
      sequence: 2,
    },
    // work type
    {
      id: 'type_work_type_01',
      code: 'BM',
      color: '#0C4A87',
      iconType: 'BM',
      i18n: {
        en: 'Breakdown Maintenance',
        ko: '고장장비',
        ja: '',
        cn: '',
      },
      role: 'WORK_TYPE',
      sequence: 0,
    },
    {
      id: 'type_work_type_02',
      code: 'PM',
      color: '#1E83C1',
      iconType: 'PM',
      i18n: {
        en: 'Preventive Maintenance',
        ko: '예지장비',
        ja: '',
        cn: '',
      },
      role: 'WORK_TYPE',
      sequence: 1,
    },
    {
      id: 'type_work_type_03',
      code: 'OTHER',
      color: '#5EA7DA',
      iconType: 'OTHER',
      i18n: {
        en: 'Other',
        ko: '기타',
        ja: '',
        cn: '',
      },
      role: 'WORK_TYPE',
      sequence: 2,
    },
    // spec leve
    {
      role: 'SPEC_LEVEL',
      id: 'type_spec_level_01',
      deletable: true,
      predefine: true,
      code: 'LOW',
      sequence: 0,
      i18n: {
        en: 'Low',
        ko: '낮음',
        ja: '',
        cn: '',
      },
    },
    {
      role: 'SPEC_LEVEL',
      id: 'type_spec_level_02',
      deletable: true,
      predefine: true,
      code: 'MEDIUM',
      sequence: 1,
      i18n: {
        en: 'Medium',
        ko: '중간',
        ja: '',
        cn: '',
      },
    },
    {
      role: 'SPEC_LEVEL',
      id: 'type_spec_level_03',
      deletable: true,
      predefine: true,
      code: 'HIGH',
      sequence: 2,
      i18n: {
        en: 'High',
        ko: '높음',
        ja: '',
        cn: '',
      },
    },
    {
      role: 'SPEC_LEVEL',
      id: 'type_spec_level_04',
      deletable: true,
      predefine: true,
      code: 'CUSTOMIZE',
      sequence: 3,
      i18n: {
        en: 'Customize',
        ko: '사용자 정의',
        ja: '',
        cn: '',
      },
    },
  ];
}
