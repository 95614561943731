import { themeType } from '../types';

export enum ReportLayoutType {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
}

export enum ReportSizeType {
  LETTER = 'LETTER',
  A4 = 'A4',
}

export enum ReportBackgroundType {
  EMPTY = 'EMPTY',
  CONFIG = 'CONFIG',
}

export enum ReportScheduleDataIntervalType {
  ALL_DAY = 'ALL_DAY',
  CUSTOM = 'CUSTOM',
}

export enum ReportStatusType {
  RUN = 'RUN',
  STOP = 'STOP',
}

export enum ReportDayType {
  SUN = 'SUN',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT'
}

export enum ReportScheduleType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum ReportPageGenerateType {
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
}

export enum ReportPageTemplateType {
  ASSET_DEFAULT = 'asset:default',
  ASSET_CUSTOM = 'asset:custom',
  LOCATION_DEFAULT = 'location:default',
  LOCATION_CUSTOM = 'location:custom',
  STATIC_PAGE = 'static:page'
}

export interface IReport {
  id?: string;
  key?: string;
  name?: string;
  publish?: boolean;
  description?: string;
  pageSize?: number;
  modifiedTime?: Date;
  modifier?: string;
  config?: IReportConfig;
  pages?: IReportPage[];
}

export interface IReportConfig {
  schedule?: {
    status?: 'RUN' | 'STOP' | string;
    timezone?: string;
    dataInterval?: {
      type: ReportScheduleDataIntervalType;
      from?: number; // 3
      toHour?: number; // 9     // hours, next day '06:00'
    };
    autoGenerate?: {
      type: ReportScheduleType;
      start: any[]; //['21:00'],
      days: string[]; //['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
    };
    period?: {
      start: string; //'2023-12-28 09:00',
      end?: string | undefined | null; //'2024-12-30 23:59' // 또는 end: ''
    }
    cronExpression?: string; // not used yet
  };
  shareUsers?: string[] | undefined;
  email?: {
    status?: 'RUN' | 'STOP' | string;
    users?: string[];
  };
  targetData?: {
    type?: string;
    filter?: string[] | undefined;
  };
  layout?: ReportLayoutType;
  size?: ReportSizeType;
  background?: ReportBackgroundType;
}

export interface IReportInitParams {
  assetId?: string; // assetId or locationId
  locationId?: string;
  start: string;
  end: string;
  [restProp: string]: any;
}

export interface IReportPage {
  pageId: string;
  name: string;
  sequence?: number;
  generatingType?: ReportPageGenerateType;
  template?: ReportPageTemplateType;
  filter?: {
    locations?: string[];
    assets?: string[];
  };
  sample?: {
    locationId?: string;
    assetId?: string;
  };
  reportlets?: IReportletPersistence[] | undefined; // reportlets config
  // when launch print app
  initParams?: IReportInitParams;
}

export interface IReportletMasterGroup {
  id: string;
  name: string;
  reportlets: IReportletMaster[];
}

export interface IReportletMaster {
  id: string;
  name: string;
  description: string | null;
  path: string;
  pluginName?: string;
  pluginPath?: string;
  imagePath?: string;
  config: IReportletMasterConfig;
  type?: {
    id: string;
    name: string;
  };
}

export interface IReportletMasterConfig {
  w: number;
  h: number;
  x?: number;
  y?: number;
  [restProp: string]: any;
}

/**
 * DB Save Model
 * 최종 DB에 저장되는 모델
 */
export interface IReportletPersistence {
  id?: string | null;
  name: string;
  config: IReportletPersistenceConfig;
  master: IReportletMaster | { id: string, path?: string, pluginName?: string };
  role?: 'INSTANCE';
}

export interface IReportletPersistenceConfig {
  w: number;
  h: number;
  x: number;
  y: number;
  [restProp: string]: any;
}

/**
 * Instance Model
 * 인스턴스성 모델
 */
export enum GV_BUILDER_REPORTLET_STATUS {
  SAVED = 'SAVED',
  NEW = 'NEW',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface IReportletInstance {
  reportletPersistence: IReportletPersistence | any;
  createReportletPath: string;
  pluginName?: string;
  // 'save', 'new', 'delete'
  reportletStatus: string;
  compareInstances?: IReportletInstance[];
  config?: any;
  master?: any; //IReportMaster.config (data, style)
  // instance model
  i: string;
  name: string;
  x: number;
  y: number;
  w: number;
  h: number;
  zIndex: number;
  sequence?: number;
  minW?: number;
  minH?: number;
}

export interface IReportletLayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface IReportPageTemplateProps {
  tabKey: string;
  preview: boolean;
  isEditorMode?: boolean;
  isPrintMode?: boolean;
  page: IReportPage;
  pageSizeLayout: string;
  pageBackground?: ReportBackgroundType;
  theme?: themeType;
  key?: string;
  initParams?: IReportInitParams;
  onPrintViewInStudio?: any; // studio에서 print view 일 때
  [restProp: string]: any;
}

export interface IReportletStyleGroup {
  id: string;
  value: any;
  name?: string;
  transferStyle?: (value: any) => any;
}

export interface IReportletDataGroup {
  id: string;
  value?: any;
  name?: string;
  transferData?: (value: any) => any;
}
