import { createContext, useContext } from 'react';
import { PageContext, themeType } from '../../../model';

export function getPageContext() {
  if (!(window as any).GV_STATE_pageContext) {
    (window as any).GV_STATE_pageContext = {};
  }
  return (window as any).GV_STATE_pageContext;
}

export function createPageContext<T>(tabKey: string, contextKey: string, defaultValue: T | null = null) {
  const createdContext = createContext<T | null>(defaultValue);

  if (!getPageContext()[tabKey]) {
    getPageContext()[tabKey] = { [contextKey]: createdContext };
  }

  if (!getPageContext()[tabKey][contextKey]) {
    getPageContext()[tabKey][contextKey] = createdContext;
  }
  return getPageContext()[tabKey][contextKey];
}

export function usePageContext(tabKey: string, contextKey: string): any {
  return useContext(getPageContext()[tabKey] ? getPageContext()[tabKey][contextKey] : 'no-tabkey');
}

export function usePageTheme(tabKey: string): themeType {
  return useContext(getPageContext()[tabKey] ? getPageContext()[tabKey][PageContext.THEME] : 'no-tabkey');
}
