/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { gvPODOApiPrefix } from '../../config/config';
import { httpService } from '../../ajax/http.service';
import { twentyFourHoursInMs } from '../../utilities/utils';

/**
 * GET Image Info List
 * domain
 * LAYOUT
 */
export function useGetImagesByDomain(domain: string): any {
  const { data, isError, isLoading }: any = useQuery(['get-images-domain', domain], () => getImages(domain), {
    staleTime: 0,
  });
  return { images: data, isError, isLoading };
}

async function getImages(domain: string) {
  const url = `${gvPODOApiPrefix()}/images?domain=${domain}`;
  return httpService.get<any>(url);
}

/**
 * GET Single Image File content
 * When using Image Tag
 */
export function useImageByIdDomain(imageId: string, ref: any = null): any {
  const prevData = useRef<any>(null);
  const preImageId = useRef<any>(null);

  const { data, isError, isLoading }: any = useQuery(['image-by-id', imageId], () => getImageById(imageId), {
    enabled: !!imageId && !!ref,
    staleTime: twentyFourHoursInMs,
  });

  // TODO: GVImageCarousel 리펙토링 필요
  // GVImageCarousel 에서 이미지 추가시 추가 하려는 이미지에 이전 이미지가 보이는 버그 (https://dev.azure.com/Aidentyx/GVI/_workitems/edit/3724/)
  if (preImageId.current && preImageId.current !== imageId) {
    prevData.current = null;
    if (ref?.current?.src) {
      ref.current.src = null;
    }
  }

  if (data) {
    prevData.current = data;
    preImageId.current = imageId;
  }

  if (isError) {
    prevData.current = null;
  }

  useEffect(() => {
    if (prevData?.current && ref?.current) {
      const reader = new FileReader();
      reader.readAsDataURL(prevData.current);
      reader.onload = () => (ref.current.src = reader.result);
    }
  }, [prevData.current, ref]);

  return { data: prevData.current, isError, isLoading };
}

export function useImageRawByIdDomain(imageId: string, staleTime = 0): any {
  const { data, isError, isLoading }: any = useQuery(['image-by-id', imageId], () => getImageById(imageId), {
    enabled: !!imageId,
    staleTime,
  });
  return { data, isError, isLoading };
}

/**
 * ref: apm-frontend/apps/metatron-apm/src/app/core/services/image.service.ts
 * ref: apm-frontend/apps/metatron-apm/src/app/shared/components/image-box/image-box.component.ts
 * @param imageId
 * @returns
 */
export async function getImageById(imageId: string) {
  const url = `${gvPODOApiPrefix()}/images/load/id?id=${imageId}`;
  return httpService.get<any>(url, null, { responseType: 'blob' });
}

export async function getImageByIdAndSetImageStream(imageId: string, setImageStream: (image: any) => void) {
  const url = `${gvPODOApiPrefix()}/images/load/id?id=${imageId}`;
  const imageObject = await httpService.get<any>(url, null, { responseType: 'blob' });
  setImageStream(imageObject);
}

/**
 * DELETE Single Image File
 */
export function useDeleteImageMutation(): any {
  const mutation = useMutation((imageId: string) => {
    return deleteImageById(imageId);
  });

  return {
    deleteImage: (imageId: string) => mutation.mutate(imageId),
    isDeleteSuccess: mutation.isSuccess,
    deletedImageId: mutation.variables,
  };
}

async function deleteImageById(imageId: string) {
  const url = `${gvPODOApiPrefix()}/images/${imageId}`;
  return httpService.delete(url);
}
