export const DEFAULT_ANIMATION = 'none';
export enum AnimationType {
  PULSE = 'pulse',
  TADA = 'tada',
  ZOOMIN = 'zoomIn',
  NONE = 'none',
}

export interface IAnimationOption {
  repeat?: number;
  duration?: number;
}