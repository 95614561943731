import { useRef, useEffect } from 'react';
import { useAtom, atom } from 'jotai';
import {
  ChangedFilterState,
  ITableActionState,
  RealtimeFilterState,
  RealtimeFilterStateOnlyAfterChanged,
  useTableActionState,
} from '../../state';
import { TableActionState } from '../../model';

const EMPTY = atom(null);

export function useRealtimeFilter(
  tabKey: string,
  forcelyUnrealtime = false,
  forcelyAllPeriod = false,
  enabled = true
): any {
  const [realtimeFilter] = useAtom(enabled ? RealtimeFilterState(tabKey, forcelyUnrealtime, forcelyAllPeriod) : EMPTY);
  return realtimeFilter;
}

export function useRealtimeFilterOnlyAfterChanged(
  tabKey: string,
  forcelyUnrealtime = false,
  forcelyAllPeriod = false,
  initCustomPeriod: { selectedDateType: string; start: string; end: string } | null = null,
  enabled = true
): any {
  const [realtimeFilter] = useAtom(
    enabled ? RealtimeFilterStateOnlyAfterChanged(tabKey, forcelyUnrealtime, forcelyAllPeriod, initCustomPeriod) : EMPTY
  );
  return realtimeFilter;
}

export function useChangedRealtimeFilterCondition(tabKey: string): any {
  const [isChangedFilter] = useAtom(ChangedFilterState(tabKey));
  return isChangedFilter;
}

export function useTableActionStateWithRealtimeFilter(
  tabKey: string,
  componentKey: string,
  initValue?: TableActionState,
  isUnmount = false,
  realtimeFilterOptions?: {
    afterChanged?: boolean;
    forcelyUnrealtime?: boolean;
    forcelyAllPeriod?: boolean;
    initCustomPeriod?: { selectedDateType: string; start: string; end: string } | null;
  }
): ITableActionState {
  const realtimeAfterChanged =
    realtimeFilterOptions?.afterChanged === undefined ? true : realtimeFilterOptions?.afterChanged;
  const refPervIsRealTime = useRef<any>(null);
  const tableActions = useTableActionState<TableActionState>(tabKey, componentKey, initValue, isUnmount);
  const realtimeFilterAfterChangedActions = useRealtimeFilterOnlyAfterChanged(
    tabKey,
    false,
    false,
    null,
    realtimeAfterChanged
  );
  const realtimeFilterActions: any = useRealtimeFilter(tabKey, false, false, !realtimeAfterChanged);

  const { interval, aggregation, isRealtime, timeCycle, refreshKey, healthStatus } = realtimeAfterChanged
    ? realtimeFilterAfterChangedActions
    : realtimeFilterActions;

  useEffect(() => {
    if (isRealtime || (!realtimeAfterChanged && refPervIsRealTime.current !== isRealtime)) {
      tableActions.setTableActionState({
        externalFilters: {
          ...(tableActions.tableActionState?.externalFilters ?? {}),
          interval,
          aggregation,
          isRealtime,
          timeCycle,
          healthStatus,
        },
      });
    } else {
      tableActions.setExternalFilters({ interval, healthStatus });
    }
    refPervIsRealTime.current = isRealtime;
    // realtime/period, interval, heathStatus(health index, health trend)
  }, [interval, isRealtime, healthStatus]);

  useEffect(() => {
    tableActions.refetch();
  }, [refreshKey]);

  return tableActions;
}
