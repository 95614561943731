import { ActionType } from '@grandview/shared';

import { PageState, usePageState } from '../web-page.state';
import { getRandomId } from '../../../utilities/random-generator';
import { sendDataToSubscriber, useSubscribeData } from '../../../hooks/tabkey/common-one-way-data.hook';

export interface Dashboard {
  id: string;
  name: string;
  description: string;
  publish: boolean;
  sequence: number;
  createdTime: Date;
  creator: string;
  modifiedTime: Date;
  modifier: string;
  widgetCount: number;
}

export interface DashboardAction extends PageState {
  action: ActionType;
  dashboard?: Dashboard;
}

/**
 * Builder State
 */
// export const DASHBOARD_MANAGEMENT_ACTION = 'dashboard-management-action';
// export const DASHBOARD_MANAGEMENT_BUILDER_ACTION = 'dashboard-builder-more-action';
// export function useDashboardAction(tabKey: string, actionName?: string) {
//   return usePageState<DashboardAction>(tabKey, actionName || DASHBOARD_MANAGEMENT_ACTION, null, true);
// }
export function sendDashboardBuilderAction(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'dashboard-builder-action', value);
}
export function useSubscribeDashboardBuilderAction(tabKey: string) {
  return useSubscribeData(tabKey, 'dashboard-builder-action', null, false);
}

export function sendDashboardBuilderMoreAction(tabKey: string, value: any) {
  sendDataToSubscriber(tabKey, 'dashboard-builder-more-action', value);
}
export function useSubscribeDashboardBuilderMoreAction(tabKey: string) {
  return useSubscribeData(tabKey, 'dashboard-builder-more-action', null, false);
}

/**
 * Builder State
 */
export function useAddWidget(tabKey: string, isDestroy = false) {
  return usePageState(tabKey, 'dashboard-builder-add-widget', null, isDestroy);
}

export function useDirtyWidgets(tabKey: string) {
  return usePageState(tabKey, 'dashboard-builder-dirty-widgets', null, true);
}

export function useWidgetPropertiesAction(tabKey: string) {
  return usePageState(tabKey, 'widget-properties-action', null, true);
}

/**
 * Widget Properties Modal to Dashboard Template
 */
export function sendDirtyWidgetOnlyTitleProperty(tabKey: string, instanceId: string | null | undefined, title: string) {
  sendDataToSubscriber(tabKey, 'dashboard-builder-dirty-widget-properties', {
    instanceId,
    title,
  });
}

export function sendDirtyWidgetProperties(tabKey: string, instanceId: string | null | undefined, config: any) {
  sendDataToSubscriber(tabKey, 'dashboard-builder-dirty-widget-properties', {
    instanceId,
    config,
  });
}

export function useDirtyWidgetProperties(tabKey: string) {
  return useSubscribeData(tabKey, 'dashboard-builder-dirty-widget-properties', null, true);
}

/**
 * Widget Properties Modal to Dashboard Template
 */
export function sendAutoSaveWidgetProperties(tabKey: string) {
  sendDataToSubscriber(tabKey, 'dashboard-builder-auto-save-widget-properties', { run: getRandomId() });
}

export function useAutoSaveWidgetProperties(tabKey: string) {
  return useSubscribeData(tabKey, 'dashboard-builder-auto-save-widget-properties', null, true);
}
