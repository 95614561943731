// source: https://github.com/wangel13/dynamic-micro-frontends-with-Nx-and-react/blob/main/libs/load-remote-module/src/lib/load-remote-module.ts
// doc: https://medium.com/@wangel13/dynamic-micro-frontends-with-nx-and-react-d04a350732a4

import { getPluginCacheKey } from '../config/config';
import { isNullOrUndefined } from '../utilities/utils';

const PLUGIN_ENTRY_FILE = 'remoteEntry.js';

export type ResolveRemoteUrlFunction = (remoteName: string) => string | Promise<string>;

declare const __webpack_init_sharing__: (scope: 'default') => Promise<void>;
declare const __webpack_share_scopes__: { default: unknown };

// let resolveRemoteUrl: ResolveRemoteUrlFunction;
function getResolveRemoteUrl() {
  if (!(window as any).GV_PLUGIN_NX_resovleRemoteUrl) {
    (window as any).GV_PLUGIN_NX_resovleRemoteUrl = new Map();
  }
  return (window as any).GV_PLUGIN_NX_resovleRemoteUrl;
}

export function setDynamicRemoteUrlResolver(_resolveRemoteUrl: ResolveRemoteUrlFunction) {
  getResolveRemoteUrl()(window as any).GV_PLUGIN_NX_resovleRemoteUrl = _resolveRemoteUrl;
}

// let remoteUrlDefinitions: Record<string, string>;
function getRemoteUrlDefinitions() {
  return (window as any).GV_PLUGIN_NX_remoteUrlDefinitions;
}

export function getRemoteUrlDefinition(pluginName: string) {
  if (!pluginName || !(window as any).GV_PLUGIN_NX_remoteUrlDefinitions) {
    return;
  }
  return (window as any).GV_PLUGIN_NX_remoteUrlDefinitions[pluginName];
}

export function setDynamicRemoteDefinitions(definitions: Record<string, string>) {
  (window as any).GV_PLUGIN_NX_remoteUrlDefinitions = definitions;
}

// set example
// setRemoteDefinitions({ 'apm-default-widgets': 'http://localhost:9000' });
export function getRemoteDefinitions() {
  return (window as any).GV_PLUGIN_NX_remoteUrlDefinitions;
}

// const remoteModuleMap = new Map<string, unknown>();
function getRemoteModuleMap() {
  if (!(window as any).GV_PLUGIN_NX_remoteModuleMap) {
    (window as any).GV_PLUGIN_NX_remoteModuleMap = new Map<string, unknown>();
  }
  return (window as any).GV_PLUGIN_NX_remoteModuleMap;
}
// const remoteContainerMap = new Map<string, unknown>();
function getRemoteContainerMap() {
  if (!(window as any).GV_PLUGIN_NX_remoteContainerMap) {
    (window as any).GV_PLUGIN_NX_remoteContainerMap = new Map<string, unknown>();
  }
  return (window as any).GV_PLUGIN_NX_remoteContainerMap;
}

export async function loadDynamicRemoteModule(remoteName: string, moduleName: string, fileName = PLUGIN_ENTRY_FILE) {
  const remoteModuleKey = `${remoteName}:${moduleName}`;
  if (getRemoteModuleMap().has(remoteModuleKey)) {
    return getRemoteModuleMap().get(remoteModuleKey);
  }

  const container = getRemoteContainerMap().has(remoteName)
    ? getRemoteContainerMap().get(remoteName)
    : await loadRemoteContainer(remoteName, fileName);

  const factory = await container.get(moduleName);
  const Module = factory();

  getRemoteModuleMap().set(remoteModuleKey, Module);

  return Module;
}

function loadModule(url: string) {
  return import(/* webpackIgnore:true */ url);
}

// let initialSharingScopeCreated = false;
function getInitialSharingScopeCreated() {
  if (isNullOrUndefined((window as any).GV_PLUGIN_NX_initialSharingScopeCreated)) {
    (window as any).GV_PLUGIN_NX_initialSharingScopeCreated = false;
  }
  return (window as any).GV_PLUGIN_NX_initialSharingScopeCreated;
}

async function loadRemoteContainer(remoteName: string, remoteFileName: string) {
  if (!getResolveRemoteUrl() && !getRemoteUrlDefinitions()) {
    throw new Error(
      'Call setRemoteDefinitions or setRemoteUrlResolver to allow Dynamic Federation to find the remote apps correctly.'
    );
  }

  if (!getInitialSharingScopeCreated()) {
    (window as any).GV_PLUGIN_NX_initialSharingScopeCreated = true;
    await __webpack_init_sharing__('default');
  }

  const remoteUrl = getRemoteUrlDefinitions()
    ? getRemoteUrlDefinitions()[remoteName]
    : await getResolveRemoteUrl()(remoteName);

  const containerUrl = `${remoteUrl}${remoteUrl.endsWith('/') ? '' : '/'}${getPluginEntryFile(remoteFileName)}`;

  const container = await loadModule(containerUrl);
  await container.init(__webpack_share_scopes__.default);

  getRemoteContainerMap().set(remoteName, container);
  return container;
}

// plugin cache key
export function getPluginEntryFile(remoteFileName: string) {
  return getPluginCacheKey() ? `${remoteFileName}?cacheId=${getPluginCacheKey()}` : remoteFileName;
}
