import { message as antdMessage } from 'antd';
import { isString } from 'lodash';

export function successMessage(message: any, marginTop: string | number = '0px', duration = 5, className = '') {
  return antdMessage.success({
    content: message,
    className: className || 'gv_message_success',
    duration,
    style: {
      marginTop: isString(marginTop) ? marginTop : `${marginTop}px`,
    },
  });
}

export function errorMessage(message: any, marginTop: string | number = '0px', duration = 5, className = '') {
  return antdMessage.error({
    content: message,
    className: className || 'gv_message_error',
    duration,
    style: {
      marginTop: isString(marginTop) ? marginTop : `${marginTop}px`,
    },
  });
}

export function warningMessage(message: any, marginTop: string | number = '0px', duration = 5, className = '') {
  return antdMessage.warning({
    content: message,
    className: className || 'gv_message_warning',
    duration,
    style: {
      marginTop: isString(marginTop) ? marginTop : `${marginTop}px`,
    },
  });
}

export function infoMessage(message: any, marginTop: string | number = '0px', duration = 5, className = '') {
  return antdMessage.info({
    content: message,
    className: className || 'gv_message_info',
    duration,
    style: {
      marginTop: isString(marginTop) ? marginTop : `${marginTop}px`,
    },
  });
}
