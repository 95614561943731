import { t } from 'i18next';

export function hasText(originText: string | null | undefined, searchText: string | null) {
  const text = searchText ? searchText.toLowerCase() : '';
  return t(originText || '')
    .toLowerCase()
    .includes(text);
}

export function firstUpperCase(str: string) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return '';
}
