export const $gv_chart_theme_color = {
  theme1: '#2F7EB8',
  theme2: '#4355F1',
  theme3: '#92A8CD',
  theme4: '#2B908F',
  theme5: '#89A54E',
  theme6: '#F2CB61',
  theme7: '#AA4643',
  theme8: '#492970',
  theme9: '#71669E',
  theme10: '#8085E9',
};

export const $gv_chart_category_colors = [
  '#3957F7',
  '#15D4EC',
  '#7F47E8',
  '#2BA2FF',
  '#AF32CE',
  '#00C4AC',
  '#2C47DC',
  '#0BBAD0',
  '#9625AA',
  '#2092EB',
  '#6936C8',
  '#009B8B',
];

export const $gv_chart_maker_line_color = '#F5222D';
export const $gv_chart_target_maker_line_color = '#f11444';
export const $gv_chart_parameter_threshold_color = '#ff364e';

export const $gv_chart_rul_trend_color = '#23439c';
export const $gv_chart_rul_threshold_color = '#FF2C36';
export const $gv_chart_rul_maker_line_color = '#a2a9c1';

export const $gv_chart_thumb_color = '#2a98ac';

export const $gv_chart_grid_border_color = '#838695';
export const $gv_chart_axis_label_color = '#838695';

// line series
export const $gv_chart_line_series_mark_area_item_opacity = 0.15;
export const $gv_chart_line_series_color = '#0492e4';
export const $gv_chart_line_scatter_series_color = '#ffffff';
export const $gv_chart_line_scatter_series_border_width = 1.2;
export const $gv_chart_line_series_mark_area_item_color = '#FF2C36';

// scatter series
export const $gv_chart_scatter_series_color = '#2abcef';
export const $gv_chart_large_scatter_series_color = '#0492e4';

// bar series
export const $gv_chart_bar_series_color = '#5e6ec3';

// parameter chart
export const $gv_chart_parameter_line_series_color = '#595959'; //'#817D7D'; //'#0492e4';

// health index chart
export const $gv_chart_health_index_line_series_color = '#5441e0'; //'#0492e4';
export const $gv_chart_health_index_scatter_series_color = '#ffffff';
export const $gv_chart_health_index_scatter_series_border_width = 1.2;
// health trend
export const $gv_chart_health_trend_series_color = '#142287';

// alarm
export const $gv_chart_alarm_series_color = '#f21344';
export const $gv_chart_alarm_series_border_color = '#f21344';
export const $gv_chart_alarm_series_border_width = 2;

// pie chart
export const $gv_chart_pie_border_color = '#dfdfdf';

// dummy series
export const $gv_chart_dummy_series_color = '#FFF';
export const $gv_chart_dummy_series_border_color = '#D9D9D9';

