/* eslint-disable prefer-const */
import { useEventEmitter } from 'ahooks';
import { useAtom } from 'jotai';
import { find } from 'lodash';

import { getPageTheme } from '../../../config/config';
import { PageMenuState, PageTabState } from '../../../state';
import { getRandomId } from '../../../utilities/random-generator';
import { checkDashboardCodePage } from '../../../services/menu.service';
import { sendOneWaySubject } from '../../../hooks/tabkey/common-one-way-subject';
import { GV_APM_PAGE, GV_MICRO_APP, IGVPageMenu, KOSMOS_DETECTOR_PAGE, OPTIMA_AD_PAGE, PageCreateType, SideMenuType } from '../../../model';
import { sendDrillDownRealtimeFilterState } from '../../../hooks/tabkey/realtime-filter-one-way-subject';

let loadPageEvent: any;
function useLoadPageBridge() {
  const event$: any = useEventEmitter();
  if (!loadPageEvent) {
    loadPageEvent = event$;
  }
  return loadPageEvent;
}

export function loadMicroAppPage(payload: any) {
  console.log('>>> [iframe child: load page] payload:', payload);
  const { page } = payload;
  if (page) {
    const { params } = payload;
    page.params = params;
  } else {
    // warning matched page
  }
  loadPageEvent.emit(payload);
}

export function useLoadMicroAppPage() {
  const [sideMenus] = useAtom(PageMenuState.menus);
  const [, setCreatePage] = useAtom(PageMenuState.createPage);
  const [, setActiveTabKey] = useAtom(PageTabState.activeTabKey);
  const [pages, setPages] = useAtom(PageTabState.pages);
  const event = useLoadPageBridge();
  // console.log('> load micro app', pages);

  event.useSubscription((payload: any) => {
    const { app, page } = payload;
    let { name, params } = page;

    // set first page and pass detail info into first page
    // if there is page, it must be switching page of micro app with params
    const items = name.split('.');
    let menu = sideMenus?.find((sideMenu: any) => sideMenu.key === `${items[0]}.${items[1]}`);
    if (!menu) {
      // dashboard의 code link인지 확인
      menu = checkDashboardCodePage(app, name);
    }

    // console.log('>>> [load page] sideMenus:', sideMenus);
    if (menu) {
      if (!params) {
        params = {};
      }
      if (items.length > 2) {
        params.detailPage = items[2];
      }

      // console.log('>>>> [load page 0]: load micro page', name, params, items);
      if (params['tabId']) {
        doExistTabId(params, items, pages, menu, setActiveTabKey, setCreatePage, setPages);
      } else {
        doLoadNewPage(params, items, pages, menu, setActiveTabKey, setCreatePage, setPages);
      }
    }
  });
}

const doExistTabId = (
  params: any,
  items: any[],
  pages: IGVPageMenu[],
  menu: IGVPageMenu,
  setActiveTabKey: any,
  setCreatePage: any,
  setPages: any
) => {
  // switch tab with history tabKey
  // page without detail or page with detail
  const pageName = params.detailPage ? `${items[0]}.${items[1]}.${items[2]}` : `${items[0]}.${items[1]}`;
  const tabKey = params.detailPage
    ? `${items[0]}.${items[1]}.${items[2]}:${params['tabId']}`
    : `${items[0]}.${items[1]}:${params['tabId']}`;
  const menuKey = params.detailPage ? `${items[0]}.${items[1]}.${items[2]}` : `${items[0]}.${items[1]}`;
  // set pageKey for asset detail
  if (params.detailPage && !params.pageKey) {
    params['pageKey'] = tabKey;
  }

  const page = pages.find((page: IGVPageMenu) => page.key === tabKey);
  if (page) {
    // if tab is alive
    setActiveTabKey(tabKey);
    if (params.detailPage) {
      if (`${items[0]}.${items[1]}` === GV_APM_PAGE.ASSET_DETAIL
        || `${items[0]}.${items[1]}` === KOSMOS_DETECTOR_PAGE.ASSET_DETAIL) {
        sendOneWaySubject(tabKey, 'active-asset-detail', params.detailPage);
        // change tab title to asset name
        menu.title = params.tabTitle || params.assetName || menu.title;
      }
    }
    if (
      `${items[0]}.${items[1]}` === GV_APM_PAGE.ALARM_DETAIL ||
      `${items[0]}.${items[1]}` === GV_APM_PAGE.WORK_ORDER_DETAIL
    ) {
      menu.title = params.tabTitle || params.assetName || menu.title;
    }
    // console.log('>>>> [load page 1-1]: switch page', page, tabKey);
  } else {
    let exitedPage: IGVPageMenu | undefined;
    if (menu.createTabType === PageCreateType.SINGLE) {
      if (menu.sidebarMenuType === SideMenuType.DYNAMIC) {
        exitedPage = find(pages, { key: menu.key });
      } else {
        exitedPage = find(pages, { menuKey });
      }
    }

    if (exitedPage) {
      // update url params
      if (params) {
        setPages(
          pages.map((page: any) => {
            if (page.key === menu.key || page.menuKey === menuKey) {
              page.initParams = { ...page.initParams, ...params };
            }
            return page;
          })
        );
      }
      setActiveTabKey(exitedPage.key);
      sendDrillDownRealtimeFilterState(exitedPage.key, params);
      console.log('>>>> [load page 1-1]: existed page in history', exitedPage);
    } else {
      // if tab was deleted
      delete params['tabId'];
      const newTabKey = `${menu.key}:${getRandomId()}`;
      const createdPage = {
        ...menu,
        key: newTabKey,
        initParams: params,
        menuKey,
        theme: menu.theme ? menu.theme : getPageTheme(),
      };
      setCreatePage(createdPage);
      // if drill down with realtime filter options
      // ex) asset-parameter in dashboard ==> asset-status-information
      sendDrillDownRealtimeFilterState(newTabKey, params);
      console.log('>>>> [load page 1-2]: created page in history', createdPage);
    }
  }
};

const doLoadNewPage = (
  params: any,
  items: any[],
  pages: IGVPageMenu[],
  menu: IGVPageMenu,
  setActiveTabKey: any,
  setCreatePage: any,
  setPages: any
) => {
  const newTabKey = params.detailPage
    ? `${items[0]}.${items[1]}.${items[2]}:${getRandomId()}`
    : `${items[0]}.${items[1]}:${getRandomId()}`;
  const menuKey = params.detailPage ? `${items[0]}.${items[1]}.${items[2]}` : `${items[0]}.${items[1]}`;

  if (
    `${items[0]}.${items[1]}` === GV_APM_PAGE.ASSET_DETAIL ||
    `${items[0]}.${items[1]}` === GV_APM_PAGE.ALARM_DETAIL ||
    `${items[0]}.${items[1]}` === GV_APM_PAGE.WORK_ORDER_DETAIL ||
    `${items[0]}.${items[1]}` === KOSMOS_DETECTOR_PAGE.ASSET_DETAIL
  ) {
    // change tab title to asset name
    menu.title = params.tabTitle || params.assetName || menu.title;
  }

  let exitedPage: IGVPageMenu | undefined;
  if (menu.createTabType === PageCreateType.SINGLE) {
    if (items[0] === GV_MICRO_APP.DASHBOARD) {
      // code is same => dashboard.<dashboardId>
      exitedPage = find(pages, { code: menu.code });
    } else {
      if (menu.sidebarMenuType === SideMenuType.DYNAMIC) {
        exitedPage = find(pages, { key: menu.key });
      } else {
        exitedPage = find(pages, { menuKey });
      }
    }
  }

  if (exitedPage) {
    // update url params
    if (params) {
      setPages(
        pages.map((page: any) => {
          if (items[0] === GV_MICRO_APP.DASHBOARD) {
            // menuKey and menun.name is same => dashboard.<dashboardId>
            if (page.menuKey === menu.name) {
              page.initParams = { ...page.initParams, ...params };
            }
          } else {
            if (page.key === menu.key || page.menuKey === menuKey) {
              page.initParams = { ...page.initParams, ...params };
            }
          }
          return page;
        })
      );
    }
    setActiveTabKey(exitedPage.key);
    sendDrillDownRealtimeFilterState(exitedPage.key, params);
    // console.log('>>>> [load page 2-1]: created page in new', exitedPage, params);
  } else {
    const createdPage = {
      ...menu,
      key: newTabKey,
      initParams: params,
      menuKey,
      theme: menu.theme ? menu.theme : getPageTheme(),
    };
    setCreatePage(createdPage);
    sendDrillDownRealtimeFilterState(newTabKey, params);
    console.log('>>>> [load page 2-2]: created page in new', createdPage);
  }
};
