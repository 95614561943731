import { IReportletMaster, IReportletMasterGroup } from "./report.model";
import { IWidgetMaster, IWidgetMasterGroup } from "./widget.model";

export enum PluginType {
  WIDGET = 'widget',
  REPORTLET = 'reportlet',
  PORTAL = 'portal',
  //TODO: add more in the future...
}

// deprecated
// export const PLUGIN_MASTER_CONFIG_GROUPS = 'plugin-master-config-groups';
// export const PLUGIN_MASTER_CONFIGS = 'plugin-master-configs';

// reportlet data form, style form
export const PLUGIN_CONFIG_DATA_FORMS = 'plugin-config-data-forms';
export const PLUGIN_CONFIG_STYLE_FORMS = 'plugin-config-style-forms';

export const PLUGIN_INIT = 'plugin-init';
export const PLUGIN_INFO = 'plugin-info';
export const PLUGIN_MASTER_CONFIGS_FUNCTION = 'masterConfigs';
export const PLUGIN_MASTER_CONFIG_BY_ID_FUNCTION = 'masterConfigById';
export const PLUGIN_MASTER_CONFIG_GROUPS_FUNCTION = 'masterConfigGroups';

export interface IWidgetPluginConfig {
  pluginName: string;
  masterConfigs: () => IWidgetMaster[];
  masterConfigGroups: () => IWidgetMasterGroup[];
}

export type REPORTLET_BASE_TYPE = 'asset-base' | 'location-base' | 'static-base';
export interface IReportletPluginConfig {
  pluginName: string;
  masterConfigById: (id: string) => IReportletMaster;
  masterConfigGroups: (baseType: REPORTLET_BASE_TYPE) => IReportletMasterGroup[];
}

export interface IPlugin {
  id?: string;
  application: {
    id: string;
    contextPath: string;
  };
  physicalName: string;
  type: {
    code: PluginType;
  };
  title: string;
  description?: string;
  activating: boolean;
  creator?: string;
  createdTime?: string;
  modifier?: string;
  modifiedTime?: string;
  histories?: IPluginHistory[];
}

export interface IPluginHistory {
  modifier: string;
  modifiedTime: string;
  changes: string[]; // title, activating, description, file
}
