export interface User {
  id?: string;
  username: string;
  password?: string;
  email?: string;
  fullName?: string;
  imageUrl?: string;
  tel?: string;
  failCount?: number;
  role?: string;
  sub?: string | number;
  currentHashedRefreshToken?: string;
}

export type LoginDto = Pick<User, 'username' | 'password'>;

export type TokenPayload = Omit<User, 'password'>;

export enum UserRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  CUSTOMER = 'CUSTOMER',
  GUEST = 'GUEST',
}
