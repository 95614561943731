import { usePageState } from '../../web-page.state';
import { ActionPayload } from '../../../../model';

/**
 * Select & CRUD State
 */
// 주의) 화면을 전환하는 state는 3번째 파라미터가 false여야 한다.
export const MANAGEMENT_HEADER_ACTION = 'management-header-action';
export function useManagementHeaderAction(tabKey: string) {
  return usePageState<ActionPayload<any>>(tabKey, MANAGEMENT_HEADER_ACTION, null, false);
}
