import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { BehaviorSubject } from 'rxjs';

import { PageMenuState } from '../../../state';

/**
 * Use None UI
 */
const setFireActionFromPortalSubject = new BehaviorSubject<any>(undefined);
const setFireActionFromBridge = {
  sendPayload: (payload: string) => setFireActionFromPortalSubject.next(payload),
  getPayload: () => setFireActionFromPortalSubject.asObservable(),
};

export function setFiredActionFromPortal(payload: string) {
  // console.log('>>> [iframe-child: fire action from gw]', payload);
  setFireActionFromBridge.sendPayload(payload);
}

export function useListenFiredActionFromPortal() {
  const [, setFireActionFromPortal] = useAtom(PageMenuState.fireActionFromPortal);

  useEffect(() => {
    const subscription = setFireActionFromBridge.getPayload().subscribe((payload: any) => {
      if (!payload) {
        return;
      }
      // console.log('>>> [iframe-child: set fire action from gw]', payload);
      setFireActionFromPortal(payload);
    });
    return () => subscription.unsubscribe();
  }, []);
}
