import { useState } from 'react';
import { useEventEmitter } from 'ahooks';
import { cloneDeep } from 'lodash';

import { sendToChild } from '../communication/iframe-host-to-child.sender';
import { getRandomId } from '../../utilities/random-generator';
import { checkDashboardCodePage, getPage } from '../../services/menu.service';
import { GV_IFRAME_COMMAND, IGVAppMenu, IMenu, ISwitchPage } from '../../model';

let currentMicroAppName = '';
let switchPageEvent: any;
function useSwitchPageBridge() {
  const event$: any = useEventEmitter();
  if (!switchPageEvent) {
    switchPageEvent = event$;
  }
  return switchPageEvent;
}

export function updateCurrentMicroApp(microAppName: string) {
  currentMicroAppName = microAppName;
}

export function switchPage(callerMicroAppName: string, payload: any) {
  payload.callerMicroAppName = callerMicroAppName;
  switchPageEvent?.emit(payload);
}

export function switchPageByCurrentMicroApp(microAppName: string, pageName: string, params: any) {
  switchPage(currentMicroAppName, { microAppName, pageName, params });
}

/**
 * Use portal web
 * ref: menu.tsx
 */
export function useSwitchPage(apps: any[] = [], setSwitchMicroApp: any, setActiveMicroAppKey: any) {
  // first set menu info state
  const [appMenusInfo, setAppMenusInfo] = useState<any>();
  const event = useSwitchPageBridge();

  event.useSubscription(({ callerMicroAppName, microAppName, pageName, params }: ISwitchPage) => {
    const activeApp = apps.find((activeApp: IGVAppMenu) => activeApp.app.name === microAppName);
    if (activeApp) {
      // change tab with already created micro-app and init page with params
      const cloneActiveApp = cloneDeep(activeApp);
      // we should send app, page, params
      const payload = {
        app: cloneActiveApp.app,
        page: { name: pageName },
        params,
      };
      sendToChild(microAppName, {
        type: GV_IFRAME_COMMAND.LOAD_PAGE,
        payload: payload,
      });

      // if other micro-app
      if (microAppName) {
        //&& callerMicroAppName !== microAppName) { // ex) not do when click alarm notification in alarm notification list to goto single alarm group detail
        cloneActiveApp.activeKey = `${microAppName}:${getRandomId()}`;
        setSwitchMicroApp(cloneActiveApp);
        setActiveMicroAppKey(microAppName);
      }
      currentMicroAppName = microAppName;
    } else {
      // change tab with new micro-app and then init-page with params
      let app: any = appMenusInfo.find((menu: IMenu) => menu.name === microAppName);
      // browser url을 통한 microAppName에 대하여 못 찾으면 다시 첫번째 microApp으로 이동한다.
      if (!app && appMenusInfo?.length) {
        app = appMenusInfo[0];
      }

      let page: any = getPage(app, pageName);
      if (!page) {
        // code로 dashboard가 link 되었을 경우
        page = checkDashboardCodePage(app, pageName);
      }
      const appMenu: IGVAppMenu = {
        key: `${microAppName}:${getRandomId()}`,
        selectedKey: JSON.stringify(page),
        app: app,
        page: page,
        params,
      };
      setSwitchMicroApp(appMenu);
      currentMicroAppName = app.name;
    }
  });

  return [appMenusInfo, setAppMenusInfo];
}
