import { cookieService } from '../../cookie/cookie.service';
import { STATUS_ROLE, SelectOption } from '../../model';

export const DEFAULT_STATUS_COLOR = '#f11444';
export const DEFAULT_STATUS_VALUE_FORMAT_PRECISION = 3;

interface CommonStatus {
  id: string;
  code: string;
  codeName?: string;
  color: string;
  role: STATUS_ROLE;
  sequence: number;
  value: any;
  i18n: any;
  type?: string; //BATCH
  alarmReceiving?: boolean; //BATCH
  iconType?: string;
}
interface IStatus {
  set: (status: { [roleId: STATUS_ROLE]: CommonStatus[] }) => void;
  get: (role: STATUS_ROLE) => CommonStatus[];
  getRow: (role: STATUS_ROLE, value: string, identifier?: string) => CommonStatus | null;
  getProperty: (role: STATUS_ROLE, id: string, property: string, identifier?: string) => any;
  getColor: (role: STATUS_ROLE, id: string, identifier?: string) => string;
  getTranslateLabel: (role: STATUS_ROLE, id: string, identifier?: string) => string;
  getSelectOptions: (role: STATUS_ROLE, identifier?: string) => SelectOption[];
}

function applyGVStatusList(data: any) {
  (window as any).GV_statusList = data;
}

function getGVStatusList() {
  return (window as any).GV_statusList;
}
// let statusList: any = [];

function setStatusList(data: { [roleId: STATUS_ROLE]: CommonStatus[] }) {
  if (data) {
    applyGVStatusList(data);
  }
}
// status id에 해당하는 속성 추출
function getStatusLevel(role: STATUS_ROLE, id: string, type: string, identifier = 'id') {
  if (!getGVStatusList() || getGVStatusList().length === 0) {
    return;
  }
  if (!getGVStatusList()[role]) {
    // console.log(`${role} status data : `, statusList[role]);
    return '';
  }
  return getGVStatusList()[role].find((status: any) => status[identifier] === id)?.[type] || '';
}

// statusList에서 identifier(property) 값과 일치하는 value의 object 추출
function getStatusRow(role: STATUS_ROLE, value: string, identifier = 'id') {
  const row: CommonStatus = getGVStatusList()?.[role]?.find((status: any) => status[identifier] === value);
  return row
    ? {
        ...row,
        codeName: getTranslateStatus(row?.i18n),
      }
    : null;
}

// ROLE에 해당하는 STATUS 목록 추출 5개의 service refact 필요
function getStatusRole(role: STATUS_ROLE) {
  if (!getGVStatusList() || getGVStatusList().length === 0) {
    return;
  }
  return getGVStatusList()[role] || [];
}

function getTranslateStatus(i18n: any) {
  const lang = cookieService.get('I18N_LANG');
  if (i18n) {
    return i18n[lang || 'en'] || '';
  } else {
    return '';
  }
}

function getStatusColor(role: STATUS_ROLE, id: string, identifier = 'id') {
  return getStatusLevel(role, id, 'color', identifier);
}

function getStatusI18n(role: STATUS_ROLE, id: string, identifier = 'id') {
  if (!role || !id) {
    return '';
  }
  return getTranslateStatus(getStatusLevel(role, id, 'i18n', identifier));
}

function getTranslateOptions(role: STATUS_ROLE, identifier = 'id') {
  if (!getGVStatusList()) {
    return;
  }
  const lang = cookieService.get('I18N_LANG');

  // sequence 가 -1인 항목은 select option에서 제외
  return (getGVStatusList()[role] || [])
    .filter(({ sequence }: any) => sequence > -1)
    .map((item: any) => {
      return {
        value: item[identifier],
        label: item.i18n[lang || 'en'] || item.i18n['en'],
        extra: item,
      };
    });
}

export const commonStatus: IStatus = {
  set: setStatusList,
  get: getStatusRole,
  getProperty: getStatusLevel,
  getRow: getStatusRow,
  getColor: getStatusColor,
  getTranslateLabel: getStatusI18n,
  getSelectOptions: (role: STATUS_ROLE, identifier = 'id') => getTranslateOptions(role, identifier),
};
