import { memo } from 'react';

/* eslint-disable-next-line */
export interface UnloadIconProps {
  type: 'unload' | '';
  color?: string; // stroke
  background?: string; // fill
  height?: number;
  width?: number;
}

/***********************************
 * Usage

  <GVUnloadIcon type="unload" />


 ***********************************/
export function UnloadIcon({ type = '', background = 'transparent' }: UnloadIconProps) {
  switch (type) {
    case 'unload':
      return (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.1334 0H0.866707C0.573374 0 0.333374 0.24 0.333374 0.533333V5.22C0.333374 5.51333 0.573374 5.75333 0.866707 5.75333H3.28671V4.62H1.46671V1.13333H12.5334V4.62H10.78V5.75333H13.1334C13.4267 5.75333 13.6667 5.51333 13.6667 5.22V0.533333C13.6667 0.24 13.4267 0 13.1334 0ZM12.2734 7.34H9.80671V5.76L8.64004 4.7V8.51333H9.86004L7.00004 11.2333L4.14003 8.51333H5.36003V4.43333L4.19337 3.49333V7.34H1.7267C1.5467 7.34 1.46003 7.56 1.5867 7.68667L6.63337 12.4933C6.84004 12.6867 7.16004 12.6867 7.36671 12.4933L12.4134 7.68667C12.5467 7.56 12.4534 7.34 12.2734 7.34ZM10.6134 2.29333H11.38C11.4905 2.29333 11.58 2.38287 11.58 2.49333V3.26C11.58 3.37045 11.4905 3.46 11.38 3.46H10.6134C10.5029 3.46 10.4134 3.37045 10.4134 3.26V2.49333C10.4134 2.38287 10.5029 2.29333 10.6134 2.29333Z"
            fill="currentColor"
          />
        </svg>
      );
    default:
      return <span>x</span>;
  }
}

export const GVUnloadIcon = memo(UnloadIcon);
