// 출처: https://inpa.tistory.com/entry/CSS-📚-CSS용-변수-variable-정리 [Inpa Dev 👨‍💻:티스토리]
// 가상 클래스 요소 얻기
let root: any = document.querySelector(':root');

export function getRootStyle(variable: string, isNew = false) {
  if (!root || isNew) {
    root = document.querySelector(':root');
  }

  if (root) {
    // window.getComputedStyle 메서드를 이용하면, 해당 요소에 전역적으로 적용된 모든 형태의 style을 반환한다
    const variables: any = getComputedStyle(root);
    if (variables?.getPropertyValue) {
      return variables.getPropertyValue(variable);
    }
    return '';
  }
  return '';
}

export function setRootStyle(variable: string, value: string, defaultValue?: string) {
  if (!root) {
    root = document.querySelector(':root');
  }

  if (root?.style) {
    if (value) {
      root.style?.setProperty(variable, value);
    } else {
      if (defaultValue) {
        root.style?.setProperty(variable, defaultValue);
      }
    }
  }
}

export function appendStyle({ id = '', cssStr }: { id?: string; cssStr: string }) {
  //ref: https://stackoverflow.com/questions/40572157/change-css-root-variable-with-jquery-or-javascript
  const alreadyStyle = document.getElementById(id);
  if (alreadyStyle?.remove) {
    alreadyStyle.remove();
  }

  const styleEl = document.createElement('style');
  // styleEl.type = 'text/css';
  styleEl.id = id;
  styleEl.textContent = cssStr;
  document.head.appendChild(styleEl);
}
