import { t } from 'i18next';

/**
 * tabTitle type
 *  1) "tabName": 전부를 translate 처리한다.
 *  2) "[tabName1] tabName2" : [xxx] 대괄호 안에있는 것을 부분적으로 translate 처리한다. 
 * @param tabTitle 
 * @returns 
 */
export function translateTabTitle(tabTitle: string) {
  if (!tabTitle) {
    return;
  }
  if (tabTitle.indexOf(']') < 0) {
    return t(tabTitle);
  }

  const target = tabTitle.substring(1, tabTitle.indexOf(']'));
  const value = tabTitle.substring(tabTitle.indexOf(']') + 1);
  return `${t(target)}${t(value)}`;
}
