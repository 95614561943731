/* eslint-disable no-loop-func */
import { cloneDeep, find } from 'lodash';

import { GV_MICRO_APP, IMenu } from '../model';

export function getAppMenus() {
  // let appMenus: any[] = [];
  if (!(window as any).GV_CONFIG_appMenus) {
    (window as any).GV_CONFIG_appMenus = [];
  }
  return (window as any).GV_CONFIG_appMenus;
}

export function setAppMenus(menus: any[]) {
  (window as any).GV_CONFIG_appMenus = menus;
}

/**
 *
 * @param app: 'asset'
 * @param pageName: 'asset.asset-list.overview'
 */
export function getPage(app: IMenu | any, pageName: string) {
  let menu: any = app;
  const items = pageName.split('.');
  let name: any;
  for (let i = 0; i < items.length - 1; i++) {
    if (i === 0) {
      name = `${items[i]}.${items[i + 1]}`;
    } else {
      name += `.${items[i + 1]}`;
    }
    menu = menu?.subMenus?.find((m: IMenu) => m.name === name);
  }
  return menu;
}

export function checkDashboardCodePage(app: IMenu | any, pageName: string) {
  // code로 dashboard가 link 되었을 경우
  if (app.name === GV_MICRO_APP.DASHBOARD && pageName?.indexOf('code-') >= 0) {
    const dashboardCode = pageName.split('-')[1];
    return find(app.subMenus, { code: dashboardCode });
  }
}

/**
 * @param app: 'asset'
 * @param pageName: 'asset.asset-list.overview'
 */
export function getSubMenus(appId: string, appMenus: any[]) {
  let subMenus = find(cloneDeep(appMenus), { name: appId })?.subMenus;
  if (subMenus) {
    subMenus = transferDashboardMenu(appId, subMenus);
    return subMenus;
  }
  return;
}

/**
 * @param app: 'dashboard'
 * @param pageName: 'dashboard.xxx-xxxx-xxxx-xxxx'
 */
export function transferDashboardMenu(appId: string, subMenus: any[]) {
  if (appId === GV_MICRO_APP.DASHBOARD) {
    subMenus = subMenus.map((menu: any) => {
      if (menu.name.indexOf('.') > 0) {
        const [app, id] = menu.name.split('.');
        menu.name = id;
      }
      return menu;
    });
  }
  return subMenus;
}
