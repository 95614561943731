import { isEqual, pick } from 'lodash';

import { locationPrefix } from '../config/config';
import { makeQueryString } from '../utilities/query-util';

/**
 * 주의: Payload는 반드시 browser location querystring에 표현될 값만 1depth object로 호출한다.
 * case-1) click page on global hamburger menu
 * case-2) switch app on global menu bar
 * case-3) click side menu, detail menu
 * case-4) drill through: micro-app to micro-app
 * case-5) click link/refresh browser on text(email)
 */
const pickKeys = [
  // realtime filter의 runtime, period
  'isRealtime',
  'selectedDateType',
  'start',
  'end',
  'from',
  'to',
  'timeCycle',
  'timeRange',
  'timeRangeUnit',
  // apm
  'detailPage',
  'detailSubPage',
  'aggregation',
  'healthStatus',
  'locationId',
  'assetId',
  // ameta
  'paramId',
  'paramGroupId',
  'assetTemplateId',
  'hierarchyId',
  'modelId',
  'modelRevisionId',
  'dashboardId',
  'layoutId',
  'reportId',
  'userGroupId',
  'username',
  // external link params
  'action', // ActionType.SELECT, CREATE..
  'analysisModelType',
  // subTabKey
  'subTabKey',
  'alarmTypeId',
  // single (micro-app) dev mode
  'single',
];

export function updateBrowserLocation(app: string, tabKey: string, params = {}): void {
  // app is microAppName
  // page is pageName
  // params is locationParams with realtime filter & other params)
  const newurl = makeQueryString({ tabKey }, params, locationPrefix());
  if (window.history.pushState) {
    // ref: https://github.com/mattphillips/deep-object-diff/blob/main/README.md
    // 해당 패키지를 사용할 수도 있다. 검토해야함
    // console.log(
    //   '> is different querystring:',
    //   isEqual(pick(window.history.state?.params, pickKeys), pick(params, pickKeys))
    // );

    if (
      window.history.state?.tabKey !== tabKey ||
      !isEqual(pick(window.history.state?.params, pickKeys), pick(params, pickKeys))
    ) {
      // console.log('> push history:', params);
      window.history.pushState({ tabKey, params }, `GVI ${app} - ${tabKey}`, newurl);
    }
  } else {
    document.location.href = newurl;
  }
}

export function updateRootLocation(params = {}, name = 'Aidentyx') {
  const newurl = makeQueryString(params, {}, locationPrefix());
  if (window.history.pushState) {
    window.history.pushState(params, `GVI ${name}`, newurl);
  } else {
    document.location.href = newurl;
  }
}

export function getURLSearchParam(key: string, search = window.parent.document.location.search) {
  const params = new URLSearchParams(search);
  return params.get(key);
}

export function isSingleMicroAppInDevMode() {
  const value = getURLSearchParam('single');
  return value === 'true' || value;
}

export function getSingleMicroAppNameInDevMode() {
  const value = getURLSearchParam('tabKey'); // apm.detail.alarm:0zrdxk0h0idr
  return value?.split('.')?.[0] || ''; // apm
}

export function getTabKeyFromUrlParams(): string {
  const value = getURLSearchParam('tabKey'); // apm.detail.alarm:0zrdxk0h0idr
  return value?.split(':')?.[0] || ''; // apm.detail.alarm
}
