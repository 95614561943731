import { applyDynamicStyleTag } from './dynamic-style/theme-dynamic-apply-style.service';
import { interpolateTheme } from './interpolation/theme-interpolation.service';
import { AntDConfiguration, ColorLevelConfiguration, CustomConfiguration } from './types';
import { applyTheme } from './dynamic-style/theme-dynamic-apply-style.service';
import { GV_MICRO_APP } from '../model';

export function applyRuntimeTheme(
  colorLevelConfig: ColorLevelConfiguration,
  antdConfig: AntDConfiguration,
  customConfig: CustomConfiguration,
  appName: GV_MICRO_APP | string | undefined = '',
) {
  // [1] Interpolation color-level.json -> antd.json -> application.json (custom)
  const { themeAntD, themeCustomComponent } = interpolateTheme(colorLevelConfig, antdConfig, customConfig, appName);

  // [2] Transfer custom theme to custom variables of style -> Apply dynamic variables to style tag in header tag
  applyDynamicStyleTag(colorLevelConfig['common-variables'], colorLevelConfig['base-variables'], themeCustomComponent);

  // [3] Set global value for AntD provider
  (window as any).GV_THEME_ANTD = themeAntD;
  console.log('> [environment] GV_THEME_ANTD:', (window as any).GV_THEME_ANTD);
}

export function changePortalHeaderStatus(status: 'open' | 'hide') {
  applyTheme('RUNTIME_THEME__CHANGED', { '--ab-header-height': status === 'open' ? '50px' : '0px' });
}
