import { memo } from 'react';

/* eslint-disable-next-line */
export interface ChartToggleIconProps {
  type: 'trace' | 'boxplot' | '';
  color?: string; // stroke
  height?: number;
  width?: number;
}

/***********************************
 * Usage

  <GVChartToggleIcon type="collapse" />
  <GVChartToggleIcon type="expand" />

 ***********************************/
export function ChartToggleIcon({ type = '', width = 12 }: ChartToggleIconProps) {
  switch (type) {
    case 'trace':
      return (
        <svg width={width} height={width} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.875 10.375H1.125V0.625C1.125 0.55625 1.06875 0.5 1 0.5H0.125C0.05625 0.5 0 0.55625 0 0.625V11.375C0 11.4438 0.05625 11.5 0.125 11.5H11.875C11.9438 11.5 12 11.4438 12 11.375V10.5C12 10.4312 11.9438 10.375 11.875 10.375ZM2.77812 7.96406C2.82656 8.0125 2.90469 8.0125 2.95469 7.96406L5.11562 5.81406L7.10938 7.82031C7.15781 7.86875 7.2375 7.86875 7.28594 7.82031L11.5891 3.51875C11.6375 3.47031 11.6375 3.39062 11.5891 3.34219L10.9703 2.72344C10.9468 2.70017 10.9151 2.68712 10.882 2.68712C10.849 2.68712 10.8172 2.70017 10.7937 2.72344L7.2 6.31563L5.20937 4.3125C5.18588 4.28924 5.15416 4.27619 5.12109 4.27619C5.08803 4.27619 5.05631 4.28924 5.03281 4.3125L2.16094 7.16719C2.13767 7.19068 2.12462 7.22241 2.12462 7.25547C2.12462 7.28853 2.13767 7.32026 2.16094 7.34375L2.77812 7.96406Z"
            fill="#262626"
          />
        </svg>
      );
    case 'boxplot':
      return (
        <svg width={width} height={width} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.66672 0.666656C8.59308 0.666656 8.53339 0.726352 8.53339 0.79999V2.66666H7.33335C6.96516 2.66666 6.66669 2.96513 6.66669 3.33332V7.33332C6.66669 7.70151 6.96516 7.99999 7.33335 7.99999H8.53339V10.5333C8.53339 10.607 8.59308 10.6667 8.66672 10.6667H9.40005C9.47369 10.6667 9.53339 10.607 9.53339 10.5333V7.99999H10.6667C11.0349 7.99999 11.3334 7.70151 11.3334 7.33332V3.33332C11.3334 2.96513 11.0349 2.66666 10.6667 2.66666H9.53339V0.79999C9.53339 0.726352 9.47369 0.666656 9.40005 0.666656H8.66672ZM7.66669 6.99999V3.66666H10.3334V6.99999H7.66669ZM2.66672 1.99999C2.59308 1.99999 2.53339 2.05969 2.53339 2.13332V3.99999H1.33335C0.965164 3.99999 0.666687 4.29847 0.666687 4.66666V8.66666C0.666687 9.03485 0.965164 9.33332 1.33335 9.33332H2.53339V11.5333C2.53339 11.607 2.59308 11.6667 2.66672 11.6667H3.40005C3.47369 11.6667 3.53339 11.607 3.53339 11.5333V9.33332H4.66669C5.03488 9.33332 5.33335 9.03485 5.33335 8.66666V4.66666C5.33335 4.29847 5.03488 3.99999 4.66669 3.99999H3.53339V2.13332C3.53339 2.05969 3.47369 1.99999 3.40005 1.99999H2.66672ZM1.66669 8.33332V4.99999H4.33335V8.33332H1.66669Z"
            fill="#333333"
          />
        </svg>
      );
    default:
      return <span>x</span>;
  }
}

export const GVChartToggleIcon = memo(ChartToggleIcon);
