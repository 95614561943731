import { usePageState } from './web-page.state';

export const UNSELECTED_ASSET_LIST_WIDGET = -1;
export const SELECTED_FIRST_ROW_ASSET_LIST_WIDGET = 1;
export const UNSELECTED_OTHER_WIDGET = 2;

export function useSelectedAssetState(tabKey: string, initValue: any = null) {
  return usePageState(tabKey, 'selected-asset', initValue);
}

export function useSelectedModelState(tabKey: string, initValue: any = null) {
  return usePageState(tabKey, 'selected-model', initValue);
}
