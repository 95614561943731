/* eslint-disable @typescript-eslint/no-explicit-any */
import { find, reject } from 'lodash';
import { useQuery } from '@tanstack/react-query';

import { setDashboardMenuFilters, setSearchMaxDays } from '../../state';
import { httpService } from '../../ajax/http.service';
import { gvPODOApiPrefix } from '../../config/config';
import { setAppMenus } from '../../services/menu.service';
import { isRedirectLicenseKey } from '../../auth/license';
import { twentyFourHoursInMs } from '../../utilities/utils';
import { useRefetchByChangedGlobalState } from '../../hooks/shared/changed-global-state.hook';
import { GV_MICRO_APP, GlobalStateType, IDashboardPersistence, PageCreateType, SideMenuType } from '../../model';

export function useAppMenus(staleTime = twentyFourHoursInMs): any {
  const { data, refetch, isError, isLoading }: any = useQuery(['app-menus'], () => getAppMenus(), {
    staleTime,
    cacheTime: staleTime,
  });

  useRefetchByChangedGlobalState([GlobalStateType.CHANGED_DASHBOARD], refetch);

  return { appMenus: data?.menuContents, data, isError, isLoading };
}

async function getAppMenus() {
  const url = `${gvPODOApiPrefix()}/menus`;
  const result: any = await httpService.get<any[]>(url);
  if (isRedirectLicenseKey()) {
    setAppMenus(result?.menuContents);
    return result;
  }

  const dashboardContents = find(result.menuContents, { name: GV_MICRO_APP.DASHBOARD });
  result.menuContents = reject(result.menuContents, { name: GV_MICRO_APP.DASHBOARD });

  // set search max days in realtime filter
  setDashboardMenuFilters(dashboardContents?.filters);
  setSearchMaxDays(dashboardContents?.filters?.searchMaxDays);

  // set dynamic dashboard list
  try {
    const dashboardUrl = `${gvPODOApiPrefix()}/dashboards?publish=true&projection=detail`;
    const dashboards: IDashboardPersistence[] = await httpService.get<any[]>(dashboardUrl);
    const dashboardMenu = transferToDashboardMenu(dashboards, dashboardContents?.filters);
    if (dashboardMenu && dashboardMenu.subMenus && dashboardMenu.subMenus.length) {
      result.menuContents.unshift(dashboardMenu);
    }
  } catch (e) {
    console.log('> load dashbarod menu error:', e);
  }

  setAppMenus(result?.menuContents);
  return result;
}

function transferToDashboardMenu(dashboards: IDashboardPersistence[], filters: any): any {
  if (!dashboards || !dashboards.length) {
    return;
  }

  const dashboardMenu: any = {
    name: GV_MICRO_APP.DASHBOARD,
    translate: 'MENU.Dashboard',
    permissions: [],
    type: 'MENU_VIEW',
    filters: filters
      ? filters
      : {
          isRealtime: true,
          timeCycle: 10,
          timeRange: 1,
          timeRangeUnit: 'hours',
          searchMaxDays: 90, // 3 months
          period: { from: null, to: null },
        },
    subMenus: [],
  };

  dashboardMenu.subMenus = makeDashboardSidebarMenus(dashboards);
  return dashboardMenu;
}

function makeDashboardSidebarMenus(dashboards: IDashboardPersistence[]) {
  return dashboards.map((dashboard: IDashboardPersistence) => ({
    name: `${GV_MICRO_APP.DASHBOARD}.${dashboard.id}`,
    code: dashboard.code,
    translate: dashboard.name,
    icon: dashboard?.config?.icon,
    theme: dashboard?.config?.theme,
    hierarchyFilterUsage: dashboard?.config?.hierarchy,
    healthStatusFilterUsage: dashboard?.config?.healthStatus,
    realtimeFilterUsage: dashboard?.config?.realtime,
    createTabType: dashboard?.config?.createTabType === 'single' ? PageCreateType.SINGLE : PageCreateType.MUTLI,
    hidden: dashboard?.config?.hideMenu === 'enable' ? true : false,
    sidebarMenuType: dashboard?.config?.hideMenu === 'enable' ? SideMenuType.DYNAMIC : '',
    permissions: [],
    type: 'MENU_LINK',
    url: `${GV_MICRO_APP.DASHBOARD}/${dashboard.id}`,
  }));
}
