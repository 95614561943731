import { t } from 'i18next';
import { isEmpty } from 'lodash';

import { $ab_font_gray } from '@grandview/stylet';

import { DEFAULT_STATUS_COLOR, DEFAULT_STATUS_VALUE_FORMAT_PRECISION, commonStatus } from './common-status.service';
import { IElementData, KPI_STATUS, STATUS_ROLE } from '../../model';
import { formatNumber } from '../../utilities/number-util';

export function getElementStatusColor(elementData: IElementData) {
  const status = (elementData as any)[elementData.objectStatus.toLowerCase()];
  if (!status || !status.id) {
    return DEFAULT_STATUS_COLOR;
  }

  switch (elementData.objectStatus) {
    case KPI_STATUS.HEALTH:
      return commonStatus.getColor(STATUS_ROLE.HEALTH, status.id);
    case KPI_STATUS.RUL:
      return commonStatus.getColor(STATUS_ROLE.RUL, status.id);
    case KPI_STATUS.ALARM:
      return commonStatus.getColor(STATUS_ROLE.ALARM, status.id);
    default:
      return DEFAULT_STATUS_COLOR;
  }
}

export function getElementStatusValue(
  elementData: IElementData,
  valueFormatPrecision = DEFAULT_STATUS_VALUE_FORMAT_PRECISION
) {
  const status = (elementData as any)[elementData.objectStatus.toLowerCase()];
  if (!status || isEmpty(status)) {
    return '';
  }

  let value = 0;
  switch (elementData.objectStatus) {
    case KPI_STATUS.HEALTH:
      value = status['assetScore'];
      break;
    case KPI_STATUS.RUL:
      value = status['value'];
      break;
    default:
      return '';
  }
  return formatNumber(value, valueFormatPrecision);
}

export function getElementStatusI18n(elementData: IElementData) {
  const status = (elementData as any)[elementData.objectStatus.toLowerCase()];
  if (!status || !status.id) {
    return '';
  }

  switch (elementData.objectStatus) {
    case KPI_STATUS.HEALTH:
      return commonStatus.getTranslateLabel(STATUS_ROLE.HEALTH, status.id);
    case KPI_STATUS.RUL:
      return commonStatus.getTranslateLabel(STATUS_ROLE.RUL, status.id);
    case KPI_STATUS.ALARM:
      return commonStatus.getTranslateLabel(STATUS_ROLE.ALARM, status.id);
    default:
      return '';
  }
}

export function getElementStatus(elementData: IElementData | any) {
  const status = (elementData as any)[elementData.objectStatus.toLowerCase()];
  if (!status || !status.id) {
    return {
      valueLabel: '',
      value: `${t('ASSET.No data')}`,
      color: $ab_font_gray(),
      i18nLabel: elementData.objectStatus,
      i18n: '',
    };
  }

  switch (elementData.objectStatus) {
    case KPI_STATUS.HEALTH:
      return {
        valueLabel: `${t('ASSET.Health index')}: `,
        value: formatNumber(status['assetScore'], DEFAULT_STATUS_VALUE_FORMAT_PRECISION),
        color: commonStatus.getColor(STATUS_ROLE.HEALTH, status.id),
        i18nLabel: `${t('DASHBOARD.Health Status')}: `,
        i18n: commonStatus.getTranslateLabel(STATUS_ROLE.HEALTH, status.id),
      };
    case KPI_STATUS.RUL:
      return {
        valueLabel: `D - `,
        value: formatNumber(status['value'], DEFAULT_STATUS_VALUE_FORMAT_PRECISION),
        color: commonStatus.getColor(STATUS_ROLE.RUL, status.id),
        i18nLabel: `${t('DASHBOARD.RUL Status')}: `,
        i18n: commonStatus.getTranslateLabel(STATUS_ROLE.RUL, status.id),
      };
    case KPI_STATUS.ALARM:
      return {
        valueLabel: '',
        value: '',
        color: commonStatus.getColor(STATUS_ROLE.ALARM, status.id),
        i18nLabel: `${t('DASHBOARD.Alarm Status')}: `,
        i18n: commonStatus.getTranslateLabel(STATUS_ROLE.ALARM, status.id),
      };
    default:
      return {
        valueLabel: '',
        value: `${t('ASSET.No data')}`,
        color: $ab_font_gray(),
        i18nLabel: '',
        i18n: '',
      };
  }
}
