/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useUnmount } from 'ahooks';
import { ReplaySubject } from 'rxjs';

export function sendDataToSubscriber(tabKey: string, eventKey: string, payload: any) {
  if (payload) {
    getDataSender(tabKey, eventKey).sendPayload(payload);
    // console.log('[one-way subject send] tabKey, eventKey, payload', tabKey, eventKey, payload);
  }
}

/**
 * Use None UI
 */
export function getOneWayData() {
  if (!(window as any).GV_STATE_RX_oneWayData) {
    (window as any).GV_STATE_RX_oneWayData = {};
  }
  return (window as any).GV_STATE_RX_oneWayData;
}

export function getDataSender(tabKey: string, eventKey: string) {
  if (!getOneWayData()[tabKey]) {
    const subject = new ReplaySubject<any>(1);
    getOneWayData()[tabKey] = {
      [eventKey]: {
        sendPayload: (payload: any) => subject.next(payload),
        payloadObservable: () => subject.asObservable(),
      },
    };
  }
  if (!getOneWayData()[tabKey][eventKey]) {
    const subject = new ReplaySubject<any>(1);
    getOneWayData()[tabKey][eventKey] = {
      sendPayload: (payload: any) => subject.next(payload),
      payloadObservable: () => subject.asObservable(),
    };
  }
  return getOneWayData()[tabKey][eventKey];
}

export function useSubscribeData(tabKey: string, eventKey: string, calllerFn: any = undefined, isUnmount = true) {
  const [state, setState] = useState<any>({});

  useUnmountOneWayData(tabKey, eventKey, isUnmount);

  useEffect(() => {
    if (!tabKey || !eventKey) {
      return;
    }

    const subject = getDataSender(tabKey, eventKey);
    const subscription = subject.payloadObservable().subscribe((payload: any) => {
      if (!payload) {
        return;
      }

      calllerFn ? calllerFn(payload) : setState(payload);
    });
    return () => subscription?.unsubscribe();
  }, [tabKey, eventKey]);

  return state;
}

export function removeOneWayData(tabKey: string, eventKey: string) {
  if (getOneWayData()?.[tabKey] && getOneWayData()[tabKey]?.[eventKey]) {
    delete getOneWayData()[tabKey][eventKey];
  }
}

export function useUnmountOneWayData(tabKey: string, eventKey: string, isUnmount = false) {
  useUnmount(() => {
    if (isUnmount) {
      removeOneWayData(tabKey, eventKey);
    }
  });
}
