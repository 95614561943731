export enum TrendTab {
  FAVORITE = 'favorite',
  PARAMETER = 'parameter',
  FEATURE = 'feature',
  MODEL = 'model',
  BATCH = 'batch',
  VIBRATION = 'vibration', // Vibration FC
}

export enum TrendChartType {
  FAVORITE = 'favorite',
  PARAMETER = 'parameter',
  FEATURE = 'feature',
  MODEL = 'model',
  MERGED_MODEL = 'merged_model',
  // vibration fc
  FFT = 'fft',
  FFT_PARAMETER = 'fft_parameter',
  // batch
  BATCH = 'batch',
  BATCH_PARAMETER = 'batch_parameter',
}

/**
 * Asset Favorite Type
 */
export enum AssetFavoriteTypes {
  MODEL = 'MODEL',
  PARAMETER = 'PARAMETER',
  FEATURE = 'FEATURE',
  BATCH = 'BATCH',
  VIBRATION = 'VIBRATION', // Vibration FC
}
