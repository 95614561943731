import {
  BarChartOutlined,
  BorderOutlined,
  LineChartOutlined,
  PieChartOutlined,
  RadarChartOutlined,
  StockOutlined,
  FundOutlined,
  DotChartOutlined,
  HeatMapOutlined,
  AlertOutlined,
  DeploymentUnitOutlined,
  CodeSandboxOutlined,
  GlobalOutlined,
  DashboardOutlined,
  FundProjectionScreenOutlined,
  ProjectOutlined 
} from '@ant-design/icons';

import {
  ABAlarmSummaryIcon, ABAssetGroupIcon, ABContextIcon,
} from '@grandview/icons';

export const dashboardIcons: any[] = [
  {
    key: '1',
    value: 'dashboard',
    name: 'Dashboard',
    icon: DashboardOutlined,
  },
  {
    key: '2',
    value: 'kpi',
    name: 'KPI',
    icon: FundProjectionScreenOutlined,
  },
  {
    key: '3',
    value: 'alarm',
    name: 'Alarm',
    icon: AlertOutlined,
  },
  {
    key: '4',
    value: 'sensor',
    name: 'Sensor',
    icon: DeploymentUnitOutlined,
  },
  {
    key: '5',
    value: 'default',
    name: 'Box',
    icon: BorderOutlined,
  },
  {
    key: '6',
    value: 'line',
    name: 'Line',
    icon: LineChartOutlined,
  },
  {
    key: '7',
    value: 'line-box',
    name: 'Line Box',
    icon: FundOutlined,
  },
  {
    key: '8',
    value: 'pie',
    name: 'Pie',
    icon: PieChartOutlined,
  },
  {
    key: '9',
    value: 'bar',
    name: 'Bar',
    icon: BarChartOutlined,
  },
  {
    key: '10',
    value: 'radar',
    name: 'Radar',
    icon: RadarChartOutlined,
  },
  {
    key: '11',
    value: 'stock',
    name: 'Stock',
    icon: StockOutlined,
  },
  {
    key: '12',
    value: 'dot',
    name: 'Dot',
    icon: DotChartOutlined,
  },
  {
    key: '13',
    value: 'heat-map',
    name: 'HeatMap',
    icon: HeatMapOutlined,
  },
  {
    key: '14',
    value: 'model',
    name: 'Model',
    icon: CodeSandboxOutlined,
  },
  {
    key: '15',
    value: 'global',
    name: 'Global',
    icon: GlobalOutlined,
  },
  {
    key: '16',
    value: 'alarm-summary',
    name: 'Alarm Summary',
    icon: ABAlarmSummaryIcon,
  },
  {
    key: '17',
    value: 'group',
    name: 'Group',
    icon: ABAssetGroupIcon,
  },
  {
    key: '18',
    value: 'context',
    name: 'Context',
    icon: ABContextIcon,
  },
  {
    key: '19',
    value: 'monitoring',
    name: 'Monitoring',
    icon: ProjectOutlined,
  },
];

export function transferDashboardIcon(icon: string | undefined): any {
  switch (icon) {
    case 'dashboard':
      return DashboardOutlined;
    case 'kpi':
      return FundProjectionScreenOutlined;
    case 'alarm':
      return AlertOutlined;
    case 'sensor':
      return DeploymentUnitOutlined;
    case 'line':
      return LineChartOutlined;
    case 'line-box':
      return FundOutlined;
    case 'pie':
      return PieChartOutlined;
    case 'bar':
      return BarChartOutlined;
    case 'radar':
      return RadarChartOutlined;
    case 'stock':
      return StockOutlined;
    case 'dot':
      return DotChartOutlined;
    case 'heat-map':
      return HeatMapOutlined;
    case 'model':
      return CodeSandboxOutlined;
    case 'global':
      return GlobalOutlined;
    case 'alarm-summary':
      return ABAlarmSummaryIcon;
    case 'group':
      return ABAssetGroupIcon;
    case 'context':
      return ABContextIcon;
    case 'monitoring':
      return ProjectOutlined;
    default:
      return BorderOutlined;
  }
}
