/* eslint-disable @typescript-eslint/no-explicit-any */
import i18next from 'i18next';
import dayjs from 'dayjs';
import { cloneDeep, isString } from 'lodash';

import { realtimeAggregation } from '../config/config';
import { timezoneService } from '../timezone/timezone.service';
import { AggregationFilterType, DateType } from '../model';

export function transferRealtimeFilterOptions(realtimeFilter: any) {
  if (!realtimeFilter) {
    return;
  }

  const options = cloneDeep(realtimeFilter);
  delete options['isRefresh'];
  delete options['refreshKey'];
  delete options['period'];
  delete options['interval'];
  if (realtimeFilter.isRealtime || realtimeFilter.isRealtime === 'true') {
    options['start'] = timezoneService.stringToUtcString(realtimeFilter.interval?.start);
    options['end'] = timezoneService.stringToUtcString(realtimeFilter.interval?.end);
  } else {
    options['start'] = timezoneService.stringToUtcString(realtimeFilter.period?.from);
    options['end'] = timezoneService.stringToUtcString(realtimeFilter.period?.to);
  }
  return options;
}

export function chageRangeFilterByDateType(
  selectedDateType: DateType,
  intervals: any
): {
  aggregation: string;
  selectedDateType: DateType;
  period: { from: Date; to: Date };
} {
  const result: any = {
    selectedDateType,
    isRealtime: false,
  };

  switch (selectedDateType) {
    // Realtime interval
    case DateType.ALL:
      result.period = { from: undefined, to: undefined };
      result.aggregation = realtimeAggregation();
      break;
    case DateType.CURRENT: // runtime default - before 1 hour
      result.period = {
        from: dayjs(dayjs().subtract(2, 'h')).toDate(),
        to: new Date(),
      };
      result.aggregation = realtimeAggregation();
      break;
    // Period
    case DateType.LAST_1_HOUR:
      result.period = {
        from: dayjs(dayjs().subtract(1, 'h')).toDate(),
        to: new Date(),
      };
      result.aggregation = realtimeAggregation();
      break;
    case DateType.LAST_3_HOURS:
      result.period = {
        from: dayjs(dayjs().subtract(3, 'h')).toDate(),
        to: new Date(),
      };
      result.aggregation = realtimeAggregation();
      break;
    case DateType.LAST_6_HOURS:
      result.period = {
        from: dayjs(dayjs().subtract(6, 'h')).toDate(),
        to: new Date(),
      };
      result.aggregation = realtimeAggregation();
      break;
    case DateType.LAST_12_HOURS:
      result.period = {
        from: dayjs(dayjs().subtract(12, 'h')).toDate(),
        to: new Date(),
      };
      result.aggregation = realtimeAggregation();
      break;
    case DateType.TODAY:
      result.period = {
        from: dayjs().startOf('d').toDate(),
        to: new Date(),
      };
      result.aggregation = AggregationFilterType.PERIOD;
      break;
    case DateType.YESTERDAY:
      result.period = {
        from: dayjs(dayjs().subtract(1, 'd')).startOf('d').toDate(),
        to: dayjs(dayjs().subtract(1, 'd')).endOf('d').toDate(),
      };
      result.aggregation = AggregationFilterType.PERIOD;
      break;
    case DateType.DAYS7:
      result.period = {
        from: dayjs(dayjs().subtract(7, 'd')).startOf('d').toDate(),
        to: new Date(),
      };
      result.aggregation = AggregationFilterType.PERIOD;
      break;
    case DateType.MONTH1:
      result.period = {
        from: dayjs(dayjs().subtract(30, 'd')).startOf('d').toDate(),
        to: new Date(),
      };
      result.aggregation = AggregationFilterType.PERIOD;
      break;
    case DateType.CUSTOM:
      result.aggregation = AggregationFilterType.PERIOD;
      if (intervals) {
        if (isString(intervals)) {
          const fromTo = intervals.split('/');
          result.period = {
            from: timezoneService.utcStringToDate(fromTo[0]),
            to: timezoneService.utcStringToDate(fromTo[1]),
          };
        } else {
          // must object with from, to properties
          if (intervals.from && intervals.to) {
            result.period = {
              from: dayjs(intervals.from).toDate(),
              to: dayjs(intervals.to).toDate(),
            };
          }
          if (intervals.period && intervals.period.from && intervals.period.to) {
            result.period = {
              from: dayjs(intervals.period.from).toDate(),
              to: dayjs(intervals.period.to).toDate(),
            };
          }
        }
      }
      break;
    // default Realtime interval
    default:
      result.period = {
        from: dayjs().startOf('d').toDate(),
        to: new Date(),
      };
      result.aggregation = AggregationFilterType.PERIOD;
  }

  return result;
}

export function getRealtimeFilterDateTypeLabel(selectedDateType: string) {
  switch (selectedDateType) {
    case DateType.CUSTOM:
      return i18next.t('DASHBOARD.Between');
    case DateType.ALL:
      return i18next.t('DASHBOARD.Between');
    case DateType.CURRENT:
      return i18next.t('DASHBOARD.Current');
    case DateType.YESTERDAY:
      return i18next.t('COMPONENT.Yesterday');
    case DateType.DAYS7:
      return i18next.t('COMPONENT.Last 7 days');
    case DateType.MONTH1:
      return i18next.t('COMPONENT.Last 1 month');
    case DateType.TODAY:
      return i18next.t('COMPONENT.Today');
    case DateType.LAST_1_HOUR:
      return i18next.t('COMPONENT.Last 1 Hour');
    case DateType.LAST_3_HOURS:
      return i18next.t('COMPONENT.Last 3 Hours');
    case DateType.LAST_6_HOURS:
      return i18next.t('COMPONENT.Last 6 Hours');
    case DateType.LAST_12_HOURS:
      return i18next.t('COMPONENT.Last 12 Hours');
    default:
      return i18next.t('COMPONENT.Today');
  }
}
