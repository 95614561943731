import { useEffect } from 'react';
import { isEmpty, isEqual, get, mapValues, isArray } from 'lodash';
import { useMemoizedFn } from 'ahooks';

import { usePageState } from '../web-page.state';
import { TableActionState } from '../../../model';
import { getRandomId } from '../../../utilities/random-generator';

export interface ITableActionState {
  tableActionState: TableActionState;
  setTableActionState: any;
  setCurrentPage: (page: number) => void;
  setPagination: (pagination: any) => void;
  setSelectedRows: (selectedRows: any[]) => void;
  setExternalFilters: (externalFilters: any) => void;
  setInternalFilters: (internalFilters: any) => void;
  setFilters: (filters: any) => void;
  hasFiltered: () => boolean;
  refetch: () => void;
}

export function useTableActionState<TableActionState>(
  tabKey: string,
  componentKey: string,
  initValue?: TableActionState,
  isUnmount = false
): ITableActionState {
  const [tableActionState, setTableActionState] = usePageState<TableActionState>(
    `${tabKey}-${componentKey}`,
    'component-table-action-props',
    initValue,
    isUnmount
  );

  useEffect(() => {
    if (!initValue) {
      return;
    }
    if (!isEqual(initValue, tableActionState)) {
      setTableActionState({ ...initValue, ...tableActionState });
    }
  }, [initValue]);

  return {
    tableActionState: tableActionState || {
      ...initValue,
      ...{
        sort: undefined,
        filters: undefined,
        externalFilters: undefined,
        internalFilters: undefined, //
        total: undefined,
        currentPage: undefined, //
        pageSize: undefined, //
        selectedRows: undefined,
        refetchTrigger: undefined,
      },
    },
    setTableActionState: useMemoizedFn((actionState: TableActionState) => {
      setTableActionState((prev: any) => {
        if (get(actionState, 'filters') && !isEqual(get(actionState, 'filters'), prev?.filters)) {
          return {
            ...prev,
            ...actionState,
            selectedRows: undefined,
            currentPage: 0,
          };
        } else {
          return { ...prev, ...actionState };
        }
      });
    }),
    setCurrentPage: useMemoizedFn((page: number) => {
      setTableActionState((prev: any) => ({ ...prev, currentPage: page }));
    }),
    setPagination: useMemoizedFn((pagination: { currentPage?: number; pageSize?: number; total?: number }) => {
      setTableActionState((prev: any) => ({ ...prev, ...pagination }));
    }),
    setSelectedRows: useMemoizedFn((selectedRows: any[]) => {
      setTableActionState((prev: any) => ({ ...prev, selectedRows }));
    }),
    setExternalFilters: useMemoizedFn((externalFilters: any) => {
      setTableActionState((prev: any) => {
        if (isEqual(externalFilters, prev?.externalFilters)) {
          //console.log('useTableActionState setExternalFilters -- return');
          return prev;
        }
        return {
          ...prev,
          ...{
            currentPage: 0,
            internalFilters: undefined,
            filters: undefined,
            selectedRows: undefined,
            externalFilters: externalFilters ? { ...prev?.externalFilters, ...externalFilters } : undefined,
          },
        };
      });
    }),
    setInternalFilters: useMemoizedFn((internalFilters: any) => {
      setTableActionState((prev: any) => ({
        ...prev,
        selectedRows: undefined,
        currentPage: 0,
        internalFilters: { ...(prev?.internalFilters || {}), ...internalFilters },
      }));
    }),
    setFilters: useMemoizedFn((filters: any) => {
      setTableActionState((prev: any) => ({
        ...prev,
        selectedRows: undefined,
        currentPage: 0,
        filters: {
          ...(prev?.filters || {}),
          ...mapValues(filters, (value: any) => (isArray(value) ? value : [value])),
        },
      }));
    }),
    hasFiltered: useMemoizedFn((): boolean => {
      return !isEmpty(tableActionState?.internalFilters);
    }),
    refetch: useMemoizedFn(() => {
      setTableActionState((prev: any) => ({
        ...prev,
        refetchTrigger: getRandomId(),
      }));
    }),
  };
}
