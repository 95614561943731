import { isObject } from 'lodash';

import { ThemeBaseVariables, ThemeCommonVariables, ThemeCustomComponent } from '../types';
import { appendStyle } from './root-style.util';

const THEME_PREFIX = '--rt';

export function applyDynamicStyleTag(
  commonVariable: ThemeCommonVariables,
  baseVariable: ThemeBaseVariables,
  themeCustomComponent: ThemeCustomComponent
) {
  // [1] 2022, 2023 하위 호환성 common, base variables
  applyTheme('RUNTIME_THEME__BASE', { ...commonVariable, ...baseVariable });

  // [2] 2024 신규 variables;
  applyTheme('RUNTIME_THEME__CUSTOM', makeVariable(themeCustomComponent));
}

export function applyTheme(id: string, theme: any) {
  appendStyle({ id, cssStr: makeStyle(theme) });
  console.log(`> set ${id} theme`);
}

function makeStyle(theme: any) {
  let result = ':root { ';
  for (const [key, value] of Object.entries(theme)) {
    result = result.concat(`${key}: ${value};\n`);
  }
  result = result.concat(' }');
  return result;
}

function makeVariable(themeCustomComponent: ThemeCustomComponent) {
  const keysTemp: any = [];
  const result: any = {};
  let depth = 0;
  recursiveVariableInObject(keysTemp, result, themeCustomComponent, ++depth);
  console.log('> [theme] transfer custom components variables:', result);
  return result;
}

function recursiveVariableInObject(
  keysTemp: any,
  result: any,
  themeCustomComponent: ThemeCustomComponent,
  depth: number
) {
  const currentDepth = depth;
  for (const [key, value] of Object.entries(themeCustomComponent)) {
    if (isObject(value)) {
      if (keysTemp.length > 0 && currentDepth < depth) {
        const resetKeysTemp: any = [];
        keysTemp.forEach((key: string, idx: number) => {
          if (idx < currentDepth - 1) {
            resetKeysTemp.push(key);
          }
        });
        keysTemp = resetKeysTemp;
        depth = currentDepth;
      }
      keysTemp.push(key);
      // console.log('-- keysTemp:', keysTemp, ', depth:', depth, ', currentDepth:', currentDepth);
      recursiveVariableInObject(keysTemp, result, value, ++depth);
    } else {
      let variable = THEME_PREFIX;
      if (keysTemp.length) {
        keysTemp.forEach((key: string) => (variable = variable.concat(`-${key}`)));
      }
      variable = variable.concat(`-${key}`);

      result[variable] = value;
    }
  }
}
