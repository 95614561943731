import { Subject } from 'rxjs';

import { sendToChild } from '../../communication/iframe-host-to-child.sender';
import { sendToHost } from '../../communication/micro-app/iframe-child-to-host.sender';
import { GV_IFRAME_COMMAND } from '../../../model';
import { getFormsDirty } from '../../../state';

/**
 * get host -> child
 */
const asyncStateBridge = new Subject();

export function getMciroAppFormDirtyState(appName: string) {
  const sendCommand = {
    type: GV_IFRAME_COMMAND.CHECK_FORM_DIRTY,
    payload: appName,
  };
  sendToChild(appName, sendCommand);

  return asyncStateBridge.asObservable();
}

/**
 * host iframe: receive form dirty state
 */
export function receiveFormsDirtyState(appName: string, payload: boolean) {
  asyncStateBridge.next({ appName, dirty: payload });
}

/**
 * child iframe: send form dirty state
 */
export function sendFormsDirtyState() {
  const sendCommand = {
    type: GV_IFRAME_COMMAND.CHECK_FORM_DIRTY,
    payload: getFormsDirty(),
  };
  sendToHost(sendCommand);
}
