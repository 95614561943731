/* eslint-disable prefer-const */
import { useEffect, useState } from 'react';
import { find, isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { gvConfig } from '../../../config/config';
import { sendToHost } from '../../communication/micro-app/iframe-child-to-host.sender';
import { GV_IFRAME_COMMAND, GV_MICRO_APP } from '../../../model';
import { useAppMenus } from '../../../domain/hooks';
import { decodeQueryString } from '../../../utilities/query-util';
import { getRandomId } from '../../../utilities/random-generator';

/**
 * Use None UI
 */

export function getInitPageSubject() {
  if (!(window as any).GV_STATE_RX_initPageSubject) {
    (window as any).GV_STATE_RX_initPageSubject = new BehaviorSubject<any>(1);
  }
  return (window as any).GV_STATE_RX_initPageSubject;
}

export function getInitBridge() {
  if (!(window as any).GV_STATE_RX_initBridge) {
    (window as any).GV_STATE_RX_initBridge = {
      sendPayload: (payload: any) => getInitPageSubject().next(payload),
      getPayload: () => getInitPageSubject().asObservable(),
    };
  }
  return (window as any).GV_STATE_RX_initBridge;
}

// const initPageSubject = new BehaviorSubject<any>(1);
// const initPageBridge = {
//   sendPayload: (payload: any) => initPageSubject.next(payload),
//   getPayload: () => initPageSubject.asObservable(),
// };

export function initMicroAppWithInitPage(payload: any, isPageObj = false) {
  // console.log('>>> [iframe-child: init micro-app] app & page & params:', payload);
  // if there is page, it must be switching page of micro app with params
  let { page, app } = payload;
  if (page) {
    const { params } = payload;
    page.params = params;
  } else {
    // TODO: Not use this config for redirecting license check, if invalid or expired license.
    page = { name: gvConfig()['INIT_PAGE_KEY'] };
  }

  getInitBridge().sendPayload({
    app,
    page,
  });

  // init micro-app되었음으로 init page name 과 함께 전달한다.
  if (!isPageObj) {
    sendToHost({
      type: GV_IFRAME_COMMAND.INIT_MICRO_APP,
      payload: {
        microAppName: app.name,
        pageName: page.name,
      },
    });
  }
}

export function useInitMicroApp() {
  const [state, setState] = useState<any>(undefined);

  useEffect(() => {
    const subscription = getInitBridge().getPayload().subscribe((payload: any) => {
      if (!payload || typeof payload === 'number') {
        return;
      }

      const { page, app } = payload;
      // console.log('>> init micro app:', page, app);
      setState({ initPage: page, activeApp: app });
    });
    return () => subscription.unsubscribe();
  }, []);

  return state;
}

/**
 * Dev Mode
 */
export function useGetMicroAppInDevMode() {
  const { appMenus } = useAppMenus();
  const [createdMicroApp, setCreateMicroApp] = useState<any>(undefined);

  useEffect(() => {
    if (!appMenus?.length) {
      return;
    }

    // check browser querystring
    // example: http://localhost:4101/studio/?single=true&tabKey=studio.report
    const queryStrings = decodeQueryString();
    if (!isEmpty(queryStrings) && queryStrings['tabKey']) {
      let [pageName] = queryStrings['tabKey'].split(':');
      const names = pageName.split('.');
      const appName = names[0];

      // link를 타고 "code-health"로 들어올 경우
      // ref: https://dev.azure.com/Aidentyx/GVI/_wiki/wikis/GVI.wiki/374/Alarm-URL-link-Policy 참조
      if (appName === GV_MICRO_APP.DASHBOARD && names[1]?.indexOf('code-') >= 0) {
        const dashboardSubMenus = find(appMenus, { name: GV_MICRO_APP.DASHBOARD })?.subMenus;
        if (dashboardSubMenus) {
          const dashboardCode = names[1].split('-')[1];
          const dashboard = find(dashboardSubMenus, { code: dashboardCode });
          if (dashboard) {
            pageName = dashboard.name;
          }
        }
      }

      const microAppName = appName;
      const activeApp = appMenus.find((activeApp: any) => {
        return activeApp.name === microAppName;
      });
      if (activeApp) {
        // we should send app, page, params
        const payload: any = {
          key: `${microAppName}:${getRandomId()}`,
          activeApp,
          page: { name: pageName },
          // micro-app-sidebar-testbed.tsx에서 url의 init page 정보 필요
          initPage: { name: pageName, params: queryStrings },
          params: queryStrings,
        };
        setCreateMicroApp(payload);
      }
    } else {
      // set first app of appMenus
      const appMenu: any = {
        key: `${appMenus[0].name}:${getRandomId()}`,
        activeApp: appMenus[0],
        selectedKey: undefined,
        page: undefined,
        params: undefined,
      };
      // console.log('> create micro app:', appMenu);
      setCreateMicroApp(appMenu);
    }
  }, [appMenus]);

  return createdMicroApp;
}
