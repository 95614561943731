/* eslint-disable @typescript-eslint/no-unused-vars */
import { find, isEmpty } from 'lodash';

import { getAppMenus, getPage } from '../services/menu.service';
import { switchPage } from '../iframe/commands/switch-page.hook';
import { sendOneWaySubject } from '../hooks/tabkey/common-one-way-subject';
import { transferRealtimeFilterOptions } from '../filters/realtime-filter-options.service';
import { gotoMicroAppPage } from '../iframe/communication/micro-app/iframe-child-to-host.sender';
import { DateType, GV_APM_PAGE, IPageLink, KOSMOS_DETECTOR_PAGE, OPTIMA_AD_PAGE, TrendTab } from '../model';
import { getCurrentRealtimeState } from '../state';

export function gotoPageByLink(
  link: IPageLink | undefined,
  params: any = {},
  tabKey: string | null,
  isAddRealTime = true
) {
  if (!link) {
    return;
  }

  // location & realtime filter 내역을 querystring에 같이 실어 보낸다.
  if (tabKey && isAddRealTime) {
    const realtimeFilterOptions = transferRealtimeFilterOptions(getCurrentRealtimeState(tabKey));
    params = { ...realtimeFilterOptions, ...params };
  }

  const [appId, pageId, detailId] = link.pageId.split('.');
  if (
    (`${appId}.${pageId}` === GV_APM_PAGE.ASSET_DETAIL || `${appId}.${pageId}` === KOSMOS_DETECTOR_PAGE.ASSET_DETAIL) &&
    detailId
  ) {
    gotoAssetDetail(link.pageId, params, null);
  } else if (`${appId}.${pageId}` === GV_APM_PAGE.ALARM_DETAIL) {
    gotoAlarmGroupDetail(link.pageId, params, null);
  } else if (`${appId}.${pageId}` === GV_APM_PAGE.WORK_ORDER_DETAIL) {
    gotoWorkOrderDetail(link.pageId, params, null);
  } else {
    gotoMicroAppPage(appId, link.pageId, params);
  }
}

export function gotoManagementPageByLink(link: IPageLink | undefined, params: any = {}) {
  if (!link) {
    return;
  }

  const [appId, pageId] = link.pageId.split('.');
  gotoMicroAppPage(appId, link.pageId, params);
}

export function getLinks(links: IPageLink[], sequence = 1) {
  const elementLink: IPageLink | undefined = find(links, { sequence });
  return elementLink;
}

/**
 * goto direct asset detail
 */
export function gotoAssetDetail(link: string, params: any = {}, tabKey: string | null) {
  if (!link) {
    return;
  }

  // location & realtime filter 내역을 querystring에 같이 실어 보낸다.
  if (tabKey) {
    const realtimeFilterOptions = transferRealtimeFilterOptions(getCurrentRealtimeState(tabKey));
    params = { ...realtimeFilterOptions, ...params };
  }

  // apm.detail.overview, trend, alarm
  // ad.detail.overview, parameter, alarm
  const [appId, pageId, detailId] = link.split('.');
  params.detailPage = detailId;
  params.detailSubPage = params.programicalDetailSubPage
    ? params.programicalDetailSubPage
    : params.paramId
      ? TrendTab.PARAMETER
      : TrendTab.FAVORITE;
  // if (detailId === GV_ASSET_TREND) {
  //   params.detailSubPage = params.paramId ? TrendTab.PARAMETER : TrendTab.FAVORITE;
  // }
  gotoMicroAppPage(appId, link, params);
}

/**
 * goto direct asset detail
 */
export function gotoAlarmGroupDetail(link: string, params: any = {}, tabKey: string | null) {
  if (!link) {
    return;
  }

  // location & realtime filter 내역을 querystring에 같이 실어 보낸다.
  if (tabKey) {
    const realtimeFilterOptions = transferRealtimeFilterOptions(getCurrentRealtimeState(tabKey));
    params = { ...realtimeFilterOptions, ...params };
  }

  // asset.alarm-detail
  const [appId, pageId] = link.split('.');
  gotoMicroAppPage(appId, link, params);
}

export function gotoWorkOrderDetail(link: string, params: any = {}, tabKey: string | null) {
  if (!link) {
    return;
  }

  // location & realtime filter 내역을 querystring에 같이 실어 보낸다.
  if (tabKey) {
    const realtimeFilterOptions = transferRealtimeFilterOptions(getCurrentRealtimeState(tabKey));
    params = { ...realtimeFilterOptions, ...params };
  }

  // asset.alarm-detail
  const [appId, pageId] = link.split('.');
  gotoMicroAppPage(appId, link, params);
}

export function gotoAlarmGroupDetailFromPortal(link: string, params: any = {}) {
  gotoMicroAppPageFromPortal(link, params);
}

export function gotoMicroAppPageFromPortal(link: string, params: any = {}) {
  if (!link) {
    return;
  }
  const [appId, pageId] = link.split('.');
  const payload = {
    microAppName: appId,
    pageName: link,
    params,
  };
  switchPage(appId, payload);
}

/**
 * goto global config
 */
export function gotoGlobalConfig(link: string, params: any = {}) {
  // TODO: link 내용 정의
  const [appId] = link.split('.');
  gotoMicroAppPage(appId, link, params);
}

export function changeSubTabInAssetDetail(
  tabKey: string,
  detailPage:
    | { detailPage: 'overview' | 'trend' | 'data' | 'parameter' | 'alarm' }
    | {
      detailPage?: 'overview' | 'trend' | 'data' | 'parameter' | 'alarm';
      assetId?: string;
      selectedDateType: DateType;
      start: string;
      end: string;
    },
  instanceLinkData?: any
) {

  // [1] asset detail의 sub tab을 변경해 주고, params를 전달하는 역할
  sendOneWaySubject(tabKey, 'active-asset-detail', detailPage);

  // [2] asset detail의 sub tab변경시 한번만 데이터를 instance성으로 전달하고 싶을 경우, sub tab DOM destroy시에 상태값은 유지되지 않는 방식.
  if (instanceLinkData && !isEmpty(instanceLinkData)) {
    sendOneWaySubject(tabKey, 'active-asset-detail-instance-data', instanceLinkData);
  }
}

/**
 * 최대 4 depth menu 까지 검색
 */
export function hasInBoundWithAssetId(links: IPageLink[]) {
  const appMenus = getAppMenus();
  if (!appMenus || !appMenus.length) {
    return false;
  }

  const link = getLinks(links);
  if (!link || !link.pageId) {
    return false;
  }

  const app = find(appMenus, { name: link.pageId.split('.')[0] });
  const menu = getPage(app, link.pageId);
  if (menu && menu.inbound && menu.inbound.includes('assetId')) {
    return true;
  }
  return false;
}
