import { ThemeConfig, theme } from "antd";

import { getThemeModeFromLS } from "../config/personalization-config.service";
import { GV_MICRO_APP } from "../model";
// import { merge } from "lodash";

// const { getDesignToken, useToken, darkAlgorithm, compactAlgorithm } = theme;
const { getDesignToken, darkAlgorithm, defaultAlgorithm } = theme;

/**
 * Ref: https://ant.design/docs/react/customize-theme, 둘 다 같은 객체 -> typescript에서 themeConfig 접근하는 방법
 */
export function parseThemeAntDConfig(themeConfig: ThemeConfig = (window as any).GV_THEME_ANTD) {
  return themeConfig;
}

export function applyDarkAlogrithmByApplication(themeConfig: ThemeConfig = (window as any).GV_THEME_ANTD, mode = getThemeModeFromLS(), appName: GV_MICRO_APP | string | undefined = '') {
  /**
   * TODO: 향후 mode 적용한 애플리케이션을 점진적으로 늘린다.
   * step-1) portal <- 5월말
   * step-2) dashboard
   * step-3) apm
   * step-4) ameta, system,
   * step-5) studio
   */
  if (appName !== GV_MICRO_APP.PORTAL && appName !== GV_MICRO_APP.DASHBOARD) {
    return themeConfig;
  }

  themeConfig.algorithm = getCurrentAntDAlgorithm(mode);
  return themeConfig;
}

export function getCurrentAntDAlgorithm(mode = getThemeModeFromLS()) {
  return mode === 'dark' ? darkAlgorithm : defaultAlgorithm;
}

export function getDefaultAlgorithmAntDAlgorithm() {
  return defaultAlgorithm;
}


export function getThemeAntDToken(config: any = {}) {
  return getDesignToken(config);
}

export function getPrimaryColorByTheme() {
  return getThemeAntDToken(applyDarkAlogrithmByApplication())?.colorPrimary;
}

export function setAntDAllModeConfig(antdAllModeConfig: any) {
  (window as any).GV_THEME_ANTD_ALL_MODE = antdAllModeConfig;
}

export function getThemeAntDByModeType(mode = getThemeModeFromLS()) {
  return (window as any).GV_THEME_ANTD_ALL_MODE?.[mode];
}

