import { DateType, HierarchyType, PageCreateType, SideMenuType } from '../types';

export enum PageContext {
  THEME = 'THEME',
}

export interface IGVPageMenu {
  content: any;
  name?: string;
  key: string;
  title?: string; // translate name
  icon?: any;
  theme?: any;
  realtimeFilterUsage?: 'enable' | 'disabled';
  healthStatusFilterUsage?: 'enable' | 'disabled';
  hierarchyFilterUsage?: 'enable' | 'disabled';
  initParams?: any;
  code?: string; // for dashboard
  menuKey?: string;
  hidden?: boolean;
  createTabType?: PageCreateType; // default multi like dashboard, sidebar 클릭할 때 동일 페이지여도 tab이 생김
  sidebarMenuType?: SideMenuType; // default single, sidebar에 하나의 아이콘만 생성, asset detail의 경우는 asset detail tab 생길 때 마다 sidebar에 icon 생김
}

export interface IGVAppMenu {
  key: string;
  selectedKey?: string;
  app?: any;
  page?: any;
  params?: any;
}

export interface IGVCommand {
  command: string;
  payload: any;
}

export interface IGVHistory {
  app?: any;
  page?: any;
}

// Not use it
export interface IPageParam {
  pageKey: string;
  initParams?: {
    assetId?: string;
    detailPage?: string;
    detailSubPage?: string;
  };
}

export interface IStatusCause {
  name: string;
  id: string;
  healthIndex: number;
  modelType?: string;
  eventTime?: string;
  lowerLimit?: number;
  upperLimit?: number;
  aggregationType?: string;
}

// use host in portal
export interface ISwitchPage {
  callerMicroAppName: string;
  microAppName: string;
  pageName: string;
  params?: any;
}

export interface ISelectedData {
  value: any;
  text: string;
  extra?: any | undefined;
}

export const ROLE = {
  ALARM_SEVERITY: 'ALARM_SEVERITY',
  ASSET_ACTIVITY_RUNNING: 'ASSET_ACTIVITY_RUNNING',
  ASSET_ACTIVITY_AUTOMATIC: 'ASSET_ACTIVITY_AUTOMATIC',
  PARAM: 'PARAM',
  ASSET: 'ASSET',
  KPI: 'KPI',
  MODEL_PARAM_TYPE: 'MODEL_PARAM_TYPE',
  LOCATION: 'LOCATION',
  COUNTRY: 'COUNTRY',
  ALARM_CRITERIA: 'ALARM_CRITERIA',
  ALARM_TARGET: 'ALARM_TARGET',
  ALARM_RULE: 'ALARM_RULE',
  ALARM_THRESHOLD_TREND: 'ALARM_THRESHOLD_TREND',
  ALARM_THRESHOLD_PARAMETER: 'ALARM_THRESHOLD_PARAMETER',
  ALARM_RULE_CONDITION: 'ALARM_RULE_CONDITION',
  ALARM_ACTION: 'ALARM_ACTION',
  ALARM_ACTION_CLOSE: 'ALARM_ACTION_CLOSE',
  WORK_TYPE: 'WORK_TYPE',
  WORK_ACTION: 'WORK_ACTION',
  RUL: 'RUL',
  RUL_SUM_UNIT: 'RUL_SUM_UNIT',
  RUL_REF_UNIT: 'RUL_REF_UNIT',
  WORK_STATUS: 'WORK_STATUS',
  WORK_COMMENT: 'WORK_COMMENT',
  WORK_PRIORITY: 'WORK_PRIORITY',
  USER_STATUS: 'USER_STATUS',
  PERM: 'PERM',
  RUL_MODEL_OPTION: 'RUL_MODEL_OPTION',
  CURRENT_MODEL_OPTION: 'CURRENT_MODEL_OPTION',
  MVA_MODEL_INFO_OPTION: 'MVA_MODEL_INFO_OPTION',
  MVA_MODEL_TRAINING_OPTION: 'MVA_MODEL_TRAINING_OPTION',
  ML_MODEL_INFO_OPTION: 'ML_MODEL_INFO_OPTION',
  ML_MODEL_TRAINING_OPTION: 'ML_MODEL_TRAINING_OPTION',
  VIBRATION_MODEL_COM_OPTION: 'VIBRATION_MODEL_COM_OPTION',
  VIBRATION_MODEL_VEL_OPTION: 'VIBRATION_MODEL_VEL_OPTION',
  VIBRATION_MODEL_ENV_OPTION: 'VIBRATION_MODEL_ENV_OPTION',
  VIBRATION_MODEL_ACC_OPTION: 'VIBRATION_MODEL_ACC_OPTION',
  HIERARCHY_SETTING: 'HIERARCHY_SETTING',
  HEALTH_CRITERIA: 'HEALTH_CRITERIA',
  DATA_INTERVAL_UNIT: 'DATA_INTERVAL_UNIT',
  // Optima AD
  SPC_RULE: 'SPC_RULE',
  FDC_RULE: 'FDC_RULE',
  PARAM_CATEGORY: 'PARAM_CATEGORY',
  PARAM_DATA: 'PARAM_DATA',
  PLUGIN_TYPE: 'PLUGIN',
  AD_ALARM_LEVEL: 'AD_ALARM_LEVEL',
  VIBRATION_TYPE: 'VIBRATION_TYPE',
  VIBRATION_FC: 'VIBRATION_FC',
};
export type ROLE = (typeof ROLE)[keyof typeof ROLE];

export const STATUS_ROLE = {
  RUL: 'RUL',
  BATCH: 'BATCH',
  HEALTH: 'HEALTH_INDEX',
  HEALTH_INDEX: 'HEALTH_INDEX',
  ALARM: 'ALARM_SEVERITY',
  ACTIVITY: 'RUNNING_STATUS',
  TRAINING_DAY: 'TRAINING_DAY',
  SENSOR: 'SENSOR',
  PARAM_AUTO_SIGMA: 'PARAM_AUTO_SIGMA',
  // for front status
  ALARM_ACTION: 'ALARM_ACTION',
  ALARM_ACTION_CLOSE: 'ALARM_ACTION_CLOSE',
  WORK_STATUS: 'WORK_STATUS',
  WORK_ACTION: 'WORK_ACTION',
  WORK_TYPE: 'WORK_TYPE',
  SPEC_LEVEL: 'SPEC_LEVEL',
  ALARM_SEVERITY: 'ALARM_SEVERITY',
  AD_ALARM_LEVEL: 'AD_ALARM_LEVEL',
};
export type STATUS_ROLE = (typeof STATUS_ROLE)[keyof typeof STATUS_ROLE];

export const NONE = 'N/A';

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
};
export type SORT_DIRECTION = (typeof SORT_DIRECTION)[keyof typeof SORT_DIRECTION];

export const ANTD_SORT_DIRECTION = {
  ASC: 'ascend',
  DESC: 'descend',
};
export type ANTD_SORT_DIRECTION = (typeof ANTD_SORT_DIRECTION)[keyof typeof ANTD_SORT_DIRECTION];

export const LIST_TYPE = {
  SELECT: 'select',
  FILTER: 'filter',
};
export type LIST_TYPE = (typeof LIST_TYPE)[keyof typeof LIST_TYPE];

export enum InputType {
  INPUT = 'input',
  SELECT = 'select',
  MULTI_SELECT = 'multiSelect',
}

export enum DisplayType {
  TABLE = 'TABLE',
  CHART = 'CHART',
}

export enum EmptyType {
  NO_PAGE = 'NO_PAGE',
  NO_IMAGE = 'NO_IMAGE',
  NO_DATA = 'NODATA',
  NO_RESULT = 'NO_RESULT',
  ERROR = 'ERROR',
}

export enum EmptySize {
  DEFAULT = 'DEFAULT',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export interface InitRealtimeFilterState {
  isRealtime?: boolean;
  selectedDateType?: DateType;
  location?: any;
  realtimeOption?: any;
  periodOption?: any;
}

export interface AssetHierarchy {
  id: string;
  name?: string;
  path?: string;
  type: HierarchyType;
  extra?: { count?: number; templateId?: string; templateName?: string };
}
