import dayjs, { Dayjs } from 'dayjs';
import { timeFormatSecond } from './time-format-locale';

// export function initTimeZoneForTzFunction() {
//   timezoneService.tenantTimeZone = timezoneService.getTimezone() || 'UTC';
//   dayjs.tz.setDefault(timezoneService.tenantTimeZone);
// }

class TimeZoneService {
  tenantTimeZone: string;
  // support browser
  // https://caniuse.com/internationalization
  // https://attacomsian.com/blog/javascript-current-timezone
  currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor() {
    this.tenantTimeZone = 'UTC';
  }

  setTenantTimeZone(tenantTz: string) {
    this.tenantTimeZone = tenantTz;
  }

  utcStringToString(value: string, fullFormat = timeFormatSecond()): string {
    if (value) {
      return dayjs(value).utc().local().format(fullFormat);
    }
    return '';
  }

  utcStringToDate(value: string): Date {
    if (value) {
      return dayjs(value).utc().local().toDate();
    }
    return new Date();
  }

  stringToUtcString(value: any, format?: string): string {
    if (value) {
      return format ? dayjs(value).utc().format(format) : dayjs(value).utc().format().replace('+00:00', 'Z');
    }
    return '';
  }

  dateToUtcString(value: Date, format?: string): string {
    if (value) {
      return format ? dayjs(value).utc().format(format) : dayjs(value).utc().format().replace('+00:00', 'Z');
    }
    return '';
  }

  dateToUtcStringReplace(value: Date, fullFormat = ''): string {
    if (value) {
      return dayjs(value).utc().format().replace('Z', '.000Z');
    }
    return '';
  }

  intervalsToUtc(intervals: string, fullFormat = ''): string {
    if (intervals && intervals.length > 0) {
      const start = intervals.split('/')[0];
      const end = intervals.split('/')[1];
      const cStart = dayjs(start).utc().format(fullFormat);
      const cEnd = dayjs(end).utc().format(fullFormat);
      return cStart + '/' + cEnd;
    }
    return '';
  }

  /**
   * Get changed intervals
   *
   * @param start
   * @param end
   */
  changeIntervals(start: any, end: any) {
    return `${this.stringToUtcString(start)}/${this.stringToUtcString(end)}`;
  }

  changeIntervalsByLocal(start: any, end: any) {
    return `${dayjs(start).format('YYYY-MM-DD HH:mm:ss')}/${dayjs(end).format('YYYY-MM-DD HH:mm:ss')}`;
  }

  /**
   * Change UTC Format
   *
   * @param value
   */
  getUTCFormat(value: any) {
    if (!value) {
      return null;
    }
    return this.stringToUtcString(dayjs(value).format('YYYY-MM-DD HH:mm'));
  }

  changePeriodsUTCTime(periods: any) {
    if (periods) {
      return periods.map((period: any) => {
        return this.intervalsToUtc(period);
      });
    }
  }

  /**
   * ref list
   * https://codepen.io/spersico/pen/BaLVwKY
   * 
   * @returns 
   */
  getTimezone() {
    return dayjs.tz.guess();
  }

  add(date: any, gap: number, unit: any = 'd', utc = true, format = null): Dayjs | string {
    let d: Dayjs | string = dayjs(date || dayjs()).add(gap, unit);
    if (utc) {
      d = d.utc();
    }
    if (format) {
      d = d.format(format);
    }
    return d;
    // return dayjs(date || dayjs())
    //   .add(gap, unit)
    //   .utc()
    //   .format();
  }
}

// It is singleton object
// Singleton Objet: https://www.digitalocean.com/community/tutorials/js-js-singletons
// You can use Object.freeze(instance) - ES6
export const timezoneService = new TimeZoneService();
